import { memo } from "react";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { noop } from "utils";

import ToolbarButton from "../../base/ToolbarButton";

const FullscreenToggle = () => {
  const handleToggle = noop;

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <ToolbarButton
      key={"FullscreenToggle"}
      icon={<FullscreenIcon fontSize="large" />}
      activeIcon={<FullscreenExitIcon fontSize="large" />}
      active={false}
      overflowLabel="Fullscreen"
      tooltipText="Toggle Fullscreen"
      onToggle={handleToggle()}
    />
  );
};

export default memo(FullscreenToggle);
