import { memo } from "react";

import BugReportIcon from "@material-ui/icons/BugReport";
import { IS_DEV_ENV } from "constants/app.constants";

import { ToolbarButton } from "components/base";
import { useChartSettings } from "components/multiphase-chart/context";
import { changeSettings } from "components/multiphase-chart/context/reducer/chartSettingsReducer";

const DevDebugToggle = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const onToggle = (value) => {
    const nextSettings = { ...chartSettings.settings, debugMode: value };
    changeSettings(chartSettingsDispatch, nextSettings);
  };

  if (!IS_DEV_ENV) {
    return null;
  }

  return (
    <ToolbarButton
      testId="multiphasechart-toolbar-debug-toggle"
      icon={<BugReportIcon fontSize="large" />}
      overflowLabel="Debug Chart Options"
      tooltipText="Debug Chart Options"
      active={chartSettings.settings?.debugMode}
      onToggle={onToggle}
    />
  );
};

export default memo(DevDebugToggle);
