import { LineSeriesOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";

export const DEFAULT_LINE_CHART_OPTIONS: Partial<LineSeriesOption> = {
  name: "",
  type: "line",
  showSymbol: false,
  animation: false,
  blendMode: "source-over",
  lineStyle: { width: 2, type: "solid", opacity: 1 },
  z: 2,
  symbol: "circle",
  symbolSize: 5,
  emphasis: {
    focus: "series",
    lineStyle: {
      width: 6,
      opacity: 1
    }
  },
  blur: {
    lineStyle: {
      opacity: 0.5
    }
  }
};

export const generateLineSeriesOptions = createStateWithDefaults(
  DEFAULT_LINE_CHART_OPTIONS
);
