import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "antd";
import { TimeStep } from "constants/charts.enums";
import { setDataSourceSetting } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { TimeScaleSelect, TimeStepIcon } from "components/shared";

const TimeStepSelect: FC = () => {
  const dispatch = useDispatch();

  // state from store
  const dataSourceSetting = useSelector(
    (state: RootState) => state.app.pdenDataSourceSetting
  );

  function handleTimeStepChange(value: TimeStep): void {
    const nextSourceSetting = {
      ...dataSourceSetting,
      timeStep: value
    };

    dispatch(setDataSourceSetting(nextSourceSetting));
  }

  return (
    <TimeScaleSelect
      trigger={["click"]}
      value={dataSourceSetting.timeStep}
      onChange={handleTimeStepChange}
      isDataSourcePublic={dataSourceSetting.source === "Public"}
      isDataSourcePrivate={dataSourceSetting.source === "Private"}>
      <StyledButton icon={TimeStepIcon[dataSourceSetting.timeStep]} />
    </TimeScaleSelect>
  );
};

const StyledButton = styled(Button)`
  background: "transparent";
  color: #a2aaad;
  &:hover {
    color: "var(--color-text)";
  }
`;

export default TimeStepSelect;
