import { BinType, QuantileState } from "./groupBy";

export class mBinSize {
  BinSize: number | "";
  LessThan: number | "" | null;
  GreaterThan: number | "" | null;
  MinSize: number | "";
  MaxBins?: number | "";
  MaxBinsSortOrder?: "WellCount" | "Production";
  Quantile?: QuantileState;
  BinType: BinType = "BinSize";
  IsLocked?: boolean = false;
  UseDynamicBins?: boolean = false;

  constructor(MinSize, LessThan, BinSize, GreaterThan) {
    this.BinSize = BinSize;
    this.LessThan = LessThan;
    this.GreaterThan = GreaterThan;
    this.MinSize = MinSize;
  }
}

export enum MaxBinsSortOrderEnum {
  WellCount = "WellCount",
  Production = "Production"
}

export interface BinSize {
  BinSize: Nullable<number>;
  LessThan: Nullable<number>;
  GreaterThan: Nullable<number>;
  MinSize: Nullable<number>;
  MaxBins?: Nullable<number>;
  MaxBinsSortOrder?: MaxBinsSortOrderEnum;
  Quantile?: QuantileState;
  BinType: BinType;
  IsLocked?: boolean;
  UseDynamicBins?: boolean;
}
