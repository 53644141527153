import { Icon } from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Palette, ShopTwo } from "@material-ui/icons";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import WorkIcon from "@material-ui/icons/Work";
import { mdiCalculator, mdiLayers } from "@mdi/js";
import {
  setActiveSetting,
  setUserSettingsOpen
} from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { useUser } from "hooks";
import useBetaFeatures from "hooks/useBetaFeatures";

import { BaseButton, SubHeading } from "components/base";
import { UserArps } from "components/icons";
import AccountSecurity from "components/icons/AccountSecurity";
import DataSource from "components/icons/DataSource";
import DisplaySettings from "components/icons/DisplaySettingsIcon";

import "./UserSettingSideNav.scss";

const useStyles = makeStyles({
  navItems: {
    fontSize: "1.2em",
    textAlign: "left",
    display: "inline"
  }
});

export default function UserSettingSideNav() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAtLeastPowerUser } = useUser();
  const { hasFeature } = useBetaFeatures();
  const activeSetting = useSelector(
    (state: RootState) => state.userSetting.activeSetting
  );

  return (
    <div className="UserSettingSideNav">
      <ul className="settings-nav">
        <li className="back-dashboard">
          <BaseButton
            appearance="subtle"
            className={classes.navItems}
            onClick={() => dispatch(setUserSettingsOpen(false))}>
            <div>
              <KeyboardBackspaceIcon style={{ fontSize: "24px" }} />
            </div>
            Back to dashboard
          </BaseButton>
        </li>
        <div className="settings-section">
          <SubHeading>General</SubHeading>
          <li
            className={`settings-nav-item ${
              activeSetting === "Profile" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("Profile"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <AccountBoxIcon style={{ fontSize: "24px" }}></AccountBoxIcon>
                </IconWrapper>
                <span>Profile</span>
              </div>
            </BaseButton>
          </li>
          <li
            className={`settings-nav-item ${
              activeSetting === "AccountSecurity" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("AccountSecurity"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <AccountSecurity></AccountSecurity>
                </IconWrapper>
                <span>Account & Security</span>
              </div>
            </BaseButton>
          </li>
        </div>
        <div className="settings-section">
          <SubHeading>Integrations</SubHeading>
          <li
            className={`settings-nav-item ${
              activeSetting === "DataSource" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("DataSource"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <DataSource></DataSource>
                </IconWrapper>
                <span>Data Source</span>
              </div>
            </BaseButton>
          </li>
          <li
            className={`settings-nav-item ${
              activeSetting === "ThirdPartyIntegration" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("ThirdPartyIntegration"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <ShopTwo style={{ fontSize: "20px" }} />
                </IconWrapper>
                <span>Third Party</span>
              </div>
            </BaseButton>
          </li>
        </div>
        <div className="settings-section">
          <SubHeading>Configuration</SubHeading>
          <li
            className={`settings-nav-item ${
              activeSetting === "WorkspacesList" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("WorkspacesList"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <WorkIcon style={{ fontSize: "20px" }}></WorkIcon>
                </IconWrapper>
                <span>Workspaces</span>
              </div>
            </BaseButton>
          </li>
          <li
            className={`settings-nav-item ${
              activeSetting === "DeclineSettings" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("DeclineSettings"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <UserArps />
                </IconWrapper>

                <span>Decline Settings</span>
              </div>
            </BaseButton>
          </li>
          {isAtLeastPowerUser && (
            <li
              className={`settings-nav-item ${
                activeSetting === "OrganizationShapefiles" ? "active" : ""
              }`}>
              <BaseButton
                appearance="subtle"
                className={classes.navItems}
                onClick={() => dispatch(setActiveSetting("OrganizationShapefiles"))}>
                <div className="inline-flex">
                  <IconWrapper>
                    <Icon path={mdiLayers} size={1.5} />
                  </IconWrapper>
                  <span>Organization Shapefiles</span>
                </div>
              </BaseButton>
            </li>
          )}
          {isAtLeastPowerUser && hasFeature("Org Geo Fields") && (
            <li
              className={`settings-nav-item ${
                activeSetting === "OrganizationGeoCalculationJobs" ? "active" : ""
              }`}>
              <BaseButton
                appearance="subtle"
                className={classes.navItems}
                data-testid={"geo-model-calculations-navigation"}
                onClick={() =>
                  dispatch(setActiveSetting("OrganizationGeoCalculationJobs"))
                }>
                <div className="inline-flex">
                  <IconWrapper>
                    <Icon path={mdiCalculator} size={1.5} />
                  </IconWrapper>
                  <span>Geo Model Calculations</span>
                </div>
              </BaseButton>
            </li>
          )}
        </div>
        <div className="settings-section">
          <SubHeading>Preferences</SubHeading>
          <li
            className={`settings-nav-item ${
              activeSetting === "DisplaySettings" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("DisplaySettings"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <DisplaySettings></DisplaySettings>
                </IconWrapper>
                <span> Display Settings</span>
              </div>
            </BaseButton>
          </li>
          <li
            className={`settings-nav-item ${
              activeSetting === "UserDefaults" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("UserDefaults"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <SettingsBackupRestoreIcon
                    style={{ fontSize: "20px" }}></SettingsBackupRestoreIcon>
                </IconWrapper>
                <span>Defaults</span>
              </div>
            </BaseButton>
          </li>
          <li
            className={`settings-nav-item ${
              activeSetting === "ColorPalette" ? "active" : ""
            }`}>
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setActiveSetting("ColorPalette"))}>
              <div className="inline-flex">
                <IconWrapper>
                  <Palette style={{ fontSize: "20px" }} />
                </IconWrapper>
                <span>Colour Palettes</span>
              </div>
            </BaseButton>
          </li>
        </div>
        {/*<div className="settings-section">
          <SubHeading>Support</SubHeading>
          <li
            className={`settings-nav-item ${
              activeSetting === "/product-tour" ? "active" : ""
            }`}
          >
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => goTo("/product-tour")}
            >
              <div className="inline-flex">
                <SchoolIcon style={{ fontSize: "24px" }}></SchoolIcon>
                <span>
                  Product Tour <Launch></Launch>
                </span>
              </div>
            </BaseButton>
          </li>
          </div>*/}
      </ul>
    </div>
  );
}

const IconWrapper = styled.div`
  min-width: 30px;
`;
