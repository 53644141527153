import merge from "lodash-es/merge";

/**
 * Recursively merges two objects
 */
function deepMerge<T extends GenericObject, U extends GenericObject>(
  target: T,
  source: U
): T & U {
  return merge({}, target, source);
}

type AllowedKeys = {
  [key: string]: AllowedKeys | Set<string>;
};

// A type guard for plain objects (excluding arrays and null).
function isObject(value: unknown): value is GenericObject {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

/**
 * Recursively builds an AllowedKeys structure from an object
 * For non-object values, returns a Set containing the key
 */
function getAllowedKeys(obj: unknown): AllowedKeys {
  if (!isObject(obj)) {
    throw new Error("Input must be an object");
  }
  return Object.keys(obj).reduce<AllowedKeys>((acc, key) => {
    if (isObject(obj[key])) {
      acc[key] = getAllowedKeys(obj[key]);
    } else {
      acc[key] = new Set([key]);
    }
    return acc;
  }, {});
}

/**
 * Recursively sanitizes an object by only including keys that exist in the allowedKeys structure
 * For object values with nested allowed keys, it recurses; otherwise, it copies the value
 */
function sanitizeObject<T extends GenericObject>(
  obj: T,
  allowedKeys: AllowedKeys
): Partial<T> {
  return Object.keys(obj).reduce((acc, key) => {
    const typedKey = key as keyof T; // Cast key to a keyof T
    const allowed = allowedKeys[key];
    if (allowed) {
      if (isObject(obj[typedKey]) && !(allowed instanceof Set)) {
        acc[typedKey] = sanitizeObject(
          obj[typedKey] as GenericObject,
          allowed
        ) as T[typeof typedKey];
      } else if (allowed instanceof Set) {
        acc[typedKey] = obj[typedKey];
      }
    }
    return acc;
  }, {} as Partial<T>);
}

// This function creates a factory function for generating state objects with default properties.
export function createStateWithDefaults<T extends GenericObject>(defaultState: T) {
  // The returned function takes an optional 'overrides' parameter.
  // 'overrides' is a partial object of type T, meaning it can contain any subset of T's properties.
  return (overrides: Partial<T> = {}): T => {
    // The function merges 'defaultState' with 'overrides'
    // Properties in 'overrides' will overwrite those in 'defaultState'.
    return { ...defaultState, ...overrides };
  };
}

/**
 * Creates a merge function that uses defaultState as the base.
 * Only allowed keys from defaultState will be merged in.
 */
export function createMergeWithDefaults<T extends GenericObject>(defaultState: T) {
  const allowedKeys = getAllowedKeys(defaultState);
  return (overrides: Partial<T> = {}): T => {
    const sanitizedOverrides = sanitizeObject(overrides, allowedKeys);
    return deepMerge(defaultState, sanitizedOverrides) as T;
  };
}
