import { useDispatch, useSelector } from "react-redux";

import { setShapefileFeatures } from "store/features";
import { RootState } from "store/rootReducer";

export const useCheckShapefileFeatureList = () => {
  const dispatch = useDispatch();

  const shapefileFeatures = useSelector(
    (state: RootState) => state.project.shapefileFeatures
  );
  const selectedShapefile = useSelector(
    (state: RootState) => state.project.selectedShapefile
  );

  const currentShapefileFeatureProperty = useSelector(
    (state: RootState) => state.project.currentShapefileFeatureProperty
  );

  const update = (
    action: "checkAll" | "uncheckAll" | "invertChecks",
    searchText: string
  ) => {
    const currentFeatures = shapefileFeatures[selectedShapefile.key];

    const features = {
      [selectedShapefile.key]: {
        ...currentFeatures,
        features: currentFeatures?.features.map((feature) => {
          // Only apply the action to features that match the search text
          // Don't apply the action to features that have an empty title (while being searched)
          if (searchText) {
            const featureProperty: string =
              feature.Properties[currentShapefileFeatureProperty]?.toString();

            if (!featureProperty?.includes(searchText)) {
              return feature;
            }
          }

          let checked: boolean;
          if (action === "checkAll") {
            checked = true;
          } else if (action === "uncheckAll") {
            checked = false;
          } else if (action === "invertChecks") {
            checked = !feature.checked;
          }
          return {
            ...feature,
            checked
          };
        })
      }
    };
    dispatch(setShapefileFeatures({ ...shapefileFeatures, ...features }));
  };

  return { update };
};
