import { lazy } from "react";
import { useDispatch } from "react-redux";

import classnames from "classnames";
import { EntityType } from "constants/entities.enum";
import { ChartsProvider } from "contexts/ChartContext";
import { createEntityState } from "entities/charts/factory";
import { createChart } from "store/features/charts/chartSlice";
import styled from "styled-components/macro";
import { retry } from "utils";

import { EntityKind } from "models/entityKind";

import { ErrorBoundary } from "components/base";

import ChartLegend from "../legend/Legend";
import ChartAxis from "./ChartAxis";
import ChartToolbar from "./ChartToolbar";
import "./ChartWrapper.scss";
import Screenshot from "./Screenshot";
import WellCountToggle from "./WellCountToggle";

const Chart = lazy(() => retry(() => import("./Chart")));

function ChartWrapper({ id, className = "", type = EntityKind.Well }) {
  const rootClassnames = classnames("chart-wrapper", className);
  const screenshotContainerId = `screenshot-${id}`;
  const dispatch = useDispatch();

  dispatch(
    createChart({
      id,
      ...createEntityState(EntityType.Chart, { id })
    })
  );

  return (
    <ChartsProvider id={id}>
      <ErrorBoundary>
        <Wrapper
          // ref={wrapperRef} // for useChartSize
          className={rootClassnames}
          data-testid="chartWrapper">
          <ChartContainer>
            <Chart />
          </ChartContainer>

          <ChartToolbar />

          {false && <ChartLegend />}

          {false && (
            <>
              <ChartAxis />
              <ChartAxis />
            </>
          )}

          <Screenshot containerId={screenshotContainerId} />

          {false && <WellCountToggle type={type} />}

          {/* <ContextMenu
          allow={lassoContextMenuAllowed}
          visible={lassoContextMenuVisible && lasso}
          position={brushEndPosition}
          content={<AddToFilterButton />}
        />

        <ContextMenu
          allow={copyUWIContextMenuAllowed}
          visible={contextMenuVisible}
          position={contextMenuPosition}
          content={<CopyUWIButton uwi={uwiRef.current} />}
        /> */}
        </Wrapper>
      </ErrorBoundary>
    </ChartsProvider>
  );
}

export default ChartWrapper;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  border-radius: var(--border-radius);
  background-color: "inherit";
  box-shadow: rgba(var(--color-shadow-rgb), 0.2) 0 0 1px;
  padding-top: 10px;
  z-index: 0;
  background: "";

  &.hovered {
    box-shadow: rgba(var(--color-shadow-rgb), 0.4) 0 0 1px;
  }

  &.fullscreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--index-fullscreen);
  }
`;

const ChartContainer = styled.div`
  justify-self: ${(props) => (props.screenshot ? "center" : "auto")};

  &:hover {
    z-index: 1;
  }
`;
