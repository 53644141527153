import { useQuery } from "react-query";

import axios from "axios";

import { FORECASTING_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { ForecastWellStatus, ForecastingChangeRecord } from "../models/forecastWell";

const API_URL = `${FORECASTING_SERVICE_ADDRESS}/api/v1/well`;

export const FetchAuditForecastQuery = "fetchAuditForecast";

const fetchAuditForecast = async (params) => {
  const uwid: string = params.queryKey[1];
  const status: string = params.queryKey[2];

  if (!uwid || !status) {
    return null;
  }
  const response = await axios.get<ForecastingChangeRecord[] | null>(
    `${API_URL}/${uwid}/audit/${status}/forecast`
  );
  if (!response.data) {
    return null;
  }
  return response.data;
};

const useFetchAuditForecast = (uwid: string, status: ForecastWellStatus) => {
  return useQuery<ForecastingChangeRecord[] | null>(
    [FetchAuditForecastQuery, uwid, status],
    fetchAuditForecast,
    {
      refetchOnWindowFocus: false
    }
  );
};

export default useFetchAuditForecast;
