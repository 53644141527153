import { EvaSource, TimeStep } from "constants/charts.enums";
import { ChartRequestType } from "types";

import { EntityKind } from "models/entityKind";

export const BASE_CHART_REQUEST: ChartRequestType = {
  FilterId: "",
  requestId: "",
  ReverseColor: false,
  ColorPalette: {
    id: "",
    name: "",
    colorScale: [],
    reverse: false,
    shared: false,
    paletteType: 0,
    thickness: [],
    colors: [],
    preferredColors: [],
    specialColors: []
  },
  colorLockedItems: undefined,
  GroupBy: undefined,
  sortBy: "",
  entityKind: EntityKind.Well,
  overrideSizeLimit: false,
  showGroupsNotInFilter: false,
  placeholderBinsEnabled: false,
  hasCheckedBins: false,
  normalizeBySetting: undefined,
  userForecast: [],
  userForecastSettings: undefined,
  forecastMode: "",
  useForecastStartDate: false,
  syncClientPden: false,
  syncClientSchema: "",
  syncClientFcst: false,
  syncForecastRescat: "",
  chartSetting: {
    chartType: 0,
    survivorBias: false,
    rateType: 0,
    cutoff: 0,
    timeStep: TimeStep.Day,
    shutInMonths: 0,
    movingAverageDays: 0,
    source: EvaSource.Public,
    normalizeBy: undefined,
    useWeightedRatioAverage: false,
    xVariable: {
      valueProperty: "",
      pdenSource: "",
      displayTitle: "",
      canBin: false,
      dataType: "",
      mappingName: "",
      bin: undefined
    },
    yVariable: {
      valueProperty: "",
      pdenSource: "",
      displayTitle: "",
      canBin: false,
      dataType: "",
      mappingName: "",
      bin: undefined
    },
    lockUnits: false,
    showForecast: false,
    pdenDataSourceSetting: {
      source: EvaSource.Public,
      timeStep: TimeStep.Day
    },
    forecastMode: "",
    useForecastStartDate: false,
    // Not all charts use the product from the product selector, however backend requires it
    product: ""
  },
  axisMinMax: undefined,
  isDefaultLockOn: false
};
