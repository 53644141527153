import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { FORECASTING_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { ForecastWellStatus } from "../models/forecastWell";
import { FetchForecastWellQuery } from "./useFetchForecastWell";
import { FetchWellHierarchyQueryKey } from "./useFetchWellHierarchy";

const API_URL = `${FORECASTING_SERVICE_ADDRESS}/api/v1`;

const useBackfillForecast = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params: { uwis: string[]; status: ForecastWellStatus }) => {
      const response = await axios.put(
        `${API_URL}/well/backfill/${params.status}/forecast`,
        params.uwis
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FetchWellHierarchyQueryKey);
        queryClient.invalidateQueries(FetchForecastWellQuery);
      }
    }
  );
};

export default useBackfillForecast;
