// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiEye } from "@mdi/js";

import { ThreeDViewerOptionT } from "models";

import { ToolbarButton } from "components/base";

function ThreeDVTopsToggle(options: ThreeDViewerOptionT) {
  const handleToggle = (active: boolean) => {
    options.onOptionChange && options.onOptionChange({ showIPDBPlane: active }, false);
  };

  return (
    <ToolbarButton
      icon={<Icon path={mdiEye} size={1.5} />}
      active={options.showIPDBPlane}
      tooltipText={"Show Zone Tops"}
      overflowLabel={"Zone Tops"}
      onToggle={handleToggle}
      styleKey="threeDVToolbar"
    />
  );
}

export default ThreeDVTopsToggle;
