// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import {
  mdiDotsVertical,
  mdiDownload,
  mdiReload,
  mdiRocketLaunch,
  mdiTrendingUp
} from "@mdi/js";
import { Button, Popconfirm, Popover } from "antd";
import Title from "antd/lib/typography/Title";
import styled from "styled-components";

import { TypeWellAction } from "components/arps/TypeWellAction";
import { Tooltip } from "components/base";

interface ITypeWellEditorHeader {
  entityName: string;
  handlePublishForecast;
  handleArchiveForecast;
  handleDownloadPublishedForecast;
  handleUnarchiveForecast;
  isWidget: boolean;
  saveStatus;
  hideButtons?: boolean;
}

export function ForecastingEditorHeader({
  entityName,
  handlePublishForecast,
  handleArchiveForecast,
  handleDownloadPublishedForecast,
  handleUnarchiveForecast,
  isWidget,
  saveStatus,
  hideButtons = false
}: ITypeWellEditorHeader) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const Wrapper = isWidget ? WidgetRibbonWrapper : ProjectForecastsRibbonWrapper;

  const moreOptions = () => {
    return (
      <ActionListWrapper>
        <Popconfirm
          placement="bottom"
          disabled={false}
          onConfirm={handleArchiveForecast}
          onCancel={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
          okText="Archive"
          okType="danger"
          title={
            <div style={{ whiteSpace: "pre-wrap" }}>
              {`Process to archive the forecast.\nThis action cannot be undone.`}
            </div>
          }>
          <TypeWellAction
            data-testid="forecasting-editor-header-archive-button"
            disabled={false}>
            <Icon path={mdiTrendingUp} size={1} />
            Archive
          </TypeWellAction>
        </Popconfirm>
        <Popconfirm
          placement="bottom"
          disabled={false}
          onConfirm={handleDownloadPublishedForecast}
          onCancel={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
          okText="Copy"
          okType="danger"
          title={
            <div style={{ whiteSpace: "pre-wrap" }}>
              {`Copy published forecast to working.`}
            </div>
          }>
          <TypeWellAction
            data-testid="forecasting-editor-header-download-publish-button"
            disabled={false}>
            <Icon path={mdiDownload} size={1} />
            From Published
          </TypeWellAction>
        </Popconfirm>
        <Popconfirm
          placement="bottom"
          disabled={false}
          onConfirm={handleUnarchiveForecast}
          onCancel={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
          okText="Copy"
          okType="danger"
          title={
            <div style={{ whiteSpace: "pre-wrap" }}>
              {`Copy archived forecast to working.`}
            </div>
          }>
          <TypeWellAction
            data-testid="forecasting-editor-header-unarchive-button"
            disabled={false}>
            <Icon path={mdiReload} size={1} />
            From Archived
          </TypeWellAction>
        </Popconfirm>
      </ActionListWrapper>
    );
  };

  return (
    <Wrapper>
      <TitleWrapper data-testid="forecasting-editor-header-title">
        <StyledTitle level={5}>Forecasting {entityName}</StyledTitle>
      </TitleWrapper>
      {!hideButtons && (
        <>
          <Popconfirm
            placement="bottom"
            disabled={false}
            onConfirm={handlePublishForecast}
            onCancel={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
            }}
            okText="Publish"
            okType="danger"
            title={
              <div style={{ whiteSpace: "pre-wrap" }}>
                {`Process to publish the forecast.\nThis action cannot be undone.`}
              </div>
            }>
            <Tooltip title="Publish">
              <span>
                <Button
                  data-testid="forecasting-editor-header-publish-button"
                  disabled={false}
                  icon={<Icon path={mdiRocketLaunch} size={1} color={"#02c7b0"} />}
                  loading={saveStatus.loading}
                  type={"text"}
                />
              </span>
            </Tooltip>
          </Popconfirm>

          <Tooltip title="More">
            <Popover
              arrowPointAtCenter={true}
              onOpenChange={(isOpen) => {
                setIsPopoverOpen(isOpen);
              }}
              content={moreOptions()}
              open={isPopoverOpen}
              overlayClassName="popover-no-padding"
              placement="bottomLeft"
              trigger="click">
              <Button
                data-testid="forecasting-editor-header-more-button"
                icon={<Icon path={mdiDotsVertical} size={1} color="#a2aaad" />}
                type={"text"}></Button>
            </Popover>
          </Tooltip>
        </>
      )}
    </Wrapper>
  );
}

const ActionListWrapper = styled.div`
  padding: 6px;
`;

const ProjectForecastsRibbonWrapper = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  gap: 5px;

  .ant-select-selector {
    max-width: 150px;
    min-width: 150px;
  }

  .ant-btn-icon-only {
    width: 24px;
    height: 24px;
  }
`;

const WidgetRibbonWrapper = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  gap: 5px;
  overflow: hidden;

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-top: 6px;

  .ant-btn-icon-only {
    width: 24px;
    height: 24px;
  }
`;

const TitleWrapper = styled.div`
  display: block;
  width: 100%;
  //   overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledTitle = styled(Title)``;
