import React from "react";

import { Delete, DeleteOutline } from "@material-ui/icons";
import { Popconfirm } from "antd";

import { ToolbarButton } from "components/base";
import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import { useMapContext } from "components/map/hooks/useMapContext";

import { useGeomBinDispatch } from "../hooks/useGeomBinDispatch";

export interface LassoSelectionComponentModel {
  drawRef;
  portalRef?: React.MutableRefObject<HTMLDivElement>;
}

export default function DeletePolygon({
  portalRef,
  drawRef
}: LassoSelectionComponentModel): JSX.Element {
  const { mapbox } = useMapContext();

  const geomBinDispatch = useGeomBinDispatch();
  const {
    isActive: isGeomBinOpen,
    geomBinItems,
    selectedGeomBinItem
  } = useGeomBinContext();

  function handleDeleteConfirm(e) {
    const selected = drawRef?.current?.getSelected();

    if (!selected?.features?.length) {
      return;
    }

    const newGeomBinItems = [];

    let order = 1;
    geomBinItems.forEach((item) => {
      if (item.id !== selected.features[0].id) {
        newGeomBinItems.push({ ...item, order: order });
        order++;
      }
    });

    geomBinDispatch({
      payload: {
        geomBinItems: newGeomBinItems,
        selectedGeomBinItem: undefined
      }
    });

    e.stopPropagation();
    e.preventDefault();
  }

  function handleDeleteCancel(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  if (!mapbox || !portalRef?.current || !isGeomBinOpen) {
    return <></>;
  }

  return (
    <Popconfirm
      onConfirm={(evt) => handleDeleteConfirm(evt)}
      onCancel={handleDeleteCancel}
      title={`Please confirm you wish to delete the selected polygon.`}>
      <ToolbarButton
        active={false}
        danger
        disabled={!selectedGeomBinItem}
        tooltipText={"Delete Polygon"}
        icon={
          selectedGeomBinItem ? (
            <Delete fontSize="large" />
          ) : (
            <DeleteOutline fontSize="large" />
          )
        }
        onToggle={(_, e) => e.preventDefault()}
        overflowLabel="Normalize"
        styleKey="mapToolbar"
        tabIndex={0}
      />
    </Popconfirm>
  );
}
