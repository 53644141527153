import { memo, useState } from "react";

import { Popover } from "antd";

import StatsIcon from "components/icons/Stats";

import ToolbarButton from "../../../base/ToolbarButton";
import SeriesStyleSelector from "../../SeriesStyleSelector";
import { useChartState } from "../../context";
import { getAverageSeriesToggleDisabledStatus } from "../../utils/toggles";

const AverageSeriesToggle = () => {
  const { settings } = useChartState();
  const [isVisible, setIsVisible] = useState(false);
  const isDisabled = getAverageSeriesToggleDisabledStatus(settings.chartType);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      open={isVisible}
      onOpenChange={(v) => setIsVisible(v)}
      content={<SeriesStyleSelector isVisible={isVisible} />}>
      <ToolbarButton
        active={
          settings.average || settings.showP10 || settings.showP50 || settings.showP90
        }
        icon={<StatsIcon />}
        isMenuButton={true}
        overflowLabel={"Statistics"}
        tooltipText={"Show Statistics"}
        disabled={isDisabled}
      />
    </Popover>
  );
};

export default memo(AverageSeriesToggle);
