import { EvaChart } from "constants/charts.enums";
import { ChartFeature } from "types";

import { createCrossPlotFeatureEntities } from "./crossPlot";
import { createRateCumFeatureEntities } from "./rateCum";

export const FEATURE_MAP = {
  [EvaChart.RateCum]: createRateCumFeatureEntities,
  [EvaChart.CrossPlot]: createCrossPlotFeatureEntities
};

export function createFeatureEntities(type: EvaChart): ChartFeature {
  const features = FEATURE_MAP[type];
  if (!features) {
    throw new Error(`Unknown chart type: ${type} to create features`);
  }
  return features();
}
