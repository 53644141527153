// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import { mdiChartAreaspline, mdiChartLine } from "@mdi/js";
import { Tabs as AntDTabs, Popover, Switch, Tooltip } from "antd";
import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { createFeatureEntities } from "entities/charts/eva/features";
import { selectChartType, updateChart } from "store/features";
import { RootState } from "store/rootReducer";
import { getChartType, noop } from "utils";
import { getShortenedProductName } from "utils/chart/productNameHelpers";

import { ToolbarButton } from "components/base";
import BoxPlot from "components/icons/BoxPlot";
import CrossPlot from "components/icons/CrossPlot";
import CumTime from "components/icons/CumTime";
import DeclineRate from "components/icons/DeclineRate";
import MaterialBalanceSheet from "components/icons/MaterialBalanceSheet";
import Mosaic from "components/icons/Mosaic";
import PieChart from "components/icons/PieChart";
import Probit from "components/icons/Probit";
import RateCum from "components/icons/RateCum";
import RateDate from "components/icons/RateDate";
import RateTime from "components/icons/RateTime";
import StackedBar from "components/icons/StackedBar";
import TotalRateCum from "components/icons/TotalRateCum";
import TotalRateDate from "components/icons/TotalRateDate";
import TrendDate from "components/icons/TrendDate";

import {
  ChartProduct,
  ChartType,
  InjectionProduct,
  ProductKind,
  SalesProduct,
  ShrinkageProduct,
  SummaryProduct,
  ThroughputProduct,
  getProductLabel
} from "../../../../constants";
import { EntityKind } from "../../../../models/entityKind";
import { getProductIcon } from "../../../../utils/chart/getProductIcon";
import ChartTypeHeader from "../ChartTypeHeader";
import ProductHeader from "../ProductHeader";
import { CHART_SElECTOR_BUTTON_LABEL } from "./ChartSelector.constants";
import "./ChartSelector.scss";
import {
  ButtonContainer,
  ChartTypeButton,
  ChartTypeMenuContainer,
  ProductButton,
  ProductContainer
} from "./ChartSelector.styles";

const chartTypeIcons = [
  <RateCum key="rate-cum" />,
  <RateTime key="rate-time" />,
  <RateDate key="rate-date" />,
  <TotalRateDate key="total-rate-date" />,
  <CumTime key="cum-time" />,
  <CrossPlot key="cross-plot" />,
  <Probit key="probit" />,
  <BoxPlot key="box-plot" />,
  <StackedBar key="stacked-bar" />,
  <PieChart key="pie-chart" />,
  <Mosaic key="mosaic" />,
  <MaterialBalanceSheet key="material-balance-sheet" />,
  <TotalRateCum key="total-rate-cum" />,
  <Icon path={mdiChartLine} size={1.8} key="cagr" />,
  <DeclineRate key="base-decline-rate" />,
  <TrendDate key="trend-date" />,
  <Icon path={mdiChartAreaspline} size={1.8} key="well-contribution-cum-date" />
];

export const chartTypeIconsGrouped = [
  [<RateCum key="rate-cum" />, <RateTime key="rate-time" />, <CumTime key="cum-time" />],
  [
    <RateDate key="rate-date" />,
    <TotalRateDate key="total-rate-date" />,
    <TotalRateCum key="total-rate-cum" />
  ],
  [
    <CrossPlot key="cross-plot" />,
    <Probit key="probit" />,
    <BoxPlot key="box-plot" />,
    <StackedBar key="stacked-bar" />,
    <PieChart key="pie-chart" />,
    <Mosaic key="mosaic" />,
    <MaterialBalanceSheet key="material-balance-sheet" />,
    <Icon path={mdiChartLine} size={1.8} key="cagr" />,
    <DeclineRate key="base-decline-rate" />,
    <TrendDate key="trend-date" />
  ]
];
export const midstreamChartTypeIconsGrouped = [
  [
    <RateDate key="rate-date" />,
    <TotalRateDate key="total-rate-date" />,
    <Icon path={mdiChartAreaspline} size={1.8} key="well-contribution-cum-date" />
  ],
  [<CrossPlot key="cross-plot" />]
];
// Multi product
export const chartTypeIconsGroupedMultiProduct = [
  <RateCum key="rate-cum" />,
  <RateTime key="rate-time" />,
  <CumTime key="cum-time" />,
  <RateDate key="rate-date" />,
  <RateDate key="trend-date" />
];
// End Multi product

// TODO chart: separate product selection from chart selection
export function ChartSelector() {
  const { id } = useChartsContext();
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  // Current tab; no product selected yet. Can change multiple times.
  const [selectedProductGroup, setSelectedProductGroup] = useState();
  // Tab with selected product; reopens selector on this tab with active product.
  const [activeProductGroup] = useState(selectedProductGroup);
  const dispatch = useDispatch();

  const [isChartTypeVisible, setIsChartTypeVisible] = useState(false);

  const [isProductVisible, setIsProductVisible] = useState(false);
  const showProduct = true;

  if (!chartType) {
    dispatch(
      updateChart({
        id,
        chartType: EvaChart.RateCum,
        features: createFeatureEntities(EvaChart.RateCum)
      })
    );
  }

  function getYieldsToggleLabels(): {
    rawLabel: string;
    salesLabel: string;
  } {
    return {
      rawLabel: "",
      salesLabel: ""
    };
  }

  const { rawLabel, salesLabel } = getYieldsToggleLabels();

  const inletRateLabel = "Inlet Rate";
  const outletRateLabel = "Outlet Rate";

  const [yieldsType, setYieldsType] = useState(rawLabel);

  const onChangeType = () => {
    if (yieldsType === rawLabel) {
      setYieldsType(salesLabel);
    } else {
      setYieldsType(rawLabel);
    }
  };

  const getLiquidYieldsProductHeader = () => {
    const header = {
      items: []
    };
    return (
      <>
        <ButtonContainer>
          <Switch
            unCheckedChildren={rawLabel}
            checkedChildren={salesLabel}
            checked={yieldsType === salesLabel}
            onChange={onChangeType}
            size="small"></Switch>
        </ButtonContainer>

        <ProductHeader>
          {header.items.map((prod) => (
            <ProductButton
              isDisabled={true}
              key={prod}
              ellipses={false}
              appearance=""
              className={`null}`}>
              {getProductIcon(prod)}
              <span>
                {getShortenedProductName(
                  selectedProductGroup,
                  getProductLabel(prod, EntityKind.Well),
                  rawLabel,
                  salesLabel,
                  inletRateLabel,
                  outletRateLabel
                )}
              </span>
            </ProductButton>
          ))}
        </ProductHeader>
      </>
    );
  };

  const getShrinkagePercentageProductHeader = () => {
    return <> </>;
  };

  useHotkeys("tab", noop, []);

  let currentProduct;
  if (selectedProductGroup === ProductKind["Wellhead"]) {
    currentProduct = ChartProduct;
  } else if (selectedProductGroup === ProductKind["Sales"]) {
    currentProduct = SalesProduct;
  } else if (selectedProductGroup === ProductKind["Throughput"]) {
    currentProduct = ThroughputProduct;
  } else if (selectedProductGroup === ProductKind["Shrinkage"]) {
    currentProduct = ShrinkageProduct;
  } else if (selectedProductGroup === ProductKind["Summary"]) {
    currentProduct = SummaryProduct;
  } else {
    currentProduct = InjectionProduct;
  }
  const availableChartTypes = [];
  return (
    <div className="chart-selector_button-wrapper">
      <Popover
        arrowPointAtCenter
        content={
          <ChartTypeMenuContainer>
            {(availableChartTypes?.length > 0 ? availableChartTypes : ChartType).map(
              (header, hIdx) => (
                <ChartTypeHeader key={header.name} header={header.name}>
                  {header.items.map((ct, tIdx) => {
                    return (
                      <Tooltip key={ct} title={getChartType(ct)?.tooltip ?? ""}>
                        <ChartTypeButton
                          key={ct}
                          ellipses={false}
                          onClick={() => {
                            dispatch(
                              updateChart({
                                id,
                                chartType: ct,
                                features: createFeatureEntities(ct)
                              })
                            );
                          }}
                          appearance=""
                          className={`null}`}>
                          {chartTypeIconsGrouped[hIdx][tIdx]}
                          <span>{ct}</span>
                        </ChartTypeButton>
                      </Tooltip>
                    );
                  })}
                </ChartTypeHeader>
              )
            )}
          </ChartTypeMenuContainer>
        }
        onOpenChange={(v) => setIsChartTypeVisible(v)}
        open={isChartTypeVisible}
        placement="bottomRight"
        trigger="click">
        <ToolbarButton
          active={false}
          icon={chartTypeIcons[0]} // hardcoded
          overflowLabel={CHART_SElECTOR_BUTTON_LABEL}
          tooltipText={""} // hardcoded
        />
      </Popover>
      {showProduct && (
        <Popover
          arrowPointAtCenter
          content={
            <ProductContainer>
              {false && <AntDTabs />}

              {currentProduct.map((header) =>
                ![
                  "Yields Raw",
                  "Yields Sales",
                  "Yields Inlet",
                  "Yields Outlet",
                  "Percent Inlet",
                  "Percent Outlet"
                ].includes(header.name) ? (
                  <ProductHeader key={header.name} header={header.name}>
                    {header.items
                      .filter(
                        (prod) =>
                          prod !== "Intra-Network Gas Inlet" &&
                          prod !== "Intra-Network Gas Outlet"
                      )
                      .map((prod) => {
                        return (
                          <ProductButton
                            isDisabled={false}
                            key={prod}
                            ellipses={false}
                            onClick={noop}
                            appearance=""
                            className={`null}`}>
                            {getProductIcon(prod)}
                            <span>PRODUCTNAME</span>
                          </ProductButton>
                        );
                      })}
                  </ProductHeader>
                ) : null
              )}
              {getLiquidYieldsProductHeader()}
              {getShrinkagePercentageProductHeader()}
            </ProductContainer>
          }
          onOpenChange={(v) => {
            setIsProductVisible(v);
            if (!v) {
              setSelectedProductGroup(activeProductGroup);
            }
          }}
          open={isProductVisible}
          placement="bottomRight"
          trigger="click">
          <ToolbarButton
            active={false}
            icon={chartTypeIcons[0]} // hardcoded
            overflowLabel="Product"
            tooltipText={""}
          />
        </Popover>
      )}
    </div>
  );
}
