import { getProductLabel } from "constants/chart.constants";
import {
  FORECAST_KEY,
  MY_FOCUS_FIELDS,
  ORG_FOCUS_FIELDS,
  ORG_GEO_FIELDS
} from "constants/settings.constants";
import { groupBy, sortBy } from "lodash";
import { getCumPlusForecastTitle } from "utils";
import getColumnLabelWithContext from "utils/column/getColumnLabelWithContext";

import { IGroupByListColumn, IGroupByListItem } from "models";
import {
  IGroupColumn,
  IProductColumn,
  IPropertyColumn,
  IPropertyColumnMap,
  ISubgroupColumn
} from "models/columns";
import { EntityKind } from "models/entityKind";

import { getProductDisplayName } from ".";

/**
 * Get the organized multilevel column property tree for a group of columns.
 *
 * @param {IGroupByListItem[]} groups the one level groups of columns
 * @returns {IGroupColumn[]} The column property tree
 */
const getColumnPropertyTree = (
  groups: IGroupByListItem[],
  entityKind: string = EntityKind.Well,
  isFacilityVolume: boolean = false
): [IGroupColumn[], IPropertyColumnMap] => {
  if (!groups) return [[], {}];

  const tree = groups
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((group) => {
      const productGroupColumnList: IProductColumn[] = [];
      const subgroupColumnList: ISubgroupColumn[] = [];
      if (group.name === "Forecast" || group.name === "Performance") {
        const products = groupBy(group.columns, "product");

        for (const [product, productColumns] of Object.entries(products)) {
          if (isFacilityVolume && product.includes("CO2")) continue;

          const productSubgroupColumnList: ISubgroupColumn[] = [];

          const subgroups = groupBy(productColumns, "subgroup");
          for (const [subgroup, subgroupColumns] of Object.entries(subgroups)) {
            const columns = subgroupColumns as IGroupByListColumn[];
            const columnsList: IPropertyColumn[] = columns.reduce((list, column) => {
              if (isFacilityVolume && column.product.includes("CO2")) return list;

              list.push({
                id: column.id.toString(),
                hexColor: group.hexColor,
                canBin: column.canBin,
                dataType: column.dataType,
                property: column.property,
                title: `${getProductLabel(product, entityKind)}: ${column.title}`,
                sortOrder: column.withinSubgroupOrder
              });
              if (group.name === "Performance" && column.property.endsWith("Cum")) {
                list.push({
                  id: column.id.toString(),
                  hexColor: group.hexColor,
                  canBin: column.canBin,
                  dataType: column.dataType,
                  property: `${column.property}.${FORECAST_KEY}`,
                  title: `${getProductLabel(
                    product,
                    entityKind
                  )}: ${getCumPlusForecastTitle(column.title, "", column.subgroup)}`,
                  sortOrder: column.withinSubgroupOrder
                });
              }
              return list;
            }, []);
            productSubgroupColumnList.push({
              key: subgroup,
              title: subgroup,
              columns: sortBy(columnsList, ["sortOrder"])
            });
          }

          productGroupColumnList.push({
            productKey: product,
            productDisplayName: getProductDisplayName(product),
            subgroups: productSubgroupColumnList
          });
        }
      } else if (
        group.name === ORG_FOCUS_FIELDS ||
        group.name === MY_FOCUS_FIELDS ||
        group.name === ORG_GEO_FIELDS
      ) {
        const subgroups = groupBy(group.columns, "subgroup");
        for (const [subgroup, subgroupColumns] of Object.entries(subgroups)) {
          const columns = subgroupColumns as IGroupByListColumn[];
          const columnsList: IPropertyColumn[] = columns
            .map((column) => {
              if (isFacilityVolume && column.product.includes("CO2")) return null; // Skip columns containing "CO2" when FacilityVolume is enabled

              const title = getColumnLabelWithContext(
                column,
                false,
                null,
                false,
                ""
              ).lightContextTitle;
              if (title) {
                return {
                  id: column.id.toString(),
                  hexColor: group.hexColor,
                  canBin: column.canBin,
                  dataType: column.dataType,
                  property: column.property,
                  title: title,
                  sortOrder: column.withinSubgroupOrder
                };
              } else {
                return {
                  id: column.id.toString(),
                  hexColor: group.hexColor,
                  canBin: column.canBin,
                  dataType: column.dataType,
                  property: column.property,
                  title: column.title,
                  sortOrder: column.withinSubgroupOrder
                };
              }
            })
            .filter(Boolean); // Filter out null values

          subgroupColumnList.push({
            key: subgroup,
            title: subgroup,
            columns: sortBy(columnsList, ["sortOrder"])
          });
        }
      } else {
        const subgroups = groupBy(group.columns, "subgroup");
        for (const [subgroup, subgroupColumns] of Object.entries(subgroups)) {
          const columns = subgroupColumns as IGroupByListColumn[];
          const columnsList: IPropertyColumn[] = columns
            .map((column) => {
              if (isFacilityVolume && column.product.includes("CO2")) return null; // Skip columns containing "CO2" when FacilityVolume is enabled

              return {
                id: column.id.toString(),
                hexColor: group.hexColor,
                canBin: column.canBin,
                dataType: column.dataType,
                property: column.property,
                title: column.title,
                sortOrder: column.withinSubgroupOrder
              };
            })
            .filter(Boolean); // Filter out null values

          subgroupColumnList.push({
            key: subgroup,
            title: subgroup,
            columns: sortBy(columnsList, ["sortOrder"])
          });
        }
      }

      const groupObj: IGroupColumn = {
        key: group.name,
        title: group.name,
        hexColor: group.hexColor,
        productGroups: productGroupColumnList,
        subgroups: subgroupColumnList,
        sortOrder: group.sortOrder
      };
      return groupObj;
    });

  let globalOrder = 0;
  const map: IPropertyColumnMap = {};
  tree.forEach((group) => {
    group.subgroups.forEach((subgroup) => {
      subgroup.columns.forEach((column) => {
        column.globalOrder = globalOrder++;
        map[column.property] = column;
      });
    });
    group.productGroups.forEach((prodGroup) => {
      prodGroup.subgroups.forEach((subgroup) => {
        subgroup.columns.forEach((column) => {
          column.globalOrder = globalOrder++;
          map[column.property] = column;
        });
      });
    });
  });

  return [tree, map];
};

export default getColumnPropertyTree;
