import { Divider } from "antd";
import styled from "styled-components/macro";

import { LabeledSwitch } from "components/base";
import { updateXDASettings, useVisState } from "components/vis/context";

import { XdaOptionsWrapper } from "./XdaOptionsLayout";

function XdaGridOptions() {
  const [{ xda }, visDispatch] = useVisState();
  const { showRelativeDepth, showTVDSSDepth, showGrid } = xda.settings;

  const handleSettingChange = (key) => (value) => {
    const nextSettings = { ...xda.settings, [key]: value };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <XdaOptionsWrapper overflowY="none">
      <SectionHeader>X-Axis</SectionHeader>
      <LabeledSwitch
        testId="xda-lineDistance"
        label={{
          value: "Line Distance (m)"
        }}
        switch={{
          isChecked: showGrid,
          onChange: handleSettingChange("showGrid")
        }}
      />
      <AxesDivider />
      <SectionHeader>Y-Axis</SectionHeader>
      <LabeledSwitch
        testId="xda-relativeDepth"
        label={{ value: "Relative Depth (m)" }}
        switch={{
          isChecked: showRelativeDepth,
          onChange: handleSettingChange("showRelativeDepth")
        }}
      />
      <LabeledSwitch
        testId="xda-tvdssDepth"
        label={{ value: "TVD SS (m)" }}
        switch={{
          isChecked: showTVDSSDepth,
          onChange: handleSettingChange("showTVDSSDepth")
        }}
      />
    </XdaOptionsWrapper>
  );
}

export default XdaGridOptions;

const AxesDivider = styled(Divider)`
  margin: 10px 0;
`;

const SectionHeader = styled.div`
  font-weight: bold;
`;
