import { memo, useState } from "react";

interface ProducingRateProps {
  producing?: boolean;
}

const CD_BUTTON_ACTIVE_COLOR = "var(--color-primary)";

function ProducingRate({ producing = false }: ProducingRateProps) {
  const [hovered, setHovered] = useState(false);

  // Exceptional case for this ToolbarButton, we want the CD button to maintain an active color,
  // even when the button is untoggled.
  const cdColor = hovered ? "currentColor" : CD_BUTTON_ACTIVE_COLOR;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      transform="scale(1, 1)"
      fill="currentColor"
      style={{
        cursor: "pointer"
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {producing ? (
        <>
          <path
            d="M9,7V17H11V13H13A2,2 0 0,0 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9Z"
            transform="translate(0, 2.5) scale(0.8, 0.8)"
            fill={"currentColor"}
          />
          <path
            d="M9,7V17H13A2,2 0 0,0 15,15V9A2,2 0 0,0 13,7H9M11,9H13V15H11V9Z"
            transform="translate(5.75, 2.5) scale(0.8, 0.8)"
            fill={"currentColor"}
          />
        </>
      ) : (
        <>
          <path
            d="M11,7A2,2 0 0,0 9,9V15A2,2 0 0,0 11,17H13A2,2 0 0,0 15,15V14H13V15H11V9H13V10H15V9A2,2 0 0,0 13,7H11Z"
            transform="translate(0, 2.5) scale(0.8, 0.8)"
            fill={cdColor}
          />
          <path
            d="M9,7V17H13A2,2 0 0,0 15,15V9A2,2 0 0,0 13,7H9M11,9H13V15H11V9Z"
            transform="translate(5.75, 2.5) scale(0.8, 0.8)"
            fill={cdColor}
          />
        </>
      )}
    </svg>
  );
}

export default memo(ProducingRate);
