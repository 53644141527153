import { memo } from "react";

import { noop } from "utils";

import CrossPlot from "components/icons/CrossPlot";

import ToolbarButton from "../../base/ToolbarButton";

const JitterPlotToggle = () => {
  const toggle = noop;

  return (
    <ToolbarButton
      icon={<CrossPlot />}
      active={false}
      overflowLabel="Show Jitter Plots"
      tooltipText="Toggle Jitter Plot"
      onToggle={toggle()}
    />
  );
};

export default memo(JitterPlotToggle);
