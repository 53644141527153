import { memo } from "react";

import { ToolbarButton } from "components/base";
import ProducingRateIcon from "components/icons/ProducingRate";
import { useChartSettings } from "components/multiphase-chart/context";
import { changeSettings } from "components/multiphase-chart/context/reducer/chartSettingsReducer";

const ProducingToggle = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const onToggle = () => {
    const nextSettings = {
      ...chartSettings.settings,
      producing: !chartSettings.settings.producing
    };
    changeSettings(chartSettingsDispatch, nextSettings);
  };

  return (
    <ToolbarButton
      testId="multiphasechart-toolbar-producing-toggle"
      icon={<ProducingRateIcon producing={chartSettings.settings.producing} />}
      overflowLabel="Producing"
      tooltipText="Toggle Producing Day Rate"
      active={false}
      onToggle={onToggle}
    />
  );
};

export default memo(ProducingToggle);
