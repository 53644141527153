import { memo } from "react";

import { noop } from "utils";

import LassoIcon from "components/icons/Lasso";

import ToolbarButton from "../../../base/ToolbarButton";

function LassoBrush() {
  // context;
  const isDisabled = false;

  const handleLassoToggle = noop;

  return (
    <ToolbarButton
      icon={<LassoIcon />}
      active={false}
      disabled={isDisabled}
      overflowLabel="Lasso Selection"
      tooltipText="Lasso Selection"
      onToggle={handleLassoToggle()}
    />
  );
}

export default memo(LassoBrush);
