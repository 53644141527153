import React, { PropsWithChildren, createContext, useContext } from "react";

interface ChartContextProps {
  id: string;
}

const ChartContext = createContext<ChartContextProps | undefined>(undefined);

export const useChartsContext = () => {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error("useChartContext must be used within a ChartProvider");
  }
  return context;
};

interface ChartProviderProps {
  id: string;
}

export const ChartsProvider = ({
  id,
  children
}: PropsWithChildren<ChartProviderProps>) => {
  return <ChartContext.Provider value={{ id }}>{children}</ChartContext.Provider>;
};
