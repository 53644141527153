import { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { GeomT } from "models/model";

export const useSearchShapefileFeatureList = () => {
  const currentShapefileFeatureProperty = useSelector(
    (state: RootState) => state.project.currentShapefileFeatureProperty
  );

  const [searchText, setSearchText] = useState<string>("");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      setSearchText("");
    }
  };

  function getSearchedList(items: GeomT[][]): GeomT[][] {
    if (searchText.length === 0) {
      return items;
    }

    const lowerSearchTerm = searchText?.toLowerCase();
    const searchedItems = items.filter((item) => {
      const property = item?.[0]?.Properties?.[currentShapefileFeatureProperty];

      // Include 0s, don't include nulls/undefined
      if (property == null) {
        return false;
      }

      return property.toString().toLowerCase().indexOf(lowerSearchTerm) >= 0;
    });

    return searchedItems;
  }

  return {
    getSearchedList,
    handleKeyDown,
    searchText,
    setSearchText
  };
};
