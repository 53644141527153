import styled from "styled-components";

export const BUSY_SPINNER_TEST_ID = "busy-indicator-icon";
const IconSpinner = ({
  className = "",
  size = 20,
  padding = 5,
  showBackground = false,
  ...props
}) => {
  const style = { animation: "spin 900ms infinite linear" };

  return (
    <Wrapper
      data-testid={BUSY_SPINNER_TEST_ID}
      {...props}
      showBackground={showBackground}
      padding={padding}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className={`spinner ${className}`}
        style={style}
        width={size}
        height={size}>
        <defs>
          <linearGradient
            id="a"
            x1="28.1542969%"
            y1="63.7402344%"
            x2="74.6289062%"
            y2="17.7832031%">
            <stop stopColor="rgba(0, 199, 177, 1)" offset="0%" />
            <stop stopColor="rgba(0, 199, 177, 0)" stopOpacity="0" offset="100%" />
          </linearGradient>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none">
          <g transform="translate(-236.000000, -286.000000)">
            <g transform="translate(238.000000, 286.000000)">
              <circle stroke="url(#a)" strokeWidth="4" cx="10" cy="12" r="10" />
              <path
                d="M10,2 C4.4771525,2 0,6.4771525 0,12"
                stroke="rgba(0, 199, 177, 1)"
                strokeWidth="4"
              />
              <rect fill="rgba(0, 199, 177, 1)" x="8" y="0" width="4" height="4" rx="8" />
            </g>
          </g>
        </g>
      </svg>
    </Wrapper>
  );
};

export default IconSpinner;

const Wrapper = styled.div`
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: ${(props) =>
    props.padding !== undefined ? `${props.padding}px` : "5px"};
  padding-right: ${(props) =>
    props.padding !== undefined ? `${props.padding}px` : "5px"};
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  background: ${(props) =>
    props.showBackground ? "rgba(160, 160, 160, 0.2)" : "transparent"};
`;
