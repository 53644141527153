import { ArpsDesignerKind } from "models/UserArpsModel";

export type TypeWellProductItem = {
  label: string;
  key: string;
  type?: string;
};

const typeWellAndForecastProducts = [
  {
    label: "Oil",
    key: "OIL"
  },
  {
    label: "Condensate",
    key: "CONDENSATE"
  },
  {
    label: "Gas",
    key: "GAS"
  },
  {
    label: "Sales Gas",
    key: "SALES GAS",
    showWhenKind: ["TypeWell"]
  },
  {
    label: "Water",
    key: "WATER"
  },
  {
    type: "divider"
  },
  {
    label: "O+W",
    key: "O+W"
  },
  {
    label: "CGR",
    key: "CGR"
  },
  {
    label: "GOR",
    key: "GOR"
  },
  {
    label: "OGR",
    key: "OGR"
  },
  {
    label: "WGR",
    key: "WGR"
  },
  {
    label: "WOR",
    key: "WOR"
  },
  {
    label: "1+WOR",
    key: "1+WOR",
    showWhenKind: ["Forecasting"]
  },
  {
    label: "C2 to Gas Ratio",
    key: "C2",
    showWhenKind: ["Forecasting"]
  },
  {
    label: "C3 to Gas Ratio",
    key: "C3",
    showWhenKind: ["Forecasting"]
  },
  {
    label: "C4 to Gas Ratio",
    key: "C4",
    showWhenKind: ["Forecasting"]
  },
  {
    label: "C5+ to Gas Ratio",
    key: "C5",
    showWhenKind: ["Forecasting"]
  },
  {
    label: "Shrinkage",
    key: "SHRINKAGE",
    showWhenKind: ["Forecasting"]
  }
] as { label: string; type?: string; key: string; showWhenKind?: ArpsDesignerKind[] }[];

export const typeWellProductsMenuItems = (
  kind: ArpsDesignerKind,
  isUserForecaster?: boolean
): TypeWellProductItem[] =>
  typeWellAndForecastProducts
    .filter((item) => {
      if (!item.showWhenKind) return true;
      if (item.showWhenKind.includes(kind)) return true;
      return isUserForecaster && item.showWhenKind.includes("Forecasting");
    })
    .map((item) => {
      return {
        label: item.label,
        key: item.key,
        ...(item.type && { type: item.type })
      };
    });
