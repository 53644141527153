import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { EntityKind } from "../../../models/entityKind";

const useChartDependencies = (entityKind: EntityKind) => {
  const normalizeTypeWell = useSelector(
    (state: RootState) => state.app.normalizeTypeWell
  );

  const pdenDataSourceSetting = useSelector(
    (state: RootState) => state.app.pdenDataSourceSetting
  );

  const activeColorPalette = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.userSetting.activeColorPalette
      : state.userSetting.activeFacilityColorPalette
  );
  const attentionWells = useSelector((state: RootState) => state.map.attentionWells);
  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const groupBy = useSelector((state: RootState) =>
    entityKind === EntityKind.Well
      ? state.groupBy.globalGroupBy
      : state.groupBy.globalFacilityFocus
  );

  const hoverDataPoint = useSelector((state: RootState) => state.groupBy.hoverDataPoint);
  const hoverLegendItem: string = useSelector(
    (state: RootState) => state.groupBy.hoverLegendItem
  );
  const hoverLegendGroup: string = useSelector(
    (state: RootState) => state.groupBy.hoverLegendGroup
  );
  const hoverLegendId: string = useSelector(
    (state: RootState) => state.groupBy.hoverLegendId
  );

  const selectedGroups = useSelector((state: RootState) => state.groupBy.selectedGroups);
  const selectedWells = useSelector((state: RootState) => state.map.selectedWells);
  const syncWells = useSelector((state: RootState) => state.app.syncWells);

  const userForecastSettings = useSelector(
    (state: RootState) => state.app.userForecastSettings
  );
  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const lockedColors = useSelector((state: RootState) => state.app.lockedColors);
  const filterId = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.filterId : state.facilityFilter.filterId
  );
  const sortBy = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.sortBy : state.facilityFilter.sortBy
  );
  const showGroupsNotInFilter = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.showGroupsNotInFilter
      : state.facilityFilter.showGroupsNotInFilter
  );
  const txnId = useSelector((state: RootState) => state.map.txnId);

  const checkedGlobalForecasts = useSelector(
    (state: RootState) => state.arps.checkedGlobalForecasts
  );
  const checkedGlobalTypeWells = useSelector(
    (state: RootState) => state.arps.checkedGlobalTypeWells
  );

  const forecastMode = useSelector((state: RootState) => state.app.forecastMode);
  const useForecastStartDate = useSelector(
    (state: RootState) => state.app.useForecastStartDate
  );

  return useMemo(
    () => ({
      activeColorPalette,
      attentionWells,
      checkedGlobalForecasts,
      checkedGlobalTypeWells,
      filterId,
      globalNormalizeBy,
      groupBy,
      hoverDataPoint,
      hoverLegendGroup,
      hoverLegendId,
      hoverLegendItem,
      lockedColors,
      normalizeTypeWell,
      pdenDataSourceSetting,
      selectedGroups,
      selectedWells,
      showGroupsNotInFilter,
      sortBy,
      syncWells,
      txnId,
      useNormalizeBy,
      userForecastSettings,
      forecastMode,
      useForecastStartDate
    }),
    [
      activeColorPalette,
      attentionWells,
      checkedGlobalForecasts,
      checkedGlobalTypeWells,
      filterId,
      globalNormalizeBy,
      groupBy,
      hoverDataPoint,
      hoverLegendGroup,
      hoverLegendId,
      hoverLegendItem,
      lockedColors,
      normalizeTypeWell,
      pdenDataSourceSetting,
      selectedGroups,
      selectedWells,
      showGroupsNotInFilter,
      sortBy,
      syncWells,
      txnId,
      useNormalizeBy,
      userForecastSettings,
      forecastMode,
      useForecastStartDate
    ]
  );
};

export default useChartDependencies;
