import { Icon } from "@mdi/react";
import React, { useEffect, useState } from "react";

import { mdiCircleOutline, mdiLasso, mdiSquareOutline } from "@mdi/js";

import ToolbarButton, { RightSideDropdownProps } from "components/base/ToolbarButton";
import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import { useMapContext } from "components/map/hooks/useMapContext";

import { useGeomBinDispatch } from "../hooks/useGeomBinDispatch";

export interface LassoSelectionComponentModel {
  drawRef;
  portalRef?: React.MutableRefObject<HTMLDivElement>;
}

export default function AddPolygon({
  portalRef,
  drawRef
}: LassoSelectionComponentModel): JSX.Element {
  const { mapbox } = useMapContext();

  const geomBinDispatch = useGeomBinDispatch();
  const { isLassoSelected, isActive: isGeomBinOpen } = useGeomBinContext();

  const [selectedShape, setSelectedShape] = useState("draw_polygon");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const shapeLabels = {
    draw_polygon: "Polygon",
    draw_rectangle: "Square",
    draw_circle: "Circle"
  };

  useEffect(() => {
    if (!mapbox) {
      return;
    }

    if (isLassoSelected) {
      mapbox.getCanvas().style.cursor = "crosshair";
      drawRef.current.changeMode(selectedShape);
    } else {
      mapbox.getCanvas().style.cursor = "pointer";
    }
  }, [isLassoSelected, mapbox, selectedShape]);

  useEffect(() => {
    if (!portalRef) {
      return;
    }
  }, [portalRef]);

  if (!mapbox || !portalRef?.current || !isGeomBinOpen) {
    return <></>;
  }

  const handleDropdownSelect = (shape) => {
    if (selectedShape === shape && isLassoSelected) {
      geomBinDispatch({
        payload: {
          isLassoSelected: false
        }
      });
      setSelectedShape("");
      setIsDropdownVisible(false);
      return;
    }
    geomBinDispatch({
      payload: {
        isLassoSelected: true
      }
    });
    setSelectedShape(shape);
    setIsDropdownVisible(false);
  };

  const handleDropDownOpen = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const toggleLasso = () => {
    if (isLassoSelected) {
      geomBinDispatch({
        payload: {
          isLassoSelected: false
        }
      });
      drawRef.current.changeMode("simple_select");
    } else {
      geomBinDispatch({
        payload: {
          isLassoSelected: true
        }
      });
      drawRef.current.changeMode(selectedShape);
    }
  };

  const renderIcon = () => {
    let path;
    switch (selectedShape) {
      case "draw_polygon":
        path = mdiLasso;
        break;
      case "draw_circle":
        path = mdiCircleOutline;
        break;
      case "draw_rectangle":
        path = mdiSquareOutline;
        break;
      default:
        path = mdiLasso;
        break;
    }

    return <Icon path={path} className="activity-action-icon" size={1} />;
  };

  const menuProps: RightSideDropdownProps = {
    menu: {
      isDropdownVisible: isDropdownVisible,
      setIsDropdownVisible: handleDropDownOpen,
      tooltip: isDropdownVisible ? null : "Lasso Type"
    },
    dropdownProps: {
      overlayClassName: "lasso-dropdown",
      trigger: ["click"],
      menu: {
        onClick: (v) => handleDropdownSelect(v.key),
        selectedKeys: [selectedShape],
        items: [
          {
            key: "draw_polygon",
            icon: <Icon path={mdiLasso} className="activity-action-icon" size={1} />,
            label: shapeLabels["draw_polygon"]
          },
          {
            key: "draw_rectangle",
            icon: (
              <Icon path={mdiSquareOutline} className="activity-action-icon" size={1} />
            ),
            label: shapeLabels["draw_rectangle"]
          },
          {
            key: "draw_circle",
            icon: (
              <Icon path={mdiCircleOutline} className="activity-action-icon" size={1} />
            ),
            label: shapeLabels["draw_circle"]
          }
        ]
      }
    }
  };

  return (
    <ToolbarButton
      active={isLassoSelected}
      aria-pressed={isLassoSelected}
      tooltipText={!isDropdownVisible ? "Lasso Selection" : null}
      rightSideDropdown={menuProps}
      icon={renderIcon()}
      onToggle={toggleLasso}
      overflowLabel="Normalize"
      styleKey="mapToolbar"
      tabIndex={0}
    />
  );
}
