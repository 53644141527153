import { EChartsOption } from "echarts";

import { IMPCSeriesLine } from "components/multiphase-chart/models/options.models";

/**
 *
 * @param options
 * @returns options with series updated to their original styling
 */
const getOptionsUnhighlighted = (options: EChartsOption): EChartsOption => {
  if (!options?.series) return {};

  const seriesArray = Array.isArray(options.series) ? options.series : [options.series];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const updatedSeries: IMPCSeriesLine[] = seriesArray.map((trace: IMPCSeriesLine) => {
    trace.lineStyle.width = trace.lineStyle.originalWidth;
    trace.lineStyle.color = trace.lineStyle.originalColor;
    trace.lineStyle.opacity = 1.0;

    return trace;
  });

  const newOptions = { ...options, series: updatedSeries };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return newOptions;
};

export default getOptionsUnhighlighted;
