import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import InfoIcon from "@material-ui/icons/Info";
import { Divider, InputNumber, Space, Switch, Typography } from "antd";
import { TimeStep } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import {
  selectChartCutoffFeature,
  selectChartLockUnitsFeature,
  selectChartMovingAverageDaysFeature,
  selectChartShutInMonthsFeature,
  selectChartSurvivorBiasFeature,
  selectChartTimeStepFeature,
  selectChartUseWeightedRatioAverageFeature,
  updateChartByPath
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";
import { ChartFeatures } from "types";
import { noop } from "utils";

import { Tooltip } from "components/base";

const { Text } = Typography;

const SettingsOptions = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const isScatterChart = false;

  const cutoff = useSelector((state: RootState) => selectChartCutoffFeature(state, id))
    ?.properties?.value;
  const shutInMonths = useSelector((state: RootState) =>
    selectChartShutInMonthsFeature(state, id)
  )?.properties?.value;
  const movingAverageDays = useSelector((state: RootState) =>
    selectChartMovingAverageDaysFeature(state, id)
  )?.properties?.value;
  const survivorBias = useSelector((state: RootState) =>
    selectChartSurvivorBiasFeature(state, id)
  )?.active;
  const useWeightedRatioAverage = useSelector((state: RootState) =>
    selectChartUseWeightedRatioAverageFeature(state, id)
  )?.active;
  const lockUnits = useSelector((state: RootState) =>
    selectChartLockUnitsFeature(state, id)
  )?.active;
  const timeStep = useSelector((state: RootState) =>
    selectChartTimeStepFeature(state, id)
  )?.properties?.value;

  const handleNumericChange = (key: keyof ChartFeatures) => (v) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.${key}.properties.value`,
        value: v
      })
    );
  };

  const handleChange = noop;

  const handleToggle = (key: keyof ChartFeatures) => (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.${key}.active`,
        value: active
      })
    );
  };

  const updateProductivityIndex = noop;
  return (
    <StyledSpace direction="vertical" split={<StyledDivider />}>
      <Container>
        <Text>Cut Off (%)</Text>
        <Tooltip title="Calculate average until well count below cutoff percent">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={cutoff}
          min={0}
          max={100}
          controls={false}
          data-testid="cutOffInput"
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleNumericChange("cutoff")(val);
          }}
        />
      </Container>
      <Container>
        <Text>Shut In Months</Text>
        <Tooltip title="Number of non producing months to be considered shut-in. Shut-in months are removed from historical production.">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={shutInMonths}
          min={0}
          max={20}
          controls={false}
          data-testid="shutInMonthsInput"
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleNumericChange("shutInMonths")(val);
          }}
        />
      </Container>

      <Container>
        <Text>Moving Average Days</Text>
        <Tooltip title="The number of days to use to calculate the simple moving average.">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={movingAverageDays}
          min={0}
          max={timeStep === TimeStep.Day ? 100 : 365}
          controls={false}
          data-testid="movingAverageDayInput"
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleNumericChange("movingAverageDays")(val);
          }}
        />
      </Container>

      {isScatterChart && (
        <>
          <Container>
            <Text>Point Size</Text>
            <Tooltip title="Size of dots">
              <StyledIcon />
            </Tooltip>
            <StyledInput
              value={1}
              min={0}
              max={100}
              controls={false}
              onChange={handleChange()}
            />
          </Container>
          <Container>
            <Text>Point Opacity</Text>
            <Tooltip title="Adjust the focused opacity">
              <StyledIcon />
            </Tooltip>
            <StyledInput
              value={1}
              min={0}
              max={1}
              step={0.1}
              controls={false}
              onChange={handleChange()}
            />
          </Container>
        </>
      )}
      <Container>
        <Text>Prevent Survivor Bias</Text>
        <Tooltip title="Allow wells that complete early to continue to affect average">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="survivorBiasCB"
          size="small"
          checked={survivorBias}
          onChange={handleToggle("survivorBias")}
        />
      </Container>

      <Container>
        <Text>Weighted Ratio</Text>
        <Tooltip title="Cumulative numerator/cumulative denominator">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="weightedRatioCB"
          size="small"
          checked={useWeightedRatioAverage}
          onChange={handleToggle("useWeightedRatioAverage")}
        />
      </Container>

      <Container>
        <Text>Lock Rate Units</Text>
        <Tooltip title="Locking rate units will keep the units on the y-axis constant, rather than switching between bbl/d and Mbbl/d, for example">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="lockUnits"
          size="small"
          checked={lockUnits}
          onChange={handleToggle("lockUnits")}
        />
      </Container>

      {false && (
        <Container>
          <Text>Show Lines of Best Fit</Text>
          <RightSwitch
            data-testid="linesOfBestFit"
            size="small"
            checked={false}
            onChange={handleChange()}
          />
        </Container>
      )}

      {false && (
        <Container>
          <Text>Productivity Index</Text>
          <Tooltip title="Plot the productivity index instead of the selected pressure.">
            <StyledIcon />
          </Tooltip>
          <Switch size="small" checked={false} onChange={updateProductivityIndex} />
        </Container>
      )}
    </StyledSpace>
  );
};

export default memo(SettingsOptions);

const StyledSpace = styled(Space)`
  --tooltip: var(--color-text);
  width: 260px;
  display: flex;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled(InfoIcon)`
  color: #a2aaad;
  margin-right: auto;
`;

const StyledInput = styled(InputNumber)`
  width: 70px;
  border-radius: var(--border-radius);
`;

const RightSwitch = styled(Switch)`
  margin-left: auto;
`;
