import { Scenario } from "../models/scenario";

export const flattenScenarioData = (scenarioData: Scenario[]) => {
  const flatternedData = [];
  scenarioData.forEach((scenario) => {
    scenario.layerPlacements?.forEach((layerPlacement) => {
      scenario.sticks?.forEach((stick) => {
        if (stick.configurationId === layerPlacement.id) {
          flatternedData.push({
            "Scenario ID": scenario.scenarioId,
            "Pad Name": scenario.padName,
            Play: scenario.play,

            "Layer ID": layerPlacement.id,
            Polygon: layerPlacement.polygon?.field + " " + layerPlacement.polygon?.value,
            "Reference Zone": layerPlacement.referenceZone,
            "On Prod Date": layerPlacement.onProdDate,
            Azimuth: layerPlacement.azimuth,
            "Hz Length": layerPlacement.hzLength,
            "Min Heel Offset": layerPlacement.minHeelOffset,
            "Min Toe Offset": layerPlacement.minToeOffset,
            Spacing: layerPlacement.horizontalSpacingBetweenSticks,
            "Left Horizontal Offset": layerPlacement.leftHorizontalOffset,
            "Right Horizontal Offset": layerPlacement.rightHorizontalOffset,
            "Vertical Offset": layerPlacement.verticalOffset,

            "Stick ID": stick.id,
            "Heel Depth": stick.heelDepth,
            "Toe Depth": stick.toeDepth,
            Length: stick.hzLength_m,
            Survey: JSON.stringify(stick.survey)
          });
        }
      });
    });
  });

  return flatternedData;
};
