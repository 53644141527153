// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiAxisZArrow } from "@mdi/js";

import { ThreeDViewerOptionT } from "models";

import { ToolbarButton } from "components/base";

function ThreeDVVerticalPortionToggle(options: ThreeDViewerOptionT) {
  const handleToggle = (active: boolean) => {
    options.onOptionChange &&
      options.onOptionChange({ showVerticalPortion: active }, false);
  };

  return (
    <ToolbarButton
      icon={<Icon path={mdiAxisZArrow} size={1.5} />}
      active={options.showVerticalPortion}
      tooltipText={"Show Vertical Portion of Hz Well"}
      overflowLabel={"Vertical Portion"}
      onToggle={handleToggle}
      styleKey="threeDVToolbar"
    />
  );
}

export default ThreeDVVerticalPortionToggle;
