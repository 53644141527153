import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject, useLayoutEffect, useState } from "react";

/**
 * Simple `hook` that abstracts `useResizeObserver` implementationand return DOMRect for given target ref.
 * @param targetRef - target ref to observe
 * @returns DOMRect
 */
const useSize = (targetRef: MutableRefObject<HTMLElement>) => {
  const [size, setSize] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (!targetRef.current) return;

    setSize(targetRef.current.getBoundingClientRect());
  }, [targetRef]);

  // Here the @rect-hook/resize-observer magic happens
  useResizeObserver(targetRef, (entry) => setSize(entry.contentRect));
  return size;
};

export default useSize;
