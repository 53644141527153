import { memo } from "react";

import CameraAltIcon from "@material-ui/icons/CameraAlt";

import { ToolbarButton } from "components/base";
import { useChartState } from "components/chart";
import { useScreenshotDispatch } from "components/screenshot/hooks";

const ScreenshotToggle = ({ widgetComponentType }) => {
  const screenshotDispatch = useScreenshotDispatch();
  const { screenshot, id } = useChartState();

  // update screenshot state when legend is toggled
  const toggle = () => (value) => {
    const next = { ...screenshot, visible: value };

    const widget = value
      ? {
          widgetId: id,
          widgetComponentType
        }
      : null;

    screenshotDispatch({
      payload: {
        settings: next.preset,
        widget
      }
    });
  };

  return (
    <ToolbarButton
      key="screenshot"
      active={screenshot.visible}
      icon={<CameraAltIcon fontSize="large" />}
      overflowLabel="Screenshot"
      tooltipText="Screenshot"
      onToggle={toggle()}
    />
  );
};

export default memo(ScreenshotToggle);
