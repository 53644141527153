import { FC } from "react";

import { LabeledSwitch } from "components/base";

type NormalizeToggleT = {
  checked: boolean;
  onToggle: (v: boolean) => void;
};

const NormalizeToggle: FC<NormalizeToggleT> = ({ checked, onToggle }) => {
  return (
    <LabeledSwitch
      testId="chart-normalize-toggle"
      switch={{
        isChecked: checked,
        onChange: onToggle
      }}
      label={{
        value: "Normalize",
        isBold: true,
        isUppercase: true
      }}
    />
  );
};

export default NormalizeToggle;
