export async function encrypt(data, base64Key) {
  const key = await importKey(base64Key); // Convert Base64 key to CryptoKey
  const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Random IV

  const encodedData = new TextEncoder().encode(data);
  const encryptedData = await window.crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encodedData
  );

  return {
    encrypted: Buffer.from(encryptedData).toString("base64"),
    iv: Buffer.from(iv).toString("base64")
  };
}

export async function decrypt(encryptedObject, base64Key) {
  const { encrypted, iv } = encryptedObject;
  const key = await importKey(base64Key); // Convert Base64 key to CryptoKey

  const decryptedData = await window.crypto.subtle.decrypt(
    { name: "AES-GCM", iv: Buffer.from(iv, "base64") },
    key,
    Buffer.from(encrypted, "base64")
  );

  return new TextDecoder().decode(decryptedData);
}

// ✅ Import a Base64-encoded 32-byte AES key
async function importKey(base64Key) {
  const rawKey = Buffer.from(base64Key, "base64");
  return await window.crypto.subtle.importKey(
    "raw",
    rawKey,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
}
