export function checkTokenExpire(currentJwtToken): boolean {
  const jwtToken = JSON.parse(atob(currentJwtToken.split(".")[1]));
  const expires = new Date(jwtToken.exp * 1000);

  //check if token is about to expire
  const nowPlusMinutes = new Date();
  nowPlusMinutes.setMinutes(nowPlusMinutes.getMinutes() + 3);

  if (nowPlusMinutes >= expires) {
    return true;
  }

  return false;
}
