// Custom hook to use the PadScenarioContext
import { useContext } from "react";

import { ForecastingContext } from "./ForecastingProvider";

export const useForecastingContext = () => {
  const context = useContext(ForecastingContext);
  if (!context) {
    throw new Error("usePadScenario must be used within a PadScenarioProvider");
  }
  return context;
};
