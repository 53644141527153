// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiSetNone } from "@mdi/js";

import { ToolbarButton } from "components/base";

import { updateXDASettings, useVisState } from "../../context";

function XdaOverlapToggle() {
  const [{ xda }, visDispatch] = useVisState();
  const { showOverlap } = xda.settings;

  const handleToggle = (active: boolean) => {
    const nextSettings = { ...xda.settings, showOverlap: active };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <ToolbarButton
      icon={<Icon path={mdiSetNone} size={1.75} />}
      active={showOverlap}
      tooltipText={"Show Overlap"}
      overflowLabel={"Overlap"}
      onToggle={handleToggle}
      styleKey="xdaToolbar"
    />
  );
}

export default XdaOverlapToggle;
