import { Rnd } from "react-rnd";
import { FixedSizeList as List } from "react-window";

import { Typography } from "antd";
import classnames from "classnames";
import styled from "styled-components";
import { noop } from "utils";

import ChartLegendItem from "./LegendItem";

const { Text } = Typography;
const noWellsFoundText = "No entities were found";

export type ParentDimensionsT = { width: number; height: number };
export type LegendPositionT = { x: number; y: number };
export type LegendDimensionsT = { width: number; height: number };

export type LegendT = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ChartLegendT = {
  className?: string;
  disableDragging?: boolean;
  showLegendBorder?: boolean;
  width?: number;
};
export type Ref = HTMLDivElement;

const ChartLegend = ({ className = "", disableDragging = false, width = 200 }) => {
  // markup: no wells found
  const noWellsMarkup = <StyledText type="danger">{noWellsFoundText}</StyledText>;

  // markup: react-window item
  const Row = ({ style }) => (
    <ChartLegendItem useCount={false} style={style} key={1} legend={1} />
  );

  // handlers
  const handleDragStop = noop;

  const handleResize = noop;

  // derived classes and styles
  const wrapperClassNames = classnames("chart-legend", className);

  // update the currentDimensions for rendering the legend
  const currentDimensions = { width: width, height: 0 };

  // update currentPosition for rendering the legend
  const currentPosition = { x: 0, y: 0 };

  return (
    <StyledRnd
      data-testid="chart-legend"
      bounds="parent"
      className={wrapperClassNames}
      disableDragging={disableDragging}
      minWidth="140px"
      onDragStop={handleDragStop}
      onResize={handleResize}
      position={{ x: currentPosition.x, y: currentPosition.y }}
      size={{ width: currentDimensions.width, height: currentDimensions.height }}>
      <Wrapper>
        <StyledTitle></StyledTitle>
        {noWellsMarkup}
        <List width="100%">{Row}</List>
      </Wrapper>
    </StyledRnd>
  );
};

export default ChartLegend;
ChartLegend.displayName = "ChartLegend";

const StyledRnd = styled(Rnd)`
  position: absolute;
  margin: 10px;
  background: ${(props) =>
    props.backgroundopacity === undefined
      ? "White"
      : `rgba(255, 255, 255, ${props.backgroundopacity})`};
  border: ${(props) => (props.showLegendBorder ? "1px solid gray" : "")};
  overflow: hidden;
  z-index: 10;
  min-height: 35px;

  & > div:last-child {
    position: unset;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  opacity: 1;
  padding-right: 7px;
  padding-bottom: 6px;
`;

const StyledTitle = styled.span`
  font-weight: 600;
  text-align: left;
  padding: 6px 12px;
  margin-bottom: 0 !important;
`;
const StyledText = styled(Text)`
  text-align: center;
  padding: 4px 16px;
`;
