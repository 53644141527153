import { memo } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { Delete } from "@material-ui/icons";
import { Popconfirm, Tooltip } from "antd";
import styled from "styled-components";

import { IColumnSet } from "models/columns";

import { useDeleteColumnSetMutation } from "./hooks";

export interface ISaveButton {
  selectedColumnSet: IColumnSet;
  setSelectedColumnSet: (columnSet: IColumnSet) => void;
}

const DeleteButton = ({ selectedColumnSet, setSelectedColumnSet = null }) => {
  const queryClient = useQueryClient();

  const deleteColumnSetMutation = useDeleteColumnSetMutation(
    async () => {
      toast.success("Column set deleted");
      await queryClient.invalidateQueries("getColumnSets");
      setSelectedColumnSet(null);
    },
    (error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as any;
      if (err && err.response?.data) {
        // eslint-disable-next-line no-console
        console.error("Export error", err.response?.data);
      }
      toast.error("Failed to delete column set");
      setSelectedColumnSet(null);
    }
  );

  return (
    <Popconfirm
      placement="bottom"
      onConfirm={(evt) => {
        if (selectedColumnSet) {
          deleteColumnSetMutation.mutate(selectedColumnSet.columnSetId);
        }
        evt.stopPropagation();
        evt.preventDefault();
      }}
      onCancel={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
      okText="Delete"
      okType="danger"
      title={`Are you sure you want to delete this column set?`}>
      <Tooltip title="Delete Column Set" mouseEnterDelay={0.6}>
        <ActionButton danger>
          <Delete fontSize="large" />
          {"Delete"}
        </ActionButton>
      </Tooltip>
    </Popconfirm>
  );
};

export default memo(DeleteButton);

const ActionButton = styled.button`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  background: transparent;
  border: none;
  color: #a2aaad;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;
