import { memo } from "react";

import LaunchIcon from "@material-ui/icons/Launch";
import { noop } from "utils";

import ToolbarButton from "../../base/ToolbarButton";

const Popout = () => {
  const popout = noop;

  return (
    <ToolbarButton
      active={false}
      icon={<LaunchIcon fontSize="large" />}
      overflowLabel="Pop out"
      tooltipText="Open in new Tab"
      onToggle={() => popout()}
    />
  );
};

export default memo(Popout);
