import { memo } from "react";

import BugReportIcon from "@material-ui/icons/BugReport";
import { IS_DEV_ENV } from "constants/app.constants";

import ToolbarButton from "../../base/ToolbarButton";
import { useChartDispatch, useChartState } from "../context";

const DevDebugToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();

  const toggle = () => (value) => {
    const next = { ...settings, debugMode: value };
    dispatch({ type: "settings", payload: next });
  };

  if (!IS_DEV_ENV) {
    return null;
  }

  return (
    <ToolbarButton
      active={settings.debugMode}
      icon={<BugReportIcon fontSize="large" />}
      overflowLabel="Debug Chart Options"
      tooltipText="Debug Chart Options"
      onToggle={toggle()}
    />
  );
};

export default memo(DevDebugToggle);
