import { createStateWithDefaults } from "entities/utils";
import { ChartEntity } from "types";

export const DEFAULT_CHART_ENTITY: Partial<ChartEntity> = {
  id: "",
  options: {},
  chartType: undefined,
  screenshot: {
    size: { width: undefined, height: undefined },
    editables: []
  },
  loading: true,
  overrides: {},
  request: undefined,
  response: undefined,
  features: {
    screenshot: undefined,
    fullScreen: undefined,
    datatable: undefined,
    spaghetti: undefined,
    forecast: undefined,
    source: undefined,
    debug: undefined,
    timeStep: undefined,
    isProducingRateType: undefined,
    cutoff: undefined,
    shutInMonths: undefined,
    movingAverageDays: undefined,
    survivorBias: undefined,
    useWeightedRatioAverage: undefined,
    lockUnits: undefined
  },
  axisState: {
    xField: undefined,
    yField: undefined,
    groupByField: undefined
  }
};

export const generateChartEntity = createStateWithDefaults(DEFAULT_CHART_ENTITY);
