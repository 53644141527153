import { LabeledSwitch } from "components/base";

import XdaDataPicker from "../../XdaDataPicker";
import { updateXDASettings, useVisState } from "../../context";
import { XdaOptionsWrapper } from "./XdaOptionsLayout";

const XdaDataOptions = () => {
  const [{ xda }, visDispatch] = useVisState();
  const { showData } = xda.settings;

  // sync option changes with context
  const handleSettingChange = (key) => (value) => {
    const nextSettings = { ...xda.settings, [key]: value };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <XdaOptionsWrapper width={"400px"}>
      <LabeledSwitch
        label={{ value: "Show Data" }}
        switch={{
          isChecked: showData,
          onChange: handleSettingChange("showData")
        }}
      />
      {showData && (
        <XdaDataPicker
          onChange={(fields) => {
            const nextSettings = { ...xda.settings, dataFields: fields };
            updateXDASettings(visDispatch, nextSettings);
          }}
        />
      )}
    </XdaOptionsWrapper>
  );
};

export default XdaDataOptions;
