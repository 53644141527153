// eslint-disable-next-line import/no-named-as-default
// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { mdiChartAreaspline, mdiChartLine } from "@mdi/js";
import { Tabs as AntDTabs, Switch, Tooltip } from "antd";
import styled from "styled-components";
import { getChartType, getChartTypeIndex } from "utils";
import { getShortenedProductName } from "utils/chart/productNameHelpers";

import useBetaFeatures from "hooks/useBetaFeatures";

import { BaseButton, BaseMenu, BaseTooltip } from "components/base";
import BoxPlot from "components/icons/BoxPlot";
import CrossPlot from "components/icons/CrossPlot";
import CumTime from "components/icons/CumTime";
import DeclineRate from "components/icons/DeclineRate";
import MaterialBalanceSheet from "components/icons/MaterialBalanceSheet";
import Mosaic from "components/icons/Mosaic";
import PieChart from "components/icons/PieChart";
import Probit from "components/icons/Probit";
import RateCum from "components/icons/RateCum";
import RateDate from "components/icons/RateDate";
import RateTime from "components/icons/RateTime";
import StackedBar from "components/icons/StackedBar";
import TotalRateCum from "components/icons/TotalRateCum";
import TotalRateDate from "components/icons/TotalRateDate";
import TrendDate from "components/icons/TrendDate";

import {
  ALL_CHART_TYPES,
  ALL_PRODUCT_TYPES,
  ChartProduct,
  ChartProductTypes,
  ChartType,
  InjectionProduct,
  MaterialBalanceTimeChartProducts,
  ProductKind,
  ProductTypeT,
  SalesProduct,
  SalesProductList,
  ShrinkageProduct,
  SummaryProduct,
  ThroughputProduct,
  getProductLabel
} from "../../constants";
import { EntityKind } from "../../models/entityKind";
import { getProductIcon } from "../../utils/chart/getProductIcon";
import "./ChartSelector.scss";
import ChartTypeHeader from "./ChartTypeHeader";
import ProductHeader from "./ProductHeader";
import { ChartProductGroupT, useChartDispatch, useChartState } from "./context";

const chartTypeIcons = [
  <RateCum key="rate-cum" />,
  <RateTime key="rate-time" />,
  <RateDate key="rate-date" />,
  <TotalRateDate key="total-rate-date" />,
  <CumTime key="cum-time" />,
  <CrossPlot key="cross-plot" />,
  <Probit key="probit" />,
  <BoxPlot key="box-plot" />,
  <StackedBar key="stacked-bar" />,
  <PieChart key="pie-chart" />,
  <Mosaic key="mosaic" />,
  <MaterialBalanceSheet key="material-balance-sheet" />,
  <TotalRateCum key="total-rate-cum" />,
  <Icon path={mdiChartLine} size={1.8} key="cagr" />,
  <DeclineRate key="base-decline-rate" />,
  <TrendDate key="trend-date" />,
  <Icon path={mdiChartAreaspline} size={1.8} key="well-contribution-cum-date" />
];

export const chartTypeIconsGrouped = [
  [<RateCum key="rate-cum" />, <RateTime key="rate-time" />, <CumTime key="cum-time" />],
  [
    <RateDate key="rate-date" />,
    <TotalRateDate key="total-rate-date" />,
    <TotalRateCum key="total-rate-cum" />
  ],
  [
    <CrossPlot key="cross-plot" />,
    <Probit key="probit" />,
    <BoxPlot key="box-plot" />,
    <StackedBar key="stacked-bar" />,
    <PieChart key="pie-chart" />,
    <Mosaic key="mosaic" />,
    <MaterialBalanceSheet key="material-balance-sheet" />,
    <Icon path={mdiChartLine} size={1.8} key="cagr" />,
    <DeclineRate key="base-decline-rate" />,
    <TrendDate key="trend-date" />
  ]
];
export const midstreamChartTypeIconsGrouped = [
  [
    <RateDate key="rate-date" />,
    <TotalRateDate key="total-rate-date" />,
    <Icon path={mdiChartAreaspline} size={1.8} key="well-contribution-cum-date" />
  ],
  [<CrossPlot key="cross-plot" />]
];
// Multi product
export const chartTypeIconsGroupedMultiProduct = [
  <RateCum key="rate-cum" />,
  <RateTime key="rate-time" />,
  <CumTime key="cum-time" />,
  <RateDate key="rate-date" />,
  <RateDate key="trend-date" />
];

// End Multi product

const tabs: { key: string; label: ChartProductGroupT }[] = [
  {
    key: ProductKind.Summary.toString(),
    label: "Summary"
  },
  {
    key: ProductKind.Wellhead.toString(),
    label: "Wellhead"
  },
  {
    key: ProductKind.Sales.toString(),
    label: "Sales"
  },
  {
    key: ProductKind.Throughput.toString(),
    label: "Throughput"
  },
  {
    key: ProductKind.Injection.toString(),
    label: "Injection"
  },
  {
    key: ProductKind.Shrinkage.toString(),
    label: "Shrinkage"
  }
];

const ProductContainer = styled.div`
  width: 375px;
  padding-bottom: 25px;
  max-height: 90vh;
  overflow-y: auto; /* Enable vertical scrolling */

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d9e1e2;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-list {
    display: flex;
    justify-content: center;
    padding: 0 25px;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 1.4rem;
    padding: 0 0px;
    margin: 0;
  }
`;
const ChartTypeMenuContainer = styled.div`
  width: 375px;
  padding-bottom: 25px;
`;
const ChartTypeButton = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  color: #041c2c;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;

  &.isSelected {
    background: rgba(var(--color-shadow-rgb), 0.3);
    color: #041c2c;

    svg {
      color: var(--color-primary);
    }
  }

  span {
    margin-top: 3px;
  }

  svg {
    color: #a2aaad;
    transition: color var(--duration-short);
  }

  &:hover {
    background: rgba(var(--color-shadow-rgb), 0.3);

    svg {
      color: var(--color-primary);
    }
  }
`;

const ProductButton = styled(BaseButton)`
  min-height: 60px;
  height: auto;
  border-radius: 4px;
  color: #041c2c;
  background-color: unset;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;

  &.isSelected {
    background: rgba(var(--color-shadow-rgb), 0.3);
    color: var(--color-primary);

    svg {
      color: var(--color-primary);
    }
  }

  span {
    margin-top: 0;
  }

  .label {
    line-height: 18px;
    margin: 0 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: rgba(var(--color-shadow-rgb), 0.3);

    svg {
      color: var(--color-primary);
    }
  }

  &.isSelected:hover {
    background: rgba(var(--color-shadow-rgb), 0.9);

    svg {
      color: var(--color-primary);
    }
  }

  svg {
    min-height: 40px;
    color: #a2aaad;
    transition: color var(--duration-short);
  }
`;

const IconDropdown = styled.div`
  color: #a2aaad;
  transition: color var(--duration-short);
  cursor: pointer;
  display: flex;
  &:hover {
    color: var(--color-primary);
  }
  margin-left: 21px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  transform: translate(-10px, 30px);
  margin-top: -16px;
  position: relative;
  z-index: 1;

  .ant-switch {
    background-color: var(--color-primary);
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ChartSelector({
  chartType,
  product,
  typeIdx,
  setState,
  onChange
}): JSX.Element {
  const chartDispatch = useChartDispatch();
  const { settings, availableChartTypes, availableChartProductGroups, entityKind } =
    useChartState();
  const { hasFeature } = useBetaFeatures();

  // Current tab; no product selected yet. Can change multiple times.
  const [selectedProductGroup, setSelectedProductGroup] = useState(
    availableChartProductGroups.includes("Throughput")
      ? ProductKind.Throughput
      : SalesProductList.includes(product) && hasFeature("Plant Liquids")
      ? ProductKind.Sales
      : ProductKind.Wellhead
  );
  // Tab with selected product; reopens selector on this tab with active product.
  const [activeProductGroup, setActiveProductGroup] = useState(selectedProductGroup);

  const showProduct = ChartProductTypes.includes(chartType);

  function getYieldsToggleLabels(selectedProductGroup: number): {
    rawLabel: string;
    salesLabel: string;
  } {
    if (
      selectedProductGroup === ProductKind.Throughput ||
      selectedProductGroup === ProductKind.Shrinkage
    ) {
      return { rawLabel: "Inlet", salesLabel: "Outlet" };
    } else {
      return { rawLabel: "Raw", salesLabel: "Sales" };
    }
  }

  const { rawLabel, salesLabel } = getYieldsToggleLabels(selectedProductGroup);

  const inletRateLabel = "Inlet Rate";
  const outletRateLabel = "Outlet Rate";

  const [yieldsType, setYieldsType] = useState(rawLabel);

  const onChangeType = () => {
    if (yieldsType === rawLabel) {
      setYieldsType(salesLabel);
    } else {
      setYieldsType(rawLabel);
    }
  };

  const setCloseProductMenuRef = useCallback((closeMenu) => {
    closeProductMenuRef.current = closeMenu;
  }, []);

  const closeProductMenuRef = useRef(null);

  const setProduct = useCallback(
    (product) => {
      setActiveProductGroup(selectedProductGroup);
      setState({ chartType, product, typeIdx });
      chartDispatch({
        type: "settings",
        payload: { ...settings, isDefaultLockOn: true }
      });
      if (closeProductMenuRef.current) {
        closeProductMenuRef.current();
      }
    },
    [chartType, selectedProductGroup, settings]
  );

  useEffect(() => {
    if (
      (settings.product === ALL_PRODUCT_TYPES.CasingPressure.label ||
        settings.product === ALL_PRODUCT_TYPES.TubingPressure.label) &&
      ((settings.timeStep !== "hybrid" && settings.timeStep !== "day") ||
        (settings.chartType !== ALL_CHART_TYPES.RateCum.label &&
          settings.chartType !== ALL_CHART_TYPES.RateTime.label))
    ) {
      setProduct(ALL_PRODUCT_TYPES.Oil.label);
    }
  }, [settings.timeStep, settings.product, settings.chartType]);

  const getLiquidYieldsProductHeader = () => {
    if (
      selectedProductGroup !== ProductKind.Sales &&
      selectedProductGroup !== ProductKind.Throughput
    ) {
      return null;
    }
    const index = yieldsType === rawLabel ? 2 : 3;
    const header: ProductTypeT =
      selectedProductGroup === ProductKind["Throughput"]
        ? ThroughputProduct[index]
        : SalesProduct[index];

    function removeCO2Strings(items) {
      return items.filter((item) => !item.includes("CO₂"));
    }
    if (hasFeature("Facility Volumes")) {
      header.items = removeCO2Strings(header.items);
    }
    return (
      <>
        <ButtonContainer>
          <Switch
            unCheckedChildren={rawLabel}
            checkedChildren={salesLabel}
            checked={yieldsType === salesLabel}
            onChange={onChangeType}
            size="small"></Switch>
        </ButtonContainer>

        <ProductHeader key={header.name} header={header.name}>
          {header.items.map((prod) => (
            <ProductButton
              isDisabled={
                chartType === ALL_CHART_TYPES.MaterialBalanceTime.label &&
                !MaterialBalanceTimeChartProducts.includes(prod)
              }
              key={prod}
              ellipses={false}
              onClick={() => {
                chartDispatch({
                  type: "settings",
                  payload: Object.assign({}, settings, {
                    axisMinMax: {
                      xMin: null,
                      xMax: null,
                      yMin: null,
                      yMax: null
                    },
                    referenceLine: {
                      lines: [],
                      points: []
                    }
                  })
                });
                setProduct(prod);
              }}
              appearance=""
              className={`${prod === product ? "isSelected" : null}`}>
              {getProductIcon(prod)}
              <span>
                {getShortenedProductName(
                  selectedProductGroup,
                  getProductLabel(prod, entityKind),
                  rawLabel,
                  salesLabel,
                  inletRateLabel,
                  outletRateLabel
                )}
              </span>
            </ProductButton>
          ))}
        </ProductHeader>
      </>
    );
  };

  const getShrinkagePercentageProductHeader = () => {
    if (selectedProductGroup !== ProductKind.Shrinkage) {
      return null;
    }
    const index = yieldsType === rawLabel ? 1 : 2;
    const header: ProductTypeT = ShrinkageProduct[index];

    return (
      <>
        <ButtonContainer>
          <Switch
            unCheckedChildren={rawLabel}
            checkedChildren={salesLabel}
            checked={yieldsType === salesLabel}
            onChange={onChangeType}
            size="small"></Switch>
        </ButtonContainer>

        <ProductHeader key={header.name} header={header.name}>
          {header.items.map((prod) => (
            <ProductButton
              isDisabled={
                chartType === ALL_CHART_TYPES.MaterialBalanceTime.label &&
                !MaterialBalanceTimeChartProducts.includes(prod)
              }
              key={prod}
              ellipses={false}
              onClick={() => {
                chartDispatch({
                  type: "settings",
                  payload: Object.assign({}, settings, {
                    axisMinMax: {
                      xMin: null,
                      xMax: null,
                      yMin: null,
                      yMax: null
                    },
                    referenceLine: {
                      lines: [],
                      points: []
                    }
                  })
                });
                setProduct(prod);
              }}
              appearance=""
              className={`${prod === product ? "isSelected" : null}`}>
              {getProductIcon(prod)}
              <span>
                {getShortenedProductName(
                  selectedProductGroup,
                  getProductLabel(prod, entityKind),
                  rawLabel,
                  salesLabel,
                  inletRateLabel,
                  outletRateLabel
                )}
              </span>
            </ProductButton>
          ))}
        </ProductHeader>
      </>
    );
  };

  useHotkeys(
    "tab",
    () => {
      if (!hasFeature("Plant Liquids")) {
        return;
      }

      // Filters out the product groups that are not available for the current chart type.
      const filteredProductGroupValues = availableChartProductGroups
        .map((group) => ProductKind[group])
        .sort();

      // Cycles through the tabs when the 'tab' key is pressed.
      const numberOfTabs = filteredProductGroupValues.length;
      const currentIndex = filteredProductGroupValues.indexOf(selectedProductGroup);
      const nextIndex = (currentIndex + 1) % numberOfTabs;
      setSelectedProductGroup(filteredProductGroupValues[nextIndex]);
    },
    [selectedProductGroup]
  );

  let currentProduct;
  if (selectedProductGroup === ProductKind["Wellhead"]) {
    currentProduct = ChartProduct;
  } else if (selectedProductGroup === ProductKind["Sales"]) {
    currentProduct = SalesProduct;
  } else if (selectedProductGroup === ProductKind["Throughput"]) {
    currentProduct = ThroughputProduct;
  } else if (selectedProductGroup === ProductKind["Shrinkage"]) {
    currentProduct = ShrinkageProduct;
  } else if (selectedProductGroup === ProductKind["Summary"]) {
    currentProduct = SummaryProduct;
  } else {
    currentProduct = InjectionProduct;
  }

  const hasPlantLiquids = hasFeature("Plant Liquids");
  const hasFacilityVolumes = hasFeature("Facility Volumes");

  function removeCO2Items(data) {
    return data.map((category) => ({
      ...category,
      items: category.items.filter((item) => !item.includes("CO₂"))
    }));
  }

  if (hasFacilityVolumes) {
    currentProduct = removeCO2Items(currentProduct);
  }

  return (
    <div className={`charts-selector flex`}>
      <BaseTooltip text={chartType}>
        <BaseMenu trigger={<IconDropdown>{chartTypeIcons[typeIdx]}</IconDropdown>}>
          {({ closeMenu }) => (
            <ChartTypeMenuContainer>
              {(availableChartTypes?.length > 0 ? availableChartTypes : ChartType).map(
                (header, hIdx) => (
                  <ChartTypeHeader key={header.name} header={header.name}>
                    {header.items.map((ct, tIdx) => {
                      return (
                        <Tooltip key={ct} title={getChartType(ct)?.tooltip ?? ""}>
                          <ChartTypeButton
                            key={ct}
                            ellipses={false}
                            onClick={() => {
                              closeMenu();
                              const typeIdx = getChartTypeIndex(ct);
                              setState({
                                chartType: ct,
                                product,
                                typeIdx: typeIdx
                              });
                              onChange && onChange(ct);
                            }}
                            appearance=""
                            className={`${ct === chartType ? "isSelected" : null}`}>
                            {entityKind === EntityKind.Well
                              ? chartTypeIconsGrouped[hIdx][tIdx]
                              : midstreamChartTypeIconsGrouped[hIdx][tIdx]}
                            <span>{ct}</span>
                          </ChartTypeButton>
                        </Tooltip>
                      );
                    })}
                  </ChartTypeHeader>
                )
              )}
            </ChartTypeMenuContainer>
          )}
        </BaseMenu>
      </BaseTooltip>
      {showProduct && (
        <BaseTooltip
          text={getProductLabel(product, entityKind).replace("Yields", "Yield")}>
          <BaseMenu
            onOpen={() => setSelectedProductGroup(activeProductGroup)}
            trigger={<IconDropdown>{getProductIcon(product)}</IconDropdown>}>
            {({ closeMenu }) => (
              <ProductContainer>
                {hasPlantLiquids && (
                  <AntDTabs
                    items={tabs.filter((tab) =>
                      availableChartProductGroups.includes(tab.label)
                    )}
                    activeKey={selectedProductGroup.toString()}
                    onChange={(newTabKey: string) =>
                      setSelectedProductGroup(parseInt(newTabKey))
                    }
                  />
                )}
                {setCloseProductMenuRef(closeMenu)}
                {currentProduct.map((header) =>
                  ![
                    "Yields Raw",
                    "Yields Sales",
                    "Yields Inlet",
                    "Yields Outlet",
                    "Percent Inlet",
                    "Percent Outlet"
                  ].includes(header.name) ? (
                    <ProductHeader key={header.name} header={header.name}>
                      {header.items
                        .filter(
                          (prod) =>
                            prod !== "Intra-Network Gas Inlet" &&
                            prod !== "Intra-Network Gas Outlet"
                        )
                        .map((prod) => {
                          if (
                            prod === ALL_PRODUCT_TYPES.CasingPressure.label ||
                            prod === ALL_PRODUCT_TYPES.TubingPressure.label
                          ) {
                            if (
                              (chartType !== ALL_CHART_TYPES.RateCum.label &&
                                chartType !== ALL_CHART_TYPES.RateTime.label) ||
                              (settings.timeStep !== "hybrid" &&
                                settings.timeStep !== "day")
                            ) {
                              return null;
                            }
                          }
                          return (
                            <ProductButton
                              isDisabled={
                                chartType === ALL_CHART_TYPES.MaterialBalanceTime.label &&
                                !MaterialBalanceTimeChartProducts.includes(prod)
                              }
                              key={prod}
                              ellipses={false}
                              onClick={() => {
                                chartDispatch({
                                  type: "settings",
                                  payload: Object.assign({}, settings, {
                                    axisMinMax: {
                                      xMin: null,
                                      xMax: null,
                                      yMin: null,
                                      yMax: null
                                    },
                                    referenceLine: {
                                      lines: [],
                                      points: []
                                    }
                                  })
                                });
                                setProduct(prod);
                              }}
                              appearance=""
                              className={`${prod === product ? "isSelected" : null}`}>
                              {getProductIcon(prod)}
                              <span>
                                {getShortenedProductName(
                                  selectedProductGroup,
                                  getProductLabel(prod, entityKind),
                                  rawLabel,
                                  salesLabel,
                                  inletRateLabel,
                                  outletRateLabel
                                )}
                              </span>
                            </ProductButton>
                          );
                        })}
                    </ProductHeader>
                  ) : null
                )}
                {getLiquidYieldsProductHeader()}
                {getShrinkagePercentageProductHeader()}
              </ProductContainer>
            )}
          </BaseMenu>
        </BaseTooltip>
      )}
    </div>
  );
}
