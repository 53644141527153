import { FC, ReactNode } from "react";

import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import StarIcon from "@material-ui/icons/Star";
import { Dropdown } from "antd";
import { EvaSource } from "constants/charts.enums";

export const DataSourceIcon: { [key in EvaSource]: JSX.Element } = {
  Public: <PublicIcon className="source-icon" />,
  Private: <LockIcon className="source-icon" />,
  Hybrid: <StarIcon className="source-icon" />
};

type DataSourceSelectT = {
  children: ReactNode;
  onChange: (value: EvaSource) => void;
  trigger: ("click" | "hover" | "contextMenu")[];
  value: EvaSource;
};

const DataSourceSelect: FC<DataSourceSelectT> = ({
  children,
  onChange,
  trigger,
  value
}) => {
  return (
    <Dropdown
      menu={{
        onClick: (v) => onChange(v.key as EvaSource),
        selectedKeys: [value],
        items: [
          {
            key: "Public",
            title: "Production data comes from public data source (gDC, IHS, etc..)",
            icon: DataSourceIcon.Public,
            label: "Public Source"
          },
          {
            key: "Private",
            title: "Production data comes from private data source.",
            icon: DataSourceIcon.Private,
            label: "Private Source"
          },
          {
            key: "Hybrid",
            title:
              "Production data comes from private data source first then public if no private data exists.",
            icon: DataSourceIcon.Hybrid,
            label: "Hybrid Source"
          }
        ]
      }}
      trigger={trigger}>
      {children}
    </Dropdown>
  );
};

export default DataSourceSelect;
