import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export const exportToExcel = (data, filename = "export.xlsx") => {
  // Convert JSON data to worksheet
  const ws = XLSX.utils.json_to_sheet(data);

  // Create a new workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write the workbook and convert to a Blob
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });

  // Trigger the file download
  saveAs(blob, filename);
};
