import { memo, useState } from "react";

import SettingsIcon from "@material-ui/icons/Settings";
import { Popover } from "antd";
import { ALL_CHART_TYPES } from "constants/chart.constants";

import { useChartState } from "components/chart/context";

import ToolbarButton from "../../../base/ToolbarButton";
import SettingsOptions from "./SettingsOptions";

const SettingsToggle = () => {
  const { settings } = useChartState();

  const [visible, setVisible] = useState(false);
  const isDisabled = settings.chartType === ALL_CHART_TYPES.WellContribution.label;

  return (
    <Popover
      arrowPointAtCenter
      content={<SettingsOptions />}
      placement="bottomRight"
      trigger="click"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        icon={<SettingsIcon fontSize="large" />}
        isMenuButton={true}
        overflowLabel="Chart Options"
        tooltipText="Chart Options"
        disabled={isDisabled}
        active={
          settings.useWeightedRatioAverage || settings.survivorBias || settings.lockUnits
        }
      />
    </Popover>
  );
};

export default memo(SettingsToggle);
