import { DEFAULT_MOVING_AVG_DAYS } from "constants/chart.constants";
import { EvaChart, EvaChartId, EvaSource, TimeStep } from "constants/charts.enums";

import { IChartSettings } from "models/chart";

export const BASE_CHART_SETTINGS: Partial<IChartSettings> = {
  product: "Oil",
  forecast: true,
  axisMinMax: null,
  isDefaultLockOn: false,
  id: "",
  chartType: EvaChart.RateCum,
  chartTypeId: EvaChartId.RateCum,
  screenshot: false,
  showToolbar: true,
  producing: false,
  normalizeBy: {
    field: "Well_Design.HzLength_m",
    display: "Hz Length (m)",
    per: 100,
    unit: "m",
    useMultilinearNormalization: false,
    threshold: 0,
    lowerScalar: 1,
    higherScalar: 1,

    useNormalizeBy: false
  },
  legend: {
    visible: false,
    position: "NW"
  },
  timeStep: TimeStep.Month,
  source: EvaSource.Public,
  fullscreen: false,
  cutoff: 50,
  shutInMonths: 3,
  survivorBias: true,
  useWeightedRatioAverage: true,
  busy: false,
  movingAverageDays: DEFAULT_MOVING_AVG_DAYS,
  log: {
    x: false,
    y: false
  }
};
