import { EvaSource, TimeStep } from "constants/charts.enums";

export enum PdenSourceEnum {
  gDC = 0,
  enverus = 2,
  aer = 3,
  mcdan = 4,
  novi = 5,
  ihs = 8,
  Public = 100,
  Private = 101,
  Hybrid = 102
}

export interface PdenDataSourceSetting {
  source: EvaSource;
  timeStep: TimeStep;
}
