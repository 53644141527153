// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo, useState } from "react";

import { mdiDraw } from "@mdi/js";
import { Popover } from "antd";

import ToolbarButton from "../../base/ToolbarButton";
import AddReferenceLineMenu from "./AddReferenceLineMenu";

const AddReferenceLineToggle = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      arrowPointAtCenter
      content={<AddReferenceLineMenu />}
      placement={"left"}
      trigger="hover"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        icon={<Icon path={mdiDraw} size={1.5} />}
        isMenuButton={true}
        overflowLabel="Reference Lines"
        tooltipText="Reference Lines"
        active={false}
      />
    </Popover>
  );
};

export default memo(AddReferenceLineToggle);
