import InfoIcon from "@material-ui/icons/Info";
import { Switch, Tooltip, Typography } from "antd";
import styled from "styled-components";

interface ILabeledSwitchProps {
  testId?: string;
  switch: {
    isDisabled?: boolean;
    isChecked: boolean;
    onChange: (checked: boolean) => void;
    size?: "small" | "large";
  };
  label: {
    isBold?: boolean;
    isUppercase?: boolean;
    value: string;
    tooltip?: string;
  };
}

const { Text } = Typography;

export default function LabeledSwitch(props: ILabeledSwitchProps) {
  return (
    <Container>
      <LabelWrapper>
        <StyledText
          className="labeled-switch_label"
          strong={props.label.isBold}
          $isUppercase={props.label.isUppercase}>
          {props.label.value}
        </StyledText>
        {props.label.tooltip && (
          <Tooltip title={props.label.tooltip}>
            <StyledIcon />
          </Tooltip>
        )}
      </LabelWrapper>
      <StyledSwitch
        data-testid={props.testId}
        disabled={props.switch.isDisabled}
        size={props.switch.size || "small"}
        checked={props.switch.isChecked}
        onChange={props.switch.onChange}
        aria-checked={props.switch.isChecked}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-switch-checked:focus {
    box-shadow: none;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledSwitch = styled(Switch)`
  margin-left: auto;
`;

const StyledText = styled(Text)<{ isUppercase?: boolean }>`
  text-transform: ${(props) => (props.$isUppercase ? "uppercase" : "none")};
  letter-spacing: ${(props) => (props.strong ? "0.25px" : 0)};
  white-space: nowrap;
`;

const StyledIcon = styled(InfoIcon)`
  color: #a2aaad;
  margin-right: auto;
`;
