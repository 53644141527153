import { memo, useCallback } from "react";
import { useSelector } from "react-redux";

import LaunchIcon from "@material-ui/icons/Launch";
import { RootState } from "store/rootReducer";

import { useUser } from "hooks";

import { ToolbarButton } from "components/base";
import { useChartSettings } from "components/multiphase-chart/context";

const getPopoutUrl = (channel) =>
  `/single/multiphase-chart/${channel}?channel=${channel}`;

const Popout = () => {
  const [chartSettings] = useChartSettings();
  const { sessionId } = useUser();

  const channel = useSelector((state: RootState) => state.channel.channel);
  const filterId = useSelector((state: RootState) => state.filter.filterId);

  const groupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);

  const selectedWells = useSelector((state: RootState) => state.map.selectedWells);

  const popout = useCallback(() => {
    sessionStorage.removeItem(channel);
    const globalGroupBy = { ...groupBy };

    const data = JSON.stringify({
      filterId,
      globalGroupBy,
      selectedWells,
      chartSettings,
      sessionId
    });

    sessionStorage.setItem(channel, data);
    window.open(getPopoutUrl(channel));
  }, [channel, filterId, groupBy, chartSettings]);

  return (
    <ToolbarButton
      key={"multiphasechart-toolbar-popout-toggle"}
      testId={"multiphasechart-toolbar-popout-toggle"}
      active={false}
      icon={<LaunchIcon fontSize="large" />}
      overflowLabel="Pop out"
      tooltipText="Open in new Tab"
      onToggle={() => popout()}
    />
  );
};

export default memo(Popout);
