/* eslint-disable @typescript-eslint/ban-ts-comment */
import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useRef, useState } from "react";

import { AXIS_TYPE } from "constants/chart.constants";
import { ECharts } from "echarts";
import * as echarts from "hd-echarts/echarts";
import styled from "styled-components/macro";
import { getProductType } from "utils";

import { useChartDependencies } from "components/chart/hooks";
import { useDashboardDispatch } from "components/dashboard/hooks/useDashboardDispatch";
import { useChartSettings } from "components/multiphase-chart/context";
import {
  IChartSettingsState,
  initialChartSettingsState
} from "components/multiphase-chart/context/ChartSettingsContext";
import {
  changeChartData,
  changeId,
  toggleIsMouseOver
} from "components/multiphase-chart/context/reducer/chartSettingsReducer";
import { useChartSize } from "components/multiphase-chart/hooks";
import { IMultiPhaseInput } from "components/multiphase-chart/models/shared.models";
import { getDefaultProductStylesMergedWithCustomStyles } from "components/multiphase-chart/util/productStyles";
import { Indicator } from "components/ui";

import { EntityKind } from "../../../../models/entityKind";
import * as eventHandlers from "../../events";
import * as hooks from "../../hooks";
import ChartAxis from "../axis/ChartAxis";
import ChartLegend from "../legend/ChartLegend";
import ChartToolbar from "../toolbar/Toolbar";
import Screenshot from "./Screenshot";

let arpsWasm: typeof import("wasm/arps") = undefined;
import("wasm/arps.js").then((wasm) => {
  arpsWasm = wasm;
});

const getSessionStorageId = (chartId: string, chartType: string) =>
  `${chartType}::${chartId}`;

export const MultiPhaseChart = ({ id, onFullscreenToggle, type }) => {
  const {
    globalNormalizeBy,
    selectedWells,
    filterId,
    userForecastSettings,
    checkedGlobalForecasts,
    syncWells,
    forecastMode,
    useForecastStartDate
  } = useChartDependencies(EntityKind.Well);
  const dashboardDispatch = useDashboardDispatch();

  const chartContainerRef = useRef(null);
  const echartRef = useRef<ECharts>(null);
  const currentlyHighlightedSeries = useRef("");

  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  // Potentially not needed
  const [selectedWell, setSelectedWell] = useState("");
  const [numberOfXAxes, setNumberXAxes] = useState(0);
  const [numberOfYAxes, setNumberYAxes] = useState(0);

  const { hasChartData, setOptionsToChart, createAndSetOptionsToChart, options } =
    hooks.useChartOptionSetter(
      echartRef.current,
      chartSettings.storedPreset,
      chartSettings.settings,
      chartSettings.screenshot,
      chartSettings.legend,
      chartSettings.axisMinMax,
      arpsWasm
    );

  // TODO: isPostLoading seems to have a bug with the library
  // Might need to make a hook to implement custom isLoading if needed
  // Give better name
  const { mutate, data } = hooks.usePostMultiPhase(createAndSetOptionsToChart);
  const wrapperRef = useRef(null);
  const showLegend =
    chartSettings.legend.visible &&
    selectedWell &&
    !chartSettings.screenshot.visible &&
    chartSettings.legend.type === "inplot";

  const screenshotContainerId = `screenshot-container-${id}`;
  useChartSize(wrapperRef);

  function saveMultiPhaseChartSettingsToSessionStorage(
    chartData: IChartSettingsState
  ): void {
    sessionStorage.setItem(getSessionStorageId(id, type), JSON.stringify(chartData));
  }

  useEffect(() => {
    if (data?.layout?.xAxis2 && data?.layout?.yAxis2) {
      setNumberXAxes(data.layout.xAxis2.length);
      setNumberYAxes(data.layout.yAxis2.length);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedWells || !Object.keys(selectedWells).length) {
      setSelectedWell("");
      return;
    }

    if (
      !echartRef.current ||
      // TODO: Is this needed?
      !chartSettings.storedPreset.chartTypeId
    ) {
      return;
    }
    setSelectedWell(Object.keys(selectedWells)[0]);
    saveMultiPhaseChartSettingsToSessionStorage({
      ...chartSettings,
      isSettingsOpen: false
    });
    const userForecastData = checkedGlobalForecasts.map((f) => ({
      folderId: f.folderId,
      id: f.id,
      type: f.type
    }));
    const mutateChart: IMultiPhaseInput = {
      filterId: filterId.id,
      products: chartSettings.storedPreset.products.map((product) => ({
        axis: product.axis,
        name: getProductType(product.name).key
      })),
      uwi: Object.keys(selectedWells)[0],
      chartStyles: {
        product: getDefaultProductStylesMergedWithCustomStyles(
          chartSettings.storedPreset.style
        )
      },
      userForecastSettings,
      userForecast: userForecastData,
      chartSetting: {
        chartType: chartSettings.storedPreset.chartTypeId,
        rateType: chartSettings.settings.producing ? "Producing" : "Calendar",
        normalizeBy: {
          useNormalizeBy: chartSettings.settings?.useNormalizeBy ?? false,
          field: chartSettings.settings?.normalizeBy?.field ?? globalNormalizeBy.field,
          displayName:
            chartSettings.settings?.normalizeBy?.display ?? globalNormalizeBy.display,
          per: chartSettings.settings?.normalizeBy?.per ?? globalNormalizeBy.per,
          unit: chartSettings.settings?.normalizeBy?.unit ?? globalNormalizeBy.unit,
          useMultilinearNormalization:
            chartSettings.settings?.normalizeBy?.useMultilinearNormalization ??
            globalNormalizeBy.useMultilinearNormalization,
          threshold:
            chartSettings.settings?.normalizeBy?.threshold ?? globalNormalizeBy.threshold,
          lowerScalar:
            chartSettings.settings?.normalizeBy?.lowerScalar ??
            globalNormalizeBy.lowerScalar,
          higherScalar:
            chartSettings.settings?.normalizeBy?.higherScalar ??
            globalNormalizeBy.higherScalar
        },
        forecastMode,
        useForecastStartDate
      },
      useBackfit: chartSettings.settings.backfit
    };

    mutate({ ...mutateChart });
  }, [
    mutate,
    filterId,
    selectedWells,
    userForecastSettings,
    chartSettings.storedPreset,
    chartSettings.axisMinMax,
    chartSettings.settings,
    chartSettings.legend,
    checkedGlobalForecasts,
    chartSettings.screenshot?.visible,
    syncWells,
    useForecastStartDate,
    forecastMode
  ]);

  useEffect(() => {
    changeChartData(chartSettingsDispatch, data);
  }, [data, chartSettingsDispatch]);

  useEffect(() => {
    echartRef.current = echarts.init(chartContainerRef.current);

    return () => {
      echarts.dispose(echartRef.current);
      echartRef.current = null;
    };
  }, [chartContainerRef]);

  useEffect(() => {
    onFullscreenToggle && onFullscreenToggle(chartSettings.isFullscreen ?? false);
  }, [chartSettings.isFullscreen]);

  // sync chart ID with context
  useEffect(() => changeId(chartSettingsDispatch, id), [id, chartSettingsDispatch]);

  // resize chart with viewport
  useResizeObserver(chartContainerRef, () => {
    if (echartRef?.current && options) {
      setOptionsToChart(options);
    }
  });

  const handleMouseEnter = () => {
    toggleIsMouseOver(chartSettingsDispatch, true);
  };

  const handleMouseLeave = () => {
    toggleIsMouseOver(chartSettingsDispatch, false);
  };

  eventHandlers.useHoverOnSeries(
    echartRef.current,
    options,
    currentlyHighlightedSeries,
    setOptionsToChart
  );
  eventHandlers.useHoverOffSeries(
    echartRef.current,
    options,
    currentlyHighlightedSeries,
    setOptionsToChart
  );

  hooks.useInitialPresetLoader(chartSettingsDispatch, getSessionStorageId(id, type));
  hooks.useChartStateForDashboard(dashboardDispatch);

  useEffect(() => {
    setOptionsToChart(options);
  }, [chartSettings.screenshot.preset, chartSettings.screenshot.visible]);

  return (
    <Container
      data-testid="multiphasechart-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={"chart-wrapper"}
      ref={wrapperRef}
      screenshot={chartSettings.screenshot.visible}>
      <ChartContainer screenshot={chartSettings.screenshot.visible}>
        <EChartWrapper
          className={`ChartPlot ${chartSettings.screenshot.visible ? "screenshot" : ""}`}
          screenshot={
            chartSettings.screenshot.visible ? chartSettings.screenshot.preset : null
          }>
          <ScreenshotContainer id={screenshotContainerId} />
          <EChart className="echarts-wrapper" ref={chartContainerRef} />
        </EChartWrapper>
      </ChartContainer>

      {!hasChartData && (
        <Indicator
          data-testid={"multiphasechart-no-data-label"}
          data-qa={"multiphasechart-no-data-label"}
          message={"No Data Available"}
        />
      )}
      {!selectedWell && (
        <OverlayContainer
          data-testid="multiphasechart-no-well-selected-label"
          data-qa="multiphasechart-no-well-selected-label">
          <OverlayMessages>
            <NoSelectedWellContainer>
              Please select a well on the map
            </NoSelectedWellContainer>
          </OverlayMessages>
        </OverlayContainer>
      )}
      {showLegend && (
        <ChartLegend
          parentDimensions={{
            width: chartSettings.bounds.width,
            height: chartSettings.bounds.height
          }}
          id="chart-legend"
        />
      )}

      {!chartSettings.screenshot?.visible && <ChartToolbar />}
      {selectedWell && numberOfXAxes > 0 && (
        <>
          {Array.from({ length: numberOfXAxes }, (_, index) => (
            <ChartAxis
              chartData={data}
              key={index}
              axisIndex={index}
              type={AXIS_TYPE.x}
              grid={Array.isArray(options.grid) ? options.grid[0] : options.grid}
              axisOptions={options?.xAxis ? options?.xAxis[index] ?? null : null}
              chartType={chartSettings.storedPreset.chartTypeId}
              isLogScale={chartSettings.settings.logs[index].x}
              axisMinMax={
                chartSettings.axisMinMax[index]
                  ? chartSettings.axisMinMax[index]
                  : initialChartSettingsState.axisMinMax[index]
              }
            />
          ))}
        </>
      )}
      {selectedWell && numberOfYAxes > 0 && chartSettings && (
        <>
          {Array.from({ length: numberOfYAxes }, (_, index) => (
            <ChartAxis
              chartData={data}
              key={index}
              axisIndex={index}
              type={AXIS_TYPE.y}
              grid={Array.isArray(options.grid) ? options.grid[0] : options.grid}
              axisOptions={options?.yAxis ? options?.yAxis[index] ?? null : null}
              chartType={chartSettings.storedPreset.chartTypeId}
              isLogScale={chartSettings.settings.logs[index].y}
              axisMinMax={
                chartSettings.axisMinMax[index]
                  ? chartSettings.axisMinMax[index]
                  : initialChartSettingsState.axisMinMax[index]
              }
            />
          ))}
        </>
      )}

      <Screenshot containerId={screenshotContainerId} chartInstance={echartRef.current} />
    </Container>
  );
};

const EChart = styled.div`
  width: 100%;
  height: 100%;
  z-index: inherit;
`;

const EChartWrapper = styled.div`
  flex-grow: 1;
  background-color: ${(props) => (props.screenshot ? "#fff" : "inherit")};
  width: ${(props) => (props.screenshot ? props.screenshot.width + "px" : "100%")};
  height: ${(props) => (props.screenshot ? props.screenshot.height + "px" : "100%")};
  transition: all var(--duration);

  .local-normalize-container {
    position: absolute;
    top: 10px;
    right: 20px;
    font-weight: var(--fontWeightMedium);
    align-items: center;
    span {
      margin-right: 8px;
    }
  }

  .chart-tooltip-container {
    max-height: 250px;
    overflow-y: auto;
  }

  /* prevent header overlap with toast when taking screenshot */
  &.screenshot .chart-toast {
    --top-offset: calc(var(--navbar-height) + var(--chart-toolbar-height));
    top: calc(var(--top-offset) + 20px);
  }

  z-index: 3;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  border-radius: var(--border-radius);
  background-color: ${(props) =>
    props.screenshot ? "rgba(46, 72, 88, 0.24)" : "inherit"};
  box-shadow: rgba(var(--color-shadow-rgb), 0.2) 0 0 1px;
  padding-top: 10px;
  z-index: 0;

  &.hovered {
    box-shadow: rgba(var(--color-shadow-rgb), 0.4) 0 0 1px;
  }

  &.fullscreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--index-fullscreen);
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.8);
  z-index: 4;
`;
const OverlayMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: inherit;
`;

const NoSelectedWellContainer = styled.div`
  color: var(--color-white);
  background-color: var(--color-text);
  border-radius: 5px;
  padding: 2px 20px;
  margin: 0 20px;
`;

const ChartContainer = styled.div`
  justify-self: ${(props) => (props.screenshot ? "center" : "auto")};

  &:hover {
    z-index: 2;
  }
`;

const ScreenshotContainer = styled.div`
  position: absolute;
  display: grid;
  justify-content: center;
  pointer-events: none;
`;
