import { useMemo } from "react";

import _debounce from "lodash/debounce";

import { ColorPicker } from "components/base";
import { useProjectContext } from "components/project/projects/context";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";
import { useUpdateProjectLinkedShapefile } from "components/project/shapefiles/hooks";

interface IProjectLinkedShapefileColorPickerProps {
  projectShapefileId: string;
  shapefileId: string;
  color: string;
  strokeColor: string;
  thickness: number;
  opacity: number;
}

export function ProjectLinkedShapefileColorPicker(
  props: IProjectLinkedShapefileColorPickerProps
) {
  const { projectShapefileId, shapefileId, color, strokeColor, thickness, opacity } =
    props;

  const { selectedProjectId } = useProjectContext();

  const projectPermissions = useSelectedProjectPermissions();

  const { updateProjectLinkedShapefile } = useUpdateProjectLinkedShapefile();

  // TODO BF: repeated code
  const debouncedUpdateProjectLinkedShapefileProperty = useMemo(
    () =>
      _debounce(
        (key, value, projectShapefileId) =>
          updateProjectLinkedShapefile.mutate({
            body: {
              key,
              value
            },
            projectId: selectedProjectId,
            projectShapefileId: projectShapefileId,
            shapefileId: shapefileId
          }),
        500
      ),
    [selectedProjectId]
  );

  const updateOpacity = (key, value) =>
    debouncedUpdateProjectLinkedShapefileProperty("opacity", value, projectShapefileId);

  const updateThickness = (key, value) =>
    debouncedUpdateProjectLinkedShapefileProperty("thickness", value, projectShapefileId);

  const updateColor = (key, value) =>
    updateProjectLinkedShapefile.mutate({
      body: {
        key,
        value
      },
      projectId: selectedProjectId,
      projectShapefileId: projectShapefileId,
      shapefileId: shapefileId
    });

  return (
    <ColorPicker
      onChange={updateColor}
      onChangeOpacity={updateOpacity}
      onChangeThickness={updateThickness}
      values={{
        color: color,
        strokeColor: strokeColor,
        thickness: thickness,
        opacity: (opacity ?? 1) * 100
      }}
      isReadOnly={!projectPermissions.canEditShapefiles}
      isAtShapefileLevel={true}
    />
  );
}
