import { useEffect } from "react";

import classnames from "classnames";
import styled from "styled-components";

import useBetaFeatures from "hooks/useBetaFeatures";
import usePlayTo3DDataPrivateSources from "hooks/usePlayTo3DDataPrivateSources";
import useUserModules from "hooks/useUserModules";

import { XdaData } from "models/xdaData";

import { visibilityToggleStyles } from "components/shared/SharedStyles";
import { useUserSettingsDefaultsQuery } from "components/user/queries";

import { useVisState } from "./context";
import useMouseOver from "./context/useMouseover";
import {
  VisFullscreenToggle,
  VisScreenshotToggle,
  Xda3DGeoToggle,
  XdaDataToggle,
  XdaGridToggle,
  XdaHangToggle,
  XdaMeasurementToggle,
  XdaOverlapToggle,
  XdaRadiusToggle,
  XdaResetSettings,
  XdaSettingsToggle,
  XdaTooltipToggle,
  XdaTopsToggle,
  XdaValueInfoToggle
} from "./toolbar/xda";

interface XdaToolbarProps {
  mouseoverRef?: React.RefObject<HTMLDivElement>;
  binUpdateErrorMessage: string;
  onFullscreenToggle: (value) => void;
  dataRef: React.RefObject<XdaData>;
  isLoading: boolean;
}

function XdaToolbar({
  mouseoverRef,
  binUpdateErrorMessage,
  onFullscreenToggle,
  dataRef,
  isLoading
}: XdaToolbarProps) {
  const Components = [
    <Xda3DGeoToggle key="3dgeo" binUpdateErrorMessage={binUpdateErrorMessage} />,
    <XdaValueInfoToggle key="valueinfo" />,
    <Divider key="divider" />,
    <XdaRadiusToggle key="radius" />,
    <XdaOverlapToggle key="overlap" />,
    <XdaHangToggle key="hang" />,
    <XdaTopsToggle key="tops" />,
    <XdaGridToggle key="grid" />,
    <XdaTooltipToggle key="tooltip" />,
    <XdaDataToggle key="data" />,
    <XdaMeasurementToggle key="measurement" />,
    <VisScreenshotToggle key="screenshot" onToggle={onFullscreenToggle} type="xda" />,
    <VisFullscreenToggle key="fullscreen" onToggle={onFullscreenToggle} />,
    <XdaResetSettings key="reset" />,
    <XdaSettingsToggle key="settings" dataRef={dataRef} isLoading={isLoading} />
  ];

  const [{ xda }] = useVisState();
  const { showIpdb } = xda.settings;

  const { has3dGeoModel } = useUserModules();
  const { data: privateDataSources, refetch: refetchPrivateDataSources } =
    usePlayTo3DDataPrivateSources();

  const userSettingsDefaults = useUserSettingsDefaultsQuery();
  const { hasFeature } = useBetaFeatures();

  useEffect(() => {
    refetchPrivateDataSources();
  }, []);

  const geoModelEnabled = has3dGeoModel || !!privateDataSources?.length;
  const userHasGeoModelEnabled =
    !hasFeature("User Feature Flags") ||
    (userSettingsDefaults?.data?.FeatureFlag?.GeoModel?.enabled ?? false);

  // don't show 3d geo model related buttons if user doesn't have access
  // and if there are no private data sources
  if (!geoModelEnabled || !userHasGeoModelEnabled) {
    Components.splice(0, 3);
  } else if (!showIpdb) {
    Components.splice(1, 1);
  }

  const visible = useMouseOver(mouseoverRef);
  const wrapperClassnames = classnames({ visible });

  return <Wrapper className={wrapperClassnames}>{Components}</Wrapper>;
}

export default XdaToolbar;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 8px;
  height: var(--chart-toolbar-height);
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 8px;
  background-color: var(--white);
  box-shadow: 0 14px 16px 0 rgb(45 53 63 / 10%),
    inset 0px 7px 8px -10px rgb(45 53 63 / 15%);
  z-index: 998;
  padding-right: 4px;
`;

const Divider = styled.div`
  width: 1px;
  height: var(--chart-toolbar-height);
  background-color: #d9e1e2;
`;
