import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import { useChartsContext } from "contexts/ChartContext";
import { selectChartForecastFeature, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";

import ToolbarButton from "../../base/ToolbarButton";

const ForecastToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const forecastState = useSelector((state: RootState) =>
    selectChartForecastFeature(state, id)
  );
  const isEnabled = forecastState?.enabled ?? false;
  const isActive = forecastState?.active ?? false;
  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.forecast.active`,
        value: active
      })
    );
  };
  return (
    <ToolbarButton
      active={isActive}
      icon={<TrendingDownIcon fontSize="large" />}
      overflowLabel="Forecast"
      tooltipText="Toggle Forecast"
      onToggle={toggle}
      disabled={!isEnabled}
    />
  );
};

export default memo(ForecastToggle);
