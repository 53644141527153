import { lineString, polygon as tPolygon } from "@turf/helpers";
import { setXdaLineRequest } from "store/features";

import { getPerpendicularLine } from "api/map";

import { setXdaLine } from "./mapboxHelper";

export async function makeXdaLineRequest(
  geom,
  selectedWells = {},
  mapbox,
  dispatch,
  selectionPolygonRef,
  getAndSetFeaturesIntersectingPolygon
) {
  const coords = geom?.coordinates;
  if (!coords) {
    return;
  }
  const poly = tPolygon([
    [
      [coords[0][0], coords[0][1]],
      [coords[1][0], coords[1][1]],
      [coords[1][0], coords[1][1]],
      [coords[0][0], coords[0][1]]
    ]
  ]);
  geom = poly;
  setXdaLine(
    {
      start: { x: coords[0][0], y: coords[0][1] },
      end: { x: coords[1][0], y: coords[1][1] }
    },
    [],
    mapbox
  );
  selectionPolygonRef.current = geom;
  if (!selectedWells || Object.keys(selectedWells).length === 0) {
    [selectedWells] = await getAndSetFeaturesIntersectingPolygon(poly);
  }
  dispatch(
    setXdaLineRequest({
      line: coords,
      wells: selectedWells
    })
  );
}

export async function getPerpendicularLineAndMakeXdaRequest(
  uwi,
  feat,
  mapbox,
  dispatch,
  selectionPolygonRef,
  getAndSetFeaturesIntersectingPolygon
) {
  const selectedWells = {};

  if (feat) {
    selectedWells[uwi] = feat.properties;
    uwi = feat.properties.Uwi;
  }

  const perpedicularLine = await getPerpendicularLine(uwi);
  // Make sure to do null checks here otherwise it will failed with 0,0 coordinates
  if (
    perpedicularLine?.[0]?.x != null &&
    perpedicularLine?.[1]?.x != null &&
    perpedicularLine?.[0]?.y != null &&
    perpedicularLine?.[1]?.y != null
  ) {
    const feat = lineString([
      [perpedicularLine[0]?.x, perpedicularLine[0]?.y],
      [perpedicularLine[1]?.x, perpedicularLine[1]?.y]
    ]);
    await makeXdaLineRequest(
      feat.geometry,
      selectedWells,
      mapbox,
      dispatch,
      selectionPolygonRef,
      getAndSetFeaturesIntersectingPolygon
    );
  }
}
