// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo, useState } from "react";

import { mdiDraw } from "@mdi/js";
import { Popover } from "antd";

import ToolbarButton from "../../base/ToolbarButton";
import { useChartState } from "../context";
import AddReferenceLineMenu from "./AddReferenceLineMenu";

const AddReferenceLineToggle = () => {
  const [visible, setVisible] = useState(false);
  const { overflowIndex, settings } = useChartState();

  return (
    <Popover
      arrowPointAtCenter
      content={<AddReferenceLineMenu />}
      placement={overflowIndex >= 18 ? "bottomLeft" : "left"}
      trigger="hover"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        icon={<Icon path={mdiDraw} size={1.5} />}
        isMenuButton={true}
        overflowLabel="Reference Lines"
        tooltipText="Reference Lines"
        active={
          settings.referenceLine?.lines?.length > 0 ||
          settings.referenceLine?.points?.length > 0
        }
      />
    </Popover>
  );
};

export default memo(AddReferenceLineToggle);
