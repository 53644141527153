import { EChartsOption } from "echarts";

import getSeriesNameStrippingForecast from "./getSeriesNameStrippingForecast";

const tooltipPosition = (point, params, dom) => {
  const xPos = point[0];
  const yPos = point[1] - dom.offsetHeight > 48 ? point[1] - dom.offsetHeight : 48;
  const pos = [xPos, yPos]; //position top right of mouse cursor

  return pos;
};

/**
 *
 * @param currentlyHighlightedSeries The series you are looking for "Gas" "Oil".
 * Forecast series will be matched as well.
 * @param series, Derived from the tooltip formatter. See note, on getOptionsWithTooltips,
 * we can not use this param right now.
 * @returns Series object.
 */
const getSeriesFromOptions = (currentlyHighlightedSeries: string, series) =>
  series?.find(
    (trace) => getSeriesNameStrippingForecast(trace.name) === currentlyHighlightedSeries
  );

/**
 * Matches the currently highlighted series, with the series from the chart options.
 * @param currentlyHighlightedSeries
 * @param series Intended to be used from chart options.
 * @param formatterSeries Derived from the tooltip formatter. See note, on getOptionsWithTooltips,
 * we can not use this param right now.
 * @returns
 */
const tooltipFormatter = (
  currentlyHighlightedSeries: string,
  series,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatterSeries
) => {
  const colorSpan = (color) =>
    `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${color}"></span>`;

  const selectedSeries = getSeriesFromOptions(currentlyHighlightedSeries, series);
  if (!selectedSeries) return;

  const content = `<div>${colorSpan(
    selectedSeries.itemStyle.color
  )}${currentlyHighlightedSeries}</div>`;
  return content;
};

/**
 * The tooltips gets triggered on axis, in which the formatter will return multiple products per axis. Therefore,
 * some product series will not return correctly.
 * We need the currentlyHighlightedSeries passed and matched to the series from the chart options in order to return the correct product.
 * Potential for improvement, but was unable to figure out how to set the tooltip to the individual series with tooltip.trigger = item
 * @param options Echarts chart options.
 * @param currentlyHighlightedSeries Derived from the hover event
 * @returns
 */
const getOptionsWithTooltips = (
  options: EChartsOption,
  currentlyHighlightedSeries
): EChartsOption => {
  const newOptions = {
    ...options,
    tooltip: {
      ...options.tooltip,
      position: tooltipPosition,
      formatter: (formatterSeries) =>
        tooltipFormatter(currentlyHighlightedSeries, options.series, formatterSeries)
    }
  };

  return newOptions;
};

export default getOptionsWithTooltips;
