import { useCallback } from "react";

import { Button, Tree } from "antd";
import styled from "styled-components";

import { IShapefileNode } from "models/projects";

import PopOver from "components/base/Popover";
import { useSystemShapefiles } from "components/org-settings/hooks/useSystemShapefiles";
import SystemFolderNode from "components/org-settings/org-shapefiles/SystemFolderNode";
import SystemRootNode from "components/org-settings/org-shapefiles/SystemRootNode";
import SystemShapefileNode from "components/org-settings/org-shapefiles/SystemShapefileNode";

interface IShapefilePromoteOverwriteSelection {
  shapefileToOverwrite: IShapefileNode;
  setShapefileToOverwrite: (shapefile: IShapefileNode) => void;
}

function ShapefilePromoteOverwriteSelection({
  shapefileToOverwrite,
  setShapefileToOverwrite
}: IShapefilePromoteOverwriteSelection) {
  const { tree: systemShapefiles } = useSystemShapefiles();

  const customTreeNode = useCallback((node: IShapefileNode | IShapefileNode) => {
    node = { ...node };
    if (node.type === "root") {
      return <SystemRootNode node={node} hasActions={false} />;
    } else if (node.type === "shapefile") {
      return <SystemShapefileNode node={node} hasActions={false} />;
    } else if (node.type === "node") {
      return <SystemFolderNode node={node} hasActions={false} />;
    } else {
      return null;
    }
  }, []);

  const onShapefileSelect = (selected) => {
    if (!selected) {
      setShapefileToOverwrite(null);
    } else if (selected?.type === "shapefile") {
      setShapefileToOverwrite(selected);
    }
  };

  return (
    <PopOver
      trigger="click"
      placement="right"
      content={
        <Tree
          selectable={true}
          multiple={false}
          treeData={systemShapefiles}
          onSelect={(_, { selectedNodes: selected }) => onShapefileSelect(selected[0])}
          selectedKeys={[shapefileToOverwrite?.shapefileId]}
          titleRender={customTreeNode}
        />
      }>
      <SelectedShapefileButton placement="right">
        {shapefileToOverwrite?.title || "Select Shapefile to Overwrite"}
      </SelectedShapefileButton>
    </PopOver>
  );
}

export default ShapefilePromoteOverwriteSelection;

const SelectedShapefileButton = styled(Button)`
  width: 100%;
  text-align: left;
  margin: 5px 0px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;
