// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiMessageOutline } from "@mdi/js";
import { List } from "antd";
import styled from "styled-components";

import { BusyIndicator } from "components/activity/shared";

import useFetchAuditForecast from "./hooks/useFetchAuditForecast";
import { ForecastWellStatus, ForecastingChangeRecord } from "./models/forecastWell";

export interface ForecastChangeRecordsModel {
  forecastId: string;
  status: ForecastWellStatus;
}

export const ForecastingChangeRecords = function ({
  forecastId,
  status
}: ForecastChangeRecordsModel) {
  const loading = false;
  const { data: changeRecords } = useFetchAuditForecast(forecastId, status);

  function getListItem(changeRecord: ForecastingChangeRecord) {
    const changeDate = new Date(changeRecord.changedDate);
    return (
      <List.Item key={changeRecord.id}>
        <List.Item.Meta
          title={
            changeRecord.changedBy.fullName +
            " " +
            changeDate.toLocaleDateString() +
            " " +
            changeDate.toLocaleTimeString() +
            " "
          }
          description={
            <CommentContainer>
              <CommentIcon path={mdiMessageOutline} size={1.5} />
              <>{changeRecord.comment}</>
            </CommentContainer>
          }
        />
      </List.Item>
    );
  }

  return (
    <>
      {loading ?? <BusyIndicator />}
      {!loading && (
        <ListWrapper>
          <List
            dataSource={changeRecords ?? []}
            renderItem={(item) => getListItem(item)}
          />
        </ListWrapper>
      )}
    </>
  );
};

const ListWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const CommentIcon = styled(Icon)`
  margin: 5px;
`;

const CommentContainer = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
`;
