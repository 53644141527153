import { useEffect } from "react";

import { Fullscreen } from "@material-ui/icons";

import { ToolbarButton } from "components/base";
import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";

import { useMapContext } from "../hooks/useMapContext";
import { useMapDispatch } from "../hooks/useMapDispatch";

export default function MapFullscreen({ onFullscreen }) {
  const { isFullscreen } = useMapContext();
  const mapDispatch = useMapDispatch();
  const { isActive: isGeomBinOpen } = useGeomBinContext();
  const handleToggle = () => (value) => {
    mapDispatch({ payload: { isFullscreen: value } });
  };

  useEffect(() => {
    mapDispatch({ payload: { isFullscreen: isGeomBinOpen } });
  }, [isGeomBinOpen, mapDispatch]);

  useEffect(() => {
    onFullscreen && onFullscreen(isFullscreen);

    // Should not listen to onFullscreen
  }, [isFullscreen]);

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even though the window was already expanded/collapsed
    <ToolbarButton
      active={isFullscreen}
      icon={<Fullscreen style={{ fontSize: "22px" }} />}
      key={"MapFullscreen" + isFullscreen}
      onToggle={handleToggle()}
      overflowLabel={"Toggle Fullscreen"}
      styleKey="miniMapToolbar"
      tooltipText="Toggle Fullscreen"
    />
  );
}
