import { useCallback, useEffect, useMemo, useState } from "react";

import booleanIntersect from "@turf/boolean-intersects";
import { lineString, points, pointsWithinPolygon, polygon } from "@turf/turf";
import { ALL_CHART_TYPES } from "constants/chart.constants";
import _debounce from "lodash/debounce";

import { getChart } from "../charts/getChart";
import {
  toggleLassoOFF,
  updateBrushEnd,
  updateBrushEndPosition,
  updateBrushedList,
  useChartDispatch,
  useChartState
} from "../context";
import { getLassoToggleDisabledStatus } from "../utils/toggles";

/**
 * Simple `hook` that abstracts:
 * - enable/disbale lasso based on selected chartType
 * - register/implement brushSelected/brushEnd handlers on chart instance
 * @returns a boolean (whether lasso is allowed or not for current chartType)
 */
function useLasso(): boolean {
  // context
  const dispatch = useChartDispatch();
  const { instance, lasso, options, settings } = useChartState();

  // state
  const [allowed, setAllowed] = useState(false);

  // memoing derived state to prevent unnecessary re-renders
  const series = useMemo(() => options?.series ?? [], [options?.series]);
  const uwiList = useMemo(() => options?.uwiList ?? [], [options?.uwiList]);

  function getStackedBarFilteredItems(filtered) {
    const filteredSeriesList = [];

    if (!filtered?.length) {
      return filteredSeriesList;
    }

    filtered.forEach((item) => {
      const { seriesIndex, dataIndex } = item;
      const seriesData = series[seriesIndex]?.data;

      if (!seriesData) {
        return;
      }
      dataIndex.forEach((index) => {
        if (seriesData[index] > 0) {
          filteredSeriesList.push(series[seriesIndex].name);
        }
      });
    });
    return filteredSeriesList;
  }

  function getBoxPlotFilteredItems(params) {
    const list = [];
    const lassoCoordinates = params.batch[0]?.areas[0]?.range;
    if (!lassoCoordinates) {
      return list;
    }
    const firstCoordinate = lassoCoordinates[0];
    lassoCoordinates.push(firstCoordinate);
    const lassoPolygon = polygon([lassoCoordinates]);
    const allPoints = [];
    const pointToUwiMap = new Map();

    // select individual points if jitter plot is enabled
    if (settings.showScatter) {
      Object.keys(instance._chartsMap).forEach((key, seriesIndex) => {
        const jitterSeries = instance._chartsMap[key];
        if (!jitterSeries.group?._children || !key.includes("custom")) return;

        jitterSeries.group._children.forEach((dataPoint, pointIndex) => {
          const circleShape = dataPoint.shape;

          if (circleShape?.cx && circleShape?.cy) {
            const coordinates = [circleShape.cx, circleShape.cy];
            allPoints.push(coordinates);
            const coordinatesKey = JSON.stringify(coordinates);

            const uwi = series[seriesIndex]?.data?.[pointIndex]?.[3];
            if (uwi) {
              pointToUwiMap.set(coordinatesKey, uwi);
            }
          }
        });
      });

      const pointsFeatureCollection = points(allPoints);
      const pointsWithin = pointsWithinPolygon(pointsFeatureCollection, lassoPolygon);
      pointsWithin.features.forEach((feature) => {
        const coordinatesKey = JSON.stringify(feature.geometry.coordinates);
        const uwi = pointToUwiMap.get(coordinatesKey);
        if (uwi) {
          list.push(uwi);
        }
      });
    } else {
      const boxPlotSeries =
        instance._chartsMap["_ec_\u0000series\u00000\u00000_series.boxplot"]?.group
          ?._children;

      if (!boxPlotSeries) {
        return list;
      }

      for (let i = 0; i < boxPlotSeries.length; i++) {
        const shape = boxPlotSeries[i].shape;
        if (!shape.points) {
          continue;
        }

        const boxPlotPoints = points(shape.points);
        const result = pointsWithinPolygon(boxPlotPoints, lassoPolygon);

        let seriesIndex = i;
        for (const prop in boxPlotSeries[i]) {
          if (
            Object.prototype.hasOwnProperty.call(boxPlotSeries[i], prop) &&
            typeof boxPlotSeries[i][prop] === "object"
          ) {
            const obj = boxPlotSeries[i][prop];
            if (obj && obj.dataIndex) {
              seriesIndex = obj.dataIndex;
            }
          }
        }

        if (result.features.length > 0) {
          list.push(series[0].data[seriesIndex]?.name);
        }
      }
    }
    return list;
  }

  function getProbitCrossPlotFilteredItems(filtered) {
    const list = [];

    if (!filtered?.length) {
      return [];
    }

    // filter brushed wells
    filtered.forEach((item) => {
      const { seriesIndex, dataIndex } = item;
      const seriesData = series[seriesIndex]?.data;

      if (!seriesData) {
        // eslint-disable-next-line no-console
        console.warn("No series data found for seriesIndex: ", seriesIndex);
        return;
      }

      // get uwi list for every dataIndex
      const uwis = dataIndex.map((index) => {
        if (settings.chartType === ALL_CHART_TYPES.CrossPlot.label) {
          const seriesDataIndex = (index + 1) * 3 - 1; // every 3rd is uwi index
          const uwiIndex = seriesData[seriesDataIndex];
          return uwiList[uwiIndex];
        } else {
          const uwiIndex = seriesData[index][2];
          return uwiList[uwiIndex];
        }
      });

      list.push(...uwis);
    });

    return list;
  }

  function getLineChartFilteredItems(params) {
    const list = [];
    const lassoCoordinates = params.batch[0]?.areas[0]?.coordRange;
    if (lassoCoordinates) {
      const firstCoordinate = lassoCoordinates[0];
      lassoCoordinates.push(firstCoordinate);
      const lassoPolygon = polygon([lassoCoordinates], { name: "lasso" });
      series.forEach((s) => {
        if (!s.data) return;
        let data;
        if (isDateChart) {
          data = s.data.map((dataPoint) => {
            const timestamp = new Date(dataPoint[0]).getTime();
            return [timestamp, dataPoint[1]];
          });
        } else {
          data = s.data.map((list) => list.slice(0, 2));
        }

        if (data.length < 2) return [];
        const line = lineString(data, { name: "line" });
        const intersects = booleanIntersect(line, lassoPolygon);
        if (!intersects) return;

        const seriesName = s.name;
        if (seriesName.includes("Forecast")) {
          const resultString = seriesName.split(" Forecast")[0];
          list.push(resultString, resultString);
        } else {
          list.push(s.name);
        }
      });
    }
    return list;
  }

  // sync allowed state with selected chartType
  useEffect(() => {
    const isAllowed = !getLassoToggleDisabledStatus(settings.chartType);
    setAllowed(isAllowed);

    // if not allowed for current chartType, turn off lasso
    if (!isAllowed) {
      toggleLassoOFF(dispatch);
      updateBrushedList(dispatch, []);
    }
  }, [settings.chartType, dispatch]);

  // clear list on brush end
  const brushEndHandler = useCallback(
    (params) => {
      if (params.areas.length) {
        updateBrushEnd(dispatch, true);
        return;
      }
      updateBrushedList(dispatch, []);
      updateBrushEnd(dispatch, false);
      updateBrushEndPosition(dispatch, null);
    },
    [dispatch]
  );

  const isDateChart =
    settings.chartType.toLowerCase().includes("date") ||
    getChart(settings.chartType)?.getXAxisType() === "Date";

  const debouncedSelectHandler = useMemo(() => {
    // map brushed series to uwiList
    const brushSelectHandler = (params) => {
      // check for selected indices length, for each series
      const selected = params.batch[0]?.selected;
      const filtered = selected?.filter((s) => s.dataIndex.length);

      let list = [];

      switch (settings.chartType) {
        case ALL_CHART_TYPES.StackedBar.label:
          list = getStackedBarFilteredItems(filtered);
          break;
        case ALL_CHART_TYPES.BoxPlot.label:
          list = getBoxPlotFilteredItems(params);
          break;
        case ALL_CHART_TYPES.CrossPlot.label:
        case ALL_CHART_TYPES.Probit.label:
          list = getProbitCrossPlotFilteredItems(filtered);
          break;
        case ALL_CHART_TYPES.RateCum.label:
        case ALL_CHART_TYPES.RateDate.label:
        case ALL_CHART_TYPES.CAGR.label:
        case ALL_CHART_TYPES.BaseDeclineRate.label:
        case ALL_CHART_TYPES.RateTime.label:
        case ALL_CHART_TYPES.CumTime.label:
        case ALL_CHART_TYPES.TrendDate.label:
          list = getLineChartFilteredItems(params);
          break;
        default:
          break;
      }
      // save to context
      updateBrushedList(dispatch, list.filter(Boolean));
    };

    return _debounce(brushSelectHandler, 600);
  }, [dispatch, series, uwiList]);

  const removeBrushEventHandlers = useCallback(() => {
    instance.off("brushSelected", debouncedSelectHandler);
    instance.off("brushEnd", brushEndHandler);
  }, [brushEndHandler, debouncedSelectHandler, instance]);

  // register brush select handler
  useEffect(() => {
    if (!instance) return;
    if (!lasso) {
      removeBrushEventHandlers();
      return;
    }

    instance.on("brushSelected", debouncedSelectHandler);
    instance.on("brushEnd", brushEndHandler);

    return () => removeBrushEventHandlers();
  }, [
    brushEndHandler,
    debouncedSelectHandler,
    instance,
    lasso,
    removeBrushEventHandlers
  ]);

  return allowed;
}

export default useLasso;
