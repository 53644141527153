// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo } from "react";

import { mdiInformationOutline } from "@mdi/js";
import { IS_INTERNAL_ENV } from "constants/app.constants";

import ToolbarButton from "../../base/ToolbarButton";
import { useChartDispatch, useChartState } from "../context";

const AnnotationToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();

  const toggle = () => (value) => {
    const next = { ...settings, showAnnotations: value };
    dispatch({ type: "settings", payload: next });
  };

  if (!IS_INTERNAL_ENV) {
    return null;
  }

  return (
    <ToolbarButton
      icon={<Icon path={mdiInformationOutline} size={1.8} />}
      active={settings.showAnnotations}
      overflowLabel="Producing"
      tooltipText="Toggle Annotations"
      onToggle={toggle()}
    />
  );
};

export default memo(AnnotationToggle);
