const backendProductToDisplayMapping: Record<string, string> = {
  "total shrinkage inlet": "SHRINKAGE",
  "sales gas": "SALES GAS",
  ethyieldsales: "C2",
  propyieldsales: "C3",
  butyieldsales: "C4",
  "pent+yieldsales": "C5"
};

const toExpectedDisplayName = (key: string): string => {
  return backendProductToDisplayMapping[key.toLowerCase()] || key;
};

const toExpectedBackendProductName = (value: string): string => {
  const entry = Object.entries(backendProductToDisplayMapping).find(
    ([, v]) => v.toLowerCase() === value.toLowerCase()
  );
  return entry ? entry[0] : value;
};

const formatProductName = (product: string): string => {
  if (product === "CONDENSATE" || product === "COND.") {
    return "COND";
  }
  if (product === "C5") {
    return "C5+GR";
  }
  if (["C2", "C3", "C4"].includes(product)) {
    return `${product}GR`;
  }
  return product;
};

export { toExpectedDisplayName, toExpectedBackendProductName, formatProductName };
