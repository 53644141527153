import { memo } from "react";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { noop } from "utils";

import ToolbarButton from "../../../base/ToolbarButton";

const ScreenshotToggle = () => {
  // update screenshot state when legend is toggled
  const toggle = noop;

  return (
    <ToolbarButton
      key="screenshot"
      active={false}
      icon={<CameraAltIcon fontSize="large" />}
      overflowLabel="Screenshot"
      tooltipText="Screenshot"
      onToggle={toggle()}
    />
  );
};

export default memo(ScreenshotToggle);
