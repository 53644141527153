import { combineReducers } from "@reduxjs/toolkit";
import { withReduxStateSync } from "redux-state-sync";
import appReducer, { resetAppSlice } from "store/features/app/appSlice";
import arpsReducer, { resetArpsSlice } from "store/features/arps/arpsSlice";
import channelReducer, { resetChannelSlice } from "store/features/channel/channelSlice";
import dataTableReducer from "store/features/dataTable/dataTableSlice";
import normalizeByReducer, {
  resetNormalizeBySlice
} from "store/features/normalizeBy/normalizeBySlice";
import projectReducer, { resetProjectSlice } from "store/features/project/projectSlice";
import documentationReducer from "store/features/userDocumentation/documentationSlice";
import userSettingReducer, {
  resetUserSettingsSlice
} from "store/features/userSettings/userSettingsSlice";

import authReducer from "./features/auth/authSlice";
import chartsReducer, { reset as resetCharts } from "./features/charts/chartSlice";
import filesReducer from "./features/files/filesSlice";
import facilityFilterReducer, {
  resetFacilityFilterSlice
} from "./features/filter/facilityFilterSlice";
import filterReducer, { resetFilterSlice } from "./features/filter/filterSlice";
import groupByReducer, { resetGroupBySlice } from "./features/groupBy/groupBySlice";
import mapReducer, { resetMapSlice } from "./features/map/mapSlice";
import organizationSettingsReducer, {
  resetOrgSettingsSlice
} from "./features/organizationSettings/organizationSettingsSlice";

const rootReducer = combineReducers({
  organizationSettings: organizationSettingsReducer,
  files: filesReducer,
  filter: filterReducer,
  facilityFilter: facilityFilterReducer,
  auth: authReducer,
  groupBy: groupByReducer,
  map: mapReducer,
  channel: channelReducer,
  userSetting: userSettingReducer,
  arps: arpsReducer,
  project: projectReducer,
  normalizeBy: normalizeByReducer,
  documentation: documentationReducer,
  dataTable: dataTableReducer,
  app: appReducer,
  charts: chartsReducer
});

export const resetSlices = (dispatch) => {
  dispatch(resetAppSlice());
  dispatch(resetArpsSlice());
  dispatch(resetChannelSlice());
  dispatch(resetNormalizeBySlice());
  dispatch(resetProjectSlice());
  dispatch(resetUserSettingsSlice());
  dispatch(resetFacilityFilterSlice());
  dispatch(resetFilterSlice());
  dispatch(resetGroupBySlice());
  dispatch(resetMapSlice());
  dispatch(resetOrgSettingsSlice());
  dispatch(resetCharts());
};
export type RootState = ReturnType<typeof rootReducer>;

export default withReduxStateSync(rootReducer);
