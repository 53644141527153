import { Radio } from "antd";
import styled from "styled-components/macro";

import { LabeledSwitch } from "components/base";
import { useChartSettings } from "components/multiphase-chart/context";
import { changeLegend } from "components/multiphase-chart/context/reducer/chartSettingsReducer";

const Legend = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();
  const { legend } = chartSettings;

  // Whether or not to allow in type selection to allow switching to inplot legend.
  const enableInPlot = false;

  const visibleToggle = (value) => {
    const nextSettings = { ...legend, ["visible"]: value };
    changeLegend(chartSettingsDispatch, nextSettings);
  };

  const legendTypeToggle = (value) => {
    const nextSettings = { ...legend, ["type"]: value.target.value };
    changeLegend(chartSettingsDispatch, nextSettings);
  };

  const positionToggle = (value) => {
    const nextSettings = { ...legend, ["position"]: value.target.value };
    changeLegend(chartSettingsDispatch, nextSettings);
  };

  return (
    <Wrapper>
      <Header>
        <LabeledSwitch
          testId="multiphasechart-legend-toggle"
          label={{
            value: "Legend",
            isBold: true,
            isUppercase: true
          }}
          switch={{
            isChecked: legend.visible,
            onChange: visibleToggle
          }}
        />
      </Header>
      {legend.visible && enableInPlot && (
        <>
          <Radio.Group name="legend-type" value={legend.type} onChange={legendTypeToggle}>
            <RadioContainer>
              <StyledRadio value="bottom">Bottom Legend</StyledRadio>
              <StyledRadio value="inplot">In-Plot Legend</StyledRadio>
            </RadioContainer>
          </Radio.Group>
          {legend.type === "inplot" && (
            <Radio.Group
              name="legend-position"
              value={legend.position}
              onChange={positionToggle}>
              <PositionRadioContainer>
                <StyledRadio value="NW">NW</StyledRadio>
                <StyledRadio value="NE">NE</StyledRadio>
                <StyledRadio value="SW">SW</StyledRadio>
                <StyledRadio value="SE">SE</StyledRadio>
              </PositionRadioContainer>
            </Radio.Group>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Legend;

const Wrapper = styled.div`
  width: 200px;
  display: grid;
  margin: -12px -16px;
`;

const Header = styled.div`
  height: 40px;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  border-bottom: 1px solid rgba(0 0 0 / 10%);
  padding: 0 16px;
`;

const RadioContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const PositionRadioContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const StyledRadio = styled(Radio)`
  font-weight: 500;
  padding: 8px 16px;

  &.ant-radio-wrapper-checked {
    font-weight: 600;
  }
`;
