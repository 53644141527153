import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ViewList } from "@material-ui/icons";
import {
  ALL_CHART_TYPES,
  ALL_PRODUCT_TYPES,
  CHART_TYPE_LABELS,
  ChartProduct,
  InjectionProduct,
  MaterialBalanceTimeChartProducts,
  SalesProduct,
  ShrinkageProduct,
  SummaryProduct,
  ThroughputProduct
} from "constants/chart.constants";
import styled from "styled-components/macro";

import { ToolbarButton } from "components/base";

import { isLabelInCategory } from "../../utils/chart/isLabelInCategory";
import ChartSelector from "./ChartSelector";
import { useChartDispatch, useChartState } from "./context";

type ChartHeaderT = {
  onChange?: (t: string) => void;
  onToggleDataTable?: (visible: boolean) => void;
  canShowDataTable?: boolean;
  dataTableVisible?: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ChartHeader: FunctionComponent<ChartHeaderT> = ({
  onChange = null,
  onToggleDataTable = null,
  canShowDataTable = false,
  dataTableVisible = false
}) => {
  const { settings: chart, id } = useChartState();
  const dispatch = useChartDispatch();
  const [{ chartType, product, typeIdx }, setState] = useState({
    chartType: chart.chartType,
    product: chart.product,
    typeIdx: CHART_TYPE_LABELS.findIndex((x) => x === chart.chartType)
  });

  useEffect(() => {
    // display "rate time" chart if "rate cum" or "cum time" or "total rate cum" is selected for on time product
    if (
      (chart.chartType === ALL_CHART_TYPES.RateCum.label ||
        chart.chartType === ALL_CHART_TYPES.TotalRateCum.label ||
        chart.chartType === ALL_CHART_TYPES.CumTime.label) &&
      (product === ALL_PRODUCT_TYPES.OnTime.label ||
        product === ALL_PRODUCT_TYPES.Shrinkage.label)
    ) {
      setState({
        chartType: ALL_CHART_TYPES.RateTime.label,
        product: product,
        typeIdx: CHART_TYPE_LABELS.findIndex((x) => x === ALL_CHART_TYPES.RateTime.label)
      });
    }
    // display "rate date" chart if "total rate date" is selected for ratios/percentages/yields/HeatingValue/Shrinkage
    const rateDateCategories = [
      "Percent",
      "Ratio",
      "Yields Raw",
      "Yields Sales",
      "Heating Value",
      "Yields Inlet",
      "Yields Outlet",
      "Percent Inlet",
      "Percent Outlet",
      "Shrinkage"
    ];

    const isProductInRateDateCategory =
      rateDateCategories.some((category) =>
        isLabelInCategory(ChartProduct, category, product)
      ) ||
      rateDateCategories.some((category) =>
        isLabelInCategory(SummaryProduct, category, product)
      ) ||
      rateDateCategories.some((category) =>
        isLabelInCategory(SalesProduct, category, product)
      ) ||
      rateDateCategories.some((category) =>
        isLabelInCategory(InjectionProduct, category, product)
      ) ||
      rateDateCategories.some((category) =>
        isLabelInCategory(ShrinkageProduct, category, product)
      ) ||
      rateDateCategories.some((category) =>
        isLabelInCategory(ThroughputProduct, category, product)
      );

    if (
      chart.chartType === ALL_CHART_TYPES.TotalRateDate.label &&
      (isProductInRateDateCategory ||
        chart.product === "Heating Value" ||
        chart.product === "Shrinkage" ||
        chart.product === "Inlet Utilization")
    ) {
      setState({
        chartType: ALL_CHART_TYPES.RateDate.label,
        product: product,
        typeIdx: CHART_TYPE_LABELS.findIndex((x) => x === ALL_CHART_TYPES.RateDate.label)
      });

      const totalRateDateUnsupportedProductWarning = `total-rate-date-unsupported-warning-${id}`;
      toast.dismiss(totalRateDateUnsupportedProductWarning);
      toast.warn(
        "Total Rate Date not supported for ratio products or percentages. Showing Rate Date instead.",
        {
          containerId: id,
          toastId: totalRateDateUnsupportedProductWarning
        }
      );
    }

    if (chart.chartType !== chartType || chart.product !== product) {
      let showAverage = chart.average;
      if (
        chartType === ALL_CHART_TYPES.RateDate.label ||
        chartType === ALL_CHART_TYPES.CAGR.label ||
        chartType === ALL_CHART_TYPES.BaseDeclineRate.label ||
        chartType === ALL_CHART_TYPES.TotalRateDate.label
      ) {
        showAverage = false;
      }

      const isMaterialBalance = chartType === ALL_CHART_TYPES.MaterialBalanceTime.label;

      // Switch product to oil if the previously selected product isn't compatible with material balance
      if (isMaterialBalance) {
        if (!MaterialBalanceTimeChartProducts.includes(product)) {
          setState({
            chartType: ALL_CHART_TYPES.MaterialBalanceTime.label,
            product: MaterialBalanceTimeChartProducts[0],
            typeIdx: CHART_TYPE_LABELS.findIndex(
              (x) => x === ALL_CHART_TYPES.MaterialBalanceTime.label
            )
          });
        }
      }

      // Spaghetti mode defaulting
      // Keep sum on if sum has been previously enabled
      const newSum = chart.sum || isMaterialBalance;

      dispatch({
        type: "settings",
        payload: Object.assign({}, chart, {
          product: product,
          chartType: chartType,
          average: showAverage,
          sum: newSum
        })
      });
    }
  }, [chart, chart.chartType, chart.product, chartType, dispatch, onChange, product]);

  return (
    <Wrapper>
      {canShowDataTable && (
        <>
          <ToolbarButton
            style={{ marginLeft: "4px", marginRight: "4px" }}
            active={dataTableVisible}
            icon={<ViewList fontSize="large" />}
            tooltipText={
              chartType === ALL_CHART_TYPES.Probit.label
                ? "Show Stats Table"
                : "Show Raw Data"
            }
            overflowLabel={
              chartType === ALL_CHART_TYPES.Probit.label
                ? "Show Stats Table"
                : "Show Raw Data"
            }
            onToggle={(isVisible) => onToggleDataTable && onToggleDataTable(isVisible)}
          />
          <Divider />
        </>
      )}

      <ChartSelector
        chartType={chartType}
        product={product}
        typeIdx={typeIdx}
        setState={setState}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default ChartHeader;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const Divider = styled.div`
  width: 1px;
  height: var(--chart-toolbar-height);
  background-color: #d9e1e2;
`;
