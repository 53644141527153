import { EvaSource, TimeStep } from "constants/charts.enums";
import { createMergeWithDefaults, createStateWithDefaults } from "entities/utils";
import { RateCumChartRequestType, RateCumChartSettings } from "types";

import { MaxBinsSortOrderEnum } from "models/binSize";
import { EntityKind } from "models/entityKind";

import { BASE_CHART_REQUEST } from "./defaults";

export const DEFAULT_RATE_CUM_CHART_SETTINGS: Partial<RateCumChartSettings> = {
  product: "Oil",
  otherProducts: [],
  chartType: 1,
  showForecast: false,
  showAverage: false,
  showWellCount: true,
  averageSettings: {
    color: "#000000",
    thickness: 3
  },
  p10Settings: {
    color: "#000000",
    thickness: 3
  },
  p50Settings: {
    color: "#000000",
    thickness: 3
  },
  p90Settings: {
    color: "#000000",
    thickness: 3
  },
  pdenDataSourceSetting: {
    source: EvaSource.Public,
    timeStep: TimeStep.Month
  },
  forecastMode: "forecastTrump",
  useForecastStartDate: false,
  survivorBias: true,
  rateType: 0,
  showIndividualSeries: false,
  cutoff: 50,
  timeStep: TimeStep.Month,
  shutInMonths: 3,
  source: EvaSource.Public,
  normalizeBy: {
    useNormalizeBy: false,
    field: "Well_Design.HzLength_m",
    per: 100,
    displayName: "Hz Length (m)",
    unit: "m",
    useMultilinearNormalization: false,
    threshold: 0,
    lowerScalar: 1,
    higherScalar: 1
  },
  useWeightedRatioAverage: true,
  xVariable: {
    valueProperty: "Dates.Vintage_Year",
    displayTitle: "Vintage",
    canBin: true,
    dataType: "Integer",
    mappingName: "Dates.Vintage_Year",
    bin: null
  },
  yVariable: {
    valueProperty: "Well_Design.HzLength_m",
    displayTitle: "HzLengthEstimate",
    canBin: true,
    dataType: "Number",
    mappingName: "Well_Design.HzLength_m"
  },
  lockUnits: false,
  showLinesOfBestFit: true,
  stackedBarCalculation: 0,
  pressureChartSettings: {
    product: "Oil",
    productivityIndex: false
  },
  movingAverageDays: null,
  annotationSettings: {}
};

export const DEFAULT_RATE_CUM_REQUEST: Partial<RateCumChartRequestType> = {
  ...BASE_CHART_REQUEST,
  FilterId: "",
  requestId: "",
  ReverseColor: false,
  ColorPalette: {
    id: "-6",
    name: "Portland",
    shared: false,
    colors: [
      "rgb(12,51,131)",
      "rgb(12,65,140)",
      "rgb(11,108,168)",
      "rgb(10,136,186)",
      "rgb(107,167,132)",
      "rgb(145,180,110)",
      "rgb(165,186,99)",
      "rgb(242,211,56)",
      "rgb(242,198,56)",
      "rgb(242,180,56)",
      "rgb(242,161,56)",
      "rgb(242,143,56)",
      "rgb(232,96,45)",
      "rgb(225,68,39)",
      "rgb(219,39,32)",
      "rgb(217,30,30)"
    ],
    preferredColors: [],
    paletteType: 0,
    colorScale: [
      {
        index: 0,
        rgbColor: "rgb(12, 51, 131)"
      },
      {
        index: 0.25,
        rgbColor: "rgb(10, 136, 186)"
      },
      {
        index: 0.5,
        rgbColor: "rgb(242, 211, 56)"
      },
      {
        index: 0.75,
        rgbColor: "rgb(242, 143, 56)"
      },
      {
        index: 1,
        rgbColor: "rgb(217, 30, 30)"
      }
    ],
    specialColors: null,
    thickness: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
    reverse: false
  },
  GroupBy: {
    title: "Resource Play",
    property: "Header.ResourcePlay",
    groupByField: "Header.ResourcePlay",
    pdenSource: "Public",
    canBin: false,
    dataType: "Text", // TODO: check this
    categoryId: 1,
    tooltip: "",
    display: "",
    entityKind: EntityKind.Well,
    bin: {
      BinSize: 0,
      MinSize: null,
      MaxBins: null,
      MaxBinsSortOrder: MaxBinsSortOrderEnum.WellCount,
      GreaterThan: null,
      LessThan: null,
      Quantile: {
        quantileType: "NumberOfQuantile",
        numQuantiles: 4
      },
      BinType: "BinSize",
      UseDynamicBins: false
    }
  },
  sortBy: "AlphabeticalAsc",
  typeWells: [],
  normalizeTypeWell: true,
  userForecast: [],
  userForecastSettings: {
    forecastSourceFolder: null,
    excludeMcDanielForecasts: false,
    reserveCategory: null,
    excludeNoviForecasts: true,
    specialForecastFolders: ["McDaniel Research"]
  },
  forecastMode: "forecastTrump",
  useForecastStartDate: false,
  showGroupsNotInFilter: true,
  placeholderBinsEnabled: false,
  hasCheckedBins: true,
  syncClientPden: false,
  syncClientSchema: "",
  colorLockedItems: {},
  syncClientFcst: false,
  syncForecastRescat: "2111",
  normalizeBySetting: {
    field: "Well_Design.HzLength_m",
    display: "Hz Length (m)",
    per: 100,
    unit: "m",
    useMultilinearNormalization: false,
    threshold: 0,
    lowerScalar: 1,
    higherScalar: 1,
    useNormalizeBy: false
  },
  chartSetting: DEFAULT_RATE_CUM_CHART_SETTINGS as RateCumChartSettings,
  axisMinMax: {
    xMin: null,
    xMax: null,
    yMin: null,
    yMax: null
  },
  isDefaultLockOn: true
};

export const generateRateCumRequestBody = createMergeWithDefaults(
  DEFAULT_RATE_CUM_REQUEST
);

export const generateRateCumChartSettings = createStateWithDefaults(
  DEFAULT_RATE_CUM_CHART_SETTINGS
);
