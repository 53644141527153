import { parseISO } from "date-fns";

import { ArpSegment } from "models/UserArpsModel";

import useDefaultTypeWell from "../hooks/useDefaultTypeWell";
import { arpsWasm, updateRampUpSegmentValues } from "./UpdateSegment";
import { getDiAtDay, getTypewellTemplateFields } from "./arpsUtils";
import { addAvgMonthsToDate, getDurationInDays } from "./dates";

export interface ExtendSegment {
  (productArps: ArpSegment[]): ArpSegment[];
}

const useExtendSegments = () => {
  const { getDefaultTypeWell } = useDefaultTypeWell();

  const calculateQf = (di: number, df: number, qi: number, b: number): number => {
    const originalQf = arpsWasm.getRateAtNominal(di, df, qi, b);
    return Math.round((originalQf + Number.EPSILON) * 1000000) / 1000000;
  };

  const extendOneSegmentTerminalDecline: ExtendSegment = (productArps: ArpSegment[]) => {
    const product = productArps[0].product;
    const defaultTypeWell = getDefaultTypeWell(product);

    const currentValues = getTypewellTemplateFields(
      null,
      productArps,
      null,
      defaultTypeWell
    );

    const bVal = currentValues.B ?? defaultTypeWell.values.Bhyp;

    const seg1 = {
      product,
      qi: currentValues.Qi,
      b: bVal,
      qf: parseFloat(currentValues.Qf.toString()),
      df: currentValues.Df,
      di: currentValues.Di,
      startDate: currentValues["Start Date"],
      segmentIndex: 1,
      trendCum: 0,
      endDate: currentValues["Start Date"]
    };

    return [seg1];
  };

  const extendTwoSegmentTerminalDecline: ExtendSegment = (productArps: ArpSegment[]) => {
    const product = productArps[0].product;
    const defaultTypeWell = getDefaultTypeWell(product);

    const currentValues = getTypewellTemplateFields(
      null,
      productArps,
      null,
      defaultTypeWell
    );

    const seg1 = {
      product,
      qi: currentValues.Qi,
      b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
      qf: parseFloat(currentValues.Qf.toString()),
      df: currentValues.Df,
      di: currentValues.Di,
      startDate: currentValues["Start Date"],
      segmentIndex: 1,
      trendCum: 0,
      endDate: currentValues["Start Date"]
    };

    seg1.qf = arpsWasm.getRateAtNominal(seg1.di, seg1.df, seg1.qi, seg1.b);

    const seg2 = {
      product,
      qi: seg1.qf,
      b: currentValues.Bf ?? defaultTypeWell?.values?.Bf,
      qf: parseFloat(currentValues.Qf.toString()),
      df: seg1.df,
      di: seg1.df,
      startDate: seg1.endDate,
      segmentIndex: 2,
      trendCum: 0,
      endDate: new Date().toISOString()
    } as ArpSegment;

    return [seg1].concat([seg2]);
  };

  const extendTwoSegmentTerminalDeclineWithRampUp: ExtendSegment = (
    productArps: ArpSegment[]
  ) => {
    const product = productArps[0].product;
    const defaultTypeWell = getDefaultTypeWell(product);

    const currentValues = getTypewellTemplateFields(
      null,
      productArps,
      null,
      defaultTypeWell
    );

    const startDate = new Date(currentValues["Start Date"]);
    let endDate = new Date(currentValues["Start Date"]);
    const monthsToAdd = currentValues.tRamp ?? defaultTypeWell.values.tramp;
    endDate = addAvgMonthsToDate(endDate, monthsToAdd).toDate();

    const seg1 = {
      product,
      qi: currentValues.Q0 ?? defaultTypeWell.values.Q0,
      b: 0,
      qf: currentValues.Qi,
      df: -1, //TODO: calculate the correct df based on Q0 and Qi
      di: -1, //TODO: calculate the correct di based on Q0 and Qi
      startDate: startDate.toISOString(),
      segmentIndex: 1,
      trendCum: 0,
      endDate: endDate.toISOString()
    };
    updateRampUpSegmentValues(seg1, true);

    const seg2 = {
      product,
      qi: seg1.qf,
      b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
      qf: seg1.qf,
      df: currentValues.Df,
      di: currentValues.Di,
      startDate: seg1.endDate,
      segmentIndex: 2,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    seg2.qf = calculateQf(seg2.di, seg2.df, seg2.qi, seg2.b);

    const seg3 = {
      product,
      qi: seg2.qf,
      b: currentValues.Bf ?? defaultTypeWell.values.Bf,
      qf: parseFloat(currentValues.Qf.toString()),
      df: seg2.df,
      di: seg2.df,
      startDate: seg2.endDate,
      segmentIndex: 3,
      trendCum: 0,
      endDate: new Date().toISOString()
    } as ArpSegment;

    return [seg1].concat([seg2, seg3]);
  };

  const extendThreeSegmentTerminalDecline: ExtendSegment = (
    productArps: ArpSegment[]
  ) => {
    const product = productArps[0].product;
    const defaultTypeWell = getDefaultTypeWell(product);

    const currentValues = getTypewellTemplateFields(
      null,
      productArps,
      null,
      defaultTypeWell
    );

    const bTrans = currentValues.Btrans ?? defaultTypeWell.values.Btrans;
    const tTrans = currentValues.tTrans ?? defaultTypeWell.values.ttrans;

    const startDate = parseISO(currentValues["Start Date"]);
    const tTransEndDate = addAvgMonthsToDate(startDate, tTrans);
    const daysInBetween = getDurationInDays(
      startDate.toISOString(),
      tTransEndDate.toISOString()
    );

    const seg1 = {
      product,
      qi: currentValues.Qi,
      b: bTrans,
      qf: arpsWasm.getRateAtDay(
        currentValues.Qi,
        currentValues.Di,
        bTrans,
        daysInBetween
      ),
      df: currentValues.Di,
      di: currentValues.Di,
      startDate: currentValues["Start Date"],
      segmentIndex: 1,
      trendCum: 0,
      endDate: currentValues["Start Date"]
    };

    seg1.df = arpsWasm.getNominalDeclineAtRate(seg1.di, seg1.qi, seg1.b, seg1.qf);

    let endDate = new Date(seg1.startDate);
    endDate = addAvgMonthsToDate(endDate, tTrans).toDate();
    seg1.endDate = endDate.toISOString();

    const seg2 = {
      product,
      qi: seg1.qf,
      b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
      qf: 100.0,
      df: currentValues.Df,
      di: seg1.df,
      startDate: seg1.endDate,
      segmentIndex: 2,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    seg2.qf = calculateQf(seg2.di, seg2.df, seg2.qi, seg2.b);

    const seg3 = {
      product,
      qi: seg2.qf,
      b: currentValues.Bf ?? defaultTypeWell.values.Bf,
      qf: currentValues.Qf,
      df: seg2.df,
      di: seg2.df,
      startDate: seg2.endDate,
      segmentIndex: 3,
      trendCum: 0,
      endDate: new Date().toISOString()
    } as ArpSegment;
    return [seg1].concat([seg2, seg3]);
  };

  const extendThreeSegmentTerminalDeclineWithRampUp: ExtendSegment = (
    productArps: ArpSegment[]
  ) => {
    const product = productArps[0].product;
    const defaultTypeWell = getDefaultTypeWell(product);

    const currentValues = getTypewellTemplateFields(
      null,
      productArps,
      null,
      defaultTypeWell
    );

    const startDate = new Date(currentValues["Start Date"]);
    const monthsToAdd = currentValues.tRamp ?? defaultTypeWell.values.tramp;
    const endDate = addAvgMonthsToDate(startDate, monthsToAdd);

    const seg1 = {
      product,
      // If Q0 is less than Qi, use Q0, otherwise use default value
      qi:
        currentValues.Q0 && currentValues.Q0 < currentValues.Qi
          ? currentValues.Q0
          : defaultTypeWell.values.Q0,
      b: 0,
      qf: currentValues.Qi,
      df: -1, //TODO: calculate the correct df based on Q0 and Qi
      di: -1, //TODO: calculate the correct di based on Q0 and Qi
      startDate: startDate.toISOString(),
      segmentIndex: 1,
      trendCum: 0,
      endDate: endDate.toISOString()
    };

    updateRampUpSegmentValues(seg1, true);
    const bTrans = currentValues.Btrans ?? defaultTypeWell.values.Btrans;
    const tTrans = currentValues.tTrans ?? defaultTypeWell.values.ttrans;

    const tTransEndDate = addAvgMonthsToDate(parseISO(seg1.startDate), tTrans);
    const daysInBetween = getDurationInDays(
      startDate.toISOString(),
      tTransEndDate.toISOString()
    );

    const seg2 = {
      product,
      qi: seg1.qf,
      b: bTrans,
      qf: arpsWasm.getRateAtDay(
        currentValues.Qi,
        currentValues.Di,
        bTrans,
        daysInBetween
      ),
      df: currentValues.Df,
      di: currentValues.Di,
      startDate: seg1.endDate,
      segmentIndex: 2,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);

    let s2endDate = new Date(seg2.startDate);
    s2endDate = addAvgMonthsToDate(s2endDate, tTrans).toDate();
    seg2.endDate = s2endDate.toISOString();

    const seg3 = {
      product,
      qi: seg2.qf,
      b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
      qf: 100.0,
      df: currentValues.Df,
      di: seg2.df,
      startDate: seg2.endDate,
      segmentIndex: 3,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    seg3.qf = calculateQf(seg3.di, seg3.df, seg3.qi, seg3.b);

    const seg4 = {
      product,
      qi: seg3.qf,
      b: currentValues.Bf ?? defaultTypeWell.values.Bf,
      qf: currentValues.Qf,
      df: seg3.df,
      di: seg3.df,
      startDate: seg3.endDate,
      segmentIndex: 4,
      trendCum: 0,
      endDate: new Date().toISOString()
    } as ArpSegment;

    return [seg1].concat([seg2, seg3, seg4]);
  };

  const extendThreeSegmentTerminalDeclineWithConstrainedPeriod: ExtendSegment = (
    productArps: ArpSegment[]
  ) => {
    const product = productArps[0].product;
    const defaultTypeWell = getDefaultTypeWell(product);

    const currentValues = getTypewellTemplateFields(
      null,
      productArps,
      null,
      defaultTypeWell
    );

    const startDate = new Date(currentValues["Start Date"]);
    const tRamp = currentValues.tRamp ?? defaultTypeWell.values.tramp;
    const endDate = addAvgMonthsToDate(startDate, tRamp);
    const constrainedQi = currentValues.Qi * 1.1;

    const seg1 = {
      product,
      qi: constrainedQi,
      b: currentValues.Btrans,
      qf: currentValues.Qi,
      di: currentValues.Di,
      df: currentValues.Di,
      startDate: startDate.toISOString(),
      segmentIndex: 1,
      trendCum: 0,
      endDate: endDate.toISOString()
    };

    const tTransEndDate = addAvgMonthsToDate(parseISO(seg1.startDate), tRamp);
    const daysInBetween = getDurationInDays(
      startDate.toISOString(),
      tTransEndDate.toISOString()
    );

    if (seg1.qi > seg1.qf) {
      seg1.di = getDiAtDay(seg1.qi, seg1.qf, seg1.b, daysInBetween);
    }

    const bTrans = currentValues.Btrans ?? defaultTypeWell.values.Btrans;
    const tTrans = currentValues.tTrans ?? defaultTypeWell.values.ttrans;

    const seg2 = {
      product,
      qi: seg1.qf,
      b: bTrans,
      qf: arpsWasm.getRateAtDay(
        currentValues.Qi,
        currentValues.Di,
        bTrans,
        daysInBetween
      ),
      df: currentValues.Df,
      di: currentValues.Di,
      startDate: seg1.endDate,
      segmentIndex: 2,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);

    let s2endDate = new Date(seg2.startDate);
    s2endDate = addAvgMonthsToDate(s2endDate, tTrans).toDate();
    seg2.endDate = s2endDate.toISOString();

    const seg3 = {
      product,
      qi: seg2.qf,
      b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
      qf: 100.0,
      df: currentValues.Df,
      di: seg2.df,
      startDate: seg2.endDate,
      segmentIndex: 3,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    seg3.qf = calculateQf(seg3.di, seg3.df, seg3.qi, seg3.b);

    const seg4 = {
      product,
      qi: seg3.qf,
      b: currentValues.Bf ?? defaultTypeWell.values.Bf,
      qf: currentValues.Qf,
      df: seg3.df,
      di: seg3.df,
      startDate: seg3.endDate,
      segmentIndex: 4,
      trendCum: 0,
      endDate: new Date().toISOString()
    } as ArpSegment;

    return [seg1].concat([seg2, seg3, seg4]);
  };

  return {
    extendOneSegmentTerminalDecline,
    extendTwoSegmentTerminalDecline,
    extendTwoSegmentTerminalDeclineWithRampUp,
    extendThreeSegmentTerminalDecline,
    extendThreeSegmentTerminalDeclineWithRampUp,
    extendThreeSegmentTerminalDeclineWithConstrainedPeriod
  };
};

export default useExtendSegments;
