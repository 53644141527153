import { IChartPreset } from "components/multiphase-chart/models/shared.models";

/**
 * Find default axis position to show grid lines
 * (bottom and left for x and y axis respectively by default,
 * top and right if no axes on bottom and left)
 *
 * @param {IChartPreset} preset chart preset
 * @returns {string[]} default axis positions
 */
const getDefaultAxisPositionForGridLines = (preset: IChartPreset): string[] => {
  const defaultXAxisForGrid =
    preset?.mBblPosition === preset?.mMcfPosition && preset.chartType === "monthlyRateCum"
      ? preset.mBblPosition
      : "bottom";
  const defaultYAxisForGrid =
    preset?.products.find((product) => product.axis === "primary") !== undefined
      ? "left"
      : "right";
  return [defaultXAxisForGrid, defaultYAxisForGrid];
};

export default getDefaultAxisPositionForGridLines;
