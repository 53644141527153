import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import CloseIcon from "@material-ui/icons/Close";
import LockIcon from "@material-ui/icons/Lock";
import area from "@turf/area";
import { Feature, MultiPolygon, Polygon } from "@turf/helpers";
import intersect from "@turf/intersect";
import { excludeWellsInPolygon, filterWellsFromMapExtent } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { BaseIconToggle, Tooltip } from "components/base";
import { FilterMinus } from "components/icons";

type FilterPolygonType =
  | Polygon
  | MultiPolygon
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Feature<Polygon | MultiPolygon, { [name: string]: any }>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function FilterSelectedWell({
  location,
  polygon,
  onFilter,
  onExcludeFilter,
  cancelPolygon,
  isFullscreen
}): JSX.Element {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const currentPolygon = useSelector(
    (state: RootState) => state.filter.polygonFilter
  ) as FilterPolygonType;

  const [polygonArea, setPolygonArea] = useState<number>(0);

  const filterToPolygon = useCallback(() => {
    if (!polygon) {
      return;
    }

    let geom = polygon;

    if (geom.type === "Feature") {
      geom = polygon.geometry;
    }

    if (currentPolygon) {
      const intersection = intersect(currentPolygon, geom);
      if (intersection && intersection.geometry) {
        geom = intersection.geometry;
      }
    }

    if (geom.type === "MultiPolygon") {
      toast.error("Invalid polygon selection. Please try again.");
      cancelPolygon();
      return;
    }

    geom.source = "selection";
    geom.operator = "=";

    dispatch(filterWellsFromMapExtent(geom));
    onFilter && onFilter();
  }, [polygon, dispatch, onFilter]);

  const removeSelectedWells = useCallback(() => {
    if (!polygon) return;

    let geom = polygon;

    if (geom.type === "Feature") {
      geom = polygon.geometry;
    }

    geom.source = "selection";
    geom.operator = "!=";

    dispatch(excludeWellsInPolygon(geom));
    onExcludeFilter && onExcludeFilter();
  }, [polygon, dispatch, onExcludeFilter]);

  useEffect(() => {
    if (polygon && polygon.properties && polygon.properties.source === "View")
      filterToPolygon();
  }, [polygon]);

  useEffect(() => {
    ReactTooltip.hide();

    if (!location) return;

    if (!menuRef.current) return;

    const element = menuRef.current;
    const menuRect = element.getBoundingClientRect();
    const mapRect = document.getElementById("map").getBoundingClientRect();

    if (menuRect.right > mapRect.right) {
      element.style.left = `${
        location.x - (menuRect.right - mapRect.right) + (isFullscreen ? 330 : 0)
      }px`;
    }

    if (menuRect.bottom > mapRect.bottom) {
      element.style.top = `${
        location.y - (menuRect.bottom - mapRect.bottom) + (isFullscreen ? 50 : 0)
      }px`;
    }
  }, [location]);

  useEffect(() => {
    if (polygon) {
      setPolygonArea(area(polygon) * 1e-6); // convert area to sqkm
    }
  }, [polygon]);

  return (
    location && (
      <ButtonContainer
        ref={menuRef}
        top={location.y + (isFullscreen ? 50 : 0)}
        left={location.x + (isFullscreen ? 330 : 0)}>
        <AreaContainer>
          {polygonArea.toFixed(2)} km<sup>2</sup>
        </AreaContainer>
        <div className="button-group">
          <Tooltip title="Add to Filter" placement="bottom" mouseEnterDelay={2}>
            <BaseIconToggle toggle={filterToPolygon}>
              <LockIcon></LockIcon>
            </BaseIconToggle>
          </Tooltip>
          <Tooltip title="Exclude from Filter" placement="bottom" mouseEnterDelay={2}>
            <BaseIconToggle toggle={removeSelectedWells}>
              <FilterMinus></FilterMinus>
            </BaseIconToggle>
          </Tooltip>
        </div>
        <div className="button-group">
          <Tooltip title="Cancel" placement="bottom" mouseEnterDelay={2}>
            <BaseIconToggle toggle={cancelPolygon}>
              <CloseIcon></CloseIcon>
            </BaseIconToggle>
          </Tooltip>
        </div>
      </ButtonContainer>
    )
  );
}

export default FilterSelectedWell;

const AreaContainer = styled.div`
  background: white;
  margin: 0 -15px;
  border-radius: 4px;
  padding: 10px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 5px;

  .button-group {
    display: flex;
    flex-direction: row;
    background: var(--color-surface-03);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    svg {
      color: #a2aaad;
      transition: color var(--duration);
    }

    & > div:hover {
      svg {
        color: var(--color-text);
      }
    }
  }

  & > .button-group:first-child > div:first-child {
    border-right: 1px solid rgba(162, 170, 173, 0.4);
  }

  z-index: 99999;
`;
