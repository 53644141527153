import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import { arrayOrEmpty } from "utils";

import { EntityKind } from "../../../models/entityKind";
import { WellInfoT, useWellList } from "../context";
import { IWellGroupMap } from "../context/WellListContext";
import { updateWellGroupMap, updateWellList } from "../context/reducer";
import useGetGroupedEntities from "./useGetGroupedEntities";
import useGetWellsFromFilterId from "./useGetWellsFromFilterId";

export default function useWellsFromFilter() {
  const [{ entityKind }, dispatch] = useWellList();
  const filterId = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.filterId : state.facilityFilter.filterId
  );
  const isFiltering = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.isFiltering
      : state.facilityFilter.isFiltering
  );
  const mapTxnId = useSelector((state: RootState) => state.map.txnId);

  const handleWellList = useCallback(
    (response) => {
      const wells: WellInfoT[] = arrayOrEmpty<{ uniqueId: string; formattedId: string }>(
        response?.data?.uwiList
      ).map((w): WellInfoT => {
        return {
          id: w.uniqueId,
          label: w.formattedId
        };
      });
      updateWellList(dispatch, wells);
    },
    [dispatch]
  );

  const handleEntities = useCallback(
    (groups) => {
      // Convert the entities mapping into
      const mapping: IWellGroupMap = {};
      Object.keys(groups).forEach((key) => {
        const group = groups[key];
        Object.keys(group.uwis).forEach((uwi: string) => {
          mapping[uwi] = {
            group: group.group?.title,
            color: group.group?.color
          };
        });
      });
      updateWellGroupMap(dispatch, mapping);
    },
    [dispatch]
  );

  const {
    isLoading: wellListIsLoading,
    isError: wellListIsError,
    error: wellListError,
    mutate: wellListMutate
  } = useGetWellsFromFilterId(handleWellList);
  const {
    isLoading: entitiesIsLoading,
    isError: entitiesIsError,
    error: entitiesError,
    mutate: entitiesMutate
  } = useGetGroupedEntities(handleEntities);

  useEffect(() => {
    if (!filterId) return;
    wellListMutate(filterId.id);
  }, [filterId]);

  useEffect(() => {
    if (!mapTxnId) return;
    if (entityKind == EntityKind.Facility) {
      entitiesMutate(mapTxnId.facilityId);
    } else {
      entitiesMutate(mapTxnId.id);
    }
  }, [mapTxnId]);

  return {
    isLoading: wellListIsLoading || entitiesIsLoading || isFiltering,
    isError: !!wellListIsError || !!entitiesIsError,
    error: wellListError ?? entitiesError
  };
}
