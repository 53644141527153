import { Heading } from "components/base";

import useBetaFeatures from "../../hooks/useBetaFeatures";
import { EntityKind } from "../../models/entityKind";
import "./UserDefaults.scss";
import {
  DefaultBinSettings,
  DefaultColorPalette,
  DefaultFilter,
  DefaultFocusTiles,
  DefaultMapSettings,
  DefaultProject
} from "./components/defaults";

export default function UserDefaults() {
  const { hasFeature } = useBetaFeatures();
  const hasFacility = hasFeature("Facility") || hasFeature("Facility Volumes");
  return (
    <div className="userAccount">
      <div className="userAccountHeader">
        <Heading element="h4">Defaults</Heading>
      </div>
      <div className="userAccountContent">
        <div className="main">
          <div className="UserDefaults">
            <DefaultProject />
            <DefaultFilter entityKind={EntityKind.Well} />
            {hasFacility && <DefaultFilter entityKind={EntityKind.Facility} />}
            <DefaultBinSettings />
            <DefaultColorPalette entityKind={EntityKind.Well} />
            {hasFacility && <DefaultColorPalette entityKind={EntityKind.Facility} />}
            <DefaultMapSettings />
            <DefaultFocusTiles entityKind={EntityKind.Well} />
            {hasFacility && <DefaultFocusTiles entityKind={EntityKind.Facility} />}
          </div>
        </div>
      </div>
    </div>
  );
}
