import { memo } from "react";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import ToolbarButton from "../../base/ToolbarButton";
import { useChartDispatch, useChartState } from "../context";
import { convertPercentageToPixels } from "../utils/convertPercentageToPixels";

const FullscreenToggle = () => {
  const dispatch = useChartDispatch();
  const { isFullscreen, settings, instance } = useChartState();

  const handleToggle = () => (value) => {
    const referenceLines = settings.referenceLine?.lines;
    const options = instance.getOption();

    // Save the grid coordinates of the current reference lines for scaling
    if (referenceLines && referenceLines.length > 0) {
      if (value) {
        referenceLines.map((line) => {
          const point1 = convertPercentageToPixels(
            [line.point1.x, line.point1.y],
            instance,
            options.grid[0]
          );
          const point2 = convertPercentageToPixels(
            [line.point2.x, line.point2.y],
            instance,
            options.grid[0]
          );
          line.point1.chartX = instance.convertFromPixel("grid", point1)[0];
          line.point1.chartY = instance.convertFromPixel("grid", point1)[1];
          line.point2.chartX = instance.convertFromPixel("grid", point2)[0];
          line.point2.chartY = instance.convertFromPixel("grid", point2)[1];
        });
        const newReferenceLine = {
          points: settings.referenceLine?.points,
          lines: referenceLines,
          useChartValues: true
        };
        const next = { ...settings, referenceLine: newReferenceLine };
        dispatch({ type: "settings", payload: next });
      } else {
        const next = {
          ...settings,
          referenceLine: { ...settings.referenceLine, useChartValues: false }
        };
        dispatch({ type: "settings", payload: next });
      }
    }

    dispatch({ type: "fullscreen", payload: value });
  };

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <ToolbarButton
      key={"FullscreenToggle" + isFullscreen}
      icon={<FullscreenIcon fontSize="large" />}
      activeIcon={<FullscreenExitIcon fontSize="large" />}
      active={isFullscreen}
      overflowLabel="Fullscreen"
      tooltipText="Toggle Fullscreen"
      onToggle={handleToggle()}
    />
  );
};

export default memo(FullscreenToggle);
