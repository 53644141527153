import { EvaChart } from "constants/charts.enums";
import { EntityType } from "constants/entities.enum";

import { generateChartEntity } from "./chart";
import {
  generateChartsFeatureEntity,
  generateCutOffFeatureEntity,
  generateDataTableFeatureEntity,
  generateMovingAverageDaysFeatureEntity,
  generateShutInMonthsFeatureEntity,
  generateSourceFeatureEntity,
  generateTimeStepFeatureEntity
} from "./eva/features/feature";
import { generateCrossPlotRequestBody } from "./eva/requests/crossPlot";
import { generateRateCumRequestBody } from "./eva/requests/rateCum";
import {
  ChartOption,
  generateLineSeriesOptions,
  generateScatterSeriesOptions,
  generateTitleOptions
} from "./options";
import { generateChartOptions } from "./options/chart";
import { generateXAxisOptions } from "./options/xAxis";
import { generateYAxisOptions } from "./options/yAxis";

const GENERATORS = {
  [EntityType.Chart]: generateChartEntity,
  [EntityType.ChartsFeature]: generateChartsFeatureEntity,
  [EntityType.ChartDataTableFeature]: generateDataTableFeatureEntity,
  [EntityType.SourceFeature]: generateSourceFeatureEntity,
  [EntityType.TimeStepFeature]: generateTimeStepFeatureEntity,
  [EntityType.CutOffChartFeature]: generateCutOffFeatureEntity,
  [EntityType.ShutInMonthsChartFeature]: generateShutInMonthsFeatureEntity,
  [EntityType.MovingAverageDaysChartFeature]: generateMovingAverageDaysFeatureEntity,
  [EvaChart.RateCum]: generateRateCumRequestBody,
  [EvaChart.CrossPlot]: generateCrossPlotRequestBody,

  [ChartOption.Chart]: generateChartOptions,

  [ChartOption.XAxis]: generateXAxisOptions,
  [ChartOption.YAxis]: generateYAxisOptions,
  [ChartOption.Title]: generateTitleOptions,

  [ChartOption.LineSeries]: generateLineSeriesOptions,
  [ChartOption.ScatterSeries]: generateScatterSeriesOptions
};

export type Generators = keyof typeof GENERATORS;

export function createEntityState<K extends Generators>(
  type: K,
  state: Partial<ReturnType<(typeof GENERATORS)[K]>>
): ReturnType<(typeof GENERATORS)[K]> {
  const generator = GENERATORS[type];
  if (!generator) {
    throw new Error(`Unknown entity type: ${type} to create state`);
  }
  return generator(state) as ReturnType<(typeof GENERATORS)[K]>;
}
