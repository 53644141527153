// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiFormatVerticalAlignTop } from "@mdi/js";

import { ToolbarButton } from "components/base";

import { updateXDASettings, useVisState } from "../../context";

function XdaHangToggle() {
  const [{ xda }, visDispatch] = useVisState();
  const { hangWellsToTop } = xda.settings;

  const handleToggle = (active: boolean) => {
    const nextSettings = { ...xda.settings, hangWellsToTop: active };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <ToolbarButton
      icon={<Icon path={mdiFormatVerticalAlignTop} size={1.5} />}
      active={hangWellsToTop}
      tooltipText={"Hang to Top Zone"}
      overflowLabel={"Hang to Top Zone"}
      onToggle={handleToggle}
      styleKey="xdaToolbar"
    />
  );
}

export default XdaHangToggle;
