import { memo } from "react";

import { AddCircle, Delete } from "@material-ui/icons";
import { Space, Tooltip } from "antd";
import styled from "styled-components/macro";
import { noop } from "utils";

const AddReferenceLineMenu = () => {
  const onAddLine = noop;

  const onDeleteLines = noop;
  return (
    <StyledSpace>
      <Header>
        <Tooltip title="Add New Line">
          <ActionButton onClick={onAddLine}>
            <AddCircle fontSize="large"></AddCircle>
          </ActionButton>
        </Tooltip>
        <Tooltip title="Delete Checked Items">
          <ActionButton danger onClick={onDeleteLines} disabled={true}>
            <Delete fontSize="large"></Delete>
          </ActionButton>
        </Tooltip>
      </Header>

      <GridContainer></GridContainer>
    </StyledSpace>
  );
};

export default memo(AddReferenceLineMenu);

const ActionButton = styled.button`
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled] > svg {
    cursor: default;
    color: lightgray;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;

const Header = styled.div`
  width: 150px;
  align-items: right;
  justify-content: right;
  text-align: right;
`;

const StyledSpace = styled(Space)`
  width: 150px;
  display: flex;
  flex-flow: row wrap;
`;
