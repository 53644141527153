import { IS_DEV_ENV } from "constants/app.constants";
import { EntityType } from "constants/entities.enum";
import { createEntityState } from "entities/charts/factory";
import { ChartFeatures } from "types";

export function createCrossPlotFeatureEntities() {
  const features: ChartFeatures = {
    screenshot: createEntityState(EntityType.ChartsFeature, {
      show: true,
      enabled: true
    }),
    fullScreen: createEntityState(EntityType.ChartsFeature, {
      show: true,
      enabled: true
    }),
    datatable: createEntityState(EntityType.ChartDataTableFeature, {
      show: true,
      enabled: true
    }),
    spaghetti: createEntityState(EntityType.ChartsFeature, {
      show: true,
      enabled: false
    }),
    forecast: createEntityState(EntityType.ChartsFeature, {
      show: true,
      enabled: true,
      active: true
    }),
    source: createEntityState(EntityType.SourceFeature, { show: true, enabled: true }),
    debug: createEntityState(EntityType.ChartsFeature, {
      show: IS_DEV_ENV,
      enabled: IS_DEV_ENV
    }),
    timeStep: createEntityState(EntityType.TimeStepFeature, {
      show: true,
      enabled: true
    }),
    isProducingRateType: createEntityState(EntityType.ChartsFeature, {
      show: true,
      enabled: true
    }),
    cutoff: createEntityState(EntityType.CutOffChartFeature, {
      show: true,
      enabled: true
    }),
    shutInMonths: createEntityState(EntityType.ShutInMonthsChartFeature, {
      show: true,
      enabled: true
    }),
    movingAverageDays: createEntityState(EntityType.MovingAverageDaysChartFeature, {
      show: true,
      enabled: true
    }),
    survivorBias: createEntityState(EntityType.ChartsFeature, {
      show: true,
      enabled: true,
      active: true
    }),
    useWeightedRatioAverage: createEntityState(EntityType.ChartsFeature, {
      show: true,
      enabled: true,
      active: true
    }),
    lockUnits: createEntityState(EntityType.ChartsFeature, {
      show: true,
      enabled: true
    })
  };
  return features;
}
