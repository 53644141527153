import { memo, useState } from "react";
import { useSelector } from "react-redux";

import SettingsIcon from "@material-ui/icons/Settings";
import { Popover } from "antd";
import { useChartsContext } from "contexts/ChartContext";
import {
  selectChartLockUnitsFeature,
  selectChartSurvivorBiasFeature,
  selectChartUseWeightedRatioAverageFeature
} from "store/features";
import { RootState } from "store/rootReducer";

import ToolbarButton from "../../../base/ToolbarButton";
import SettingsOptions from "./SettingsOptions";

const SettingsToggle = () => {
  const { id } = useChartsContext();

  const [visible, setVisible] = useState(false);
  const isDisabled = false;
  const survivorBias = useSelector((state: RootState) =>
    selectChartSurvivorBiasFeature(state, id)
  )?.active;
  const useWeightedRatioAverage = useSelector((state: RootState) =>
    selectChartUseWeightedRatioAverageFeature(state, id)
  )?.active;
  const lockUnits = useSelector((state: RootState) =>
    selectChartLockUnitsFeature(state, id)
  )?.active;
  return (
    <Popover
      arrowPointAtCenter
      content={<SettingsOptions />}
      placement="bottomRight"
      trigger="click"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        icon={<SettingsIcon fontSize="large" />}
        isMenuButton={true}
        overflowLabel="Chart Options"
        tooltipText="Chart Options"
        disabled={isDisabled}
        active={useWeightedRatioAverage || survivorBias || lockUnits}
      />
    </Popover>
  );
};

export default memo(SettingsToggle);
