import { memo } from "react";

import { noop } from "utils";

import { LegendFilled } from "components/icons";

import ToolbarButton from "../../../base/ToolbarButton";

const LegendToggle = () => {
  //TODO clean up the legend position as it is not used anymore
  const toggle = noop;

  return (
    <ToolbarButton
      active={false}
      icon={<LegendFilled />}
      overflowLabel="Legend"
      tooltipText="Legend"
      onToggle={toggle()}
    />
  );
};

export default memo(LegendToggle);
