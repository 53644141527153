import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EvaSource } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { selectChartSourceFeature, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";
import { capitalizeFirstLetter } from "utils";

import { DataSourceIcon, DataSourceSelect } from "components/shared";

import ToolbarButton from "../../base/ToolbarButton";

const DataSourceToggle = () => {
  // derived state
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const chart = useSelector((state: RootState) => selectChartSourceFeature(state, id));
  const source = chart?.properties?.value;
  const icon = DataSourceIcon[(source as EvaSource) || EvaSource.Public];
  const label = capitalizeFirstLetter(source || EvaSource.Public);
  function handleSourceChange(value: EvaSource) {
    dispatch(
      updateChartByPath({
        id,
        path: `features.source.properties.value`,
        value: value
      })
    );
  }

  return (
    <DataSourceSelect
      trigger={["hover"]}
      value={source as EvaSource}
      onChange={handleSourceChange}>
      <ToolbarButton
        data-testid="datasource-trigger"
        aria-label={source}
        active={false}
        icon={icon}
        overflowLabel={label}
      />
    </DataSourceSelect>
  );
};

export default memo(DataSourceToggle);
