import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { fetchDimensionsFromPlay, fetchRadiusDimensions } from "api/ipdp";
import { fetchDefaultSizesForXDA } from "api/map";

import { TxnT } from "models";

import { updateXDASettings, useVisState } from "../context";
import { XDA_RADIUS_TYPES } from "../context/types";

/**
 * Simple `hook` that abstracts API call that fetched default size/constraints for selected wells on Map
 */
const useXdaConstraint = () => {
  const [{ xda }, dispatch] = useVisState();
  const { isUsingResourcePlayDefault, selectedRadius } = xda.settings;
  const padScenario = useSelector((state: RootState) => state.app.padScenario);

  const txnId: TxnT = useSelector((state: RootState) => state.map.txnId);
  const selectedWells = useSelector((state: RootState) => state.map.selectedWells);
  const uwiList = Object.keys(selectedWells) || [];

  // query params
  // txnId/uwiList added to queryKey will make sure query is executed again on changes, as long as enabled resolves to true.
  const queryKey = ["xdaConstraint", txnId?.id, ...uwiList, selectedRadius];
  const queryFn = () =>
    // If there is no selected wells, we should try to load dimensions from pad scenario play
    !uwiList?.length && padScenario?.showSticksOnMap && !!padScenario?.play
      ? fetchDimensionsFromPlay(padScenario.play)
      : selectedRadius === XDA_RADIUS_TYPES.Custom
      ? fetchDefaultSizesForXDA(uwiList)
      : fetchRadiusDimensions(uwiList);

  // query fires as wells are selected on Map
  const { data, refetch } = useQuery(queryKey, queryFn, {
    enabled: Boolean(uwiList.length) || padScenario?.showSticksOnMap
  });

  // sync fetched defaults with XDA settings in context
  useEffect(() => {
    if (!data) {
      //If there is no data to set the radius then it should be 0
      if (
        (xda.settings.upHeight != 0 ||
          xda.settings.downHeight != 0 ||
          xda.settings.leftWidth != 0 ||
          xda.settings.rightWidth != 0) &&
        isUsingResourcePlayDefault
      ) {
        updateXDASettings(dispatch, {
          ...xda.settings,
          upHeight: 0,
          downHeight: 0,
          leftWidth: 0,
          rightWidth: 0
        });
      }
      return;
    }

    if (selectedRadius !== XDA_RADIUS_TYPES.Custom && data.dimensions) {
      const dimensions = data.dimensions.find((d) => d.name === selectedRadius);
      updateXDASettings(dispatch, {
        ...xda.settings,
        upHeight: dimensions?.upHeight ?? 50,
        downHeight: dimensions?.downHeight ?? 50,
        leftWidth: dimensions?.leftWidth ?? 300,
        rightWidth: dimensions?.rightWidth ?? 300
      });
      return;
    }
    const {
      XDAWidth,
      XDAHeight,
      xdaHeightDown,
      xdaHeightUp,
      xdaWidthLeft,
      xdaWidthRight
    } = data;

    if (isUsingResourcePlayDefault) {
      const newUpHeight = xdaHeightUp ?? XDAHeight ?? 50;
      const newDownHeight = xdaHeightDown ?? XDAHeight ?? 50;
      const newLeftWidth = xdaWidthLeft ?? XDAWidth ?? 300;
      const newRightWidth = xdaWidthRight ?? XDAWidth ?? 300;
      updateXDASettings(dispatch, {
        ...xda.settings,
        upHeight: newUpHeight,
        downHeight: newDownHeight,
        leftWidth: newLeftWidth,
        rightWidth: newRightWidth
      });
    } else {
      updateXDASettings(dispatch, {
        ...xda.settings,
        upHeight: xda.settings.upHeight,
        downHeight: xda.settings.downHeight,
        leftWidth: xda.settings.leftWidth,
        rightWidth: xda.settings.rightWidth
      });
    }
  }, [data, isUsingResourcePlayDefault, selectedRadius]);

  return {
    playConstraint: data,
    refetchPlayConstraint: refetch
  };
};

export default useXdaConstraint;
