import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lock, LockOpen } from "@material-ui/icons";
import { removeAllExcludePolygon, setViewLock } from "store/features";
import { RootState } from "store/rootReducer";

import { ToolbarButton } from "components/base";
import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";

import { useMapContext } from "../hooks/useMapContext";
import { useMapDispatch } from "../hooks/useMapDispatch";

export interface MapLockComponentModel {
  getAndSetFeaturesIntersectingPolygon;
  mapSyncRef;
  setMapSync;
  // eslint-disable-next-line no-unused-vars
  onMapLockChanged: (val: boolean) => void;
}

export default function MapLock({
  mapSyncRef,
  setMapSync,
  onMapLockChanged
}: MapLockComponentModel) {
  const { mapbox } = useMapContext();
  const dispatch = useDispatch();
  const mapDispatch = useMapDispatch();
  const viewLockState = useSelector((state: RootState) => state.map.viewLock);
  const { isActive: isGeomBinOpen } = useGeomBinContext();

  async function lockCurrentView() {
    if (!mapbox) return;
    mapDispatch({
      payload: {
        selectedFeatures: []
      }
    });
  }
  function cancelViewLock() {
    if (!mapbox) {
      return;
    }
    mapSyncRef.current = true;
    setMapSync(mapSyncRef.current);
    mapbox.fire("viewLockCancel", {});
    dispatch(removeAllExcludePolygon());
  }
  useEffect(() => {
    onMapLockChanged && onMapLockChanged(viewLockState);
    dispatch(setViewLock(viewLockState));
    if (viewLockState) {
      lockCurrentView();
    } else {
      cancelViewLock();
    }
  }, [viewLockState]);
  if (!mapbox || isGeomBinOpen) {
    return <></>;
  }

  return (
    <ToolbarButton
      active={viewLockState}
      activeIcon={
        <div style={{ fontSize: "20px", top: "2px" }}>
          <Lock fontSize="inherit" />
        </div>
      }
      data-testid="map-lock"
      icon={
        <div style={{ fontSize: "20px", top: "2px" }}>
          <LockOpen fontSize="inherit" />
        </div>
      }
      onToggle={(v) => {
        dispatch(setViewLock(v));
      }}
      overflowLabel={"Polygon Lock"}
      styleKey="mapToolbar"
      tooltipText="Polygon Lock"
    />
  );
}
