import { useState } from "react";

import classnames from "classnames";
import styled from "styled-components";
import { noop } from "utils";

const ChartLegendItem = ({ legend, useCount, ...rest }) => {
  // state
  const [highlighted, setHighlighted] = useState(false);

  // derived state
  const fontSize = 14;

  // events
  const handleMouseOver = noop;
  const handleMouseOut = () => {
    setHighlighted(false);
  };

  // derived class names
  const wrapperClassNames = classnames({ highlighted });

  return (
    <Wrapper
      data-testid="chart-legend-item-wrapper"
      className={wrapperClassNames}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      {...rest}>
      <Indicator data-testid="chart-legend-item-color-indicator" color={legend.color} />
      <Title fontSize={fontSize}>{useCount ? legend.title : legend.value}</Title>
    </Wrapper>
  );
};

export default ChartLegendItem;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  background-color: transparent;
  backface-visibility: hidden;

  &.highlighted,
  &:hover {
    background-color: #dbdbdb;
  }
`;

const Indicator = styled.div`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const Title = styled.div`
  font-size: ${(props) => props.fontSize}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;
