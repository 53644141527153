import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Popover, Select, Tabs } from "antd";
import { HIGHLIGHTED_PLAY_LAYER } from "constants/mapLayers.constants";
import { GeoJSONSource } from "mapbox-gl";
import { setMapState, setUpdatePlayLegend } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { getPlaysList } from "api/filter";
import { getPlay } from "api/map";

import { ToolbarButton } from "components/base";
import Navigate from "components/icons/Navigate";

import { useMapContext } from "../hooks/useMapContext";
import MapTownshipSelector from "./MapTownshipSelector";
import SavedMapLocationSelector from "./SavedMapLocationSelector";

const SAVED_KEY = "saved";
const TOWNSHIP_KEY = "township";
const PLAY_KEY = "play";

export default function GoToTownshipPlay() {
  const dispatch = useDispatch();
  const { mapbox } = useMapContext();
  const updatePlayLegend = useSelector(
    (state: RootState) => state.filter.updatePlayLegend
  );

  const [navigateOpen, setNavigateOpen] = useState(false);
  const [navigateTab, setNavigateTab] = useState(TOWNSHIP_KEY);
  const [playsList, setPlaysList] = useState([]);
  const [selectedPlay, setSelectedPlay] = useState("");

  useEffect(() => {
    getPlaysList()
      .then((response) => {
        const index = response.indexOf(null);
        if (index > -1) {
          response.splice(index, 1);
        }
        const plays = response.map((play) => {
          return { label: play, value: play };
        });
        setPlaysList(plays);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    return () => {
      setPlaysList([]);
    };
  }, []);

  const [highlightedPlay] = useState({
    type: "FeatureCollection",
    features: []
  });

  const updateHighlightedPlay = useCallback(
    (features) => {
      highlightedPlay.features = features;
      if (!mapbox) {
        return;
      }
      const source = mapbox.getSource(HIGHLIGHTED_PLAY_LAYER) as GeoJSONSource;
      if (!source) return;
      source.setData(highlightedPlay as GeoJSON.FeatureCollection);
    },
    [highlightedPlay]
  );

  function goToPointAndHighlightPolygon(data, zoom = 5) {
    const play = data;
    if (!play || !mapbox) {
      return;
    }

    const centroid = play.Centroid;
    mapbox.flyTo({
      center: [centroid.coordinates[0], centroid.coordinates[1]],
      zoom,
      essential: true
    });
    const newMapState = {
      lng: centroid.coordinates[1],
      lat: centroid.coordinates[0],
      zoom: zoom
    };
    dispatch(setMapState(newMapState));
  }

  useEffect(() => {
    if (!updatePlayLegend?.length) {
      updateHighlightedPlay([]);
    }
  }, [updatePlayLegend]);

  useEffect(() => {
    if (selectedPlay === "Select" || !selectedPlay) return;
    getPlay(selectedPlay)
      .then((response) => {
        goToPointAndHighlightPolygon(response.data);
        dispatch(setUpdatePlayLegend(selectedPlay));
      })
      .catch(() => {
        toast.warn(`Unable to fetch location of play ${selectedPlay}`);
      });
  }, [selectedPlay]);

  if (!mapbox) {
    return null;
  }

  return (
    <Popover
      arrowPointAtCenter
      content={
        <GoToContainer>
          <Tabs
            defaultActiveKey={TOWNSHIP_KEY}
            activeKey={navigateTab}
            onTabClick={(key) => setNavigateTab(key)}
            items={[
              {
                key: TOWNSHIP_KEY,
                label: "Township Range",
                children: (
                  <MapTownshipSelector
                    closeMenu={() => setNavigateOpen(false)}
                    goToPointAndHighlightPolygon={goToPointAndHighlightPolygon}
                  />
                )
              },
              {
                key: SAVED_KEY,
                label: "Saved",
                children: <SavedMapLocationSelector />
              },
              {
                key: PLAY_KEY,
                label: "Play",
                children: (
                  <PlaySelector
                    placeholder="Select"
                    options={playsList}
                    onChange={(value) => {
                      setSelectedPlay(value);
                      setNavigateOpen(false);
                    }}
                    value={selectedPlay}
                  />
                )
              }
            ]}
          />
        </GoToContainer>
      }
      onOpenChange={(v) => setNavigateOpen(v)}
      open={navigateOpen}
      placement="bottomRight"
      trigger="click">
      <ToolbarButton
        active={false}
        icon={<Navigate size={15} />}
        overflowLabel="Jump to Location"
        tooltipText="Jump to Location"
        styleKey="miniMapToolbar"
      />
    </Popover>
  );
}

const GoToContainer = styled.div`
  width: 420px;
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d9e1e2;
  }

  .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-evenly;
  }
  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 1.4rem;
    padding: 0 15px;
    margin: 0;
  }
  .ant-tabs .ant-tabs-content {
    height: 100%;
    padding: 20px 20px;
  }
  .ant-tabs-content {
    display: grid;
    overflow-y: auto;
    padding-bottom: 10px;
    width: 100%;
  }
`;

const PlaySelector = styled(Select)`
  width: 100%;
`;
