import axios, { AxiosResponse } from "axios";

import { Percentile } from "components/vis/context/types";

const ROOT_ENDPOINT = process.env.REACT_APP_DATA_ROOT;
const DATA_ENDPOINT = `${ROOT_ENDPOINT}/api/v1/data`;

interface DynamicBinResponse {
  binSize: number;
  greaterThan: number;
  lessThan: number;
}

export async function getDynamicBinSettings(
  percentile: Percentile
): Promise<DynamicBinResponse | null> {
  const API_URL = `${DATA_ENDPOINT}/dynamic-bin-size/${percentile.p10}/${percentile.p90}`;

  try {
    const response: AxiosResponse<DynamicBinResponse> = await axios.get(API_URL);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Failed to fetch bin size: ${response.statusText}`);
    }
  } catch (error) {
    return null;
  }
}
