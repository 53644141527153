// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo, useState } from "react";

import { mdiFullscreen, mdiFullscreenExit } from "@mdi/js";

import { ToolbarButton } from "components/base";

const VisFullscreenToggle = ({ onToggle }) => {
  const [fullscreen, setFullscreen] = useState(false);

  const handleToggle = () => (value) => {
    onToggle && onToggle(value);
    setFullscreen(value);
  };

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <ToolbarButton
      key={"FullscreenToggleButton" + fullscreen}
      active={fullscreen}
      activeIcon={<Icon path={mdiFullscreenExit} size={1.75} />}
      icon={<Icon path={mdiFullscreen} size={1.75} />}
      tooltipText="Toggle Fullscreen"
      overflowLabel="Fullscreen"
      onToggle={handleToggle()}
      styleKey="threeDVToolbar"
    />
  );
};

export default memo(VisFullscreenToggle);
