import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CHART_ACTION } from "constants/chart.constants";
import { RootState } from "store/rootReducer";

import LassoIcon from "components/icons/Lasso";

import ToolbarButton from "../../../base/ToolbarButton";
import {
  toggleLassoOFF,
  toggleLassoON,
  updateBrushEndPosition,
  updateBrushedList,
  useChartDispatch,
  useChartState
} from "../../context";
import { useLasso } from "../../hooks";

function LassoBrush() {
  // context
  const dispatch = useChartDispatch();
  const { busy: isChartLoading, instance, lasso, options, response } = useChartState();
  const isFiltering = useSelector((state: RootState) => state.filter.isFiltering);
  // custom hook
  const isAllowed = useLasso();

  const [isDisabled, setIsDisabled] = useState(
    !isAllowed || isChartLoading || isFiltering
  );

  useEffect(() => {
    if (!isAllowed || isChartLoading || isFiltering) {
      setIsDisabled(true);
    } else {
      const timer = setTimeout(() => setIsDisabled(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [isAllowed, isChartLoading, isFiltering]);

  const handleLassoToggle = () => (isActive) => {
    instance.setOption({
      tooltip: { show: !isActive, showContent: !isActive }
    });

    if (isActive) {
      toggleLassoON(dispatch);
      updateBrushedList(dispatch, []);
      instance.dispatchAction(CHART_ACTION.brushStart);
    } else {
      toggleLassoOFF(dispatch);
      instance.dispatchAction(CHART_ACTION.brushClear);
      instance.dispatchAction(CHART_ACTION.brushEnd);
      updateBrushEndPosition(dispatch, null);
    }
  };

  // keep lasso ON between chart updates
  useEffect(() => {
    if (!lasso) return;
    handleLassoToggle()(lasso);
  }, [lasso, options, response]);

  // disable lasso when chart is loading or filtering
  useEffect(() => {
    if (isChartLoading || isFiltering) {
      toggleLassoOFF(dispatch);
    }
  }, [isChartLoading, isFiltering]);

  return (
    <ToolbarButton
      icon={<LassoIcon />}
      active={lasso}
      disabled={isDisabled}
      overflowLabel="Lasso Selection"
      tooltipText="Lasso Selection"
      onToggle={handleLassoToggle()}
    />
  );
}

export default memo(LassoBrush);
