import { toast } from "react-toastify";

import {
  getDefaultRateUnits,
  productStringToProductTypeEnum,
  ratioProductListForForecasting
} from "utils";
import { convert } from "utils/units/unitConversion";

import { ForecastConstant } from "models/UserArpsModel";

/**
 * Formats an array of forecast constants by converting their values to the default rate units.
 * If a constant has a unit, it converts the value to the default ratio unit.
 * If a constant does not have a unit, it assumes the value is in the database unit and converts it to the default ratio unit.
 *
 * @param {ForecastConstant[]} constants - The array of forecast constants to format.
 * @returns {ForecastConstant[]} - The array of formatted forecast constants with values converted to default rate units.
 *
 * @throws Will display an error toast if there is an error converting a constant to the default rate unit.
 */
export function convertForecastConstantUnits(
  constants: ForecastConstant[]
): ForecastConstant[] {
  const constantsWithUnits = [];
  for (const constant of constants) {
    const constantWithUnit = { ...constant };

    // Forecasting widget requires bbl/MMcf for the liquid ratio products,
    // so before returning the values to the widget, we have to convert these
    // products back to bbl/MMcf. This is a workaround until we sort out
    // consistent units across the entire app.
    if (ratioProductListForForecasting.includes(constant.product)) {
      constantWithUnit.value = constant.value * 1000;
      constantWithUnit.unit = "bbl/MMcf";
      constantsWithUnits.push(constantWithUnit);
      continue;
    }

    try {
      constantWithUnit.value = constant.unit
        ? convert(
            constantWithUnit.value,
            constantWithUnit.unit,
            getDefaultRateUnits(productStringToProductTypeEnum(constant.product))
          )
        : constantWithUnit.value * (constant.product === "WOR" ? 1 : 1000);
    } catch (err) {
      toast.error(`Error converting constant ${constant.product} to default rate unit`);
    }
    constantWithUnit.unit = getDefaultRateUnits(
      productStringToProductTypeEnum(constant.product)
    );
    constantsWithUnits.push(constantWithUnit);
  }
  return constantsWithUnits;
}
