import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import _debounce from "lodash/debounce";
import {
  clearFacilityFilter,
  doFilter as doFacilityFilter,
  filterWellsFromMapExtent as filterFacilityFromMapExtent
} from "store/features/filter/facilityFilterSlice";
import {
  clearWellFilter,
  doFilter,
  filterWellsFromMapExtent
} from "store/features/filter/filterSlice";
import { RootState } from "store/rootReducer";

import useBetaFeatures from "../../hooks/useBetaFeatures";
import { EntityKind } from "../../models/entityKind";
import { clearFacilityMap, clearWellMap } from "../../store/features";
import useFilterQuery from "./hooks/useFilterQuery";

export default function useFilterWatcher(
  isPopoutDashboard: boolean,
  entityKind: EntityKind
) {
  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);
  const { hasFeature } = useBetaFeatures();
  function getFilterSelector(entityKind: EntityKind, state: RootState, name: string) {
    const reducer = entityKind === EntityKind.Well ? state.filter : state.facilityFilter;
    return reducer[name];
  }

  const dispatch = useDispatch();

  // state from store
  const excludePolygonsFilter = useSelector(
    (state: RootState) => state.filter.excludePolygonsFilter
  );
  const mapExtent = useSelector((state: RootState) => state.map.mapExtent);
  const polygonFilter = useSelector((state: RootState) =>
    getFilterSelector(entityKind, state, "polygonFilter")
  );
  const propertiesFilter = useSelector((state: RootState) =>
    getFilterSelector(entityKind, state, "propertiesFilter")
  );
  const wellListFilter = useSelector((state: RootState) =>
    getFilterSelector(entityKind, state, "wellListFilter")
  );
  const filtersLoaded = useSelector((state: RootState) =>
    getFilterSelector(entityKind, state, "filtersLoaded")
  );

  const { getFilterFromQueryScreenshot } = useFilterQuery();
  const hasFacilityFeature = hasFeature("Facility") || hasFeature("Facility Volumes");

  const debouncedFilter = useMemo(
    () =>
      _debounce(() => {
        if (
          entityKind === EntityKind.Facility &&
          hasFacilityFeature &&
          activeEntities.includes(EntityKind.Facility)
        ) {
          dispatch(doFacilityFilter(getFilterFromQueryScreenshot));
        }
        if (entityKind === EntityKind.Well && activeEntities.includes(EntityKind.Well)) {
          dispatch(doFilter(getFilterFromQueryScreenshot));
        }
      }, 800),
    [dispatch, activeEntities, entityKind]
  );

  useEffect(() => {
    if (!mapExtent.coordinates.length) return;
    const facilityEntityIsActive = activeEntities.includes(EntityKind.Facility);
    const wellEntityIsActive = activeEntities.includes(EntityKind.Well);

    if (
      hasFacilityFeature &&
      entityKind === EntityKind.Facility &&
      facilityEntityIsActive
    ) {
      dispatch(filterFacilityFromMapExtent(mapExtent));
    }

    if (
      entityKind == EntityKind.Well &&
      activeEntities.includes(EntityKind.Well) &&
      wellEntityIsActive
    ) {
      dispatch(filterWellsFromMapExtent(mapExtent));
    }
    if (!wellEntityIsActive) {
      dispatch(clearWellMap());
      dispatch(clearWellFilter());
    }
    if (!facilityEntityIsActive) {
      dispatch(clearFacilityMap());
      dispatch(clearFacilityFilter());
    }
  }, [dispatch, activeEntities, mapExtent]);

  useEffect(() => {
    // Not exactly sure of the root cause, but the filters do not seem to persist between popout dashboards
    // Likely in StandaloneDashboard.tsx, propertiesFilter need to be passed through the channel so they can be used here.
    // Right now only filter id is passed.

    // This seems acceptable, unless popout dashboards can have a map in the future, or affect the selectors above
    if (!filtersLoaded) {
      return;
    }
    if (!isPopoutDashboard) {
      debouncedFilter();
    }
  }, [
    filtersLoaded,
    debouncedFilter,
    excludePolygonsFilter,
    isPopoutDashboard,
    polygonFilter,
    propertiesFilter,
    wellListFilter
  ]);
}
