import { ALL_CHART_TYPES } from "constants/chart.constants";
import _zip from "lodash/zip";

import { IChartType } from "models/chart";
import { IChartResult } from "models/model";

import { IMPCSeriesLine } from "components/multiphase-chart/models/options.models";
import { IChartPreset } from "components/multiphase-chart/models/shared.models";

import { getCurrentSeriesStyleByProductLabel } from "../productStyles";

export const defaultDataZoom = [
  {
    type: "inside",
    xAxisIndex: [0, 1],
    zoomOnMouseWheel: true,
    filterMode: "none" // Ensures series line goes to the edge of the grid
  },
  {
    type: "inside",
    yAxisIndex: [0, 1],
    zoomOnMouseWheel: true,
    filterMode: "none"
  }
];
export const alignTicks = false;

export const getChartType = (chartTypeId: number): IChartType => {
  if (!chartTypeId) return null;
  const chartType = Object.values(ALL_CHART_TYPES).find(
    (chartType) => chartType.id === chartTypeId
  );

  if (!chartType) {
    throw "invalid label provided";
  }

  return chartType;
};

export const getDefaultLineSeries = (
  option: IChartPreset,
  data: IChartResult
): IMPCSeriesLine[] => {
  const series: IMPCSeriesLine[] = data.series.map((series) => {
    const seriesData = _zip(series.x, series.y);

    const currentStyle = getCurrentSeriesStyleByProductLabel(series.label, option?.style);

    return {
      type: "line",
      name: series.label,
      data: seriesData,
      xAxisIndex: series.xAxisIndex,
      yAxisIndex: series.yAxisIndex,
      showSymbol: false,
      lineStyle: {
        color: currentStyle.color,
        originalColor: currentStyle.color,
        originalWidth: currentStyle.width,
        type: currentStyle.dashed ? "dashed" : "solid",
        width: currentStyle.width
      },
      itemStyle: {
        color: currentStyle.color,
        type: currentStyle.dashed ? "dashed" : "solid",
        width: currentStyle.width
      }
    };
  });

  return series;
};

export const tooltip = {
  trigger: "axis",
  enterable: true,
  confine: true,
  formatter: () => {
    return null;
  },
  axisPointer: {
    type: "cross",
    label: {
      show: true,
      precision: "1"
    }
  },
  showDelay: 500,
  hideDelay: 0
};
