import { ScatterSeriesOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";

export const DEFAULT_SCATTER_SERIES_OPTIONS: Partial<ScatterSeriesOption> = {
  type: "scatter",
  data: [],
  id: "",
  name: "",
  emphasis: {
    focus: "self",
    itemStyle: {
      borderWidth: 1,
      color: "rgba(0,0,0,0.9)",
      opacity: 1
    }
  },
  blur: {
    itemStyle: {
      opacity: 0.5
    }
  }
};

export const generateScatterSeriesOptions = createStateWithDefaults(
  DEFAULT_SCATTER_SERIES_OPTIONS
);
