// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiRefresh } from "@mdi/js";

import { ToolbarButton } from "components/base";

interface ThreeDVResetToggleProps {
  onToggle: () => void;
}

function ThreeDVResetToggle({ onToggle }: ThreeDVResetToggleProps) {
  return (
    <ToolbarButton
      icon={<Icon path={mdiRefresh} size={1.5} />}
      active={false}
      tooltipText={"Reset Camera"}
      overflowLabel={"Reset Camera"}
      onToggle={onToggle}
      styleKey="threeDVToolbar"
    />
  );
}

export default ThreeDVResetToggle;
