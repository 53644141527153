// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiDatabaseOutline } from "@mdi/js";
import { Popover } from "antd";

import { ToolbarButton } from "components/base";

import { useVisState } from "../../context";
import XdaDataOptions from "./XdaDataOptions";

function XdaDataToggle() {
  const [{ xda }] = useVisState();
  const { showData } = xda.settings;
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={<XdaDataOptions />}
      open={visible}
      onOpenChange={(v) => setVisible(v)}
      trigger="click"
      arrowPointAtCenter
      placement="bottomLeft">
      <ToolbarButton
        icon={<Icon path={mdiDatabaseOutline} size={1.75} />}
        isMenuButton={true}
        active={showData}
        tooltipText={"Show Data"}
        overflowLabel={"Toggle Data"}
        styleKey="xdaToolbar"
      />
    </Popover>
  );
}

export default XdaDataToggle;
