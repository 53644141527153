import { SegmentDto } from "arps_wasm";
import { EPSILON, isApproximatelyLessThan, roundTo } from "utils/numbers";

import { ArpSegment, ArpsDesignerKind } from "models/UserArpsModel";

import { CumulativeData } from "components/forecasting/Forecasting";

import { SegmentTemplate } from "../models/SegmentTemplate";
import { arpsWasm } from "./UpdateSegment";
import { AVG_DAYS_IN_YEAR, avgMonthsDiff, getDurationInMonths } from "./dates";
import { getDeclineTitleWithType } from "./declineHelpers";

export function ArpsSegmentToSegmentDto(
  arpsWasm: typeof import("wasm/arps"),
  segment: ArpSegment[]
): SegmentDto[] {
  if (!segment) {
    return [];
  }
  return segment.map((s) => arpsWasm.arpSegmentToSegmentDto(s));
}

export const declineTypes = {
  Secant: "sec",
  Tangent: "tan",
  Nominal: "nom"
};

export function convertDecline(di, b, type) {
  let result: number;
  if (type === "Tangent") {
    result = arpsWasm.nominalToTangent(di) * 100;
  } else if (type === "Secant") {
    result = arpsWasm.nominalToSecant(di, b) * 100;
  } else {
    result = di * 100;
  }
  // Rounding to six decimal places to ensure values like .999999 are rounded up.
  return Math.round(result * 1000000) / 1000000;
}

export function convertDeclineToNominal(di, b, type) {
  if (type === "Tangent") {
    return arpsWasm.tangentToNominal(di / 100.0);
  } else if (type === "Secant") {
    return arpsWasm.secantToNominal(di / 100.0, b);
  } else {
    return di / 100;
  }
}

export function getSpacingBetweenValues(
  start: number,
  end: number,
  numberOfItems: number
) {
  return (start > end ? start - end : end - start) / numberOfItems;
}

export function getRampUpSegmentCumVolume(seg: ArpSegment) {
  return arpsWasm.getSegmentVolume(
    seg.qi,
    seg.di,
    seg.b,
    seg.qf,
    BigInt(Math.floor(new Date(seg.startDate).getTime() / 1000)),
    BigInt(Math.floor(new Date(seg.endDate).getTime() / 1000))
  );
}

export const isRampUpSegment = ({
  segmentTemplateName,
  segments
}: {
  segmentTemplateName: string;
  segments: ArpSegment[];
}): boolean => {
  if (!segmentTemplateName) {
    return segments.length > 2 && segments[0].qi < segments[0].qf && segments[0].di < 0;
  }
  return segments.length > 2 && segmentTemplateName.toLowerCase().includes("ramp-up");
};

export const isConstrainedSegment = ({
  segmentTemplateName,
  segments
}: {
  segmentTemplateName: string;
  segments: ArpSegment[];
}): boolean => {
  return segments.length === 4 && !isRampUpSegment({ segmentTemplateName, segments });
};

export function getTypewellTemplateFields(
  segmentTemplateName: string,
  productSegments: ArpSegment[],
  declineType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultTypeWell?: any
) {
  const segmentLength = productSegments.length;
  let eur = 0;

  const isRampUp = isRampUpSegment({
    segments: productSegments,
    segmentTemplateName
  });

  const hasConstrainedSegment = isConstrainedSegment({
    segments: productSegments,
    segmentTemplateName
  });

  const hasInitialSegment = isRampUp || hasConstrainedSegment;

  productSegments.forEach((s) => {
    const startDate_unixTimestamp_seconds = Math.round(
      new Date(s.startDate).getTime() / 1000
    );
    const endDate_unixTimestamp_seconds = Math.round(
      new Date(s.endDate).getTime() / 1000
    );
    const segVolume =
      arpsWasm.getSegmentVolume(
        s.qi,
        s.di,
        s.b,
        s.qf,
        BigInt(startDate_unixTimestamp_seconds),
        BigInt(endDate_unixTimestamp_seconds)
      ) * 0.001;
    eur += segVolume;
  });

  const seg1 = productSegments[0];
  const seg2 = hasInitialSegment ? productSegments[1] : null;
  const lastSegment = productSegments[segmentLength - 1];
  const secondToLastSegment =
    segmentLength > 2 ? productSegments[segmentLength - 2] : null;

  // tRamp only available for 2 ramp-up and constrains templates
  const tRamp = hasInitialSegment
    ? roundTo(avgMonthsDiff(seg1.startDate, seg1.endDate), 1)
    : undefined;

  const is3SegmentsTemplate = (segmentLength === 3 && !isRampUp) || segmentLength === 4;

  const Bhyp = is3SegmentsTemplate
    ? secondToLastSegment.b
    : segmentLength > 1
    ? hasInitialSegment
      ? seg2.b
      : seg1.b
    : seg1.b;

  const Btrans = is3SegmentsTemplate
    ? hasInitialSegment
      ? seg2.b
      : seg1.b
    : defaultTypeWell?.values.Btrans; // The Previous segment didn't have Btrans, use the default

  const Bf = segmentLength > 1 ? lastSegment?.b ?? seg2.b : defaultTypeWell?.values.Bf; // The Previous segment didn't have Bf, use the default

  // tTrans only available for 3 segment templates
  const tTrans = is3SegmentsTemplate
    ? hasInitialSegment
      ? Math.round(getDurationInMonths(new Date(seg2.startDate), new Date(seg2.endDate)))
      : Math.round(getDurationInMonths(new Date(seg1.startDate), new Date(seg1.endDate)))
    : undefined;

  if (!declineType) {
    return {
      "Start Date": seg1.startDate,
      Q0: hasInitialSegment ? seg1.qi : undefined,
      Bcons: hasConstrainedSegment ? seg1.b : undefined,
      tCons: hasConstrainedSegment ? tRamp : undefined,
      Qi: hasInitialSegment ? seg2.qi : seg1.qi,
      Di: hasInitialSegment ? seg2.di : seg1.di,
      B: hasInitialSegment ? seg2.b : seg1.b,
      Btrans: Btrans,
      Bhyp: Bhyp,
      Bf: Bf,
      tRamp: hasInitialSegment ? tRamp : undefined,
      tTrans: tTrans,
      Df: secondToLastSegment ? secondToLastSegment.df : seg1.df,
      Qf: lastSegment.qf,
      EUR: eur
    };
  }

  return {
    "Start Date": seg1.startDate,
    Q0: seg1.qi,
    Bcons: hasConstrainedSegment ? seg1.b : undefined,
    tCons: hasConstrainedSegment ? tRamp : undefined,
    Qi: hasInitialSegment ? seg2.qi : seg1.qi,
    [getDeclineTitleWithType("Di", declineType)]: roundTo6Decimal(
      convertDecline(
        hasInitialSegment ? seg2.di : seg1.di,
        hasInitialSegment ? seg2.b : seg1.b,
        declineType
      )
    ),
    B: segmentLength === 1 ? seg1.b : undefined, // B only available for 1 segment templates
    Btrans: is3SegmentsTemplate ? Btrans : undefined, // Btrans only available for 3 segment templates
    Bhyp: segmentLength > 1 ? Bhyp : undefined, // Bhyp only available for 2 segment templates and above
    Bf: segmentLength > 1 ? Bf : undefined, // Bf only available for 2 segment templates and above
    tRamp: hasInitialSegment ? tRamp : undefined,
    tTrans: tTrans,
    [getDeclineTitleWithType("Df", declineType)]: roundTo6Decimal(
      secondToLastSegment
        ? convertDecline(secondToLastSegment.df, secondToLastSegment.b, declineType)
        : convertDecline(seg1.df, seg1.b, declineType)
    ),
    Qf: lastSegment.qf.toFixed(2),
    EUR: eur.toFixed(2)
  };
}

/// The `get_di_at_day` function calculates the value of di based on the given parameters.
/// It first converts the day to a fractional year value (t) by dividing it by the number of days in a year (DAYS_IN_YEAR).
/// Then, it performs different calculations depending on the values of b:
///   - If the absolute value of b is less than a small threshold (EPSILON),
///     it calculates di as -(qf / qi).ln() / t.
///   - If the absolute difference between b and 1.0 is less than EPSILON,
///     it calculates di as (qi - qf) / q * t.
///   - Otherwise, it calculates di as (qi.pow(b) / qf.pow(b)).pow(b - 1.0) / (b * t).
/// Note: EPSILON is a small threshold used for floating-point comparisons.
///
/// TODO: expose from arps_wasm, remove this helper function
export function getDiAtDay(qi: number, qf: number, b: number, day: number): number {
  const t = AVG_DAYS_IN_YEAR / day;
  if (Math.abs(b) < EPSILON) {
    return -Math.log(qf / qi) * t;
  } else if (Math.abs(b - 1.0) < EPSILON) {
    return ((qi - qf) / qf) * t;
  } else {
    return ((Math.pow(qf / qi, -b) - 1.0) / b) * t;
  }
}

const roundTo6Decimal = (val) => {
  return Math.round(val * 1000000) / 1000000;
};

export function isSegmentFlowRateValid(seg1: ArpSegment, seg2: ArpSegment) {
  if (isNaN(seg1.qf) || isNaN(seg1.df) || isNaN(seg2.df) || isNaN(seg2.qf)) return false;

  if (
    seg1.di >= 0 &&
    (isApproximatelyLessThan(seg1.qi, seg2.qf, EPSILON) ||
      isApproximatelyLessThan(seg1.qf, seg2.qf, EPSILON))
  ) {
    return false;
  } else if (
    (isApproximatelyLessThan(seg1.di, 0, EPSILON) &&
      isApproximatelyLessThan(seg1.qi, seg2.qf, EPSILON)) ||
    isApproximatelyLessThan(seg1.qf, seg2.qf, EPSILON)
  ) {
    return false;
  }
  return true;
}

export function getPrimarySegmentEndDate(
  segments: ArpSegment[],
  kind: ArpsDesignerKind,
  cumulativeData: CumulativeData,
  segmentTemplate: SegmentTemplate
): Date | undefined {
  if (kind === "Forecasting" && cumulativeData?.primaryProduct) {
    const primarySegments = segments.filter(
      (seg) => seg.product === cumulativeData.primaryProduct
    );
    const lastSegment = primarySegments[primarySegments.length - 1];
    if (lastSegment && lastSegment.endDate) {
      return new Date(Date.parse(lastSegment.endDate));
    }
  }
  // Fallback for TypeWell: assume primary product is the first product added
  const index = segmentTemplate.numberOfSegments - 1;
  if (segments[index]?.endDate) {
    return new Date(Date.parse(segments[index].endDate));
  }
  return undefined;
}
