import { FC, useRef } from "react";

import styled from "styled-components/macro";

import { Portal } from "../../ui";
import { ChartLegend } from "../legend";

type ScreenshotT = {
  containerId: string;
};

const Screenshot: FC<ScreenshotT> = ({ containerId }) => {
  // ref
  const overlayRef = useRef<HTMLDivElement>(null);

  // derived state
  const screenshotContainer = document.getElementById(containerId);

  if (!screenshotContainer) return null;

  const screenshotBounds = {
    width: 1152,
    height: 681
  };

  return (
    <Portal container={screenshotContainer}>
      <Overlay
        ref={overlayRef}
        width={screenshotBounds.width}
        height={screenshotBounds.height}>
        {false && <ChartLegend disableDragging={false} width={250} />}
      </Overlay>
    </Portal>
  );
};

export default Screenshot;

const Overlay = styled.div`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  border: 2px solid rgb(255, 208, 0);
  z-index: 1;
  pointer-events: none;
`;
