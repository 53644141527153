// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import AllInboxIcon from "@material-ui/icons/AllInbox";
import LockIcon from "@material-ui/icons/Lock";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { mdiBullseye } from "@mdi/js";
import { Button, Popover, Switch, Tooltip, Typography } from "antd";
import classnames from "classnames";
import { AXIS_TYPE } from "constants/chart.constants";
import styled from "styled-components";
import { noop } from "utils";

import { mBinSize } from "models/binSize";
import { EntityKind } from "models/entityKind";

import BinSettings from "components/filter/BinSettings";
import GroupBy from "components/groupBy";

import { visibilityToggleStyles } from "../../shared/SharedStyles";
import PressureChartOptions from "../toggles/PressureChartOptions";
import StackedBarCalculation from "../toggles/stacked-bar-options/StackedBarCalculation";
import AxisInput from "./AxisInput";
import "./ChartAxis.scss";

const { Text } = Typography;

function ChartAxis({
  className = "",
  axisInput = null,
  isLogScale = false,
  savedAxis = [],
  setAxisInput = null,
  type = null,
  onSaveAxis = null
}) {
  const xAxis = type === AXIS_TYPE.x;
  const yAxis = type === AXIS_TYPE.y;

  const [axisBinOpen] = useState(false);
  const [bin] = useState<mBinSize>({
    LessThan: null,
    GreaterThan: null,
    MinSize: null,
    BinSize: null,
    BinType: "BinSize"
  });

  const inputFormat = "number";

  const updateLog = noop;

  const swapAxesParamFields = noop;

  const updateParamField = noop;

  const updateAxisBin = noop;

  const setAxisBin = noop;

  const resetAxisBin = noop;

  const handleLockClick = noop;

  const axisInputClassnames = classnames({ xAxis, yAxis });
  const lockClassnames = (index: number, minMax) =>
    classnames("axis-lock", "flex", {
      isLocked: savedAxis[index],
      xAxis,
      yAxis,
      [minMax]: true,
      stackDown: false // HARDCODED
    });
  const logToggleClassnames = classnames({
    xAxis,
    yAxis,
    visible: false // HARDCODED
  });
  const fieldContainerClassName = classnames(axisInputClassnames, {
    visible: false, // HARDCODED,
    stackUp: false // HARDCODED
  });
  const wrapperClassnames = classnames(className, {
    xAxis,
    yAxis,
    stackDown: false // HARDCODED
  });
  return (
    <Wrapper className={wrapperClassnames}>
      <LogToggle className={logToggleClassnames}>
        <span>Log</span>
        <Switch size="small" checked={isLogScale} onChange={() => updateLog()} />
      </LogToggle>

      {false && (
        <>
          <LockIconWrapper
            className={lockClassnames(0, "min")}
            onClick={() => handleLockClick()}>
            <LockIcon />
          </LockIconWrapper>

          <LockIconWrapper
            className={lockClassnames(1, "max")}
            onClick={() => handleLockClick()}>
            <LockIcon />
          </LockIconWrapper>

          {axisInput && (
            <AxisInput
              className={axisInputClassnames}
              axisInput={axisInput}
              setAxisInput={setAxisInput}
              onSave={onSaveAxis}
              inputFormat={inputFormat}
            />
          )}
        </>
      )}

      {false && (
        <ProductSelectContainer>
          <PressureChartOptions />
        </ProductSelectContainer>
      )}

      <FieldContainer className={fieldContainerClassName}>
        {false && <StackedBarCalculation />}

        <Tooltip title="Swap x and y axis field">
          <AxisBinButton
            size="small"
            icon={<SwapVertIcon />}
            onClick={swapAxesParamFields}
            disabled={true} // HARDCODED
          />
        </Tooltip>

        <GroupBy
          value={null}
          onChange={() => updateParamField()}
          placement="left"
          entityKind={EntityKind.Well} // HARDCODED
          isDisabled={false}
          axisType={type}>
          <StyledButton size="small"></StyledButton>
        </GroupBy>
        {xAxis && (
          <Tooltip title="Sync x-axis field to focus field">
            <SyncButton
              size={"small"}
              onClick={noop}
              icon={<Icon path={mdiBullseye} size={1.0} />}
            />
          </Tooltip>
        )}
        {false && (
          <Popover
            trigger="click"
            placement="topRight"
            overlayClassName="chart-bin-settings"
            open={axisBinOpen}
            onOpenChange={noop}
            content={
              <AxisBinContainer>
                <BinsSettingsHeader>
                  <StyledText strong>Bin Settings</StyledText>
                </BinsSettingsHeader>
                <BinSettings
                  bin={bin}
                  entityKind={EntityKind.Well} // HARDCODED
                  canEditBin={false} // HARDCODED
                  onBinChange={() => setAxisBin()}
                  onBinSettingChange={updateAxisBin}
                  onReset={() => resetAxisBin()}
                  isBinningFromAxis={true}
                />
              </AxisBinContainer>
            }>
            <AxisBinButton size="small" icon={<AllInboxIcon />} />
          </Popover>
        )}
      </FieldContainer>
    </Wrapper>
  );
}

export default ChartAxis;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;

  &.xAxis {
    right: 0;
    height: 24px;
    z-index: 1;
  }

  &.yAxis {
    top: 0;
    z-index: 1;
  }

  &.yAxis.stackDown {
    z-index: auto;
  }
`;

const LogToggle = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--color-text);
  font-weight: 500;
  font-size: 12px;
  z-index: 1;

  &.xAxis {
    right: 44px;
    bottom: 4px;
  }

  &.yAxis {
    bottom: -12px;
    transform-origin: left top;
    transform: translateX(4px) rotate(-90deg);
  }
`;

const FieldContainer = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  display: flex;
  align-items: center;

  &.stackUp {
    z-index: 1;
  }

  &.xAxis {
    bottom: 0;
    left: 50%;
    transform: translateX(calc(24px - 50%));
  }

  &.yAxis {
    right: 0;
    top: 50%;
    transform: translate(calc(50% + 14px), -50%) rotate(-90deg);
    transform-origin: center;
  }
`;

const LockIconWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100vmax;
  isolation: isolate;
  z-index: 3;

  &.stackDown {
    z-index: 0;
  }
`;

const ProductSelectContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  &.stackUp {
    z-index: 1;
  }

  bottom: 0;
  left: 50%;
  transform: translateX(calc(24px - 50%));
`;

const StyledButton = styled(Button)`
  max-width: 180px;
  width: 180px;

  & > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const AxisBinContainer = styled.div`
  max-width: 36rem;
  background-color: var(--color-text-06);
  display: grid;
  gap: 1px;
`;

const AxisBinButton = styled(Button)`
  color: var(--color-text);
  display: grid;
  justify-content: center;
  align-items: center;
`;
const StyledText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.25px;
`;

const BinsSettingsHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 var(--space-4);
`;

const SyncButton = styled(AxisBinButton)`
  background-color: ${(props: { on?: boolean }) =>
    props.on ? "var(--color-primary)" : "white"};
  color: ${(props: { on?: boolean }) => (props.on ? "white" : "#a2aaad")};

  &:hover {
    background-color: ${(props: { on?: boolean }) =>
      props.on ? "var(--color-primary)" : "white"};
    color: ${(props: { on?: boolean }) => (props.on ? "white" : "#a2aaad")};
  }

  &:focus {
    background-color: ${(props: { on?: boolean }) =>
      props.on ? "var(--color-primary)" : "white"};
    color: ${(props: { on?: boolean }) => (props.on ? "white" : "#a2aaad")};
  }

  &:active {
    background-color: ${(props: { on?: boolean }) =>
      props.on ? "var(--color-primary)" : "white"};
    color: ${(props: { on?: boolean }) => (props.on ? "white" : "#a2aaad")};
  }
`;
