import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { selectChartSpaghettiFeature, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";

import { SpaghettiMode } from "components/icons";

import ToolbarButton from "../../base/ToolbarButton";

const SpaghettiToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const spaghettiStates = useSelector((state: RootState) =>
    selectChartSpaghettiFeature(state, id)
  );
  const isEnabled = spaghettiStates.enabled;
  const isActive = spaghettiStates.active;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.spaghetti.active`,
        value: active
      })
    );
  };
  return (
    <ToolbarButton
      active={isActive}
      icon={<SpaghettiMode />}
      overflowLabel="Spaghetti"
      tooltipText="Toggle Spaghetti Plot"
      onToggle={toggle}
      disabled={!isEnabled}
    />
  );
};

export default memo(SpaghettiToggle);
