import { RateDto } from "arps_wasm";
import { ChartTypeLabels } from "constants/chart.constants";

import { scaleDataBasedOnYAxisUnits } from "./scaleDataBasedOnYAxisUnits";

const TYPE_WELL_MAX_NUMBER_OF_YEARS_CAP = 100;

export function getTypeWellSeries(
  chartType: ChartTypeLabels,
  rateCum: RateDto[],
  yAxisTitle: string
) {
  if (chartType == "Rate Cum") {
    return rateCum.map((rc) => {
      return [rc.cum * 0.001, rc.rate, undefined];
    });
  }

  if (chartType == "Rate Time") {
    const series = [];
    let idx = 1;

    rateCum.forEach((rc) => {
      series.push([idx, rc.rate, undefined]);
      idx++;
    });

    return series;
  }

  if (chartType == "Cum Time") {
    const scale = scaleDataBasedOnYAxisUnits(yAxisTitle);
    return rateCum.map((rc, i) => {
      return [i + 1, rc.cum * scale, undefined];
    });
  }
  if (chartType == "Rate Date") {
    const scale = scaleDataBasedOnYAxisUnits(yAxisTitle);
    const series = [];

    const date = new Date();
    const maxYear = date.getFullYear() + TYPE_WELL_MAX_NUMBER_OF_YEARS_CAP;

    rateCum
      .filter((rc, idx) => {
        return (
          idx > 0 &&
          (rc.year < maxYear || (rc.year === maxYear && rc.month === 1 && rc.day === 1))
        );
      })
      .forEach((rc) => {
        series.push([
          rc.year +
            "-" +
            rc.month.toString().padStart(2, "0") +
            "-" +
            rc.day.toString().padStart(2, "0") +
            "T00:00:00",
          rc.rate * scale,
          undefined
        ]);
      });

    return series;
  }
}
