export enum ChartEntity {
  Chart = "Chart",
  ChartsFeature = "ChartsFeature",
  CutOffChartFeature = "CutOffChartFeature",
  ShutInMonthsChartFeature = "ShutInMonthsChartFeature",
  MovingAverageDaysChartFeature = "MovingAverageDaysChartFeature",
  ChartDataTableFeature = "ChartDataTableFeature",
  SourceFeature = "SourceFeature",
  TimeStepFeature = "TimeStepFeature"
}

export enum MapEntity {
  Map = "Map"
}

export enum ThreeDViewEntity {
  ThreeDView = "3dView"
}

export const EntityType = {
  ...ChartEntity,
  ...MapEntity,
  ...ThreeDViewEntity
};
