import { memo } from "react";

import TrendingDownIcon from "@material-ui/icons/TrendingDown";

import { ToolbarButton } from "components/base";
import { useChartSettings } from "components/multiphase-chart/context";
import { changeSettings } from "components/multiphase-chart/context/reducer/chartSettingsReducer";

const ForecastToggle = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const toggle = (value) => {
    const next = { ...chartSettings.settings, forecast: value };
    changeSettings(chartSettingsDispatch, next);
  };

  return (
    <ToolbarButton
      testId="multiphasechart-toolbar-forecast-toggle"
      icon={<TrendingDownIcon fontSize="large" />}
      overflowLabel="Forecast"
      tooltipText="Toggle Forecast"
      active={chartSettings.settings?.forecast}
      onToggle={toggle}
    />
  );
};

export default memo(ForecastToggle);
