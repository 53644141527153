type dlsValues = {
  township: number;
  range: number;
  meridian: number;
};
/**
 * Validates DLS values
 * @param dlsValues - DLS values
 * @returns invalid fields
 */
export function validateDLS(dlsValues: dlsValues) {
  const invalidFields = [];
  if (dlsValues.township < 1 || dlsValues.township > 126) {
    invalidFields.push("Township");
  }
  if (dlsValues.range < 1 || dlsValues.range > 34) {
    invalidFields.push("Range");
  }
  if (dlsValues.meridian < 1 || dlsValues.meridian > 6) {
    invalidFields.push("Meridian");
  }
  return invalidFields;
}
