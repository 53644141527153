import { ALL_PRODUCT_TYPES } from "constants/chart.constants";
import { getProductType } from "utils";

/**
 * Conditionally adds a percentage suffix to the value
 *
 * @param {string} type Type of chart. Example "Rate Cum", "Rate Time".
 * @param {string} product Chart product. Example: "IWB", "CWB".
 * @param {string} value Y axis value.
 * @returns {string | number} Transformed y axis value.
 */
export const getLabelWithPercentageSuffix = (
  type: string,
  product: string,
  value: number | string
): number | string => {
  const productType = getProductType(product);

  return type.includes("Rate") &&
    (productType === ALL_PRODUCT_TYPES.IWB ||
      productType === ALL_PRODUCT_TYPES.CWB ||
      productType === ALL_PRODUCT_TYPES.OilCut ||
      productType === ALL_PRODUCT_TYPES.WaterCut)
    ? `${value}%`
    : formatWithCommas(value);
};

const formatWithCommas = (value: number | string): string => {
  return value.toLocaleString("en-US");
};
