import { memo } from "react";

import { UserArps } from "components/icons";

import ToolbarButton from "../../base/ToolbarButton";
import { useChartDispatch, useChartState } from "../context";
import { getTypewellToggleDisabledStatus } from "../utils/toggles";

const TypewellsToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();
  const isDisabled = getTypewellToggleDisabledStatus(settings.chartType);

  const toggle = () => (value) => {
    const next = { ...settings, typewells: value };
    dispatch({ type: "settings", payload: next });
  };
  return (
    <ToolbarButton
      active={settings.typewells}
      icon={<UserArps />}
      overflowLabel="Typewells"
      tooltipText="Type Wells"
      onToggle={toggle()}
      disabled={isDisabled}
    />
  );
};

export default memo(TypewellsToggle);
