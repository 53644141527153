import { GridWidget } from "models/dashboard";
import { DashboardWidget } from "models/workspace";

export function applyNormalizationAndProductionSettings(
  gridLayoutItem: GridWidget,
  item: DashboardWidget,
  isHardRefresh: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previousGlobalSettings: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentGlobalSettings: any
) {
  if (isHardRefresh && gridLayoutItem.component === "chart") {
    const savedSettings = JSON.parse(item.settings);
    gridLayoutItem.componentState.useNormalizeBy = savedSettings.useNormalizeBy;
    gridLayoutItem.componentState.normalizeBy = savedSettings.normalizeBy;
    gridLayoutItem.componentState.timeStep = savedSettings.timeStep;
    gridLayoutItem.componentState.source = savedSettings.source;
  } else if (gridLayoutItem.component === "chart") {
    if (previousGlobalSettings.useNormalizeBy !== currentGlobalSettings.useNormalizeBy) {
      gridLayoutItem.componentState.useNormalizeBy = currentGlobalSettings.useNormalizeBy;
    }
    if (previousGlobalSettings.normalizeBy !== currentGlobalSettings.normalizeBy) {
      gridLayoutItem.componentState.normalizeBy = currentGlobalSettings.normalizeBy;
    }
    if (previousGlobalSettings.timeStep !== currentGlobalSettings.timeStep) {
      gridLayoutItem.componentState.timeStep = currentGlobalSettings.timeStep;
    }
    if (previousGlobalSettings.source !== currentGlobalSettings.source) {
      gridLayoutItem.componentState.source = currentGlobalSettings.source;
    }
  }
}
