import { memo } from "react";

import { noop } from "utils";

import { Histogram } from "components/icons";

import ToolbarButton from "../../base/ToolbarButton";

const HistogramToggle = () => {
  const toggle = noop;

  return (
    <ToolbarButton
      icon={<Histogram />}
      active={false}
      overflowLabel="Histogram"
      tooltipText="Toggle Histogram"
      onToggle={toggle()}
    />
  );
};

export default memo(HistogramToggle);
