// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiSphere } from "@mdi/js";
import { Popover } from "antd";

import { ThreeDViewerOptionT } from "models";

import { ToolbarButton } from "components/base";

import ThreeDVGeoValueOptions from "./ThreeDVGeoValueOptions";

function ThreeDVWellVolumeToggle(options: ThreeDViewerOptionT) {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={<ThreeDVGeoValueOptions {...options} />}
      trigger="click"
      placement="bottomRight"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        icon={<Icon path={mdiSphere} size={1.5} />}
        isMenuButton={true}
        active={options.showDrainage}
        tooltipText={"Show Well Volume"}
        overflowLabel={"Volume"}
        styleKey="threeDVToolbar"
      />
    </Popover>
  );
}

export default ThreeDVWellVolumeToggle;
