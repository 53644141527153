import {
  addDays,
  addMonths,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  getDaysInMonth,
  isValid,
  parseISO,
  startOfDay
} from "date-fns";
import dayjs, { extend } from "dayjs";
import utc from "dayjs/plugin/utc";
import { roundTo } from "utils/numbers";

extend(utc);
export const AVG_DAYS_IN_YEAR = 365.242374;
export const AVG_DAYS_IN_MONTH = 30.436;
export const SECONDS_IN_DAY = 60 * 60 * 24;

interface RateDate {
  year: number;
  month: number;
  day: number;
}

export function getStartOfNextMonth(): Date {
  const currentDate = new Date();
  const day = currentDate.getDay();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  if (day > 1) {
    month += 1;
  }
  if (month > 11) {
    month = 0;
    year += 1;
  }
  return new Date(year, month, 1);
}

export function getDurationInMonths(date1?: Date, date2?: Date): number {
  if (!date1 || !date2) {
    return 12;
  }

  const monthsDifference = differenceInMonths(date2, date1);
  const startOfNextMonth = addMonths(date1, monthsDifference);
  const remainingDays = differenceInDays(date2, startOfNextMonth);
  const endMonthDays = getDaysInMonth(date2);

  return monthsDifference + remainingDays / endMonthDays;
}

export function numberOfMonthsAfterDate(date1?: Date, date2?: Date): number {
  if (!date1 || !date2) {
    return 0;
  }
  return Math.max(0, differenceInMonths(date2, date1));
}

export function getDurationInYears(startDate: string, endDate: string): number {
  const start = startOfDay(parseISO(startDate));
  const end = startOfDay(parseISO(endDate));
  if (!isValid(start) || !isValid(end)) {
    throw "Invalid date format";
  }
  const yearsDifference = differenceInYears(end, start);
  const startOfNextYear = addMonths(start, yearsDifference * 12);
  const remainingDays = differenceInDays(end, startOfNextYear);

  return yearsDifference + remainingDays / AVG_DAYS_IN_YEAR;
}

export function getDurationInDays(startDate: string, endDate: string) {
  const start = startOfDay(parseISO(startDate));
  const end = startOfDay(parseISO(endDate));
  if (!isValid(start) || !isValid(end)) {
    throw "Invalid date format";
  }
  return differenceInDays(end, start);
}

export function compareRateDate(a: RateDate, b: RateDate): number {
  return new Date(a.year, a.month + 1, a.day) >= new Date(b.year, b.month + 1, b.day)
    ? 1
    : -1;
}

export function addMonthsToDate(date: Date, monthsToAdd: number): Date {
  const wholeMonthsToAdd = Math.floor(monthsToAdd);
  const partialMonthToAdd = monthsToAdd - wholeMonthsToAdd;

  let newDate = addMonths(date, wholeMonthsToAdd);

  // Add fractional month (approximated to days based on the specific month length).
  if (partialMonthToAdd > 0) {
    const nextMonthDate = addMonths(newDate, 1);
    const daysInNextMonth = differenceInDays(nextMonthDate, newDate);
    const additionalDays = Math.round(partialMonthToAdd * daysInNextMonth);
    newDate = addDays(newDate, additionalDays);
  }

  return newDate;
}

export function addAvgMonthsToDate(date: Date, monthsToAdd: number): dayjs.Dayjs {
  const seconds = monthsToAdd * AVG_DAYS_IN_MONTH * SECONDS_IN_DAY;
  return dayjs.utc(date).add(seconds, "seconds");
}

/**
 * Computes the absolute difference in days between two dates.
 *
 * @param d1 - The first date string (ISO format)
 * @param d2 - The second date string (ISO format)
 * @returns The absolute difference in days as a decimal value
 */
export const daysDiff = (d1: string, d2: string): number => {
  if (!d1 || !d2 || typeof d1 !== "string" || typeof d2 !== "string") {
    throw new Error("Both dates must be provided as non-empty strings");
  }

  if (!dayjs(d1).isValid() || !dayjs(d2).isValid()) {
    throw new Error("Invalid date format. Dates should be in ISO format");
  }

  return Math.abs(dayjs.utc(d1).diff(dayjs.utc(d2), "days", true));
};
/**
 * Computes the difference in avg months between two dates.
 *
 * @param d1 - The first date string (ISO format)
 * @param d2 - The second date string (ISO format)
 * @returns The absolute difference in months as a decimal value
 */
export const avgMonthsDiff = (d1: string, d2: string): number => {
  if (!d1 || !d2 || typeof d1 !== "string" || typeof d2 !== "string") {
    throw new Error("Both dates must be provided as non-empty strings");
  }

  if (!dayjs(d1).isValid() || !dayjs(d2).isValid()) {
    throw new Error("Invalid date format. Dates should be in ISO format");
  }
  const date1 = dayjs.utc(d1);
  const date2 = dayjs.utc(d2);
  const days = Math.abs(date1.diff(date2, "days", true));
  return roundTo(days / AVG_DAYS_IN_MONTH, 2);
};

export const addAvgFractionalMonths = (utcString: string, months: number): string => {
  return addAvgMonthsToDate(new Date(utcString), months).toISOString();
};

export const getFirstDayOfNextMonthUTC = (date: Date): Date => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const nextMonth = month + 1;

  const isNextMonthJanuary = nextMonth > 11;

  if (isNextMonthJanuary) {
    return new Date(Date.UTC(year + 1, 0, 1)); // January 1 of the next year.
  } else {
    return new Date(Date.UTC(year, nextMonth, 1)); // First day of the next month.
  }
};

export const getDateStringAtMidnightUtc = (date: string): string => {
  return date.split("T")[0] + "T00:00:00.000Z";
};
