import { EvaSource, TimeStep } from "constants/charts.enums";
import { createStateWithDefaults } from "entities/utils";
import { ChartFeature } from "types/charts";

export const DEFAULT_FEATURE_ENTITY: Partial<ChartFeature> = {
  properties: {},
  show: false,
  active: false,
  enabled: false
};

export const generateChartsFeatureEntity =
  createStateWithDefaults(DEFAULT_FEATURE_ENTITY);

export const CHART_DATA_TABLE_LABEL = "Show Raw Data";
export const DEFAULT_DATA_TABLE_FEATURE_ENTITY: Partial<ChartFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    tooltipText: CHART_DATA_TABLE_LABEL,
    overflowLabel: CHART_DATA_TABLE_LABEL
  }
};

export const generateDataTableFeatureEntity = createStateWithDefaults(
  DEFAULT_DATA_TABLE_FEATURE_ENTITY
);

export const DEFAULT_SOURCE_FEATURE_ENTITY: Partial<ChartFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: EvaSource.Public
  }
};

export const generateSourceFeatureEntity = createStateWithDefaults(
  DEFAULT_SOURCE_FEATURE_ENTITY
);

export const DEFAULT_TIME_STEP_FEATURE_ENTITY: Partial<ChartFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: TimeStep.Month
  }
};

export const generateTimeStepFeatureEntity = createStateWithDefaults(
  DEFAULT_TIME_STEP_FEATURE_ENTITY
);

export const DEFAULT_CUT_OFF_FEATURE_ENTITY: Partial<ChartFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: 50
  }
};

export const generateCutOffFeatureEntity = createStateWithDefaults(
  DEFAULT_CUT_OFF_FEATURE_ENTITY
);

export const DEFAULT_SHUT_IN_MONTHS_FEATURE_ENTITY: Partial<ChartFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: 3
  }
};

export const generateShutInMonthsFeatureEntity = createStateWithDefaults(
  DEFAULT_SHUT_IN_MONTHS_FEATURE_ENTITY
);
export const DEFAULT_MOVING_AVERAGE_DAYS_FEATURE_ENTITY: Partial<ChartFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: 5
  }
};

export const generateMovingAverageDaysFeatureEntity = createStateWithDefaults(
  DEFAULT_MOVING_AVERAGE_DAYS_FEATURE_ENTITY
);
