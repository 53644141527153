import { memo } from "react";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import { ToolbarButton } from "components/base";
import { useChartSettings } from "components/multiphase-chart/context";
import { toggleIsFullscreen } from "components/multiphase-chart/context/reducer/chartSettingsReducer";

const FullscreenToggle = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const handleToggle = () => (value) => {
    toggleIsFullscreen(chartSettingsDispatch, value);
  };

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <ToolbarButton
      key={"multiphasechart-toolbar-fullscreen-toggle" + chartSettings.isFullscreen}
      testId={"multiphasechart-toolbar-fullscreen-toggle"}
      icon={<FullscreenIcon fontSize="large" />}
      activeIcon={<FullscreenExitIcon fontSize="large" />}
      active={chartSettings.isFullscreen}
      overflowLabel="Fullscreen"
      tooltipText="Toggle Fullscreen"
      onToggle={handleToggle()}
    />
  );
};

export default memo(FullscreenToggle);
