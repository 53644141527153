import styled from "styled-components";

import { ForecastingChangeRecords } from "./ForecastingChangeRecords";
import { ForecastWellStatus } from "./models/forecastWell";

function ForecastingEditorChangeRecords({
  forecastId,
  status
}: {
  forecastId: string;
  status: ForecastWellStatus;
}) {
  return (
    <ForecastingEditorChangeRecordsWrapper>
      <ForecastingChangeRecords forecastId={forecastId} status={status} />
    </ForecastingEditorChangeRecordsWrapper>
  );
}

export default ForecastingEditorChangeRecords;

const ForecastingEditorChangeRecordsWrapper = styled.div`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0px 0px 20px 20px;
  background: white;
`;
