// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiCodeTags, mdiCounter, mdiGreaterThan, mdiLessThan, mdiSigma } from "@mdi/js";
import { Button, Dropdown, MenuProps } from "antd";
import { noop } from "rxjs";
import styled from "styled-components";

export const enum CalculationMethod {
  Count,
  Sum,
  Average,
  Min,
  Max
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  width: 30px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface StackedBarCalculationModel {
  onSelect: (item: CalculationMethod) => void;
}

const items: MenuProps["items"] = [
  {
    key: CalculationMethod.Count,
    label: "Well Count",
    icon: <Icon path={mdiCounter} size={1} />
  },
  {
    key: CalculationMethod.Sum,
    label: "Sum",
    icon: <Icon path={mdiSigma} size={1} />
  },
  {
    key: CalculationMethod.Average,
    label: "Average",
    icon: <Icon path={mdiCodeTags} size={1} />
  },
  {
    key: CalculationMethod.Min,
    label: "Min",
    icon: <Icon path={mdiLessThan} size={1} />
  },
  {
    key: CalculationMethod.Max,
    label: "Max",
    icon: <Icon path={mdiGreaterThan} size={1} />
  }
];

/**
 * StackedBarCalculation is a custom dropdown component for selecting calculation methods.
 * It displays a button with the currently selected calculation method icon,
 * and a dropdown menu with available calculation methods.
 */
export default function StackedBarCalculation() {
  const handleMenuItemClick: MenuProps["onClick"] = noop;

  return (
    <Wrapper>
      <Dropdown menu={{ items, onClick: handleMenuItemClick }} trigger={["click"]}>
        <StyledButton
          onClick={(e) => e.preventDefault()}
          data-testid={"stackedBar-button"}></StyledButton>
      </Dropdown>
    </Wrapper>
  );
}
