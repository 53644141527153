import { DEFAULT_TITLE_FONT_SIZE } from "constants/chart.constants";
import { TitleOption } from "echarts/types/dist/shared";
import { createStateWithDefaults } from "entities/utils";

export const DEFAULT_TITLE_OPTIONS: Partial<TitleOption> = {
  text: "",
  itemGap: 0,
  subtext: "",
  textStyle: {
    fontSize: DEFAULT_TITLE_FONT_SIZE,
    fontWeight: "bold",
    color: "#041C2C"
  },
  padding: [14, 0, 10, 16]
};

export const generateTitleOptions = createStateWithDefaults(DEFAULT_TITLE_OPTIONS);
