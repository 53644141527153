import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppProviders from "AppProviders";
import AppRouter from "AppRouter";
import axios from "axios";
import { cookies } from "constants/settings.constants";
import {
  logout,
  setUser,
  updateRefresingTokenLock,
  updateTokenFromRefreshToken
} from "store/features";
import { RootState } from "store/rootReducer";
import { checkTokenExpire, decrypt } from "utils";
import { broadcast, broadcastTypes } from "utils/broadcast";

import useOnWindowFocus from "hooks/useOnWindowFocus";

import { refreshToken, signoutUser } from "api/auth";

import { IUser } from "models/User";

import "./App.css";

let tokenInterval: NodeJS.Timeout = null;
function App(): JSX.Element {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.auth.user);
  const isTokenRefreshing = useSelector((state: RootState) => state.auth.tokenRefreshing);
  const currentJwtToken = useSelector((state: RootState) => state.auth.jwtToken);

  const history = useHistory();
  //tells the loading screen to redirect to either /app or /login
  const [doneLoading, setDoneLoading] = useState(false);

  const updateFromRefreshResponse = async (newUser) => {
    if (newUser) {
      dispatch(setUser(newUser));
    } else {
      //sign out
      await signoutUser(user?.username);
      dispatch(logout());

      window.location.href = "/login";
    }
  };

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401 && !isTokenRefreshing) {
          //try to refresh token
          if (user) {
            //unauthroized so we try to get a new token from refresh token
            const newUser = await refreshToken(user?.secret);
            updateFromRefreshResponse(newUser);
          } else if (history) {
            history.push("/login");
          }
        }
        return Promise.reject(error);
      }
    );
  }, [history, user, isTokenRefreshing]);

  useEffect(() => {
    const handleBroadcastMessage = async (event) => {
      switch (event.data.type) {
        case broadcastTypes.TOKEN_UPDATED:
          if (event.data.encrypted && event.data.iv && user?.secret) {
            // only decrypt if the secret is available
            const deData = await decrypt(
              { encrypted: event.data.encrypted, iv: event.data.iv },
              user.secret
            );
            const newUser: IUser = JSON.parse(deData);
            updateFromRefreshResponse(newUser);
          }
          break;
        case broadcastTypes.TOKEN_REFRESHING:
          {
            const lock = event.data.tokenRefreshing;
            dispatch(updateRefresingTokenLock(lock));
          }
          break;
      }
    };

    // Listen for token updates from other tabs
    broadcast.addEventListener("message", handleBroadcastMessage);

    return () => {
      // Remove the event listener on cleanup
      broadcast.removeEventListener("message", handleBroadcastMessage);
    };
  }, [user]);

  // Get Token on load
  useEffect(() => {
    const refreshTokenOnExpiry = async () => {
      const lastLoggedIn = localStorage.getItem(cookies.LAST_LOGGED_IN);

      if (!lastLoggedIn || window.location.href.includes("/login") || isTokenRefreshing) {
        setDoneLoading(true);
        return;
      }

      const newUser = await refreshToken(user?.secret);
      updateFromRefreshResponse(newUser);

      setDoneLoading(true);
    };

    refreshTokenOnExpiry();
  }, []);

  async function updateTokenIfAboutToExpire(jwtToken) {
    if (!currentJwtToken) {
      return;
    }

    // Check if token is about to expire
    if (checkTokenExpire(jwtToken)) {
      dispatch(updateTokenFromRefreshToken(user.username));
    }
  }

  // Check for token expiry on window focus
  useOnWindowFocus(() => {
    if (currentJwtToken) {
      updateTokenIfAboutToExpire(currentJwtToken);
    }
  });

  useEffect(() => {
    if (tokenInterval) {
      clearInterval(tokenInterval);
      tokenInterval = null;
    }

    //run every minute
    tokenInterval = setInterval(async () => {
      updateTokenIfAboutToExpire(currentJwtToken);
    }, 60000);

    return () => {
      if (tokenInterval) {
        clearInterval(tokenInterval);
        tokenInterval = null;
      }
    };
  }, [currentJwtToken, dispatch]);

  return (
    <AppProviders>
      <div className="App">
        <AppRouter doneLoading={doneLoading} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          closeButton={false}
          limit={1}
          enableMultiContainer
          pauseOnHover
        />
      </div>
    </AppProviders>
  );
}

export default App;
