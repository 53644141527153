import { ArpSegment } from "models/UserArpsModel";

import useDefaultTypeWell from "../hooks/useDefaultTypeWell";
import { arpsWasm, updateRampUpSegmentValues } from "./UpdateSegment";
import { getDiAtDay } from "./arpsUtils";
import {
  addAvgFractionalMonths,
  addAvgMonthsToDate,
  getDateStringAtMidnightUtc,
  getDurationInDays
} from "./dates";

export interface InitialSegment {
  (product: string, startDate: string): ArpSegment[];
}

const useInitialSegments = () => {
  const { getDefaultTypeWell } = useDefaultTypeWell();
  const TODAY = new Date();
  const MAX_SAFE_INTEGER = 9007199254740991;
  const MIN_SAFE_INTEGER = -9007199254740991;

  const initialSegment1 = (product, startDate) => {
    const defaultTypeWell = getDefaultTypeWell(product);

    startDate = !startDate
      ? getDateStringAtMidnightUtc(
          new Date(TODAY.getUTCFullYear(), TODAY.getUTCMonth(), 1).toISOString()
        )
      : startDate;
    const endDate = new Date(TODAY.getUTCFullYear(), TODAY.getUTCMonth(), 1);

    return {
      product,
      qi: defaultTypeWell.values.Qi,
      b: defaultTypeWell.values.Bhyp,
      qf: defaultTypeWell.values.Qf,
      df: defaultTypeWell.values.Df,
      di: defaultTypeWell.values.Di,
      startDate: startDate,
      segmentIndex: 1,
      trendCum: 0,
      endDate: endDate.toISOString()
    };
  };

  const initialRampupSegment = (product, startDate) => {
    const defaultTypeWell = getDefaultTypeWell(product);
    startDate = !startDate
      ? new Date(TODAY.getUTCFullYear(), TODAY.getUTCMonth(), 1).toISOString()
      : startDate;
    const endDate = addAvgMonthsToDate(
      startDate,
      defaultTypeWell.values.tramp
    ).toISOString();

    const segment = {
      product,
      qi: defaultTypeWell.values.Q0,
      b: -1.0,
      qf: defaultTypeWell.values.Qi,
      df: -1, //TODO: calculate the correct df based on Q0 and Qi
      di: -1, //TODO: calculate the correct di based on Q0 and Qi
      startDate: startDate,
      segmentIndex: 1,
      trendCum: 0,
      endDate: endDate
    } as ArpSegment;
    updateRampUpSegmentValues(segment, true);
    return segment;
  };

  const initialConstrainSegment = (product, startDate) => {
    const defaultTypeWell = getDefaultTypeWell(product);
    startDate = !startDate
      ? new Date(TODAY.getUTCFullYear(), TODAY.getUTCMonth(), 1).toISOString()
      : startDate;
    const endDate = addAvgFractionalMonths(startDate, defaultTypeWell.values.tramp);

    const qi = defaultTypeWell.values.Qi * 1.1;

    const rampUpDurationInDays = getDurationInDays(startDate, endDate);

    const calculatedDi = getDiAtDay(
      qi,
      defaultTypeWell.values.Qi,
      defaultTypeWell.values.Btrans,
      rampUpDurationInDays
    );

    return {
      product,
      qi: qi,
      b: defaultTypeWell.values.Btrans,
      qf: defaultTypeWell.values.Qi,
      di: calculatedDi ?? defaultTypeWell.values.Di,
      df: defaultTypeWell.values.Di,
      startDate: startDate,
      segmentIndex: 1,
      trendCum: 0,
      endDate: endDate
    };
  };

  const getRampUpEndDate = (rampUpStartDate: string, tramp: number): string => {
    const startDateObj = new Date(Date.parse(rampUpStartDate));
    startDateObj.setMonth(startDateObj.getMonth() + tramp);
    return startDateObj.toISOString();
  };

  function updateDfFromQt(segment: ArpSegment) {
    segment.df = arpsWasm.getNominalDeclineAtRate(
      segment.di,
      segment.qi,
      segment.b,
      segment.qf
    );
  }

  function updateEndDates(s1: ArpSegment, s1StartDate: Date, s2?: ArpSegment) {
    let s1DayDelta = arpsWasm.getDayAtRate(s1.qi, s1.di, s1.b, s1.qf);
    const s1EndDate = new Date(s1StartDate.getTime());
    // s1DayDelta can be invalid date due to incorrect decline settings, set to zero to catch this.
    if (
      !s1DayDelta ||
      !isFinite(s1DayDelta) ||
      s1DayDelta > MAX_SAFE_INTEGER ||
      s1DayDelta < MIN_SAFE_INTEGER
    ) {
      s1DayDelta = 0;
    }
    s1EndDate.setDate(s1EndDate.getDate() + s1DayDelta);
    s1.endDate = s1EndDate.toISOString();

    if (s2) {
      s2.startDate = s1.endDate;
      //decline linked
      s2.di = s1.df;
      updateDfFromQt(s2);
      const s2DayDelta = arpsWasm.getDayAtRate(s2.qi, s2.di, s2.b, s2.qf);
      const s2EndDate = new Date(s2.startDate);
      s2EndDate.setDate(s2EndDate.getDate() + s2DayDelta);
      s2.endDate = s2EndDate.toISOString();
    }
  }

  const initialOneSegmentTerminalDecline: InitialSegment = (product, startDate) => {
    const seg1 = initialSegment1(product, startDate);
    updateEndDates(seg1, new Date(seg1.startDate));
    return [seg1];
  };

  const initialTwoSegmentTerminalDecline: InitialSegment = (product, startDate) => {
    const defaultTypeWell = getDefaultTypeWell(product);

    const s1 = initialSegment1(product, startDate);

    s1.df = defaultTypeWell.values.Df;
    s1.qf = arpsWasm.getRateAtNominal(s1.di, s1.df, s1.qi, s1.b);

    const s2 = {
      product,
      qi: s1.qf,
      b: defaultTypeWell.values.Bf,
      qf: defaultTypeWell.values.Qf,
      df: s1.df,
      di: s1.df,
      startDate: new Date().toISOString(),
      segmentIndex: 2,
      trendCum: 0,
      endDate: new Date().toISOString()
    };
    updateEndDates(s1, new Date(s1.startDate), s2);
    return [s1, s2];
  };

  const initialTwoSegmentTerminalDeclineWithRampUp: InitialSegment = (
    product,
    startDate
  ) => {
    const defaultTypeWell = getDefaultTypeWell(product);
    const s1 = initialRampupSegment(product, startDate);
    const s2StartDate = getRampUpEndDate(startDate, defaultTypeWell.values.tramp);
    const s2 = { ...initialSegment1(product, s2StartDate), segmentIndex: 2 };
    s2.df = defaultTypeWell.values.Df;
    s2.qf = arpsWasm.getRateAtNominal(s2.di, s2.df, s2.qi, s2.b);

    const s3 = {
      product,
      qi: s2.qf,
      b: defaultTypeWell.values.Bf,
      qf: defaultTypeWell.values.Qf,
      df: s2.df,
      di: s2.df,
      startDate: new Date().toISOString(),
      segmentIndex: 3,
      trendCum: 0,
      endDate: new Date().toISOString()
    };
    updateEndDates(s2, new Date(s2.startDate), s3);

    return [s1, s2, s3];
  };

  const initialThreeSegmentTerminalDecline: InitialSegment = (product, startDate) => {
    const defaultTypeWell = getDefaultTypeWell(product);

    const s1 = {
      ...initialSegment1(product, startDate),
      switchMonth: defaultTypeWell.values.ttrans,
      b: defaultTypeWell.values.Btrans
    };

    s1.endDate = addAvgFractionalMonths(s1.startDate, s1.switchMonth);

    const daysInBetween = getDurationInDays(s1.startDate, s1.endDate);
    const qf1 = arpsWasm.getRateAtDay(s1.qi, s1.di, s1.b, daysInBetween);
    s1.qf = qf1;
    s1.df = arpsWasm.getNominalDeclineAtRate(s1.di, s1.qi, s1.b, qf1);

    const s2 = {
      product,
      qi: s1.qf,
      b: defaultTypeWell.values.Bhyp,
      qf: 100.0,
      df: defaultTypeWell.values.Df,
      di: s1.df,
      startDate: s1.endDate,
      segmentIndex: 2,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    s2.qf = arpsWasm.getRateAtNominal(s2.di, s2.df, s2.qi, s2.b);

    const s3 = {
      product,
      qi: s2.qf,
      b: defaultTypeWell.values.Bf,
      qf: defaultTypeWell.values.Qf,
      df: s2.df,
      di: s2.df,
      startDate: s2.endDate,
      segmentIndex: 3,
      trendCum: 0,
      timeCalculated: true,
      endDate: new Date().toISOString()
    };
    updateEndDates(s2, new Date(s2.startDate), s3);
    return [s1, s2, s3];
  };

  const initialThreeSegmentTerminalDeclineWithRampUp: InitialSegment = (
    product,
    startDate
  ) => {
    const defaultTypeWell = getDefaultTypeWell(product);

    const s1 = initialRampupSegment(product, startDate);
    const s2StartDate = getRampUpEndDate(startDate, defaultTypeWell.values.tramp);
    const s2 = {
      ...initialSegment1(product, s2StartDate),
      switchMonth: defaultTypeWell.values.ttrans,
      b: defaultTypeWell.values.Btrans,
      segmentIndex: 2
    };

    s2.endDate = addAvgFractionalMonths(s2.startDate, s2.switchMonth);
    const daysInBetween = getDurationInDays(s2.startDate, s2.endDate);

    const qf2 = arpsWasm.getRateAtDay(s2.qi, s2.di, s2.b, daysInBetween);
    s2.qf = qf2;
    s2.df = arpsWasm.getNominalDeclineAtRate(s2.di, s2.qi, s2.b, qf2);

    const s3 = {
      product,
      qi: s2.qf,
      b: defaultTypeWell.values.Bhyp,
      qf: 100.0,
      df: defaultTypeWell.values.Df,
      di: s2.df,
      startDate: s2.endDate,
      segmentIndex: 3,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    updateEndDates(s3, new Date(s3.startDate));

    const s4 = {
      product,
      qi: s3.qf,
      b: defaultTypeWell.values.Bf,
      qf: defaultTypeWell.values.Qf,
      df: s3.df,
      di: s3.df,
      startDate: s3.endDate,
      segmentIndex: 4,
      trendCum: 0,
      timeCalculated: true,
      endDate: new Date().toISOString()
    };

    updateEndDates(s4, new Date(s4.startDate));

    return [s1, s2, s3, s4];
  };

  const initialThreeSegmentTerminalDeclineWithConstrainedPeriod: InitialSegment = (
    product,
    startDate
  ) => {
    const defaultTypeWell = getDefaultTypeWell(product);

    const s1 = initialConstrainSegment(product, startDate);
    const s2 = {
      ...initialSegment1(product, s1.endDate),
      switchMonth: defaultTypeWell.values.ttrans,
      b: defaultTypeWell.values.Btrans,
      segmentIndex: 2
    };

    s2.endDate = addAvgFractionalMonths(s2.startDate, s2.switchMonth);

    const daysInBetween = getDurationInDays(s2.startDate, s2.endDate);

    const qf2 = arpsWasm.getRateAtDay(s2.qi, s2.di, s2.b, daysInBetween);
    s2.qf = qf2;
    s2.df = arpsWasm.getNominalDeclineAtRate(s2.di, s2.qi, s2.b, qf2);

    const s3 = {
      product,
      qi: s2.qf,
      b: defaultTypeWell.values.Bhyp,
      qf: 100.0,
      df: defaultTypeWell.values.Df,
      di: s2.df,
      startDate: s2.endDate,
      segmentIndex: 3,
      trendCum: 0,
      endDate: new Date().toISOString()
    };

    updateEndDates(s3, new Date(s3.startDate));

    const s4 = {
      product,
      qi: s3.qf,
      b: defaultTypeWell.values.Bf,
      qf: defaultTypeWell.values.Qf,
      df: s3.df,
      di: s3.df,
      startDate: s3.endDate,
      segmentIndex: 4,
      trendCum: 0,
      timeCalculated: true,
      endDate: new Date().toISOString()
    };
    updateEndDates(s4, new Date(s4.startDate));

    return [s1, s2, s3, s4];
  };

  return {
    initialOneSegmentTerminalDecline,
    initialTwoSegmentTerminalDecline,
    initialTwoSegmentTerminalDeclineWithRampUp,
    initialThreeSegmentTerminalDecline,
    initialThreeSegmentTerminalDeclineWithRampUp,
    initialThreeSegmentTerminalDeclineWithConstrainedPeriod
  };
};

export default useInitialSegments;
