// Create and export a single instance of BroadcastChannel
const broadcast = new BroadcastChannel("auth_channel");

export const broadcastTypes = {
  TOKEN_UPDATED: "token_updated",
  TOKEN_REFRESHING: "token_refreshing"
};

// Helper function to broadcast messages
export const broadcastMessage = (type, data) => {
  try {
    // Ensure data is serializable and handle null data
    const serializableData = data ? JSON.parse(JSON.stringify(data)) : {};
    broadcast.postMessage({ type, ...serializableData });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to broadcast message:", error);
    // Handle the error, e.g., log it or notify the user
  }
};

export default broadcast;
