import { FC } from "react";

import { Dropdown } from "antd";
import { TimeStep } from "constants/charts.enums";

import { CalendarMonthFilled, CalendarStarFilled, CalendarToday } from "components/icons";

export const TimeStepIcon: { [key in TimeStep]: JSX.Element } = {
  month: <CalendarMonthFilled />,
  day: <CalendarToday />,
  hybrid: <CalendarStarFilled />
};

type TimeScaleSelectT = {
  children: React.ReactNode;
  onChange: (v: TimeStep) => void;
  trigger: ("click" | "hover" | "contextMenu")[];
  value: TimeStep;
  isDataSourcePublic: boolean;
  isDataSourcePrivate: boolean;
};

const TimeScaleSelect: FC<TimeScaleSelectT> = ({
  children,
  trigger,
  onChange,
  value,
  isDataSourcePublic,
  isDataSourcePrivate
}) => {
  // Switch time scale to monthly if the data source / time scale combination is invalid
  if (
    (isDataSourcePublic && value !== TimeStep.Month) ||
    (isDataSourcePrivate && value === TimeStep.Hybrid)
  ) {
    onChange(TimeStep.Month);
  }

  return (
    <Dropdown
      menu={{
        selectedKeys: [value],
        onClick: (v) => onChange(v.key as TimeStep),
        items: [
          {
            key: TimeStep.Month,
            icon: TimeStepIcon.month,
            label: "Monthly"
          },
          {
            key: TimeStep.Day,
            icon: TimeStepIcon.day,
            label: "Daily",
            disabled: isDataSourcePublic
          },
          {
            key: TimeStep.Hybrid,
            icon: TimeStepIcon.hybrid,
            label: "Hybrid",
            disabled: isDataSourcePublic || isDataSourcePrivate
          }
        ]
      }}
      trigger={trigger}>
      {children}
    </Dropdown>
  );
};

export default TimeScaleSelect;
