import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ClipboardItem {}

declare global {
  interface Window {
    ClipboardItem: ClipboardItem | undefined;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAsyncClipboardApi: () => any = () => {
  if (
    typeof window === "undefined" ||
    typeof window.navigator.clipboard === "undefined" ||
    typeof window.ClipboardItem === "undefined"
  ) {
    return null;
  }
  return {
    ClipboardItem: window.ClipboardItem,
    clipboard: navigator.clipboard
  };
};

const useAsyncClipboardApi = () => {
  const api = React.useMemo(() => getAsyncClipboardApi(), []);
  return api;
};

export default useAsyncClipboardApi;
