import { useQuery } from "react-query";

import { getIpdbPercentiles } from "api/map";

import { Percentile } from "components/vis/context/types";

export const useIpdbPercentileQueryKeys = {
  ipdbP10P90: "ipdbPercentile"
};

export interface UseIpdbPercentileQueryParamsT {
  modelSource: string;
  ipdbZones: string[];
  fieldName: string;
  onSuccess?: (data: Percentile) => void;
  onError?: (error: Error) => void;
  staleTimeMs?: number;
  enabled?: boolean;
}

export interface UseIpdbPercentileQueryReturnT {
  percentiles: Percentile | null;
  error: string | null;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export const fetchIpdbP10P90 = async (
  modelSource: string,
  ipdbZones: string[],
  fieldName: string
): Promise<Percentile | null> => {
  if (!modelSource || !ipdbZones?.length || !fieldName) {
    return null;
  }

  try {
    const response = await getIpdbPercentiles(modelSource, ipdbZones, fieldName);
    const { p10Val, p90Val } = response.data;
    return { p10: p10Val, p90: p90Val };
  } catch (error) {
    throw new Error("Unable to fetch IPDB P10/P90 values");
  }
};

export function usePercentilesQuery(
  params: UseIpdbPercentileQueryParamsT
): UseIpdbPercentileQueryReturnT {
  const { modelSource, ipdbZones, fieldName, onError, onSuccess, staleTimeMs } = params;

  const queryInfo = useQuery({
    queryKey: [useIpdbPercentileQueryKeys.ipdbP10P90, modelSource, ipdbZones, fieldName],
    queryFn: () => fetchIpdbP10P90(modelSource, ipdbZones, fieldName),
    onError,
    onSuccess,
    enabled: Boolean(modelSource && ipdbZones?.length && fieldName),
    staleTime: staleTimeMs || 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    retry: 2
  });

  return {
    percentiles: queryInfo.data,
    error: queryInfo.error ? (queryInfo.error as Error).message : null,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
