import { memo } from "react";

import { capitalizeFirstLetter } from "utils";

import { DataSourceIcon, DataSourceSelect } from "components/shared";

import ToolbarButton from "../../base/ToolbarButton";
import { useChartDispatch, useChartState } from "../context";

const DataSourceToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();

  function handleSourceChange(value) {
    const chartCopy = { ...settings, source: value };
    dispatch({ type: "settings", payload: chartCopy });
  }

  // derived state
  const icon = DataSourceIcon[settings.source || "Public"];
  const label = capitalizeFirstLetter(settings.source || "Public");

  return (
    <DataSourceSelect
      trigger={["hover"]}
      value={settings.source}
      onChange={handleSourceChange}>
      <ToolbarButton
        data-testid="datasource-trigger"
        aria-label={settings.source}
        active={false}
        icon={icon}
        overflowLabel={label}
      />
    </DataSourceSelect>
  );
};

export default memo(DataSourceToggle);
