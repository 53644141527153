import { memo, useState } from "react";

import { Popover } from "antd";

import CollapseVerticalIcon from "components/icons/CollapseVertical";

import ToolbarButton from "../../../base/ToolbarButton";
import Normalize from "./Normalize";

const NormalizeToggle = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      arrowPointAtCenter
      content={<Normalize />}
      onOpenChange={(v) => setVisible(v)}
      open={visible}
      overlayClassName="normalize-popover"
      placement="bottomRight"
      trigger="click">
      <ToolbarButton
        active={false}
        data-testid="chart-normalize-button"
        icon={<CollapseVerticalIcon />}
        isGlobalSetting={true}
        isMenuButton={true}
        overflowLabel="Normalize"
        tooltipText="Normalize"
      />
    </Popover>
  );
};

export default memo(NormalizeToggle);
