import { useEffect } from "react";

import { useScreenshotContext } from "./useScreenshotContext";

/**
 * A custom hook that manages screenshot mode by adding/removing a CSS class to the document body.
 * The hook automatically handles the cleanup of the CSS class when the component unmounts.
 *
 * @remarks
 * This hook depends on the `useScreenshotContext` hook to determine if screenshot mode is active.
 * When active, it adds the 'screenshot-mode' class to the document body.
 * When inactive or on unmount, it removes the 'screenshot-mode' class.
 *
 * @returns void
 */
export const useScreenshotModeStyles = () => {
  const { widget } = useScreenshotContext();
  const screenshotActive = !!widget;

  useEffect(() => {
    if (screenshotActive) {
      document.body.classList.add("screenshot-mode");
    } else {
      document.body.classList.remove("screenshot-mode");
    }

    return () => {
      document.body.classList.remove("screenshot-mode");
    };
  }, [screenshotActive]);
};
