import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { FORECASTING_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { ForecastWellStatus } from "../models/forecastWell";
import { FetchWellHierarchyQueryKey } from "./useFetchWellHierarchy";

const API_URL = `${FORECASTING_SERVICE_ADDRESS}/api/v1`;

const usePromoteForecast = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params: { uwis: string[]; status: ForecastWellStatus }) => {
      const response = await axios.post(
        `${API_URL}/well/promote/${params.status}/forecast`,
        params.uwis
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FetchWellHierarchyQueryKey);
      }
    }
  );
};

export default usePromoteForecast;
