import { Select } from "antd";

import useBetaFeatures from "hooks/useBetaFeatures";

import { useGroupByState, useGroupByUpdater } from "./group-by.context";
import { useGroupedProductOptions } from "./hooks";

function removeCO2Options(products) {
  return products.map((category) => ({
    ...category,
    options: category.options.filter(
      (option) => !option.value.includes("CO₂") && !option.label.includes("CO₂")
    )
  }));
}

export default function ProductSelect() {
  const productOptions = useGroupedProductOptions();
  const { hasFeature } = useBetaFeatures();

  const { selectedProduct } = useGroupByState();
  const updateGroupByState = useGroupByUpdater();

  const changeProduct = () => (value) => {
    return updateGroupByState({ type: "selected_product", payload: value });
  };

  return (
    <Select
      data-testid="product-select"
      value={selectedProduct}
      onChange={changeProduct()}
      showSearch={true}
      options={
        hasFeature("Facility Volumes") ? removeCO2Options(productOptions) : productOptions
      }
      popupClassName="modal-select"
    />
  );
}
