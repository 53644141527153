import {
  getDatabaseRateUnits,
  productStringToProductTypeEnum,
  ratioProductListForForecasting
} from "utils";
import { convert } from "utils/units/unitConversion";

import { ForecastConstant } from "models/UserArpsModel";

export function convertConstantsToDatabaseUnits(constants: ForecastConstant[]) {
  return constants.map((c) => {
    // Forecasting widget requires bbl/MMcf for the liquid ratio products
    // however the chart-rust library is expecting bbl/Mcf. This ensures the
    // correct units are used for the chart-rust library and the charts properly render the data.
    // This is a workaround until we sort out consistent units across the entire app.
    if (ratioProductListForForecasting.includes(c.product)) {
      return {
        ...c,
        value: c.value / 1000,
        unit: "bbl/Mcf"
      };
    }

    const dbUnit = getDatabaseRateUnits(productStringToProductTypeEnum(c.product));
    const convertedValue = convert(c.value, c.unit, dbUnit);
    return {
      ...c,
      value: convertedValue,
      unit: dbUnit
    };
  });
}
