import { utc } from "dayjs";

import { ArpSegment } from "../../../models/UserArpsModel";
import { EPSILON, areApproximatelyEqual, roundTo } from "../../../utils/numbers";
import { CumulativeData } from "../../forecasting/Forecasting";
import { ProductTypeEnumAllCaps } from "../models/ArpsSegment";
import {
  convertDecline,
  convertDeclineToNominal,
  getDiAtDay,
  isConstrainedSegment,
  isRampUpSegment
} from "./arpsUtils";
import {
  SECONDS_IN_DAY,
  addAvgFractionalMonths,
  avgMonthsDiff,
  daysDiff,
  getDateStringAtMidnightUtc,
  getDurationInDays,
  getDurationInMonths
} from "./dates";

export let arpsWasm: typeof import("wasm/arps") = undefined;
import("wasm/arps.js").then((x) => {
  arpsWasm = x;
});

const RATE_DECIMAL_PLACES = 8;

export interface UpdateSegment {
  (
    segmentTemplateName: string,
    productArps: ArpSegment[],
    declineType: string,
    header: string,
    val,
    cumulativeData?: CumulativeData
  ): ArpSegment[];
}

function updateEndDates(segments: ArpSegment[]) {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  for (let i = 0; i < segments.length; i++) {
    const seg = segments[i];
    const startDate = utc(seg.startDate);
    const day = arpsWasm.getDayAtRate(seg.qi, seg.di, seg.b, seg.qf);

    if (day === Infinity || isNaN(day) || areApproximatelyEqual(day, 0, EPSILON)) {
      // This will be caught by the validation
      continue;
    }
    const totalSeconds = Math.round(day * SECONDS_IN_DAY);

    const endDate = startDate.add(totalSeconds, "seconds");

    seg.endDate = endDate.toISOString();
    if (i + 1 < segments.length) {
      const next = segments[i + 1];
      next.startDate = seg.endDate;
    }
  }
}

function updateTrendCum(segments: ArpSegment[]) {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  for (let i = 0; i < segments.length; i++) {
    const seg = segments[i];
    if (seg.endDate && seg.startDate) {
      seg.trendCum = arpsWasm.getSegmentVolume(
        seg.qi,
        seg.di,
        seg.b,
        seg.qf,
        BigInt(Math.round(new Date(seg.startDate).getTime() / 1000)),
        BigInt(Math.round(new Date(seg.endDate).getTime() / 1000))
      );
    } else {
      seg.trendCum = 0;
    }
  }
}

/**
 * Updates the properties of a ramp-up segment based on its start and end dates.
 *
 * This function calculates the decline parameters for a ramp-up segment by:
 * 1. Computing the duration in days between the start and end dates
 * 2. Setting a minimum value for initial rate (qi) to avoid calculation errors
 * 3. Setting the b-factor to -1.0 (harmonic decline)
 * 4. Calculating the initial decline rate (di) based on the given flow rates and duration
 * 5. Calculating the final decline rate (df) based on the newly computed parameters
 *
 * @param segment - The ArpSegment object representing a ramp-up segment to be updated
 * @param isRampUp
 */
export const updateRampUpSegmentValues = (segment: ArpSegment, isRampUp: boolean) => {
  const day = daysDiff(segment.startDate, segment.endDate);
  if (Math.abs(segment.qi) < EPSILON) {
    //qi zero is nan when calculating di
    segment.qi = 0.000001;
  }
  if (isRampUp) {
    if (areApproximatelyEqual(segment.qi, segment.qf, EPSILON)) {
      segment.b = 0;
      segment.di = 0;
    } else {
      segment.b = -1.0; //ramp segment is harmonic
      segment.di = arpsWasm.calculateDiAtDay(segment.qi, segment.qf, segment.b, day);
    }
  }
  segment.df = arpsWasm.getNominalDeclineAtRate(
    segment.di,
    segment.qi,
    segment.b,
    segment.qf
  );
};

export function updateSegmentsFromHeaderValues(
  header: string,
  segmentTemplateName: string,
  segments: ArpSegment[],
  declineType: string,
  val,
  cumulativeData?: CumulativeData
) {
  const hasRampUpSegment = isRampUpSegment({ segmentTemplateName, segments });
  const hasConstrainedSegment = isConstrainedSegment({ segmentTemplateName, segments });

  const hasInitialSegment = hasRampUpSegment || hasConstrainedSegment;

  const offset = hasInitialSegment ? 0 : -1;
  const seg0 = offset === 0 ? segments[0] : null;
  const seg1 = segments[offset + 1];
  const seg2 = segments[offset + 2];
  const seg3 = segments[offset + 3];
  const is2Segments =
    (!hasInitialSegment && segments.length === 2) ||
    (hasInitialSegment && segments.length === 3);

  if (header === "Q0" && seg0) {
    seg0.qi = val;
    if ((seg0.qf < val && hasRampUpSegment) || (seg0.qf > val && hasConstrainedSegment)) {
      seg0.qf = val;
      seg1.qi = val;
    }
    if (hasRampUpSegment) {
      updateRampUpSegmentValues(seg0, hasRampUpSegment);
    }
  }

  if (header === "Bcons") {
    seg0.b = val;
    seg0.di = getDiAtDay(
      seg0.qi,
      seg0.qf,
      val,
      getDurationInDays(seg0.startDate, seg0.endDate)
    );
  }

  if (header === "tCons") {
    seg0.endDate = addAvgFractionalMonths(seg0.startDate, val);
    seg1.startDate = seg0.endDate;
    const daysInBetween = getDurationInDays(seg0.startDate, seg0.endDate);
    seg0.di = getDiAtDay(seg0.qi, seg0.qf, seg0.b, daysInBetween);

    updateEndDates(segments);
  }

  if (header === "tRamp" && seg0) {
    seg0.endDate = addAvgFractionalMonths(seg0.startDate, val);
    updateRampUpSegmentValues(seg0, hasRampUpSegment);
    seg1.startDate = seg0.endDate;
    updateEndDates(segments);
  }

  if (header == "tTrans") {
    seg1.endDate = addAvgFractionalMonths(seg1.startDate, val);
  }

  if (header === "Qi") {
    if (seg0) {
      seg0.qf = val;

      if (seg0.qi > val && hasRampUpSegment) {
        seg0.qi = val;
      } else if (seg0.qi < val && hasConstrainedSegment) {
        seg0.qi = val;
      }

      updateRampUpSegmentValues(seg0, hasRampUpSegment);
    }

    seg1.qi = val;
    if (segments.length > 1) {
      //qi changed so qf needs to reflect that
      seg1.qf = roundTo(
        arpsWasm.getRateAtNominal(seg1.di, seg1.df, seg1.qi, seg1.b),
        RATE_DECIMAL_PLACES
      );
      if (seg2) {
        seg2.qi = seg1.qf;
      }
    }
  }

  if (header.includes("Di")) {
    seg1.di = convertDeclineToNominal(val, seg1.b, declineType);
  }

  if (header.includes("Df")) {
    if (seg3) {
      seg2.df = convertDeclineToNominal(val, seg2.b, declineType);
    } else {
      seg1.df = convertDeclineToNominal(val, seg1.b, declineType);
    }
  }

  if (header === "Start Date") {
    const date = val as Date;
    if (seg0) {
      // Update start date for initial segment will also affect first segment start date
      const months = avgMonthsDiff(
        getDateStringAtMidnightUtc(seg0.startDate),
        seg0.endDate
      );

      // Reset the time to midnight UTC to avoid time zone offsets affecting calculations
      seg0.startDate = getDateStringAtMidnightUtc(date.toISOString());

      //remove the time segment because users cant set time
      seg0.endDate = addAvgFractionalMonths(seg0.startDate, months);
      seg1.startDate = seg0.endDate;
      //need to recalculate di because the number of days between the months could have changed
      updateRampUpSegmentValues(seg0, hasRampUpSegment);
    } else {
      seg1.startDate = date.toISOString();
    }

    updateEndDates(segments);
  }

  if (header === "B" || header === "Btrans") {
    if (declineType === "Secant") {
      seg1.di = convertDeclineToNominal(
        convertDecline(seg1.di, seg1.b, declineType),
        val,
        declineType
      );
      seg1.df = convertDeclineToNominal(
        convertDecline(seg1.df, seg1.b, declineType),
        val,
        declineType
      );
    }
    seg1.b = val;
  }

  if (header === "Bhyp") {
    const updatingSeg = is2Segments ? seg1 : seg2;
    if (declineType === "Secant") {
      updatingSeg.di = convertDeclineToNominal(
        convertDecline(updatingSeg.di, updatingSeg.b, declineType),
        val,
        declineType
      );
      updatingSeg.df = convertDeclineToNominal(
        convertDecline(updatingSeg.df, updatingSeg.b, declineType),
        val,
        declineType
      );
    }
    updatingSeg.b = val;
  }

  if (header === "Bf") {
    const updatingSeg = is2Segments ? seg2 : seg3;
    updatingSeg.b = val;
  }

  if (header === "Qf") {
    const lastSeg = segments[segments.length - 1];
    lastSeg.qf = val;
  }

  if (header === "TotalEUR") {
    seg1.switchMonth = getDurationInMonths(
      new Date(seg1.startDate),
      new Date(seg1.endDate)
    );
    const product = segments[0].product;
    const cumulativeToDateProduction = cumulativeData?.[product.toLowerCase()] ?? 0;
    const lastProdDate = cumulativeData?.lastProductionDate
      ? BigInt(Math.round(new Date(cumulativeData.lastProductionDate).getTime() / 1000))
      : BigInt(0);
    const di = arpsWasm.getDiFromCumAndRemaining(
      segments.map(arpsWasm.arpSegmentToSegmentDto),
      val * 1000.0,
      cumulativeToDateProduction * 1000.0,
      lastProdDate,
      hasInitialSegment,
      seg1.switchMonth
    );

    if (di > 0) {
      seg1.di = di;
    } else {
      seg1.di = null;
    }
  }

  if (header === "EUR") {
    if (hasInitialSegment && seg0) {
      //set timeCalculated to false to use end date
      seg0.timeCalculated = false;
    }
    seg1.switchMonth = getDurationInMonths(
      new Date(seg1.startDate),
      new Date(seg1.endDate)
    );
    const di = arpsWasm.getDiFromCumAndRemaining(
      segments.map(arpsWasm.arpSegmentToSegmentDto),
      val * 1000.0,
      0,
      BigInt(Math.round(new Date("1000-01-01").getTime() / 1000)),
      //start at 1000 to ensure that forecast doesn't get trumped by historical
      hasInitialSegment,
      seg1.switchMonth
    );

    if (di > 0) {
      seg1.di = di;
    } else {
      seg1.di = null;
    }
  }
}

export const updateOneSegmentTerminalDecline: UpdateSegment = (
  segmentTemplateName: string,
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val,
  cumulativeData?: CumulativeData
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }

  const clone = productArps.map((item) => ({ ...item }));
  const seg1 = clone[0];

  if (clone.length !== 1) {
    throw "The selected type well has an invalid number of segments. Please verify and try again.";
  }

  updateSegmentsFromHeaderValues(
    header,
    segmentTemplateName,
    clone,
    declineType,
    val,
    cumulativeData
  );
  seg1.df = arpsWasm.getNominalDeclineAtRate(seg1.di, seg1.qi, seg1.b, seg1.qf);
  if (header !== "Qf") {
    const productNames = productArps.map((a) =>
      a.product ? a.product.replace(/\s+/g, "") : a.product
    );
    const productEnums = productNames ? ProductTypeEnumAllCaps[productNames[0]] : 0;
    if (!productEnums) {
      throw "invalid product";
    }
  }
  updateEndDates(clone);
  updateTrendCum(clone);
  return clone;
};

export const updateTwoSegmentTerminalDecline: UpdateSegment = (
  segmentTemplateName: string,
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val,
  cumulativeData?: CumulativeData
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }

  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 2) {
    throw "The selected type well has an invalid number of segments. Please verify and try again.";
  }

  const seg1 = clone[0];
  const seg2 = clone[1];
  updateSegmentsFromHeaderValues(
    header,
    segmentTemplateName,
    clone,
    declineType,
    val,
    cumulativeData
  );

  //these are the calculated fields
  seg1.qf = roundTo(
    arpsWasm.getRateAtNominal(seg1.di, seg1.df, seg1.qi, seg1.b),
    RATE_DECIMAL_PLACES
  );
  //di is linked to seg1
  seg2.di = seg1.df;
  seg2.qi = seg1.qf;
  seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);

  updateEndDates(clone);
  updateTrendCum(clone);

  return clone;
};

export const updateTwoSegmentTerminalDeclineWithRampUp: UpdateSegment = (
  segmentTemplateName: string,
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val,
  cumulativeData?: CumulativeData
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }

  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 3) {
    throw "The selected type well has an invalid number of segments. Please verify and try again.";
  }

  // Ignore clone[0] which is ramp-up segment.
  const seg2 = clone[1];
  const seg3 = clone[2];
  updateSegmentsFromHeaderValues(
    header,
    segmentTemplateName,
    clone,
    declineType,
    val,
    cumulativeData
  );

  //segment 1 is set by user so only segment 2 an 3 are calculated
  seg2.qf = roundTo(
    arpsWasm.getRateAtNominal(seg2.di, seg2.df, seg2.qi, seg2.b),
    RATE_DECIMAL_PLACES
  );
  //di is linked to seg1
  seg3.di = seg2.df;
  seg3.qi = seg2.qf;
  seg3.df = arpsWasm.getNominalDeclineAtRate(seg3.di, seg3.qi, seg3.b, seg3.qf);

  updateEndDates([seg2, seg3]);
  updateTrendCum(clone);
  return clone;
};

export const updateThreeSegmentTerminalDecline: UpdateSegment = (
  segmentTemplateName: string,
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val,
  cumulativeData?: CumulativeData
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }

  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 3) {
    throw "The selected type well has an invalid number of segments. Please verify and try again.";
  }

  const seg1 = clone[0];
  const seg2 = clone[1];
  const seg3 = clone[2];
  updateSegmentsFromHeaderValues(
    header,
    segmentTemplateName,
    clone,
    declineType,
    val,
    cumulativeData
  );

  const switchMonth = getDurationInDays(seg1.startDate, seg1.endDate);
  seg1.qf = roundTo(
    arpsWasm.getRateAtDay(seg1.qi, seg1.di, seg1.b, switchMonth),
    RATE_DECIMAL_PLACES
  );
  seg1.df = arpsWasm.getNominalDeclineAtRate(seg1.di, seg1.qi, seg1.b, seg1.qf);

  seg2.qi = seg1.qf;
  seg2.di = seg1.df;
  seg2.qf = roundTo(
    arpsWasm.getRateAtNominal(seg2.di, seg2.df, seg2.qi, seg2.b),
    RATE_DECIMAL_PLACES
  );

  seg3.qi = seg2.qf;
  seg3.di = seg2.df;
  seg3.df = arpsWasm.getNominalDeclineAtRate(seg3.di, seg3.qi, seg3.b, seg2.qf);

  updateEndDates(clone);
  updateTrendCum(clone);
  return clone;
};

export const updateThreeSegmentTerminalDeclineWithRampUp: UpdateSegment = (
  segmentTemplateName: string,
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val,
  cumulativeData?: CumulativeData
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 4) {
    throw "The selected type well has an invalid number of segments. Please verify and try again.";
  }

  // Ignore clone[0] which is the ramp-up segment.
  const seg2 = clone[1];
  const seg3 = clone[2];
  const seg4 = clone[3];
  updateSegmentsFromHeaderValues(
    header,
    segmentTemplateName,
    clone,
    declineType,
    val,
    cumulativeData
  );

  const switchMonth = getDurationInDays(seg2.startDate, seg2.endDate);
  seg2.qf = roundTo(
    arpsWasm.getRateAtDay(seg2.qi, seg2.di, seg2.b, switchMonth),
    RATE_DECIMAL_PLACES
  );
  seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);

  seg3.qi = seg2.qf;
  seg3.di = seg2.df;
  seg3.qf = roundTo(
    arpsWasm.getRateAtNominal(seg3.di, seg3.df, seg3.qi, seg3.b),
    RATE_DECIMAL_PLACES
  );

  seg4.qi = seg3.qf;
  seg4.di = seg3.df;
  seg4.df = arpsWasm.getNominalDeclineAtRate(seg4.di, seg4.qi, seg4.b, seg4.qf);

  updateEndDates([seg2, seg3, seg4]);
  updateTrendCum(clone);
  return clone;
};

export const updateThreeSegmentTerminalDeclineWithConstrainedPeriod: UpdateSegment = (
  segmentTemplateName: string,
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val,
  cumulativeData?: CumulativeData
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }

  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 4) {
    throw "The selected type well has an invalid number of segments. Please verify and try again.";
  }

  const seg2 = clone[1];
  const seg3 = clone[2];
  const seg4 = clone[3];
  updateSegmentsFromHeaderValues(
    header,
    segmentTemplateName,
    clone,
    declineType,
    val,
    cumulativeData
  );

  const switchMonth = getDurationInDays(seg2.startDate, seg2.endDate);
  seg2.qf = roundTo(
    arpsWasm.getRateAtDay(seg2.qi, seg2.di, seg2.b, switchMonth),
    RATE_DECIMAL_PLACES
  );
  seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);

  seg3.qi = seg2.qf;
  seg3.di = seg2.df;
  seg3.qf = roundTo(
    arpsWasm.getRateAtNominal(seg3.di, seg3.df, seg3.qi, seg3.b),
    RATE_DECIMAL_PLACES
  );

  seg4.qi = seg3.qf;
  seg4.di = seg3.df;
  seg4.df = arpsWasm.getNominalDeclineAtRate(seg4.di, seg4.qi, seg4.b, seg4.qf);

  updateEndDates([seg2, seg3, seg4]);
  updateTrendCum(clone);
  return clone;
};
