import { toast } from "react-toastify";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { IS_MCDAN_ENV } from "constants/app.constants";
import {
  MY_FOCUS_FIELDS,
  ORG_FOCUS_FIELDS,
  ORG_GEO_FIELDS
} from "constants/settings.constants";
import _debounce from "lodash/debounce";
import { AppThunk } from "store/store";

import { BetaFeatures } from "hooks/useBetaFeatures";

import { EntityKind } from "models/entityKind";
import {
  IGroupBy,
  IGroupByListColumn,
  IGroupByListItem,
  IGroupByState
} from "models/groupBy";

import { getGeoCalculationsJobs } from "components/org-settings/hooks/useGetGeoCalculationsJobs";
import { getUserSettingsDefaults } from "components/user/queries";

import { fetchOrgDefinedColumns } from "../../../components/geom-bin/helpers/fetchOrgDefinedColumns";
import { fetchUserDefinedColumns } from "../../../components/geom-bin/helpers/fetchUserDefinedColumns";

function getSessionGroupBy(): IGroupBy {
  const sessionData = sessionStorage.getItem("globalGroupBy");
  if (sessionData) {
    return JSON.parse(sessionData);
  }
  return undefined;
}

const updateRecentFocus = (
  focusList: IGroupBy[],
  currentGb: IGroupBy,
  newGb: IGroupBy
) => {
  let index = focusList.findIndex(
    (gb) => gb.property === newGb.property || gb.property === currentGb.property
  );

  while (index >= 0) {
    focusList.splice(index, 1);
    index = focusList.findIndex(
      (gb) => gb.property === newGb.property || gb.property === currentGb.property
    );
  }
  // Add previous group by to recent focus
  return [currentGb, ...focusList].slice(0, 6);
};

export const initialGroupByState: IGroupByState = {
  categoryList: [],
  facilityFields: [],
  presetList: [],
  recentWellFocus: [],
  recentFacilityFocus: [],
  hoverDataPoint: undefined,
  hoverLegendItem: "",
  hoverLegendGroup: "",
  hoverLegendId: "",
  cumPlusForecast: false,
  selectedGroups: [],
  selectedFacilityGroups: [],
  hasCheckedBins: false,
  hasCheckedBinsFacility: false,
  placeholderBinsEnabled: false,
  globalGroupBy: getSessionGroupBy() ?? {
    title: "Resource Play",
    property: "Header.ResourcePlay",
    groupByField: "Header.ResourcePlay",
    pdenSource: "Public",
    canBin: false,
    dataType: "text",
    categoryId: 1,
    tooltip: "",
    display: "",
    entityKind: EntityKind.Well,
    bin: {
      BinSize: "",
      MinSize: "",
      MaxBins: "",
      MaxBinsSortOrder: "WellCount",
      GreaterThan: "",
      BinType: "BinSize",
      LessThan: ""
    }
    //color: "#F3C300",
  },
  globalFacilityFocus: {
    title: "Facility Operator",
    property: "Header.OperatorName",
    groupByField: "Header.OperatorName",
    pdenSource: "Public",
    canBin: false,
    dataType: "text",
    categoryId: 1,
    tooltip: "",
    display: "",
    entityKind: EntityKind.Facility,
    bin: {
      BinSize: "",
      MinSize: "",
      MaxBins: "",
      MaxBinsSortOrder: "WellCount",
      GreaterThan: "",
      BinType: "BinSize",
      LessThan: ""
    }
    //color: "#F3C300",
  }
};
const setGlobalGroupByBaseOnEntityKind = (state, gb: IGroupBy) => {
  if (gb.entityKind === EntityKind.Well) {
    state.globalGroupBy = gb;
  } else {
    state.globalFacilityFocus = gb;
  }
};

const groupBySlice = createSlice({
  name: "groupby",
  initialState: initialGroupByState,
  reducers: {
    setCategoryList(state, action: PayloadAction<IGroupByListItem[]>) {
      state.categoryList = action.payload;
    },
    setSelectedGroups(state, action: PayloadAction<string[]>) {
      state.selectedGroups = action.payload;
    },
    setHasCheckedBins(state, action: PayloadAction<boolean>) {
      state.hasCheckedBins = action.payload;
    },
    setHasCheckedBinsFacility(state, action: PayloadAction<boolean>) {
      state.hasCheckedBinsFacility = action.payload;
    },
    setPlaceholderBinsEnabled(state, action: PayloadAction<boolean>) {
      state.placeholderBinsEnabled = action.payload;
    },
    setSelectedFacilityGroups(state, action: PayloadAction<string[]>) {
      state.selectedFacilityGroups = action.payload;
    },
    setUserDefinedColumns(state, action: PayloadAction<IGroupByListColumn[]>) {
      const udcIndex = state.categoryList.findIndex((f) => f.name === MY_FOCUS_FIELDS);
      if (udcIndex < 0) {
        return;
      }
      state.categoryList[udcIndex].columns = action.payload;
    },
    setOrgDefinedColumns(state, action: PayloadAction<IGroupByListColumn[]>) {
      const udcIndex = state.categoryList.findIndex((f) => f.name === ORG_FOCUS_FIELDS);
      if (udcIndex < 0) {
        return;
      }
      state.categoryList[udcIndex].columns = action.payload;
    },
    setPresetList(state, action) {
      state.presetList = action.payload;
    },
    setHoverDataPoint(state, action) {
      state.hoverDataPoint = action.payload;
    },
    setHoverLegendItem(state, action) {
      state.hoverLegendItem = action.payload;
    },
    setHoverLegendGroup(state, action) {
      state.hoverLegendGroup = action.payload;
    },
    setHoverLegendId(state, action) {
      state.hoverLegendId = action.payload;
    },
    setFacilityFields(state, action: PayloadAction<IGroupByListItem[]>) {
      state.facilityFields = action.payload;
    },
    setFacilityUserDefinedColumns(state, action: PayloadAction<IGroupByListColumn[]>) {
      const udcIndex = state.facilityFields.findIndex((f) => f.name === MY_FOCUS_FIELDS);
      if (udcIndex < 0) {
        return;
      }
      state.facilityFields[udcIndex].columns = action.payload;
    },
    setFacilityOrgDefinedColumns(state, action: PayloadAction<IGroupByListColumn[]>) {
      const udcIndex = state.facilityFields.findIndex((f) => f.name === ORG_FOCUS_FIELDS);
      if (udcIndex < 0) {
        return;
      }
      state.facilityFields[udcIndex].columns = action.payload;
    },
    setGlobalGroupBy(state, action: PayloadAction<IGroupBy>) {
      if (action.payload.entityKind === EntityKind.Well) {
        sessionStorage.setItem("globalGroupBy", JSON.stringify(action.payload));
      } else {
        sessionStorage.setItem("globalFacilityFocus", JSON.stringify(action.payload));
      }
      const currentGb =
        action.payload.entityKind === EntityKind.Well
          ? state.globalGroupBy
          : state.globalFacilityFocus;
      if (action.payload.property === currentGb.property) {
        //only update groupby state if property is the same
        //skip the recent focus list
        setGlobalGroupByBaseOnEntityKind(state, action.payload);
        return;
      }

      if (action.payload.entityKind === EntityKind.Well) {
        state.recentWellFocus = updateRecentFocus(
          state.recentWellFocus,
          currentGb,
          action.payload
        );
      } else {
        state.recentFacilityFocus = updateRecentFocus(
          state.recentFacilityFocus,
          currentGb,
          action.payload
        );
      }
      setGlobalGroupByBaseOnEntityKind(state, action.payload);
    },
    setCumPlusForecast(state, action: PayloadAction<boolean>) {
      state.cumPlusForecast = action.payload;
    },
    resetGroupBySlice: () => initialGroupByState
  }
});

const rootUrl = process.env.REACT_APP_COLUMN_SET_SERVICE;

export const {
  setCategoryList,
  setGlobalGroupBy,
  setHasCheckedBins,
  setHasCheckedBinsFacility,
  setPlaceholderBinsEnabled,
  setHoverDataPoint,
  setHoverLegendItem,
  setHoverLegendGroup,
  setHoverLegendId,
  setCumPlusForecast,
  setUserDefinedColumns,
  setOrgDefinedColumns,
  setSelectedGroups,
  setSelectedFacilityGroups,
  setFacilityFields,
  setFacilityUserDefinedColumns,
  setFacilityOrgDefinedColumns,
  resetGroupBySlice
} = groupBySlice.actions;

const debounceDataPoint = _debounce((item, dispatch) => {
  dispatch(setHoverDataPoint(item));
}, 50);

export const updateDataPoint =
  (item): AppThunk =>
  (dispatch) => {
    debounceDataPoint(item, dispatch);
  };

const debounceUWI = _debounce((item, dispatch) => {
  dispatch(setHoverLegendItem(item));
}, 50);

export const updateHoverLegendItem =
  (item): AppThunk =>
  (dispatch) => {
    debounceUWI(item, dispatch);
  };

const debounceGroup = _debounce((group, dispatch) => {
  dispatch(setHoverLegendGroup(group));
}, 50);

export const updateHoverLegendGroup =
  (group): AppThunk =>
  (dispatch) => {
    debounceGroup(group, dispatch);
  };

const debounceId = _debounce((id, dispatch) => {
  dispatch(setHoverLegendId(id));
}, 50);

export const updateHoverLegendId =
  (id): AppThunk =>
  (dispatch) => {
    debounceId(id, dispatch);
  };

export const getCategoryList =
  (kind: EntityKind = EntityKind.Well): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(`${rootUrl}/column/new?entityKind=${kind.toString()}`);
      const list: IGroupByListItem[] = res.data;
      const state = getState();
      const orgList = await fetchOrgDefinedColumns(kind);
      const jobs = await getGeoCalculationsJobs();
      const userSettings = await getUserSettingsDefaults();

      if (orgList && kind === EntityKind.Well) {
        const hasFeature = (feature: BetaFeatures): boolean => {
          return state.auth?.user?.organization?.betaFeatures?.includes(feature);
        };

        const orgGeoFieldsEabled = (): boolean => {
          if (hasFeature("Org Geo Fields")) {
            if (hasFeature("User Feature Flags")) {
              // If org requires user feature flag, check if user has the feature flag
              return userSettings.data?.FeatureFlag?.GeoModel?.enabled ?? false;
            } else {
              return true;
            }
          }

          return false;
        };

        // Collect all the geo fields from the org list
        const orgGeoFields = orgList.columns.filter((item) =>
          jobs.some(
            (job) => item.subgroup === job.name && item.title.startsWith(job.name + " - ")
          )
        );

        // Remove the geo fields from the org list
        orgList.columns = orgList.columns.filter(
          (item) => !orgGeoFields.some((field) => item.property === field.property)
        );

        // Add 3D Geo Model category
        if (orgGeoFieldsEabled()) {
          list.push(orgList);

          list.push({
            name: ORG_GEO_FIELDS,
            hexColor: "#136d8b",
            sortOrder: 100,
            property: "",
            section: "Organization",
            columns: orgGeoFields,
            id: ORG_GEO_FIELDS
          });
        } else {
          list.push(orgList);
        }
      } else {
        list.push(orgList);
      }

      if (state.auth?.user?.organization?.allowUserDefinedFields) {
        const userList = await fetchUserDefinedColumns(kind);
        if (userList) {
          list.push(userList);
        }
      }

      list.push({
        name: "Shapefile",
        hexColor: "#8B4513",
        sortOrder: 100,
        property: "",
        section: "Organization",
        columns: [],
        id: "Shapefile"
      });

      const schema = state.app.syncWells?.schema;
      if (IS_MCDAN_ENV && schema) {
        const schemaDefinedColumnResponse = await axios.get(
          `${rootUrl}/schema-defined-columns/${schema}`
        );
        if (schemaDefinedColumnResponse.status === 200) {
          list.push(schemaDefinedColumnResponse.data);
        }
      }
      if (list?.length > 0) {
        if (kind === EntityKind.Well) {
          dispatch(setCategoryList(list));
        } else if (kind === EntityKind.Facility) {
          dispatch(setFacilityFields(list));
        }
      }
    } catch (err) {
      toast.error("Unable to fetch focus fields.");
      // eslint-disable-next-line no-console
      console.error("error", err);
    }
  };

export const updateUserDefinedColumns =
  (entityKind = EntityKind.Well): AppThunk =>
  async (dispatch) => {
    const userList = await fetchUserDefinedColumns(entityKind);

    if (userList) {
      if (entityKind === EntityKind.Well) {
        dispatch(setUserDefinedColumns(userList.columns));
      } else {
        dispatch(setFacilityUserDefinedColumns(userList.columns));
      }
    }

    const orgList = await fetchOrgDefinedColumns(entityKind);

    if (orgList) {
      if (entityKind === EntityKind.Well) {
        dispatch(setOrgDefinedColumns(orgList.columns));
      } else {
        dispatch(setFacilityOrgDefinedColumns(orgList.columns));
      }
    }
  };

export default groupBySlice.reducer;
