/* eslint-disable @typescript-eslint/ban-ts-comment */
import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useEffect, useRef } from "react";

import axios from "axios";
import _debounce from "lodash/debounce";
import _filter from "lodash/filter";
import styled from "styled-components";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";

import { Toolbar } from "./components";
import FullscreenToggleButton from "./toolbar/FullscreenToggleButton";

const dataServiceEndpoint = process.env.REACT_APP_DATA_ROOT + "/api/v1";

export type WellCompletion3dViewT = {
  uwid: string;
  onFullscreenToggle: (fullscreen: boolean) => void;
};

function WellCompletions3dView({ uwid, onFullscreenToggle }: WellCompletion3dViewT) {
  const containerRef = useRef(null);
  const mouseoverRef = useRef(null);
  const state = useRef({
    scene: null,
    renderer: null,
    controls: null,
    container: null,
    camera: null
  });

  useResizeObserver(containerRef.current, () => {
    const resizeBounce = _debounce(() => {
      onWindowResize();
    }, 400);
    resizeBounce();
  });

  function onWindowResize() {
    if (!state.current) return;
    state.current.camera.aspect =
      state.current.container.offsetWidth / state.current.container.offsetHeight;
    state.current.camera.updateProjectionMatrix();

    state.current.renderer.setSize(
      state.current.container.offsetWidth,
      state.current.container.offsetHeight
    );
  }

  function init3dScene() {
    const scene = new THREE.Scene();
    state.current.scene = scene;

    state.current.renderer = new THREE.WebGLRenderer({ antialias: true });
    const renderer = state.current.renderer;
    const container = containerRef.current;

    renderer.domElement.id = "threejs-canvas";
    //@ts-ignore
    renderer.setPixelRatio(window.devicePixelRatio);
    state.current.renderer.setSize(
      containerRef.current.offsetWidth,
      containerRef.current.offsetHeight
    );
    containerRef.current.appendChild(renderer.domElement);

    state.current.camera = new THREE.PerspectiveCamera(
      45,
      container.offsetWidth / container.offsetHeight,
      1,
      1000000
    );

    const camera = state.current.camera;

    state.current.scene = scene;
    camera.position.set(0, 0, 500);
    camera.up = new THREE.Vector3(0, 1, 0);
    camera.lookAt(0, 0, 0);

    // controls

    const controls = new OrbitControls(camera, renderer.domElement);
    state.current.controls = controls;
    controls.enableRotate = false;
    controls.enableDamping = false;
    controls.screenSpacePanning = true;
    // lights

    scene.background = new THREE.Color(0xffffff);
    scene.add(new THREE.AmbientLight(0xffffff));
    const dirLight = new THREE.DirectionalLight(0xdddddd, 0.2);
    dirLight.position.set(-100, -100, 5000);
    dirLight.lookAt(0, 0, -1000);
    dirLight.castShadow = false;
    scene.add(dirLight);
  }

  function renderScene() {
    if (!state.current) return;
    const controls = state.current.controls;
    const renderer = state.current.renderer;
    controls.update();
    renderer.render(state.current.scene, state.current.camera);
  }

  function animate() {
    requestAnimationFrame(animate);
    renderScene();
  }

  function initialize3dView() {
    init3dScene();
    animate();
  }

  function setupStages(stages) {
    const color = new THREE.Color();
    for (const key of Object.keys(stages)) {
      const positions = [];
      const colors = [];
      color.setHex(Math.random() * 0xffffff);
      for (const c of stages[key]) {
        // positions
        positions.push(c.x, c.y, c.z);
        colors.push(color.r, color.g, color.b);
      }
      const geometry = new LineGeometry();
      //@ts-ignore
      geometry.colors = colors;
      geometry.setPositions(positions);
      geometry.setColors(colors);
      const material = new LineMaterial({
        color: 0xffffff,
        linewidth: 15, // in pixels
        vertexColors: true,
        //resolution:  // to be set by renderer, eventually
        dashed: false
      });
      material.resolution.set(
        state.current.container.offsetWidth,
        state.current.container.offsetHeight
      );
      const line = new Line2(geometry, material);
      line.computeLineDistances();
      state.current.scene.add(line);
    }
  }

  function setupPerfs(perfs) {
    for (const perf of perfs) {
      const geom = new THREE.SphereGeometry(2, 16, 16);
      geom.translate(perf.x, perf.y, perf.z);
      const mat = new THREE.MeshBasicMaterial({
        color: 0xd1d1cf
      });
      const mesh = new THREE.Mesh(geom, mat);
      state.current.scene.add(mesh);
    }

    //add proppant lines
    for (const perf of perfs) {
      const material = new THREE.LineBasicMaterial({
        color: 0x333333
      });

      const points = [];
      const proppant = perf.value2;
      points.push(new THREE.Vector3(perf.x, perf.y - proppant * 0.5, perf.z));
      points.push(new THREE.Vector3(perf.x, perf.y + proppant * 0.5, perf.z));

      const geometry = new THREE.BufferGeometry().setFromPoints(points);

      const line = new THREE.Line(geometry, material);
      state.current.scene.add(line);
    }
  }

  function setupWell(survey) {
    const positions = [];
    const colors = [];
    const color = new THREE.Color();
    color.setHex(0x888888);
    for (const c of survey) {
      // positions
      positions.push(c.x, c.y, c.z);
      colors.push(color.r, color.g, color.b);
    }
    if (positions.length === 0) return;
    const geometry = new LineGeometry();
    //@ts-ignore
    geometry.colors = colors;
    geometry.setPositions(positions);
    geometry.setColors(colors);
    const material = new LineMaterial({
      color: 0xffffff,
      linewidth: 1, // in pixels
      vertexColors: true,
      //resolution:  // to be set by renderer, eventually
      dashed: false
    });
    material.resolution.set(
      state.current.container.offsetWidth,
      state.current.container.offsetHeight
    );
    const line = new Line2(geometry, material);
    line.computeLineDistances();
    state.current.scene.add(line);
  }

  function clearEntireSceneAndDisposeRenderer() {
    THREE.Cache.clear();
    const localState = state.current;
    if (!localState) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    localState.scene.children.forEach((mesh: any) => {
      localState.scene.remove(mesh);
      if (mesh.geometry) mesh.geometry.dispose();
      if (mesh.material) mesh.material.dispose();
      if (mesh.texture) mesh.texture.dispose();
    });

    if (localState.renderer) {
      //@ts-ignore
      localState.renderer.forceContextLoss();
      localState.renderer.domElement = null;
      //@ts-ignore
      localState.renderer.dispose();
      state.current = null;
    }
  }

  function loadSceneData(uwid) {
    if (!state.current) return;
    axios
      .get(`${dataServiceEndpoint}/frac/frac-entry-scene?uwid=${uwid}`)
      .then((response) => {
        try {
          if (!state.current) return;
          const data = response.data;
          clearScene();
          setupStages(data.stages);
          setupPerfs(data.perfs);
          setupWell(data.survey);
          addHelpers(data.minZ);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      });
    // TODO: error handling
  }

  function updateScene(uwid) {
    loadSceneData(uwid);
  }

  function addHelpers(z) {
    const helper = new THREE.GridHelper(10000, 200);
    helper.name = "GridHelper";
    helper.position.z = z;
    helper.geometry.rotateX(Math.PI / 2);
    //@ts-ignore
    helper.material.opacity = 0.25;
    //@ts-ignore
    helper.material.transparent = true;

    state.current.scene.remove(state.current.scene.getObjectByName("GridHelper"));

    state.current.scene.add(helper);

    // var axes = new THREE.AxesHelper(1000);
    // axes.position.set(-500, -500, -500);
    // state.current.scene.add(axes);
  }

  const initializeScene = useCallback(() => {
    if (containerRef.current == null) return;
    if (state.current.container == null) {
      state.current.container = containerRef.current;
      initialize3dView();
    }
  }, [state]);

  function clearScene() {
    if (!state.current) return;
    clearSceneObj(state.current.scene);
  }

  const clearSceneObj = (obj) => {
    if (typeof obj === "undefined" || !obj) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const meshes = _filter(obj.children, (child: any) => {
      return (
        child.type === "Mesh" ||
        child.type === "Line2" ||
        child.type === "Line" ||
        child.type === "LineSegments"
      );
    });
    meshes.forEach((mesh) => {
      obj.remove(mesh);
      //@ts-ignore
      if (mesh.geometry) mesh.geometry.dispose();
      //@ts-ignore
      if (mesh.material) mesh.material.dispose();
      //@ts-ignore
      if (mesh.texture) mesh.texture.dispose();
    });
  };

  useEffect(() => {
    if (!containerRef.current) return;
    initializeScene();
    clearScene();
    updateScene(uwid);
    onWindowResize();
  }, [uwid]);

  useEffect(() => {
    initializeScene();
    return () => {
      clearEntireSceneAndDisposeRenderer();
      containerRef.current = null;
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }} ref={mouseoverRef}>
      <div
        className="well_3d_stage_scene"
        style={{ width: "100%", height: "100%" }}
        ref={containerRef}
      />

      <LegendWrapper>
        <LegendItem>
          <svg width="60" height="15">
            <rect width="55" height="15" rx="15" fill="orange" />
          </svg>
          <LegendTitle>Stage</LegendTitle>
        </LegendItem>

        <LegendItem>
          <svg width="60" height="25" viewBox="0 0 60 26">
            <circle cx="30" cy="13" r="5" fill="lightgray" />
          </svg>
          <LegendTitle>Cluster</LegendTitle>
        </LegendItem>

        <LegendItem>
          <svg width="60" height="25" viewBox="0 0 60 26">
            <line x1="30" y1="0" x2="30" y2="25" stroke="gray" />
          </svg>
          <LegendTitle>Cluster Proppant</LegendTitle>
        </LegendItem>
      </LegendWrapper>

      <Toolbar mouseoverRef={mouseoverRef}>
        <FullscreenToggleButton onToggle={onFullscreenToggle} />
      </Toolbar>
    </div>
  );
}

export default WellCompletions3dView;

const LegendWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 85px;
  border: 1px solid gray;
  background: white;
  padding: 5px;
`;
const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const LegendTitle = styled.span`
  margin-left: 5px;
`;
