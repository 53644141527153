import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EvaSource, TimeStep } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import {
  selectChartSourceFeature,
  selectChartTimeStepFeature,
  updateChartByPath
} from "store/features";
import { RootState } from "store/rootReducer";
import { capitalizeFirstLetter } from "utils";

import { TimeScaleSelect, TimeStepIcon } from "components/shared";

import ToolbarButton from "../../../base/ToolbarButton";

const TimeStepToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const sourceState = useSelector((state: RootState) =>
    selectChartSourceFeature(state, id)
  );
  const timeStepState = useSelector((state: RootState) =>
    selectChartTimeStepFeature(state, id)
  );
  const source = sourceState?.properties?.value;
  const timeStep = timeStepState?.properties?.value;
  // derived state
  const icon = TimeStepIcon[timeStep as TimeStep];
  const label = capitalizeFirstLetter(timeStep);
  function handleTimeStepChange(value: TimeStep) {
    dispatch(
      updateChartByPath({
        id,
        path: `features.timeStep.properties.value`,
        value: value
      })
    );
  }
  return (
    <TimeScaleSelect
      trigger={["hover"]}
      value={timeStep as TimeStep}
      onChange={handleTimeStepChange}
      isDataSourcePublic={source === EvaSource.Public}
      isDataSourcePrivate={source === EvaSource.Private}>
      <ToolbarButton
        data-testid="timestep-trigger"
        aria-label={timeStep}
        active={false}
        icon={icon}
        overflowLabel={label}
        onToggle={null}
      />
    </TimeScaleSelect>
  );
};

export default memo(TimeStepToggle);
