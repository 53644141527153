import { memo } from "react";

import { noop } from "utils";

import { UserArps } from "components/icons";

import ToolbarButton from "../../base/ToolbarButton";

const TypewellsToggle = () => {
  const isDisabled = false;

  const toggle = noop;
  return (
    <ToolbarButton
      active={false}
      icon={<UserArps />}
      overflowLabel="Typewells"
      tooltipText="Type Wells"
      onToggle={toggle()}
      disabled={isDisabled}
    />
  );
};

export default memo(TypewellsToggle);
