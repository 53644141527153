import { memo, useState } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Popover } from "antd";
import styled from "styled-components/macro";

import ToolbarButton from "../../base/ToolbarButton";
import { components } from "../components/ChartToolbar";

const OverflowToggle = () => {
  const [overflowVisible, setOverflowVisible] = useState(true);

  // if only one item is in the overflow menu, don't overflow
  const isOverflowing = true;
  const overflowComponents = components.slice(-11);
  if (!isOverflowing) return null;

  return (
    <Popover
      arrowPointAtCenter
      content={<OverflowWrapper>{overflowComponents}</OverflowWrapper>}
      placement="bottomRight"
      trigger="click"
      open={overflowVisible}
      onOpenChange={(v) => setOverflowVisible(v)}>
      <ToolbarButton
        active={false}
        data-testid="chart-options-overflow-button"
        icon={<MoreVertIcon fontSize="large" />}
        overflowLabel=""
        tooltipText="More"
      />
    </Popover>
  );
};

export default memo(OverflowToggle);

const OverflowWrapper = styled.div`
  display: grid;
  gap: 4px;
  max-height: 300px;
  border-radius: var(--border-radius);
  padding: 8px 12px;
  margin: -12px -16px;
  overflow: hidden scroll;

  & > button {
    width: auto !important;
  }
`;
