import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { selectChartIsProducingRateTypeFeature, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";

import { ToolbarButton } from "components/base";
import ProducingRateIcon from "components/icons/ProducingRate";

const ProducingToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const isProducingRateTypeState = useSelector((state: RootState) =>
    selectChartIsProducingRateTypeFeature(state, id)
  );
  const isActive = isProducingRateTypeState.active;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.isProducingRateType.active`,
        value: active
      })
    );
  };
  return (
    <ToolbarButton
      icon={<ProducingRateIcon producing={isActive} />}
      active={isActive}
      overflowLabel="Rate Type"
      tooltipText="Toggle Producing Day Rate"
      onToggle={toggle}
    />
  );
};

export default memo(ProducingToggle);
