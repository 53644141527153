import { toast } from "react-toastify";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cookies } from "constants/settings.constants";
import { AppThunk } from "store/store";

import { loginUser, refreshToken, registerUser, signoutUser } from "api/auth";

import { IOrganization, IUser } from "models/User";

interface IAuthState {
  unmodifiedUser: IUser;
  user: IUser;
  jwtToken: string;
  tokenRefreshing: boolean;
  dataAccess: string[];
  dataSources: string[];
}

const initialState: IAuthState = {
  user: null,
  jwtToken: "",
  tokenRefreshing: false,
  dataAccess: [],
  dataSources: [],
  unmodifiedUser: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<IUser>) {
      state.user = Object.assign(state.user, action.payload);
    },
    updateUserOrganization(state, action: PayloadAction<IOrganization>) {
      state.user = Object.assign(state.user, { organization: action.payload });
    },
    setUser(state, action: PayloadAction<IUser>) {
      const user = action.payload;

      if (!user || !user.jwtToken) {
        // if no user or jwt token, clear user state
        state.user = null;
        state.unmodifiedUser = null;

        return;
      }

      state.unmodifiedUser = { ...user };

      // parse json object from base64 encoded jwt token
      const parsedToken = JSON.parse(atob(user.jwtToken.split(".")[1]));
      const newUser = { ...user, modules: parsedToken.modules };
      state.user = newUser;

      state.jwtToken = newUser.jwtToken;
      state.dataAccess = [...newUser.dataAccess];
      state.dataSources = [...newUser.dataSources];

      axios.defaults.headers.common["Authorization"] = "Bearer " + state.jwtToken;
      localStorage.setItem(cookies.LAST_LOGGED_IN, new Date().toString());
    },
    updateJwtToken(state, action: PayloadAction<string>) {
      state.jwtToken = action.payload;
      axios.defaults.headers.common["Authorization"] = "Bearer " + state.jwtToken;
      localStorage.setItem(cookies.LAST_LOGGED_IN, new Date().toString());
    },
    updateRefresingTokenLock(state, action: PayloadAction<boolean>) {
      state.tokenRefreshing = action.payload;
    },

    logout(state) {
      localStorage.removeItem(cookies.LAST_LOGGED_IN);
      state.user = null;
      state.unmodifiedUser = null;

      state.jwtToken = "";
      state.dataAccess = [];
      state.dataSources = [];
      axios.defaults.headers.common["Authorization"] = null;
    }
  }
});

export default authSlice.reducer;
export const {
  setUser,
  logout,
  updateUser,
  updateUserOrganization,
  updateJwtToken,
  updateRefresingTokenLock
} = authSlice.actions;

export const register =
  (user): AppThunk =>
  async () => {
    try {
      await registerUser(user);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

export const logoutUser = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    if (!state) {
      return;
    }
    const user = state.auth.user;
    await signoutUser(user.email);
    dispatch(logout());
  } catch (err) {
    toast.error(`error signing out. ${err.message}`);
  }
};

export const login =
  (credentials, onSuccess, onError): AppThunk =>
  async (dispatch) => {
    try {
      loginUser(
        credentials,
        (user) => {
          if (user.requiresMfa) {
            onSuccess(user);
          } else {
            dispatch(setUser(user));
            onSuccess(user);
          }
        },
        onError
      );
    } catch (err) {
      onError(err);
    }
  };

export const updateTokenFromRefreshToken =
  (username: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    if (state.auth.tokenRefreshing) {
      // other tab is already refreshing the token
      return;
    }

    const user = await refreshToken(state.auth.user.secret);

    if (user) {
      // Create a shallow copy of the user object and remove the jwtToken property
      const { jwtToken: newToken, ...userWithoutToken } = user;
      const { jwtToken: oldToken, ...unmodifiedUserWithoutToken } =
        state.auth.unmodifiedUser;

      const parsedNewToken = JSON.parse(atob(newToken.split(".")[1]));
      const parsedOldToken = JSON.parse(atob(oldToken.split(".")[1]));

      if (
        JSON.stringify(userWithoutToken) !== JSON.stringify(unmodifiedUserWithoutToken) ||
        parsedNewToken.modules !== parsedOldToken.modules
      ) {
        // only update the user object if:
        // - user info changed
        // - subcription modules changed
        dispatch(setUser(user));
      } else {
        // if the user object has not changed, update the jwt token
        dispatch(updateJwtToken(newToken));
      }
    } else {
      //sign out
      await signoutUser(username);
      dispatch(logout());
    }
  };
