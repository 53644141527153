import { useCallback } from "react";

import { ICheckedForecast } from "../../../store/features";
import { CumulativeData } from "../../forecasting/Forecasting";
import { arpsWasm } from "../utils/UpdateSegment";

interface UpdateEURParams {
  productName: string;
  cumulativeData: CumulativeData;
  arps?: ICheckedForecast;
  forecastEur: number;
}

const useUpdateEUR = () => {
  const calculateUnixTimestamp = (date: Date) => Math.floor(date.getTime() / 1000);

  const updateEUR = useCallback(
    ({ productName, cumulativeData, arps, forecastEur }: UpdateEURParams) => {
      if (!cumulativeData?.lastProductionDate || !arps) {
        return {
          eur: forecastEur.toFixed(2).toString(),
          remaining: forecastEur.toFixed(2).toString()
        };
      }

      const cumulativeProduction = cumulativeData?.[productName.toLowerCase()] ?? 0;
      const productionEndDate = new Date(cumulativeData?.lastProductionDate);
      const arpsSegments = arps.arps.filter((segment) => segment.product === productName);

      // The reason for adding a month is to account for end date being inclusive.
      productionEndDate.setMonth(productionEndDate.getMonth() + 1);
      const productionEndUnixTimestampSeconds = calculateUnixTimestamp(productionEndDate);

      let overlapForecastAndProductionVolume = 0;

      // Note: This only calculates the volume for the overlapping segments.
      arpsSegments.forEach((segment) => {
        const segmentStartUnixTimestampSeconds = calculateUnixTimestamp(
          new Date(segment.startDate)
        );
        const segmentEndUnixTimestampSeconds = calculateUnixTimestamp(
          new Date(segment.endDate)
        );

        const isSegmentFullyOverlappedByProduction =
          segmentEndUnixTimestampSeconds < productionEndUnixTimestampSeconds;
        const isSegmentPartiallyOverlappedByProduction =
          segmentStartUnixTimestampSeconds < productionEndUnixTimestampSeconds;

        if (isSegmentFullyOverlappedByProduction) {
          overlapForecastAndProductionVolume +=
            arpsWasm.calculateSegmentCumulativeVolumeOverDays(
              segment,
              BigInt(segmentStartUnixTimestampSeconds),
              BigInt(segmentEndUnixTimestampSeconds)
            );
        } else if (isSegmentPartiallyOverlappedByProduction) {
          overlapForecastAndProductionVolume +=
            arpsWasm.calculateSegmentCumulativeVolumeOverDays(
              segment,
              BigInt(segmentStartUnixTimestampSeconds),
              BigInt(productionEndUnixTimestampSeconds)
            );
        }
      });

      overlapForecastAndProductionVolume = overlapForecastAndProductionVolume / 1000;
      const remainingForecastVolume = forecastEur - overlapForecastAndProductionVolume;
      const ctdPlusRemaining = cumulativeProduction + remainingForecastVolume;
      return {
        eur: ctdPlusRemaining.toFixed(2).toString(),
        remaining: remainingForecastVolume.toFixed(2).toString()
      };
    },
    []
  );
  return { updateEUR };
};

export default useUpdateEUR;
