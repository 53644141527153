export enum SlopeType {
  Time = 0,
  Volume = 1
}

export enum ProductTypeEnum {
  Oil = 1,
  Gas = 2,
  Water = 3,
  OilPlusWater = 4,
  GOR = 5,
  CGR = 6,
  WGR = 7,
  OGR = 8,
  WOR = 9,
  OnePlusWOR = 10,
  Cond = 14,
  Condensate = 14,
  OilCut = 15,
  WaterCut = 16,
  Unknown = 20,
  SalesGas = 30,
  C2 = 31,
  C3 = 32,
  C4 = 33,
  C5 = 34,
  Shrinkage = 39,
  GasEnergy = 56,
  HeatingValue = 57, //gas energy yields sales
  OnTime = 13,
  C2YieldSales = 48,
  C3YieldSales = 49,
  C4YieldSales = 50,
  C5YieldSales = 51
}

export enum ProductTypeEnumAllCaps {
  OIL = 1,
  GAS = 2,
  WATER = 3,
  OILPLUSWATER = 4,
  GOR = 5,
  CGR = 6,
  WGR = 7,
  OGR = 8,
  WOR = 9,
  ONEPLUSWOR = 10,
  COND = 14,
  CONDENSATE = 14,
  Unknown = 20,
  SALESGAS = 30,
  C2 = 31,
  C3 = 32,
  C4 = 33,
  C5 = 34,
  SHRINKAGE = 39
}
