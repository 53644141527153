import { memo } from "react";

import InfoIcon from "@material-ui/icons/Info";
import { Divider, InputNumber, Space, Switch, Typography } from "antd";
import { ALL_CHART_TYPES, ALL_PRODUCT_TYPES } from "constants/chart.constants";
import styled from "styled-components/macro";

import { LabeledSwitch, Tooltip } from "components/base";

import { IChartSettings } from "../../../../models/chart";
import { useChartDispatch, useChartState } from "../../context";

const { Text } = Typography;

const SettingsOptions = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();
  const isScatterChart =
    settings.chartType === ALL_CHART_TYPES.Probit.label ||
    settings.chartType === ALL_CHART_TYPES.CrossPlot.label ||
    (settings.chartType === ALL_CHART_TYPES.BoxPlot.label && settings.showScatter);

  const isPressureChart =
    settings.product === ALL_PRODUCT_TYPES.CasingPressure.label ||
    settings.product === ALL_PRODUCT_TYPES.TubingPressure.label;

  const {
    cutoff,
    shutInMonths,
    lockUnits,
    survivorBias,
    useWeightedRatioAverage,
    showLinesOfBestFit,
    dotSize,
    dotOpacity,
    movingAverageDays,
    pressureChartSettings
  } = settings;

  const handleChange = (key: keyof IChartSettings) => (v) => {
    const nextValue = { ...settings, [key]: v };
    dispatch({ type: "settings", payload: nextValue });
  };

  const updateProductivityIndex = (v) => {
    const nextValue = {
      ...settings,
      pressureChartSettings: {
        ...pressureChartSettings,
        productivityIndex: v
      }
    };
    dispatch({ type: "settings", payload: nextValue });
  };
  return (
    <StyledSpace direction="vertical" split={<StyledDivider />}>
      <Container>
        <Text>Cut Off (%)</Text>
        <Tooltip title="Calculate average until well count below cutoff percent">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={cutoff}
          min={0}
          max={100}
          controls={false}
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleChange("cutoff")(val);
          }}
        />
      </Container>
      <Container>
        <Text>Shut In Months</Text>
        <Tooltip title="Number of non producing months to be considered shut-in. Shut-in months are removed from historical production.">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={shutInMonths}
          min={0}
          max={20}
          controls={false}
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleChange("shutInMonths")(val);
          }}
        />
      </Container>

      <Container>
        <Text>Moving Average Days</Text>
        <Tooltip title="The number of days to use to calculate the simple moving average.">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          type="number"
          value={movingAverageDays}
          min={0}
          max={settings.timeStep === "day" ? 100 : 365}
          controls={false}
          onChange={(val: string | null | number) => {
            if (val === null || val === "") {
              return;
            }
            handleChange("movingAverageDays")(val);
          }}
        />
      </Container>

      {isScatterChart && (
        <>
          <Container>
            <Text>Point Size</Text>
            <Tooltip title="Size of dots">
              <StyledIcon />
            </Tooltip>
            <StyledInput
              value={dotSize}
              min={0}
              max={100}
              controls={false}
              onChange={handleChange("dotSize")}
            />
          </Container>
          <Container>
            <Text>Point Opacity</Text>
            <Tooltip title="Adjust the focused opacity">
              <StyledIcon />
            </Tooltip>
            <StyledInput
              value={dotOpacity}
              min={0}
              max={1}
              step={0.1}
              controls={false}
              onChange={handleChange("dotOpacity")}
            />
          </Container>
        </>
      )}

      <LabeledSwitch
        testId="survivorBiasCB"
        label={{
          value: "Prevent Survivor Bias",
          tooltip: "Allow wells that complete early to continue to affect average"
        }}
        switch={{
          isChecked: survivorBias,
          onChange: handleChange("survivorBias")
        }}
      />

      <LabeledSwitch
        testId="weightedRatioCB"
        label={{
          value: "Weighted Ratio",
          tooltip: "Cumulative numerator/cumulative denominator"
        }}
        switch={{
          isChecked: useWeightedRatioAverage,
          onChange: handleChange("useWeightedRatioAverage")
        }}
      />

      <LabeledSwitch
        testId="lockUnits"
        label={{
          value: "Lock Rate Units",
          tooltip:
            "Locking rate units will keep the units on the y-axis constant, rather than switching between bbl/d and Mbbl/d, for example"
        }}
        switch={{
          isChecked: lockUnits,
          onChange: handleChange("lockUnits")
        }}
      />

      {settings.chartType === ALL_CHART_TYPES.Probit.label && (
        <Container>
          <Text>Show Lines of Best Fit</Text>
          <RightSwitch
            data-testid="linesOfBestFit"
            size="small"
            checked={showLinesOfBestFit}
            onChange={handleChange("showLinesOfBestFit")}
          />
        </Container>
      )}

      {isPressureChart && (
        <Container>
          <Text>Productivity Index</Text>
          <Tooltip title="Plot the productivity index instead of the selected pressure.">
            <StyledIcon />
          </Tooltip>
          <Switch
            size="small"
            checked={pressureChartSettings.productivityIndex}
            onChange={updateProductivityIndex}
          />
        </Container>
      )}
    </StyledSpace>
  );
};

export default memo(SettingsOptions);

const StyledSpace = styled(Space)`
  --tooltip: var(--color-text);
  width: 260px;
  display: flex;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled(InfoIcon)`
  color: #a2aaad;
  margin-right: auto;
`;

const StyledInput = styled(InputNumber)`
  width: 70px;
  border-radius: var(--border-radius);
`;

const RightSwitch = styled(Switch)`
  margin-left: auto;
`;
