export enum ChartOption {
  Chart = "chartOptions",

  XAxis = "xAxisOptions",
  YAxis = "yAxisOptions",
  Tooltip = "tooltipOptions",
  Title = "titleOptions",

  LineSeries = "lineOptions",
  Bar = "barOptions",
  Pie = "pieOptions",
  ScatterSeries = "scatterOptions"
}
