import { memo, useState } from "react";

import { Popover } from "antd";

import { ToolbarButton } from "components/base";
import { LegendFilled } from "components/icons";
import { useChartSettings } from "components/multiphase-chart/context";

import Legend from "./Legend";

const LegendToggle = () => {
  const [visible, setVisible] = useState(false);
  const [chartContext] = useChartSettings();

  const onToggleHandle = (value) => {
    setVisible(value);
  };
  return (
    <Popover
      arrowPointAtCenter
      content={<Legend />}
      placement="bottomRight"
      trigger="click"
      open={visible}
      onOpenChange={onToggleHandle}>
      <ToolbarButton
        testId="multiphasechart-toolbar-legend-toggle"
        active={chartContext.legend.visible}
        icon={<LegendFilled style={{ width: "30px", height: "30px" }} />}
        isMenuButton={true}
        overflowLabel="Legend"
        tooltipText="Legend"
      />
    </Popover>
  );
};

export default memo(LegendToggle);
