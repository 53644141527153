import axios from "axios";
import { encrypt } from "utils";
import { broadcastMessage, broadcastTypes } from "utils/broadcast";

import { IMfaRequired, IUser } from "models/User";

const userServiceRoot = process.env.REACT_APP_USER_SERVICE + "/user";
const endpoint_email_auth = `${userServiceRoot}/auth`;

export async function registerUser(user) {
  const response = await axios.post(userServiceRoot, user);
  const data = response.data;
  return data;
}
export function loginUser(credentials, onSuccess, onError) {
  axios
    .post(endpoint_email_auth, credentials, {
      withCredentials: true
    })
    .then((response) => onSuccess(response.data))
    .catch(onError);
  return;
}

export function sendPasswordResetEmail(email, onSuccess, onError) {
  axios
    .post(`${userServiceRoot}/reset-password-email`, { email })
    .then((response) => onSuccess(response.data))
    .catch(onError);

  return;
}
export function sendPasswordReset(token, password, onSuccess, onError) {
  axios
    .post(`${userServiceRoot}/reset-password`, { token, newPassword: password })
    .then((response) => onSuccess(response.data))
    .catch(onError);

  return;
}
export async function signoutUser(username) {
  try {
    const response = await axios.post(
      `${userServiceRoot}/${username}/sign-out`,
      {},
      {
        withCredentials: true
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
}

// local lock to prevent multiple refreshes on same tab
let isRefreshing = false;
let refreshPromise: Promise<IUser | null> | null = null;

export async function refreshToken(secret: string): Promise<IUser> {
  if (isRefreshing) {
    // If a refresh is already in progress, fallback to the existing promise
    return refreshPromise;
  }

  // lock the refresh for current tab
  isRefreshing = true;
  // lock the refresh for other tabs
  broadcastMessage(broadcastTypes.TOKEN_REFRESHING, { tokenRefreshing: isRefreshing });

  refreshPromise = (async () => {
    try {
      const response = await axios.post(
        `${userServiceRoot}/refresh-token`,
        {},
        {
          withCredentials: true
        }
      );

      if (response.status !== 200) {
        return null;
      }

      if (secret) {
        // don't broadcast the token if the secret is not available
        const enData = await encrypt(JSON.stringify(response.data), secret);

        // Broadcast the updated token to other tabs
        broadcastMessage(broadcastTypes.TOKEN_UPDATED, enData);
      }

      return response.data;
    } catch (error) {
      return null;
    } finally {
      // Ensure the lock is released regardless of whether the refresh succeeds or fails
      isRefreshing = false;
      broadcastMessage(broadcastTypes.TOKEN_REFRESHING, {
        tokenRefreshing: isRefreshing
      });
    }
  })();

  return refreshPromise;
}

export async function revokeRefreshToken() {
  try {
    return axios.post(
      `${userServiceRoot}/revoke-refresh-token`,
      {},
      {
        withCredentials: true
      }
    );
  } catch (err) {
    return null;
  }
}

export enum MfaDeliveryMethod {
  Email = 0,
  Sms = 1,
  App = 2
}
export interface IMfaPayload {
  requestId: string;
  code: string;
  deliveryMethod: MfaDeliveryMethod;
}

export enum ReCAPTCHAVersion {
  V2 = 0,
  V3 = 1
}

export async function authenticateMfa(payload: IMfaPayload, onError): Promise<IUser> {
  try {
    const response = await axios.post(`${userServiceRoot}/validate-mfa-code`, payload, {
      withCredentials: true
    });
    if (response.status !== 200) {
      onError && onError(response);
      return null;
    }
    return response.data;
  } catch (error) {
    onError && onError(error);
    return null;
  }
}

export async function requestMfaCode(
  payload: IMfaPayload,
  onError
): Promise<IMfaRequired> {
  try {
    const response = await axios.post(`${userServiceRoot}/resend-mfa-code`, payload);
    if (response.status !== 200) {
      onError && onError(response);
      return null;
    }
    return response.data;
  } catch (error) {
    onError && onError(error);
    return null;
  }
}
