import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMutation, useQuery } from "@apollo/client";
import { TYPE_WELLS } from "constants/settings.constants";
import {
  setCheckedGlobalTypeWells,
  setCheckedKeys,
  setHasTypeWellSaveSucceeded,
  setIsTypeWellSavable,
  setSelectedTypeWell
} from "store/features/arps/arpsSlice";
import { RootState } from "store/rootReducer";
import { toExpectedBackendProductName } from "utils/arps/productMapping";
import { v4 as uuidv4 } from "uuid";

import { FOLDERS, SAVE_FORECAST } from "api/userArps";

import { UserArpsItem } from "models/UserArpsModel";

import { SaveResult } from "components/project/forecasts/ProjectForecasts";
import { useSelectedProject } from "components/project/projects/hooks";
import { useWellList } from "components/well-list/context";

import { MoveTypeWellInput } from "../../../models/projects";
import "../context/reducer/arpsReducer";
import { convertConstantsToDatabaseUnits } from "../utils/convertConstantsToDatabaseUnits";
import useTypeWellUpdater from "./useTypeWellUpdater";

export default function useTypeWellSaver(undoRedoManager) {
  const dispatch = useDispatch();

  const isTypeWellSavable = useSelector(
    (state: RootState) => state.arps.isTypeWellSavable
  );
  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);

  const checkedGlobalTypeWells = useSelector(
    (state: RootState) => state.arps.checkedGlobalTypeWells
  );

  // Will possible  need to refactor to global state for popup to work
  const [wellListState] = useWellList();
  const { changeSelectedTypeWell } = useTypeWellUpdater(undoRedoManager);

  const { selectedProject: project } = useSelectedProject();

  const [saveForecast, status] = useMutation<SaveResult>(SAVE_FORECAST, {
    refetchQueries: ["changeRecords"]
  });

  const { refetch } = useQuery(FOLDERS, {
    variables: {
      req: {
        projectId: project?.projectId,
        type: TYPE_WELLS
      }
    },
    skip: !project?.projectId
  });

  useEffect(() => {
    if (isTypeWellSavable) {
      dispatch(setHasTypeWellSaveSucceeded(false));
    }
  }, [isTypeWellSavable]);

  async function onCloneTypeWell(
    newTypeWellTitle: string,
    moveTypeWellToProjectInput?: MoveTypeWellInput
  ) {
    const id = uuidv4();
    const result = await saveTypeWellInternal(
      selectedTypeWell,
      id,
      newTypeWellTitle,
      !!selectedTypeWell?.source,
      moveTypeWellToProjectInput
    );

    await refetch({
      req: {
        projectId: project?.projectId,
        type: TYPE_WELLS
      }
    });

    const tw: UserArpsItem = {
      ...selectedTypeWell,
      id: id,
      key: id,
      uniqueID: id,
      title: newTypeWellTitle,
      source: null
    };

    if (moveTypeWellToProjectInput) {
      changeSelectedTypeWell(null);
    } else {
      dispatch(
        setCheckedKeys({
          type: TYPE_WELLS,
          checkedKeys: [id]
        })
      );
      dispatch(setCheckedGlobalTypeWells([tw]));
      changeSelectedTypeWell(tw);
    }

    dispatch(setIsTypeWellSavable(result?.data.saveForecast !== "ok"));
    dispatch(setHasTypeWellSaveSucceeded(result?.data.saveForecast === "ok"));
  }

  async function onSaveTypeWell(tw?: UserArpsItem, newTypeWellTitle?: string) {
    // Update currently selected type well title on chart.
    if (tw && newTypeWellTitle) {
      const typeWellWithUpdatedTitle = {
        ...tw,
        title: newTypeWellTitle
      };

      const updatedGlobalTypeWells = checkedGlobalTypeWells.map((typeWell) => {
        if (typeWell.id === typeWellWithUpdatedTitle.id) {
          return typeWellWithUpdatedTitle;
        }
        return typeWell;
      });

      dispatch(setCheckedGlobalTypeWells(updatedGlobalTypeWells));
      // A type well can be renamed without being selected or checked.
      // If it's selected it can show up in the type well widget (if open).
      if (selectedTypeWell?.id === tw.id) {
        dispatch(
          setSelectedTypeWell({
            ...selectedTypeWell,
            title: newTypeWellTitle
          })
        );
      }
    }

    const result = await saveTypeWellInternal(tw, null, newTypeWellTitle);

    await refetch({
      req: {
        projectId: project?.projectId,
        type: TYPE_WELLS
      }
    });

    dispatch(setIsTypeWellSavable(result?.data.saveForecast !== "ok"));
    dispatch(setHasTypeWellSaveSucceeded(result?.data.saveForecast === "ok"));
  }

  async function saveTypeWellInternal(
    tw?: UserArpsItem,
    id?: string,
    newTypeWellTitle?: string,
    isSync?: boolean,
    moveTypeWellToProjectInput?: MoveTypeWellInput
  ) {
    const projectId = project?.projectId;
    const data = tw ?? selectedTypeWell;
    const folderId = data?.folderId;

    const wellList = wellListState.wells.map((x) => x.id) ?? [];
    const title = newTypeWellTitle ?? tw?.title ?? selectedTypeWell.title;
    const rescat =
      tw != null && tw.id != selectedTypeWell?.id
        ? data.reserveCategory
        : selectedTypeWell.reserveCategory;

    const forecast = {
      id: id ?? data.id,
      owner: "",
      uniqueID: id ?? data.uniqueID,
      // get the updated title from input
      name: title,
      arps: data.arps.map((seg) => {
        //convert the date string to ISO format that backend is expected
        let date;
        try {
          date = new Date(Date.parse(seg.startDate)).toISOString();
        } catch {
          date = seg.startDate;
        }
        return {
          ...seg,
          // user-arps service currently requires both b and n values.
          n: seg.b,
          startDate: date,
          product: toExpectedBackendProductName(seg.product)
        };
      }),
      constants: convertConstantsToDatabaseUnits(data.constants).map((fc) => ({
        ...fc,
        product: toExpectedBackendProductName(fc.product)
      })),
      wellData: data.wellData,
      folderId: folderId,
      reserveCategory: rescat,
      createdDate: new Date().toISOString(),
      source: isSync ? null : data.source
    };
    const input = {
      projectId,
      folderId,
      type: TYPE_WELLS,
      forecast,
      wellList,
      moveProject: moveTypeWellToProjectInput
    };
    return saveForecast({
      variables: {
        input
      }
    });
  }

  return { onSaveTypeWell, onCloneTypeWell, saveStatus: status };
}
