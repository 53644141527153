import { useCallback } from "react";

import axios from "axios";
import { PAD_SCENARIO_SERVICE_ADDRESS } from "constants/app.constants";

import { useProjectContext } from "components/project/projects/context";

import { Scenario } from "../models/scenario";

const API_URL = `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario`;

const useExportScenarios = () => {
  const { selectedProjectId } = useProjectContext();
  const exportScenarios = useCallback(async () => {
    const response = await axios.get<Scenario[]>(
      `${API_URL}/${selectedProjectId}/export`
    );
    return response.data;
  }, []);

  return { exportScenarios };
};

export default useExportScenarios;
