import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import styled from "styled-components";

import ScreenshotActivity from "components/screenshot/ScreenshotActivity";
import { useScreenshotContext } from "components/screenshot/hooks";

import { ChartSettingsProvider } from "../../context";
import { MultiPhaseChart } from "./Chart";

function SingleComponentMultiphaseChart() {
  // state from store
  const channelId = useSelector((state: RootState) => state.channel.channel);
  const user = useSelector((state: RootState) => state.auth.user);

  // context from hooks
  const { widget } = useScreenshotContext();

  // session storage data
  const channelData = sessionStorage.getItem(channelId);

  // derived state
  const sessionData = channelData ? JSON.parse(channelData) : null;
  const initialState = sessionData ? sessionData.chartSettings : null;

  return (
    <Wrapper>
      {widget ? (
        <ActivityWrapper>
          <ScreenshotActivity />
        </ActivityWrapper>
      ) : (
        <div></div>
      )}
      <ChartSettingsProvider initialState={{ ...initialState, isReadOnly: true }}>
        {user && <StyledChartWrapper id="custom" />}
      </ChartSettingsProvider>
    </Wrapper>
  );
}

export default SingleComponentMultiphaseChart;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
  width: 100%;
`;

const ActivityWrapper = styled.div`
  border-right: 1px solid rgba(var(--color-text-rgb), 0.1);
  width: 359px;
`;

const StyledChartWrapper = styled(MultiPhaseChart)`
  .ChartPlot.screenshot {
    position: relative;
    top: 60px;
    min-height: auto;
    min-width: auto;
    max-width: none;
    max-height: none;
  }
`;
