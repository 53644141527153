import { noop } from "utils";

import { NormalizeByToggle, NormalizeByWrapper } from "components/filter";

const Normalize = () => {
  const updateChartSettings = noop;

  return (
    <NormalizeByWrapper>
      <NormalizeByToggle checked={false} onToggle={updateChartSettings()} />

      {/* {false && (
        // <NormalizeBySettings
        //   value={}
        //   onChange={updateChartSettings("normalizeBy")}
        // />
      )} */}
    </NormalizeByWrapper>
  );
};

export default Normalize;
