export const EPSILON = 1e-10;

/**
 * A small constant representing a value very close to zero.
 * Used for floating-point comparisons to handle precision issues.
 */

/**
 * Rounds a number to a specified number of decimal places.
 *
 * @param n - The number to round
 * @param decimalPlaces - The number of decimal places to round to (non-negative integer)
 * @returns The rounded number
 * @throws Error if decimalPlaces is negative, not an integer, or if n is not a finite number
 */
export function roundTo(n: number, decimalPlaces: number): number {
  if (decimalPlaces < 0) {
    throw new Error("decimalPlaces cannot be negative");
  }
  if (!Number.isFinite(n)) {
    throw new Error("Input must be a finite number");
  }
  if (!Number.isInteger(decimalPlaces)) {
    throw new Error("decimalPlaces must be an integer");
  }
  // Limit decimalPlaces to a reasonable maximum (e.g., 15)
  // to avoid floating-point precision issues
  if (decimalPlaces > 15) {
    decimalPlaces = 15;
  }
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(n * factor) / factor;
}

/**
 * Compares two numbers to determine if they are approximately equal within a specified tolerance.
 *
 * This function is useful when comparing floating-point numbers where exact equality
 * may fail due to rounding errors and precision limitations.
 *
 * @param n1 - The first number to compare
 * @param n2 - The second number to compare
 * @param epsilon - The maximum allowable difference between the numbers
 * @returns True if the numbers are within epsilon of each other, false otherwise
 * @throws Error if any input is not a finite number or if epsilon is negative
 */
export function areApproximatelyEqual(n1: number, n2: number, epsilon: number): boolean {
  if (!Number.isFinite(n1) || !Number.isFinite(n2)) {
    return false;
  }
  if (!Number.isFinite(epsilon) || epsilon < 0) {
    return false;
  }
  // Check if the absolute difference between numbers is less than epsilon
  return Math.abs(n1 - n2) < epsilon * Math.max(1.0, Math.abs(n1), Math.abs(n2));
}

/**
 * Determines if a number is approximately less than or equal to another number within a specified tolerance.
 *
 * This function returns true if n1 is either clearly less than n2 or approximately equal to n2.
 * It helps compare floating-point values where precision issues might affect the comparison.
 *
 * @param n1 - The first number to compare (the one being tested if it's less than or equal)
 * @param n2 - The second number to compare against
 * @param epsilon - The tolerance threshold for the comparison
 * @returns True if n1 is approximately less than or equal to n2 within the specified epsilon, false otherwise
 * @throws Error if any input is not a finite number or if epsilon is negative
 */
export function isApproximatelyLessThan(
  n1: number,
  n2: number,
  epsilon: number
): boolean {
  if (!Number.isFinite(n1) || !Number.isFinite(n2)) {
    throw new Error("Inputs must be finite numbers");
  }
  if (!Number.isFinite(epsilon) || epsilon < 0) {
    throw new Error("Epsilon must be a positive finite number");
  }
  if (Math.abs(n1 - n2) <= epsilon) {
    return false;
  }

  // If not approximately equal, check if strictly less
  return n1 < n2;
}
