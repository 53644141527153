export function getP10P90Percentile(array: (number | null)[]): number[] {
  // Remove all null items from the array
  const filteredArray = array.filter((value) => value != null);

  if (!filteredArray?.length) {
    return [];
  }

  filteredArray.sort((a, b) => a - b);

  const upperPercentileIndex = Math.round(0.9 * (filteredArray.length - 1));
  const lowerPercentileIndex = Math.round(0.1 * (filteredArray.length - 1));
  const upperPercentileValue = filteredArray[upperPercentileIndex];
  const lowerPercentileValue = filteredArray[lowerPercentileIndex];

  return [lowerPercentileValue, upperPercentileValue];
}

/**
 * Returns the input if it's an array, otherwise returns an empty array
 * @param input The value to check
 * @returns The original array or an empty array
 */
export function arrayOrEmpty<T>(input: unknown): T[] {
  if (Array.isArray(input)) {
    return input as T[];
  }
  return [];
}
