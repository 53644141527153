import { memo, useState } from "react";

import { Popover } from "antd";

import SeriesStyleSelector from "components/chart/SeriesStyleSelector";
import StatsIcon from "components/icons/Stats";

import ToolbarButton from "../../../base/ToolbarButton";

const AverageSeriesToggle = () => {
  const [isVisible, setIsVisible] = useState(false);
  const isDisabled = false;

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      open={isVisible}
      onOpenChange={(v) => setIsVisible(v)}
      content={<SeriesStyleSelector isVisible={isVisible} />}>
      <ToolbarButton
        active={false}
        icon={<StatsIcon />}
        isMenuButton={true}
        overflowLabel={"Statistics"}
        tooltipText={"Show Statistics"}
        disabled={isDisabled}
      />
    </Popover>
  );
};

export default memo(AverageSeriesToggle);
