// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiDragVerticalVariant } from "@mdi/js";

import { ThreeDViewerOptionT } from "models";

import { ToolbarButton } from "components/base";

function ThreeDVVerticalWellToggle(options: ThreeDViewerOptionT) {
  const handleToggle = (active: boolean) => {
    options.onOptionChange && options.onOptionChange({ showVertical: active }, true);
  };

  return (
    <ToolbarButton
      icon={<Icon path={mdiDragVerticalVariant} size={1.5} />}
      active={options.showVertical}
      tooltipText={"Show Vertical Wells"}
      overflowLabel={"Vertical Wells"}
      onToggle={handleToggle}
      styleKey="threeDVToolbar"
    />
  );
}

export default ThreeDVVerticalWellToggle;
