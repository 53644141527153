import { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Divider, Form, Select } from "antd";
import { cookies } from "constants/settings.constants";
import Cookies from "js-cookie";
import { login as loginUser, setUser } from "store/features/auth/authSlice";
import styled from "styled-components";
import { checkEmail } from "utils";

import { ReCAPTCHAVersion } from "api/auth";
import useImpersonateMutation from "api/useImpersonateMutation";
import useOrganizations from "api/useOrganization";
import useUsers from "api/useUsers";

import { IMfaRequired, IUser } from "models/User";

import { BaseButton, BaseCheckboxInput, BaseInput, Heading } from "components/base";
import FieldErrorMessage from "components/base/FieldErrorMessage";
import PasswordInput from "components/base/PasswordInput";
import { IconSpinner } from "components/icons";
import { initialUserState, useUserContext } from "components/user/context";
import { updateSettings } from "components/user/context/reducer";

import "./Login.scss";

const reCaptchaV2SiteKey = "6LeaXGEpAAAAAAjCrAWqP9SND1z40218D75V_Xth";

export default function Login() {
  const data = {
    title: "Sign in",
    emailLabel: "Email address",
    passwordLabel: "Your password",
    buttonLabel: "Login"
  };
  const dispatch = useDispatch();
  const [, userDispatch] = useUserContext();
  const [canImpersonate, setCanImpersonate] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRemember] = useState(Cookies.get(cookies.LOGIN_EMAIL));
  const [errors, setErrors] = useState("");
  const [isRecaptchaError, setIsRecaptchaError] = useState(false);
  const [isBusy, setBusy] = useState(false);
  const history = useHistory();
  const [token, setToken] = useState("");
  const [selectedOrg, setSelectedOrg] = useState<string>("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [mfa] = useState("");
  const [impersonateRequestId, setImpersonateRequestId] = useState("");
  const [recaptchaV2Token, setRecaptchaV2Token] = useState("");
  const {
    data: organizations,
    isLoading: isOrgLoading,
    refetch: refetchOrg
  } = useOrganizations(token);

  const {
    data: usersInOrg,
    isLoading: isUserLoading,
    refetch: refetchUsers
  } = useUsers(token, selectedOrg);

  const impersonateMutation = useImpersonateMutation(
    (user: IUser) => {
      dispatch(setUser(user));
      history.push("/app");

      // reset the users settings.
      updateSettings(userDispatch, initialUserState.settings);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (err: any) => {
      if (err.response.data) {
        setErrors(err.response.data);
      } else {
        setErrors(
          "Unable to connect to backend service. Please try again at a later time."
        );
      }
    }
  );

  function checkForm() {
    if (!checkEmail(email)) {
      setErrors("Please enter a valid email");
    }
  }

  useEffect(() => {
    const storedEmail = Cookies.get(cookies.LOGIN_EMAIL);
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    refetchUsers && refetchUsers();
  }, [selectedOrg]);

  function onRecaptchaCheck(value) {
    setRecaptchaV2Token(value);
  }

  const login = useCallback(
    async (event) => {
      event.preventDefault();
      checkForm();
      setErrors("");

      if (!executeRecaptcha) {
        setErrors("reCAPTCHA is loading, try again.");
        return;
      }

      // generating reCAPTCHA token
      const recaptchaV3Token = await executeRecaptcha("TuringRecaptchaAction");

      setBusy(true);

      dispatch(
        loginUser(
          {
            email: email,
            password: password,
            // fallback on v2 if v3 is failing
            token: isRecaptchaError ? recaptchaV2Token : recaptchaV3Token,
            version: isRecaptchaError ? ReCAPTCHAVersion.V2 : ReCAPTCHAVersion.V3
          },
          (data: IMfaRequired | IUser) => {
            setBusy(false);

            if (rememberMe) Cookies.set(cookies.LOGIN_EMAIL, email);
            else Cookies.remove(cookies.LOGIN_EMAIL);

            if (data.requiresMfa) {
              const mfaResponse = data as IMfaRequired;
              history.push(
                `/mfa-login?token=${mfaResponse.requestId}&method=${mfaResponse.deliveryMethod}`
              );
            } else {
              const authResponse = data as IUser;
              if (data.canImpersonate && authResponse) {
                setCanImpersonate(true);
                setImpersonateRequestId(authResponse.impersonateRequestId);
                setToken(data.jwtToken);
                //give some time for token to update
                setTimeout(() => refetchOrg(), 100);
                return;
              }
              setCanImpersonate(false);
              history.push("/app");
            }
          },
          (error) => {
            if (error.response?.data?.errorMessage) {
              setErrors(error.response.data.errorMessage);
              if (
                error.response.data.errorMessage ==
                "reCAPTCHA verification failed, please try again."
              ) {
                setIsRecaptchaError(true);
              }
            } else {
              setErrors(
                "Unable to login, please check that the email and password is valid."
              );
              setIsRecaptchaError(false);
            }
            setBusy(false);
          }
        )
      );
    },
    [executeRecaptcha, email, isRecaptchaError, password, recaptchaV2Token]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function impersonate(values: any) {
    impersonateMutation.mutate({
      ...values,
      impersonateRequestId: impersonateRequestId,
      token: token
    });
  }

  if (canImpersonate && (isOrgLoading || isUserLoading)) {
    return <div>Loading...</div>;
  }
  return (
    <div className="content-container">
      <div>
        <Heading className="align-center large" element="h3">
          {data.title}
        </Heading>
        {!canImpersonate && (
          <form name="login" className="login-form" onSubmit={login}>
            <fieldset className="fieldset">
              <BaseInput
                type="email"
                value={email}
                label={data.emailLabel}
                required
                onChange={setEmail}
                placeholder={"email@gmail.com"}
              />
              <PasswordInput
                value={password}
                label={data.passwordLabel}
                onChange={setPassword}
                actionButton={"Forgot Password?"}
                onActionClick={() => {
                  history.push("/forgot-password");
                }}
                required
              />
              <div className="flex">
                <BaseCheckboxInput
                  label="Remember me"
                  value={rememberMe}
                  onChange={setRemember}
                />
              </div>
              {isRecaptchaError && (
                <ReCAPTCHA sitekey={reCaptchaV2SiteKey} onChange={onRecaptchaCheck} />
              )}
              <div className="flex items-center justify-center">
                <BaseButton
                  type="submit"
                  appearance="primary"
                  className="justify-end login-btn">
                  {data.buttonLabel}
                </BaseButton>
              </div>
            </fieldset>
            <div className="flex items-center justify-center">
              {errors.length > 0 && <FieldErrorMessage message={errors} />}
            </div>
          </form>
        )}

        {canImpersonate && (
          <ImpersonateContainer>
            <Button type="primary" onClick={() => history.push("/app")}>
              Login as Myself
            </Button>
            <Divider />
            <ImpersonateSelector>
              <h5>Impersonate</h5>
              <Form layout="vertical" onFinish={impersonate}>
                <Form.Item name="organizationId" label="Organization">
                  <Select
                    showSearch
                    loading={isOrgLoading}
                    optionFilterProp="label"
                    onChange={(val) => {
                      setSelectedOrg(val);
                    }}
                    options={organizations.map((org) => {
                      return { label: org.name, value: org.id };
                    })}></Select>
                </Form.Item>
                <Form.Item name="userId" label="User">
                  <Select
                    showSearch
                    loading={isUserLoading}
                    optionFilterProp="label"
                    options={(usersInOrg ?? []).map((user) => {
                      return {
                        label: `${user.firstName} ${user.lastName} - ${
                          user.role === 1
                            ? "Standard"
                            : user.role === 2
                            ? "Admin"
                            : user.role === 4
                            ? "Power User"
                            : "unknown"
                        }`,
                        value: user.id
                      };
                    })}></Select>
                </Form.Item>
                <Form.Item
                  name="mfa"
                  label="MFA Code"
                  rules={[
                    { required: true, message: "Must enter MFA code to impersonate" }
                  ]}>
                  <BaseInput value={mfa} required />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Impersonate
                  </Button>
                </Form.Item>
              </Form>
              <div className="flex items-center justify-center">
                {errors.length > 0 && <FieldErrorMessage message={errors} />}
              </div>
            </ImpersonateSelector>
          </ImpersonateContainer>
        )}
        <div className={`busy-indicator ${!isBusy ? "hidden" : ""}`}>
          <IconSpinner></IconSpinner>
        </div>
      </div>
    </div>
  );
}

const ImpersonateContainer = styled.div`
  .ant-btn {
    border-radius: 6px;
  }
`;

const ImpersonateSelector = styled.div``;
