import { useSelector } from "react-redux";

import { useMutation, useQuery } from "@apollo/client";
import { TYPE_WELLS } from "constants/settings.constants";
import { RootState } from "store/rootReducer";

import { FOLDERS, UPDATE_FOLDER_NAME } from "api/userArps";

import { ForecastFolder, ForecastFolderItem } from "models/UserArpsModel";

import { useSelectedProject } from "components/project/projects/hooks";

import { DUPLICATE_FOLDER_ERROR, EMPTY_FOLDER_ERROR } from "../ProjectForecasts";
import { useForecastContext, useForecastDispatch } from "../context";
import { getNewFolderName } from "../utils";

/**
 * This hook is used to add, and edit the list of temporary forecast folders as they're being created
 *
 */
export function useAddForecastFolderList(type, location) {
  const { temporaryForecastFolderList, forecastFolderNameFieldMap } =
    useForecastContext();
  const forecastDispatch = useForecastDispatch();

  const [updateFolderName] = useMutation(UPDATE_FOLDER_NAME);

  const { selectedProject: project } = useSelectedProject();

  const { refetch } = useQuery(FOLDERS, {
    variables: {
      req: {
        projectId: project?.projectId,
        type: type
      }
    },
    skip: !project?.projectId
  });

  const forecastFoldersState = useSelector(
    (state: RootState) => state.arps.forecastFolders
  );
  const typeWellFolders = useSelector((state: RootState) => state.arps.typeWellFolders);
  const forecastFolders = type === TYPE_WELLS ? typeWellFolders : forecastFoldersState;

  function validateUniqueNames() {
    const existingFolders = forecastFolders.map((folder) => ({
      name: folder.title,
      parentId: folder.parentId
    }));
    const newNames = Object.entries(forecastFolderNameFieldMap);
    const uniqueNames = new Set();

    for (const [id, name] of newNames) {
      if (!name) {
        return { invalidId: id, error: EMPTY_FOLDER_ERROR };
      }
      const isDuplicate = existingFolders.some(
        (folder) =>
          folder.name === name &&
          folder.parentId ===
            temporaryForecastFolderList.find((f) => f.temporaryId === id)?.parentId
      );
      if (isDuplicate) {
        return {
          invalidId: id,
          error: DUPLICATE_FOLDER_ERROR
        };
      }
      if (uniqueNames.has(name)) {
        return {
          invalidId: id,
          error: DUPLICATE_FOLDER_ERROR
        };
      }
      uniqueNames.add(name);
    }

    return { invalidId: null, error: null };
  }

  async function mutate() {
    // Re-validate the new folder names
    const { invalidId, error } = validateUniqueNames();

    const hasError = !!error;

    forecastDispatch({
      payload: {
        nameChangeErrorId: hasError ? invalidId : null,
        isAddingFolderList: hasError ? false : true,
        nameChangeError: hasError ? error : null
      }
    });

    if (hasError) return;

    // Creation of new folders
    try {
      for (const folder of temporaryForecastFolderList) {
        const folderName = forecastFolderNameFieldMap[folder.temporaryId] || folder.title;

        const input = {
          projectId: project?.projectId,
          folderId: folder.folderId,
          parentId: folder.parentId,
          name: folderName,
          type: type
        };

        // Add
        await updateFolderName({
          variables: {
            input
          }
        });
      }

      await refetch({
        req: {
          projectId: project?.projectId,
          type: type
        }
      });
    } catch (error) {
      forecastDispatch({
        payload: { nameChangeErrorId: null, error: "Unable to create folders." }
      });
    }
  }

  async function addTemporaryFolder() {
    const folderName = getNewFolderName([
      ...forecastFolders,
      ...temporaryForecastFolderList
    ]);

    const folderItem: ForecastFolderItem = {
      // Add well list here
      folderId: null,
      projectId: project?.projectId,
      forecasts: null,
      name: folderName,
      folderName: folderName,
      reserveCategory: "",
      parentId: "",
      temporaryId: folderName
    };

    const folder = new ForecastFolder(folderItem);
    folder.isEdit = true;
    folder.editLocation = location;

    const temporaryForecastFolderListCopy = [...temporaryForecastFolderList, folder];

    forecastDispatch({
      payload: {
        forecastFolderNameFieldMap: {
          ...forecastFolderNameFieldMap,
          [folderName]: folderName
        },
        temporaryForecastFolderList: temporaryForecastFolderListCopy
      }
    });
  }

  function setNameFieldMap(id: string, folderName: string) {
    forecastDispatch({
      payload: {
        forecastFolderNameFieldMap: { ...forecastFolderNameFieldMap, [id]: folderName }
      }
    });
  }

  return {
    mutate,
    addTemporaryFolder,
    setNameFieldMap
  };
}
