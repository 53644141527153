import { useQuery } from "react-query";

import axios from "axios";

import { RasterAttributes } from "models/model";

export interface Shapefile {
  boundingBox?: number[];
  featureCollection: GeoJSON.FeatureCollection;
  fileType?: string;
  layerName: string;
  opacity: string;
  projectShapefileId: string;
  rasterAttributes?: RasterAttributes;
  shapefileId: string;
  key?: string;
  source?: string;
}

export const SHAPEFILE_LIST_QUERY_KEYS = {
  currentProjectShapefiles: "currentShapefileList"
};

export type UseShapefileListQueryKeysT = keyof typeof SHAPEFILE_LIST_QUERY_KEYS;

export interface UseShapefileListQueryParams {
  projectId: string;
  shapefileIds: string[];
  projectLinkedShapefileIds: string[];
  onSuccess?: (data) => void;
  onError?: (error) => void;
  queryKey?: UseShapefileListQueryKeysT;
}

export interface UseShapefilesQueryReturn {
  data: Shapefile[];
  error;
  isError: boolean;
  isIdle: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

const fetchShapefileList = async ({ queryKey }) => {
  const [, projectId, shapefileIds, projectLinkedShapefileIds] = queryKey;
  const response = axios.post(
    `${process.env.REACT_APP_PROJECT_SERVICE}/shapefiles/geojson`,
    {
      projectId: projectId,
      shapefileIds: shapefileIds,
      projectShapefileIds: projectLinkedShapefileIds
    }
  );

  return response;
};

/**
 * TODO: rename
 * Used to retrieve the geo properties of the shapefiles that the map uses
 * @param params
 * @returns
 */
export function useShapefileListQuery(
  params: UseShapefileListQueryParams
): UseShapefilesQueryReturn {
  const {
    onError,
    onSuccess,
    projectId,
    shapefileIds,
    queryKey,
    projectLinkedShapefileIds: projectLinkedShapefileIds
  } = params;

  const queryInfo = useQuery({
    queryKey: queryKey || [
      SHAPEFILE_LIST_QUERY_KEYS.currentProjectShapefiles,
      projectId,
      shapefileIds,
      projectLinkedShapefileIds
    ],
    queryFn: fetchShapefileList,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      data?.data?.forEach((fc) => {
        fc.featureCollection.features.forEach((feat) => {
          Object.keys(feat.properties).forEach((key) => {
            if (feat.properties[key]) {
              //convert all properties to string because sometimes the
              //numbers can be too big for mapbox's geobuf
              //see https://github.com/mapbox/geobuf/issues/87
              feat.properties[key] = feat.properties[key]?.toString();
            }
          });
        });
      });

      onSuccess && onSuccess(data);
    },
    // Enable automatic refreshing after projectId and shapefileIds have been defined
    enabled:
      !!params.projectId &&
      (!!params.shapefileIds?.length || !!params.projectLinkedShapefileIds?.length)
    // Disabling stale refetches to avoid lag if the user has a lot of shapefiles checked
    // staleTime: Infinity
  });

  return {
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isFetching: queryInfo.isFetching,
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch,
    data: queryInfo.data?.data
  };
}
