import React, { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import { ForecastWell, WellHierarchy } from "../models/forecastWell";
import { ForecastHierarchy } from "../models/hierarchy";

export interface ForecastingContextState {
  selectedHierarchy?: ForecastHierarchy;
  zoomToWell: { zoom: boolean; xMiles: number };
  nearbyWellDistance?: number;
  hierarchies?: ForecastHierarchy[];
  isLoadingWellHierarchy?: boolean;
  wellHierarchy?: { hierarchy: WellHierarchy[]; leafs: ForecastWell[] };
  checkedHierarchyWells?: string[];
}

type SetHierarchy = { type: "SET_HIERARCHY"; payload: ForecastHierarchy };
type SetHierarchyLoading = { type: "SET_WELL_HIERARCHY_LOADING"; payload: boolean };
type SetZoomToWell = {
  type: "SET_ZOOM_TO_WELL";
  payload: { zoom: boolean; xMiles: number };
};
type SetWellHierarchy = {
  type: "SET_WELL_HIERARCHY";
  payload: { hierarchy: WellHierarchy[]; leafs: ForecastWell[] };
};
type SetHierarchies = { type: "SET_HIERARCHIES"; payload: ForecastHierarchy[] };
type SetCheckedHierarchyWells = {
  type: "SET_CHECKED_HIERARCHY_WELLS";
  payload: string[];
};

export type ForecastingActions =
  | SetHierarchy
  | SetZoomToWell
  | SetWellHierarchy
  | SetHierarchies
  | SetHierarchyLoading
  | SetCheckedHierarchyWells;

// Reducer function to handle state updates
function reducer(state: ForecastingContextState, action: ForecastingActions) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_HIERARCHY":
        draft.selectedHierarchy = action.payload;
        break;
      case "SET_ZOOM_TO_WELL":
        draft.zoomToWell = action.payload;
        break;
      case "SET_WELL_HIERARCHY":
        draft.wellHierarchy = action.payload;
        break;
      case "SET_HIERARCHIES":
        draft.hierarchies = action.payload;
        break;
      case "SET_WELL_HIERARCHY_LOADING":
        draft.isLoadingWellHierarchy = action.payload;
        break;
      case "SET_CHECKED_HIERARCHY_WELLS":
        draft.checkedHierarchyWells = action.payload;
        break;
      default:
        break;
    }
  });
}

// Create the context with a default value
export const ForecastingContext = createContext<
  | {
      state: ForecastingContextState;
      dispatch: React.Dispatch<ForecastingActions>;
    }
  | undefined
>(undefined);

// Create a provider component
export const ForecastingProvider: React.FC<{
  children: ReactNode;
  initialState?: ForecastingContextState;
}> = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState ?? {
      zoomToWell: { zoom: false, xMiles: 3.0 }
    }
  );

  return (
    <ForecastingContext.Provider value={{ state, dispatch }}>
      {children}
    </ForecastingContext.Provider>
  );
};
