import { Select } from "antd";
import { SUPPORTED_FONT_FAMILIES, THEME_FONT_FAMILY } from "constants/style.constants";

import { ScreenshotSetting } from "components/screenshot/components";
import {
  SettingsControl,
  SettingsGroups,
  useScreenshotContext
} from "components/screenshot/hooks";

const { Option } = Select;

function useChartScreenshotSettingsControls(): SettingsControl[] {
  // hooks
  const { widget, settings } = useScreenshotContext();

  const ChartSettingsGroups = {
    title: "Chart Title",
    axis: "Chart Axis",
    legend: "Chart Legend",
    advanced: "Advanced Chart Settings"
  };

  const controls: SettingsControl[] = [];

  if (
    widget?.widgetComponentType !== "chart" &&
    widget?.widgetComponentType !== "chart beta"
  ) {
    return controls;
  }

  controls.push(
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="titleFontSize"
          name="titleFontSize"
          label="Title Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="axisFontSize"
          name="axisFontSize"
          label="Axis Title Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="axisLabelFontSize"
          name="axisLabelFontSize"
          label="Axis Labels Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="legendFontSize"
          name="legendFontSize"
          label="Legend Labels Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="legendVisible"
          name="legendVisible"
          label="Legend"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="applyAbbreviations"
          name="applyAbbreviations"
          label="Use Abbreviations"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: ChartSettingsGroups.title,
      control: (
        <ScreenshotSetting.Item key="titleText" name="titleText" label="Title">
          <ScreenshotSetting.Input type="text" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: ChartSettingsGroups.axis,
      control: (
        <ScreenshotSetting.Item
          key="showAxisTitle"
          name="showAxisTitle"
          label="Axis Title"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    }
  );

  if (settings?.showAxisTitle) {
    controls.push(
      {
        group: ChartSettingsGroups.axis,
        control: (
          <ScreenshotSetting.Item
            key="xAxisTitleText"
            name="xAxisTitleText"
            label="X-Axis Label">
            <ScreenshotSetting.Input type="text" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: ChartSettingsGroups.axis,
        control: (
          <ScreenshotSetting.Item
            key="yAxisTitleText"
            name="yAxisTitleText"
            label="Y-Axis Label">
            <ScreenshotSetting.Input type="text" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: ChartSettingsGroups.axis,
        control: (
          <ScreenshotSetting.Item
            key="axisFontWeight"
            name="axisFontWeight"
            label="Title Font Weight">
            <ScreenshotSetting.Input type="number" step={100} min={100} />
          </ScreenshotSetting.Item>
        )
      }
    );
  }

  controls.push(
    {
      group: ChartSettingsGroups.axis,
      control: (
        <ScreenshotSetting.Item
          key="showAxisLabels"
          name="showAxisLabels"
          label="Axis Labels"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: ChartSettingsGroups.axis,
      control: (
        <ScreenshotSetting.Item
          key="gridShowXLines"
          name="gridShowXLines"
          label="X-Axis Major Lines"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: ChartSettingsGroups.axis,
      control: (
        <ScreenshotSetting.Item
          key="gridShowXMinorLines"
          name="gridShowXMinorLines"
          label="X-Axis Minor Lines"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: ChartSettingsGroups.axis,
      control: (
        <ScreenshotSetting.Item
          key="gridShowYLines"
          name="gridShowYLines"
          label="Y-Axis Major Lines"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: ChartSettingsGroups.axis,
      control: (
        <ScreenshotSetting.Item
          key="gridShowYMinorLines"
          name="gridShowYMinorLines"
          label="Y-Axis Minor Lines"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    }
  );

  if (settings?.legendVisible) {
    controls.push(
      // {
      //   group: ChartSettingsGroups.legend,
      //   control: (
      //     <ScreenshotSetting.Item
      //       key="legendTitle"
      //       name="legendTitle"
      //       label="Title"
      //     >
      //       <ScreenshotSetting.Input type="text" />
      //     </ScreenshotSetting.Item>
      //   ),
      // },
      {
        group: ChartSettingsGroups.legend,
        control: (
          <ScreenshotSetting.Item
            key="legendShowCounts"
            name="legendShowCounts"
            label="Show Count"
            valuePropName="checked">
            <ScreenshotSetting.Input type="boolean" />
          </ScreenshotSetting.Item>
        )
      },
      // {
      //   group: ChartSettingsGroups.legend,
      //   control: (
      //     <ScreenshotSetting.Item
      //       key="legendWidth"
      //       name="legendWidth"
      //       label="Width"
      //       calculatedValue={250}
      //     >
      //       <ScreenshotSetting.Input type="number" min={0} unit="px" />
      //     </ScreenshotSetting.Item>
      //   ),
      // },
      // {
      //   group: ChartSettingsGroups.legend,
      //   control: (
      //     <ScreenshotSetting.Item
      //       key="legendHeight"
      //       name="legendHeight"
      //       label="Height"
      //       calculatedValue={400}
      //     >
      //       <ScreenshotSetting.Input type="number" min={0} unit="px" />
      //     </ScreenshotSetting.Item>
      //   ),
      // },
      // {
      //   group: ChartSettingsGroups.legend,
      //   control: (
      //     <ScreenshotSetting.Item
      //       key="legendPositionX"
      //       name="legendPositionX"
      //       label="X Position"
      //       calculatedValue={10}
      //     >
      //       <ScreenshotSetting.Input type="number" min={0} />
      //     </ScreenshotSetting.Item>
      //   ),
      // },
      // {
      //   group: ChartSettingsGroups.legend,
      //   control: (
      //     <ScreenshotSetting.Item
      //       key="legendPositionY"
      //       name="legendPositionY"
      //       label="Y Position"
      //       calculatedValue={10}
      //     >
      //       <ScreenshotSetting.Input type="number" min={0} />
      //     </ScreenshotSetting.Item>
      //   ),
      // },
      {
        group: ChartSettingsGroups.legend,
        control: (
          <ScreenshotSetting.Item
            key="legendBorderVisible"
            name="legendBorderVisible"
            label="Legend Border"
            valuePropName="checked">
            <ScreenshotSetting.Input type="boolean" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: ChartSettingsGroups.legend,
        control: (
          <ScreenshotSetting.Item
            key="legendOpacity"
            name="legendOpacity"
            label="Opacity">
            <ScreenshotSetting.Input type="number" step={0.1} min={0} max={1} />
          </ScreenshotSetting.Item>
        )
      }
    );
  }

  controls.push({
    group: ChartSettingsGroups.advanced,
    control: (
      <ScreenshotSetting.Item
        key="fontFamily"
        name="fontFamily"
        label="Font Family"
        valuePropName="value">
        <Select defaultValue={THEME_FONT_FAMILY}>
          {SUPPORTED_FONT_FAMILIES.map((option) => (
            <Option key={option.value} value={option.value} label={option.label}>
              <span style={{ fontFamily: option.value }}>{option.label}</span>
            </Option>
          ))}
        </Select>
      </ScreenshotSetting.Item>
    )
  });

  return controls;
}

export default useChartScreenshotSettingsControls;
