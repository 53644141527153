import styled from "styled-components/macro";

interface IXdaOptionsWrapper {
  overflowY?: string;
  paddingRight?: string;
  width?: string;
  cardBodyPadding?: string;
}

export const XdaOptionsWrapper = styled.div<IXdaOptionsWrapper>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  overflow-y: ${(props) => props.overflowY || "auto"};
  /* Specifically fixes that toggles clicked state border from being cut off */
  padding: 2px;
  ${(props) => props.paddingRight && `padding-right: ${props.paddingRight};`}
  width: ${(props) => props.width || "400px"};

  .ant-card-body {
    padding: ${(props) => props.cardBodyPadding || "5px 10px 5px 5px"};
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const XdaOptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;

  .ant-slider {
    width: 80px;
  }

  .ant-input-number {
    max-width: 130px;
    width: 130px;
  }

  .ant-select {
    width: 130px;
  }

  .ant-btn {
    border-radius: 5px;
  }

  .ant-btn-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
`;

export const XdaOptionLabel = styled.label`
  min-width: 120px;
`;
