import { FORECASTER_ORG_ID } from "constants/app.constants";

import { useUser } from "hooks";

const useIsUserForecaster = (): boolean => {
  const user = useUser();

  return user?.user.organization.id === FORECASTER_ORG_ID;
};

export default useIsUserForecaster;
