import { ArpSegment, ForecastConstant } from "models/UserArpsModel";

// export type ForecastWellStatus = "working" | "pending" | "accepted";s

export enum ForecastWellStatus {
  Working = "working",
  Published = "published",
  Archived = "archived"
}

export interface WellHierarchy {
  hierarchyName: string;
  key: string;
  title?: string;
  children: WellHierarchy[];
  selectable?: boolean;
  leafs: ForecastWell[];
}

export interface ForecastWell {
  entityName: string;
  key: string;
  title?: string;
  parentKey?: string;
  uwi: string;
  status: ForecastWellStatus;
  created: Date;
  createdBy: string;
  selectable?: boolean;
  forecast?: {
    segments: ArpSegment[];
    arps: ArpSegment[];
    constants: ForecastConstant[];
  };
}

export interface WellHierarchyCollection {
  hierarchy: WellHierarchy[];
  leafs: ForecastWell[];
}

export interface ApplyTypeWellRequest {
  typeWell: {
    arps: ArpSegment[];
    constants: ForecastConstant[];
  };
  wells: string[];
}

export interface UpdateForecastWell {
  forecast: {
    segments: ArpSegment[];
    constants: ForecastConstant[];
    onTimeArray: OnTime[];
  };
}

export interface OnTime {
  month: string;
  value: number;
}

export interface ForecastingChangeByRecord {
  id: string;
  email: string;
  fullName: string;
}

export interface ForecastingChangeRecord {
  id: string;
  changedBy: ForecastingChangeByRecord;
  changedDate: string;
  forecastId: string;
  comment: string;
}
