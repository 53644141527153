import { useCallback } from "react";

import { ToolbarButton } from "components/base";
import MapScreenshot from "components/icons/MapScreenshot";

import { useMapContext } from "../hooks/useMapContext";
import "./Screenshot.scss";

interface ScreenshotProps {
  setScreenshotOverlayVisible: (visible: boolean) => void;
  screenshotOverlayVisible: boolean;
}

export default function ScreenshotToggle({
  setScreenshotOverlayVisible,
  screenshotOverlayVisible
}: ScreenshotProps) {
  const { mapbox } = useMapContext();

  const toggleScreenshot = useCallback(() => {
    setScreenshotOverlayVisible(!screenshotOverlayVisible);
  }, [screenshotOverlayVisible]);

  // render
  if (!mapbox) return null;

  /*
    "Key" will help react to re-render the button and correct the state of tooltip
    Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
  */
  return (
    <ToolbarButton
      active={screenshotOverlayVisible}
      icon={<MapScreenshot style={{ fontSize: "13px" }} />}
      key={"MapScreenshot" + screenshotOverlayVisible}
      onToggle={toggleScreenshot}
      overflowLabel={"Toggle Screenshot"}
      styleKey="miniMapToolbar"
      tooltipText="Toggle Screenshot"
    />
  );
}
