import classnames from "classnames";
import styled from "styled-components";

import useBetaFeatures from "hooks/useBetaFeatures";
import useUserModules from "hooks/useUserModules";

import { ThreeDViewerOptionT } from "models";

import { visibilityToggleStyles } from "components/shared/SharedStyles";
import { useUserSettingsDefaultsQuery } from "components/user/queries";

import useMouseOver from "./context/useMouseover";
import {
  ThreeDVResetToggle,
  ThreeDVSettingsToggle,
  ThreeDVTopsToggle,
  ThreeDVVerticalPortionToggle,
  ThreeDVVerticalWellToggle,
  ThreeDVWellVolumeToggle
} from "./toolbar/3dv";
import VisFullscreenToggle from "./toolbar/VisFullscreenToggle";
import VisScreenshotToggle from "./toolbar/VisScreenshotToggle";

const env = process.env.REACT_APP_ENV;

interface ThreeDVToolbarProps {
  options: ThreeDViewerOptionT;
  mouseoverRef?: React.RefObject<HTMLDivElement>;
  resetCamera: () => void;
  onFullscreenToggle: (value) => void;
}

function ThreeDVToolbar({
  options,
  mouseoverRef,
  resetCamera,
  onFullscreenToggle
}: ThreeDVToolbarProps) {
  const Components = [
    <ThreeDVVerticalWellToggle key="verticalWell" {...options} />,
    <ThreeDVWellVolumeToggle key="wellVolume" {...options} />,
    <Divider key="divider" />,
    <ThreeDVResetToggle key="reset" onToggle={resetCamera} />,
    <ThreeDVTopsToggle key="tops" {...options} />,
    <ThreeDVVerticalPortionToggle key="verticalPortion" {...options} />,
    <VisScreenshotToggle key="screenshot" onToggle={onFullscreenToggle} type="ml" />,
    <VisFullscreenToggle key="fullscreen" onToggle={onFullscreenToggle} />,
    <ThreeDVSettingsToggle key="settings" {...options} />
  ];

  const { has3dGeoModel } = useUserModules();

  const userSettingsDefaults = useUserSettingsDefaultsQuery();
  const { hasFeature } = useBetaFeatures();

  const geoModelEnabled = env === "mcdan" || has3dGeoModel;
  const userHasGeoModelEnabled =
    !hasFeature("User Feature Flags") ||
    (userSettingsDefaults?.data?.FeatureFlag?.GeoModel?.enabled ?? false);

  // don't allow vertical well toggle and well volume if no access to 3d geo model
  if (!geoModelEnabled || !userHasGeoModelEnabled) {
    Components.splice(0, 3);
  }

  const visible = useMouseOver(mouseoverRef);
  const wrapperClassnames = classnames({ visible });

  return <Wrapper className={wrapperClassnames}>{Components}</Wrapper>;
}

export default ThreeDVToolbar;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 8px;
  height: var(--chart-toolbar-height);
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 8px;
  background-color: var(--white);
  box-shadow: 0 14px 16px 0 rgb(45 53 63 / 10%),
    inset 0px 7px 8px -10px rgb(45 53 63 / 15%);
  z-index: 1201;
  padding-right: 4px;
`;

const Divider = styled.div`
  width: 1px;
  height: var(--chart-toolbar-height);
  background-color: #d9e1e2;
`;
